import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Id, Uuid } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY } from './useGetAllRules';

export type ApiResponse_Create_Rule = never;

export type ApiRequest_Create_Rule = {
  description: string;
  expected_output: string;
  location_id: Id;
  user_id?: Uuid;
};

const METHOD = 'POST';
const ENDPOINT = 'rule';

export const useCreateRule = (stateFns?: MutationStateFns<ApiResponse_Create_Rule>) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_Create_Rule) =>
      handleApiCall<ApiResponse_Create_Rule, ApiRequest_Create_Rule>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: (data, variables) => {
      enqueueSnackbar('Rule created successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY({ location_id: variables.location_id }),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to create rule', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
