import { Theme } from '@mui/material/styles';

export default function OverridesDialogTitle(theme: Theme) {
  return {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 500,
          padding: '3rem 3rem 0.5rem',
          ...theme.typography.h5,
        },
      },
    },
  };
}
