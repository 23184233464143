import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiRequest_Create_Building,
  ApiResponse_Create_Building,
  useCreateBuilding,
} from '@/api/endpoints/building/useCreateBuilding';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

import FormAddressAutocomplete from '../form/FormAddressAutocomplete';
import FormPropertyLocationSelect from '../form/FormPropertyLocationSelect';
import FormTextField from '../form/FormTextField';
import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  propertyLocationId?: number;
  onClose: () => void;
  onBuildingCreated?: (building: ApiResponse_Create_Building) => void;
}

const BuildingCreateModal: React.FC<Props> = ({
  propertyLocationId,
  onClose,
  onBuildingCreated,
}) => {
  const form = useForm<ApiRequest_Create_Building>({
    defaultValues: {
      property_location_id: propertyLocationId,
    },
  });
  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = form;

  const { selectedPropertyLocation } = usePropertyManager();
  const selectedPropertyLocationId = watch('property_location_id');

  const createBuildingMutation = useCreateBuilding({
    onSuccess: data => {
      onBuildingCreated?.(data);
      onClose();
    },
  });

  // Pre-populate address when property location changes
  useEffect(() => {
    if (
      selectedPropertyLocation?.id === selectedPropertyLocationId &&
      selectedPropertyLocation?.address_text
    ) {
      setValue('address_text', selectedPropertyLocation.address_text);
    }
  }, [selectedPropertyLocation, selectedPropertyLocationId, setValue]);

  const onSubmit = useCallback(
    (data: ApiRequest_Create_Building) => createBuildingMutation.mutateAsync(data),
    [createBuildingMutation]
  );

  return (
    <ModalWithForm
      title="Create Building"
      onClose={onClose}
      onSubmit={onSubmit}
      form={form}
      maxWidth="sm"
    >
      <FormTextField
        label="Name"
        required
        control={control}
        {...register('name', {
          required: 'Name is required',
        })}
        size="small"
        error={!!errors.name}
        helperText={errors.name?.message?.toString()}
      />

      <FormPropertyLocationSelect
        label="Property Location"
        control={control}
        required
        {...register('property_location_id', {
          required: 'Property Location is required',
        })}
        size="small"
        error={!!errors.property_location_id}
        helperText={errors.property_location_id?.message?.toString()}
      />

      <FormAddressAutocomplete
        label="Address"
        control={control}
        required
        {...register('address_text', {
          required: 'Address is required',
        })}
        size="small"
        error={!!errors.address_text}
        helperText={errors.address_text?.message?.toString()}
      />
    </ModalWithForm>
  );
};

export default BuildingCreateModal;
