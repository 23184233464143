import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY } from './useGetAllRules';

export type ApiResponse_Update_Rule = never;

export type ApiRequest_Update_Rule = {
  description: string;
  expected_output: string;
};

const METHOD = 'PUT';
const ENDPOINT = 'rule/:id';

export const useUpdateRule = (id: Id, stateFns?: MutationStateFns<ApiResponse_Update_Rule>) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_Update_Rule) =>
      handleApiCall<ApiResponse_Update_Rule, ApiRequest_Update_Rule>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: id.toString() }),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Rule updated successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY(),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to update rule', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
