import { environment } from '@env';

if (!environment.supabaseUrl) throw new Error('Missing supabaseUrl config.');

export const SELECTED_PROPERTY_LOCATION_ID_STORAGE_KEY = `terrai-${environment.stage}-selected-property-location-id`;

export const clearSelectedPropertyLocationIdFromLocalStorage = () =>
  localStorage.removeItem(SELECTED_PROPERTY_LOCATION_ID_STORAGE_KEY);

export const setSelectedPropertyLocationIdInLocalStorage = (
  selectedPropertyLocationId: number | null
) => {
  if (selectedPropertyLocationId === null) {
    clearSelectedPropertyLocationIdFromLocalStorage();
    return;
  }

  localStorage.setItem(
    SELECTED_PROPERTY_LOCATION_ID_STORAGE_KEY,
    String(selectedPropertyLocationId)
  );
};

export const getSelectedPropertyLocationIdFromLocalStorage = () =>
  localStorage.getItem(SELECTED_PROPERTY_LOCATION_ID_STORAGE_KEY)
    ? Number(localStorage.getItem(SELECTED_PROPERTY_LOCATION_ID_STORAGE_KEY))
    : null;
