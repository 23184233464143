import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiResponse_Undelete_Tenant,
  useUndeleteTenant,
} from '@/api/endpoints/tenant/useUndeleteTenant';
import { TenantModel } from '@/api/models/tenant';

import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  tenant: TenantModel;
  onClose: () => void;
  onTenantRetrieved?: (tenant: ApiResponse_Undelete_Tenant) => void;
}

const TenantRetrieveModal: React.FC<Props> = ({ tenant, onClose, onTenantRetrieved }) => {
  const form = useForm();

  const undeleteTenantMutation = useUndeleteTenant(tenant.id, {
    onSuccess: data => {
      onTenantRetrieved?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    () => undeleteTenantMutation.mutateAsync(),
    [undeleteTenantMutation]
  );

  return (
    <ModalWithForm
      form={form}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="xs"
      title="Retrieve Tenant"
      buttonColor="success"
    >
      <Typography variant="body2">
        Are you sure you want to retrieve tenant <strong>{tenant.full_name}</strong>?
      </Typography>
    </ModalWithForm>
  );
};

export default TenantRetrieveModal;
