import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { SmsSessionModel } from '@/api/models/smsSession';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY as GET_SMS_THREAD_QUERY_KEY } from './useGetSmsThread';

export type ApiResponse_RespondTo_Sms = SmsSessionModel;

export type ApiRequest_RespondTo_Sms = {
  sessionId: string;
  response: string;
};

const METHOD = 'POST';
const ENDPOINT = 'twilio/send-response';

export const useRespondToSms = (stateFns?: MutationStateFns<ApiResponse_RespondTo_Sms>) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_RespondTo_Sms) =>
      handleApiCall<ApiResponse_RespondTo_Sms, ApiRequest_RespondTo_Sms>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: GET_SMS_THREAD_QUERY_KEY(data.session_id),
        refetchType: 'all',
      });
      enqueueSnackbar('SMS sent successfully', { variant: 'success' });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to sent SMS', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
