import { Button, Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { EmailSessionModelWithEmailThread } from '@/api/models/emailSession';
import { Attachment01Icon } from '@/assets/icons/Attachment01Icon';
import { BoxIcon } from '@/assets/icons/BoxIcon';
import { Send01Icon } from '@/assets/icons/Send01Icon';
import { formatFullDateTime } from '@/utils/date';
import { getLastAiResponse, getLastUserMessage } from '@/utils/email';
import { capitalizeFirstLetter } from '@/utils/text';

interface Props {
  email: EmailSessionModelWithEmailThread;
  isSending?: boolean;
  isArchiving?: boolean;
  readOnly?: boolean;
  onAttachmentClick: (documentId: string) => void;
  onEmailAccept?: (email: EmailSessionModelWithEmailThread) => void;
  onEmailArchive?: (email: EmailSessionModelWithEmailThread) => void;
  onViewHistory?: (email: EmailSessionModelWithEmailThread) => void;
  onStoreFeedback?: (email: EmailSessionModelWithEmailThread) => void;
}

const EmailMobileCard: React.FC<Props> = ({
  email,
  isSending,
  isArchiving,
  readOnly,
  onAttachmentClick,
  onEmailAccept,
  onEmailArchive,
  onViewHistory,
  onStoreFeedback,
}) => {
  const [aiResponse, setAiResponse] = useState(getLastAiResponse(email));

  const handleSend = () => {
    if (onEmailAccept) {
      onEmailAccept({
        ...email,
        last_ai_message: aiResponse,
      });
    }
  };

  const onAiResponseChange = useCallback(
    (_aiResponse: string) => {
      setAiResponse(_aiResponse);

      if (!email.session?.id) return;

      onStoreFeedback?.({
        ...email,
        last_ai_message: _aiResponse,
      });
    },
    [email, onStoreFeedback]
  );

  return (
    <Card sx={{ mb: 2, width: '100%' }}>
      <CardContent>
        <Stack spacing={2}>
          {email.urgent && (
            <Typography variant="dataGridTitleSmall" color="error">
              URGENT
            </Typography>
          )}

          <Typography variant="body4">ID: {email.session.session_id}</Typography>

          <Stack spacing={1}>
            <Typography variant="subtitle3">
              {email.session?.from_name
                ? capitalizeFirstLetter(email.session.from_name)
                : 'Unknown'}
            </Typography>

            <Typography variant="body3">{email.session?.from_email}</Typography>

            {email.session?.tenant?.unit && (
              <Typography variant="body4">
                {email.session.tenant.unit.building?.name} - {email.session.tenant.unit.name}
              </Typography>
            )}
          </Stack>

          <Stack spacing={1}>
            <Typography variant="subtitle3">Subject:</Typography>
            <Typography variant="body3">{email.session.subject}</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="subtitle3">Message:</Typography>

            <Typography variant="body3">{getLastUserMessage(email)}</Typography>

            {!!email.session?.documents?.length && (
              <Stack direction="row" spacing={1} mt={1} flexWrap="wrap">
                {email.session.documents.map(document => (
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    startIcon={<Attachment01Icon />}
                    sx={{ textTransform: 'none' }}
                    onClick={() => onAttachmentClick(document.id)}
                  >
                    {document.original_name}
                  </Button>
                ))}
              </Stack>
            )}
          </Stack>

          <Stack spacing={1}>
            <Typography variant="subtitle3">{readOnly ? 'Response:' : 'AI Response:'}</Typography>

            {readOnly ? (
              <Typography variant="body3">{getLastAiResponse(email)}</Typography>
            ) : (
              <TextField
                multiline
                fullWidth
                minRows={3}
                size="small"
                value={aiResponse}
                onChange={e => onAiResponseChange(e.target.value)}
              />
            )}
          </Stack>

          <Typography variant="body4">
            {formatFullDateTime(new Date(email.session.created_at))}
          </Typography>

          <Stack direction="row" spacing={2}>
            {!!onEmailAccept && (
              <LoadingButton
                variant="contained"
                onClick={handleSend}
                startIcon={<Send01Icon />}
                loading={isSending}
                fullWidth
              >
                Send
              </LoadingButton>
            )}

            {!!onEmailArchive && (
              <LoadingButton
                variant="outlined"
                color="secondary"
                startIcon={<BoxIcon />}
                onClick={() => onEmailArchive(email)}
                loading={isArchiving}
                fullWidth
              >
                Archive
              </LoadingButton>
            )}
          </Stack>

          <Stack direction="row" spacing={1} mt={2}>
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={() => onViewHistory?.(email)}
              fullWidth
            >
              View History
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default EmailMobileCard;
