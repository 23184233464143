import { Card, Link } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, useTheme } from '@mui/material/styles';
import { useState, useRef } from 'react';

import Avatar from '@/@mantis/components/@extended/Avatar';
import { useDrawer } from '@/@mantis/contexts/DrawerContext';
import { ChevronSelectorVerticalIcon } from '@/assets/icons/ChevronSelectorVerticalIcon';
import { PublicRoutes, PMPRoutes, ResidentRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { getEmail, getFullName, getInitials } from '@/utils/user';

import ExpandMore from './ExpandMore';

const NavUser: React.FC = () => {
  const { isOpen: isDrawerOpen } = useDrawer();
  const { user, isResident } = useAuth();

  const anchorRef = useRef<HTMLButtonElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const theme = useTheme();

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const accountRoute = isResident ? ResidentRoutes.account : PMPRoutes.account;

  if (!isDrawerOpen)
    return (
      <>
        <Avatar
          alt="Avatar"
          ref={divRef}
          sx={{ width: 40, height: 40, cursor: 'pointer' }}
          onClick={() => setIsMenuOpen(true)}
        >
          {getInitials(user)}
        </Avatar>

        {isMenuOpen && (
          <Menu
            id="nav-user-menu"
            anchorEl={divRef.current}
            open={isMenuOpen}
            onClose={handleClose}
            onClick={handleClose}
            MenuListProps={{ 'aria-labelledby': 'nav-user-expand-more' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <MenuItem dense component={Link} href={PublicRoutes.logout}>
              Logout
            </MenuItem>

            <MenuItem dense component={Link} href={accountRoute}>
              Account
            </MenuItem>
          </Menu>
        )}
      </>
    );

  return (
    <Card
      sx={{
        p: 1.5,
        bgcolor: 'error.0',
        borderColor: alpha(theme.palette.primary.main, 0.25),
        borderWidth: 0.25,
      }}
    >
      <List disablePadding>
        <ListItem
          disablePadding
          secondaryAction={
            <ExpandMore
              ref={anchorRef}
              expand={false}
              drawerOpen={isDrawerOpen}
              onClick={() => setIsMenuOpen(true)}
              sx={{ mr: -1, mt: -1 }}
            >
              <ChevronSelectorVerticalIcon color="secondary" fontSize="small" />
            </ExpandMore>
          }
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& .MuiListItemSecondaryAction-root': {
              alignSelf: 'flex-start',
            },
          }}
        >
          <ListItemAvatar>
            <Avatar alt="Avatar" sx={{ width: 40, height: 40 }}>
              {getInitials(user)}
            </Avatar>
          </ListItemAvatar>

          <ListItemText primary={getFullName(user)} secondary={getEmail(user)} />
        </ListItem>
      </List>

      {isMenuOpen && (
        <Menu
          id="nav-user-menu"
          anchorEl={anchorRef.current}
          open={isMenuOpen}
          onClose={handleClose}
          onClick={handleClose}
          MenuListProps={{ 'aria-labelledby': 'nav-user-expand-more' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <MenuItem dense component={Link} href={PublicRoutes.logout}>
            Logout
          </MenuItem>

          <MenuItem dense component={Link} href={accountRoute}>
            Account
          </MenuItem>
        </Menu>
      )}
    </Card>
  );
};

export default NavUser;
