import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { QuestionAnswerModel } from '@/api/models/ai/questionAnswer';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_Update_QuestionAnswer = QuestionAnswerModel;

export type ApiRequest_Update_QuestionAnswer = {
  question: string;
  answer: string;
};

const METHOD = 'PUT';
const ENDPOINT = 'question/:id';

export const useUpdateQuestionAnswer = (
  id: QuestionAnswerModel['id'],
  stateFns?: MutationStateFns<ApiResponse_Update_QuestionAnswer>
) => {
  const { session } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_Update_QuestionAnswer) =>
      handleApiCall<ApiResponse_Update_QuestionAnswer, ApiRequest_Update_QuestionAnswer>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: id.toString() }),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Question & answer updated successfully', { variant: 'success' });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to update question & answer', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
