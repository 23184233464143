import { Box, Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

import BlurBackground from './BlurBackground';
import { Props as FeatureItemProps } from './pricing/FeatureItem';
import Features from './pricing/Features';

const SINGLE_HOME_FEATURES: FeatureItemProps[] = [
  {
    title: 'AI-Powered Communications',
    description:
      'Your AI assistant handles tenant inquiries 24/7, responding to messages and calls with human-like replies while you focus on what matters.',
    iconUrl: '/assets/images/undraw_messaging.svg',
  },
  {
    title: 'Automated Rent Collection',
    description:
      'Set up automatic rent reminders, process payments, and generate receipts with minimal effort. Get paid on time, every time.',
    iconUrl: '/assets/images/undraw_credit_card.svg',
  },
  {
    title: 'Maintenance Management',
    description:
      'Easily track, prioritize, and manage maintenance requests. Route tickets to the right vendors and keep tenants updated automatically.',
    iconUrl: '/assets/images/undraw_maintenance.svg',
  },
];

const MULTI_UNIT_FEATURES: FeatureItemProps[] = [
  {
    title: 'Portfolio Dashboard',
    description:
      "Get a bird's-eye view of all your properties, buildings, and units. Monitor performance metrics across your entire portfolio in one place.",
    iconUrl: '/assets/images/undraw_dashboard.svg',
  },
  {
    title: 'Document Vault',
    description:
      'Securely store and organize all property-related documents including leases, inspections, and financial records for your entire portfolio.',
    iconUrl: '/assets/images/undraw_folder.svg',
  },
  {
    title: 'Advanced Workflows',
    description:
      'Create customized workflows to automate complex property management tasks across multiple buildings and units.',
    iconUrl: '/assets/images/undraw_process.svg',
  },
];

const PricingFeaturesSection: React.FC = () => (
  <Grid2 container size={12} sx={{ position: 'relative', py: 6 }} spacing={4}>
    <BlurBackground
      sx={{
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />

    <Grid2 size={12}>
      <Stack spacing={2} sx={{ textAlign: 'center', maxWidth: 770, margin: '0 auto' }}>
        <Stack spacing={1}>
          <Box>
            <Box
              sx={{
                py: 0.5,
                px: 1.5,
                borderRadius: '16px',
                border: '1px solid #D9D9D9',
                margin: '0 auto',
                display: 'inline-flex',
                height: '30px',
                alignItems: 'center',
              }}
            >
              <Typography variant="overline">Comprehensive Tools</Typography>
            </Box>
          </Box>

          <Typography variant="h3">
            Features for <strong>Every Property Owner</strong>
          </Typography>
        </Stack>

        <Typography variant="body1">
          Our platform offers specialized features tailored to both single-home owners and
          multi-unit property managers, all included in your subscription.
        </Typography>
      </Stack>
    </Grid2>

    <Grid2 size={{ xs: 12, md: 5 }} offset={{ xs: 0, md: 1 }}>
      <Features
        title="Single Home Owners"
        image={
          <img
            src="/assets/images/undraw_house.svg"
            alt="Single Home"
            style={{ height: 120, maxWidth: '100%' }}
          />
        }
        features={SINGLE_HOME_FEATURES}
      />
    </Grid2>

    <Grid2 size={{ xs: 12, md: 5 }}>
      <Features
        title="Multi-Unit Managers"
        image={
          <img
            src="/assets/images/undraw_buildings.svg"
            alt="Multi-Unit"
            style={{ height: 120, maxWidth: '100%' }}
          />
        }
        features={MULTI_UNIT_FEATURES}
      />
    </Grid2>
  </Grid2>
);

export default PricingFeaturesSection;
