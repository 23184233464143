import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile';
import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

import { environment } from '@env';

import FormField, { FormFieldProps, getControllerProps, getFormFieldProps } from './FormField';

type Props = FormFieldProps & {
  turnstileRef: React.RefObject<TurnstileInstance | undefined>;
};

const FormCaptcha = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <FormField {...getFormFieldProps(props)} ref={ref}>
    <Controller
      {...getControllerProps(props)}
      render={({ field }) => (
        <Turnstile
          ref={props.turnstileRef}
          options={{
            size: 'flexible',
          }}
          siteKey={environment.turnstileSiteKey}
          onSuccess={token => field.onChange(token)}
        />
      )}
    />
  </FormField>
));

export default FormCaptcha;
