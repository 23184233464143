import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { BuildingModel } from '@/api/models/building';
import { SmsSessionModel } from '@/api/models/smsSession';
import { TenantModel } from '@/api/models/tenant';
import { UnitModel } from '@/api/models/unit';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_GetAll_SmsSession = {
  unit_id: Id;
};

export type ApiResponse_GetAll_SmsSession = Array<
  SmsSessionModel & {
    tenant: TenantModel & {
      unit: UnitModel & {
        building: BuildingModel;
      };
    };
  }
>;

const METHOD = 'GET';
// Different endpoints based on user role
const PM_ENDPOINT = 'sms-session/all';
const RESIDENT_ENDPOINT = 'sms-session/all/resident';

export const QUERY_KEY = (params: ApiRequest_GetAll_SmsSession, isResident: boolean) =>
  getQueryKey(METHOD, isResident ? RESIDENT_ENDPOINT : PM_ENDPOINT, params);

export const useGetAllSmsSessions = (params: ApiRequest_GetAll_SmsSession, enabled = true) => {
  const { session, isResident } = useAuth();

  // Determine which endpoint to use based on user role
  const ENDPOINT = isResident ? RESIDENT_ENDPOINT : PM_ENDPOINT;

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY(params, isResident),
    queryFn: async () => {
      try {
        const data = await handleApiCall<
          ApiResponse_GetAll_SmsSession,
          ApiRequest_GetAll_SmsSession
        >(session, METHOD, getResolvedEndpoint(ENDPOINT, {}), params);

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch sms sessions.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
