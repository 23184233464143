import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { environment } from '@env';

import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY } from './useGetVaultDocuments';

const ENDPOINT = 'vault/documents';

export type ApiRequest_Delete_VaultDocument = {
  documentId: string;
};

export const useDeleteVaultDocument = (stateFns?: MutationStateFns<string>) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ documentId }: ApiRequest_Delete_VaultDocument) => {
      await axios.delete(`${environment.api}/${ENDPOINT}/${documentId}`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });
      return documentId;
    },
    onSuccess: documentId => {
      enqueueSnackbar('Document deleted successfully.', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: QUERY_KEY() });
      stateFns?.onSuccess?.(documentId);
    },
    onError: (error: AxiosError) => {
      stateFns?.onError?.(error);
    },
  });
};
