import { Session } from '@supabase/supabase-js';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';

const METHOD = 'POST';
const ENDPOINT = 'auth/resident-login';
export const QUERY_KEY = () => getQueryKey(METHOD, ENDPOINT, {});

export interface ResidentLoginResponse {
  access_token: string;
}

export const useResidentLoginMutation = (session: Session | null) =>
  useMutation({
    mutationKey: QUERY_KEY(),
    mutationFn: async () => {
      if (!session?.access_token) {
        throw new Error('No authorization token available');
      }

      try {
        const data = await handleApiCall<ResidentLoginResponse>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {})
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to login.', { variant: 'error' });
        throw err;
      }
    },
  });
