import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { forwardRef } from 'react';
import { Control } from 'react-hook-form';

import { HelpCircleIcon } from '@/assets/icons/HelpCircleIcon';

interface Props {
  disabled?: boolean;
  children: React.ReactNode;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  label?: string;
  name?: string;
  sx?: SxProps<Theme>;
  tooltipText?: string;
  size?: 'small' | 'medium';
  required?: boolean;
}

export interface FormFieldProps extends Omit<Props, 'children' | 'name'> {
  color?: 'primary' | 'secondary';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  name: string;
  size?: 'small' | 'medium';
}

export const getFormFieldProps = ({
  disabled,
  error,
  helperText,
  fullWidth = true,
  label,
  name,
  tooltipText,
  required,
  size,
  sx,
}: FormFieldProps) => ({
  disabled,
  error,
  helperText,
  fullWidth,
  label,
  name,
  tooltipText,
  required,
  size,
  sx,
});

export const getControllerProps = ({ control, defaultValue, name }: FormFieldProps) => ({
  control,
  defaultValue,
  name,
});

const FormField = forwardRef<HTMLDivElement, Props>(
  (
    {
      disabled,
      children,
      helperText,
      name,
      label,
      error,
      fullWidth = true,
      tooltipText,
      required,
      size,
      sx,
    },
    ref
  ) => {
    const theme = useTheme();
    return (
      <FormControl fullWidth={fullWidth} disabled={disabled} sx={sx} ref={ref}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {!!label && (
            <FormLabel
              htmlFor={name}
              sx={size === 'small' ? theme.typography.body3 : theme.typography.h5}
            >
              {label}
              {required && (
                <Typography
                  component="span"
                  color="error"
                  sx={{ fontSize: 'inherit', ml: '0.2em' }}
                >
                  *
                </Typography>
              )}
            </FormLabel>
          )}

          {!!tooltipText && (
            <Tooltip
              placement="top-start"
              arrow
              title={
                <Stack>
                  {!!label && <Typography variant="h5">{label}</Typography>}

                  <Typography variant="body3">{tooltipText}</Typography>
                </Stack>
              }
              sx={{ ml: 'auto', cursor: 'pointer' }}
            >
              <Box sx={{ cursor: 'pointer' }}>
                <HelpCircleIcon color="info" fontSize="small" />
              </Box>
            </Tooltip>
          )}
        </Stack>

        {children}

        {!!helperText && (
          <FormHelperText error={error} sx={{ mt: 0.75 }}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default FormField;
