import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { Payment } from '@/types/payment';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

const METHOD = 'GET';
const ENDPOINT = 'payments';

export type ApiRequest_GetAll_Payments = {
  tenant_id?: string;
};

export type ApiResponse_GetAll_Payments = Payment[];

export const QUERY_KEY = (params?: ApiRequest_GetAll_Payments) =>
  getQueryKey(METHOD, ENDPOINT, params);

export const useGetPayments = (params?: ApiRequest_GetAll_Payments, enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    queryKey: QUERY_KEY(params),
    enabled: enabled && !!session?.access_token,
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetAll_Payments, ApiRequest_GetAll_Payments>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {}),
          params
        );

        return data;
      } catch (error) {
        enqueueSnackbar('Failed to fetch payments.', { variant: 'error' });
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      }
    },
  });
};
