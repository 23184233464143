import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import {
  PropertyLocationModel,
  PropertyLocationWithEmailAndPhoneModel,
} from '@/api/models/propertyLocation';
import { PropertyManagementModel } from '@/api/models/propertyManagement';
import { PropertyManagerModel } from '@/api/models/propertyManager';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY as GET_ALL_QUERY_KEY } from './useGetAllPropertyLocations';
import { QUERY_KEY as GET_ONE_QUERY_KEY } from './useGetOnePropertyLocation';

export type ApiResponse_GenerateEmailAddress_PropertyLocation =
  PropertyLocationWithEmailAndPhoneModel & {
    management: Array<PropertyManagementModel & { manager: PropertyManagerModel }>;
  };

const METHOD = 'POST';
const ENDPOINT = 'property-location/:id/email-address';

export const useGenerateEmailAddress = (
  id: PropertyLocationModel['id'],
  stateFns?: MutationStateFns<ApiResponse_GenerateEmailAddress_PropertyLocation>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      handleApiCall<ApiResponse_GenerateEmailAddress_PropertyLocation>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: id.toString() })
      ),
    onSuccess: data => {
      enqueueSnackbar('Property location email address generated successfully', {
        variant: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: GET_ALL_QUERY_KEY,
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: GET_ONE_QUERY_KEY(id),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to generate property location email address', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
