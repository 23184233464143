import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiRequest_Update_Rule,
  ApiResponse_Update_Rule,
  useUpdateRule,
} from '@/api/endpoints/rule/useUpdateRule';
import { Id } from '@/api/utils/sql';

import FormTextField from '../form/FormTextField';
import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  rule: ApiRequest_Update_Rule & { id: Id };
  onClose: () => void;
  onRuleUpdated?: (rule: ApiResponse_Update_Rule) => void;
}

const RuleUpdateModal: React.FC<Props> = ({ onClose, onRuleUpdated, rule }) => {
  const form = useForm<ApiRequest_Update_Rule>({
    defaultValues: {
      description: rule.description,
      expected_output: rule.expected_output,
    },
  });
  const {
    register,
    control,
    formState: { errors },
  } = form;

  const updateRuleMutation = useUpdateRule(rule.id, {
    onSuccess: data => {
      onRuleUpdated?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_Update_Rule) => updateRuleMutation.mutateAsync(data),
    [updateRuleMutation]
  );

  return (
    <ModalWithForm
      title="Edit Rule"
      onClose={onClose}
      onSubmit={onSubmit}
      form={form}
      maxWidth="md"
    >
      <FormTextField
        multiline
        rows={3}
        label="Description"
        control={control}
        {...register('description', {
          required: 'Description is required',
        })}
        placeholder="Enter rule description"
        size="small"
        error={!!errors.description}
        helperText={errors.description?.message?.toString()}
      />

      <FormTextField
        multiline
        rows={3}
        control={control}
        {...register('expected_output', {
          required: 'Expected output is required',
        })}
        label="Expected Output"
        placeholder="Enter expected output"
        size="small"
        error={!!errors.expected_output}
        helperText={errors.expected_output?.message?.toString()}
      />
    </ModalWithForm>
  );
};

export default RuleUpdateModal;
