import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { CalendarEvent } from '@/types/calendar';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_GetAll_CalendarEvents = {
  property_location_id: Id;
};

export type ApiResponse_GetAll_CalendarEvents = Array<CalendarEvent>;

const METHOD = 'GET';
const ENDPOINT = 'calendar-events';
export const QUERY_KEY = (params?: ApiRequest_GetAll_CalendarEvents) =>
  getQueryKey(METHOD, ENDPOINT, params);

export const useGetAllCalendarEvents = (
  params: ApiRequest_GetAll_CalendarEvents,
  enabled = true
) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token && !!params.property_location_id,
    queryKey: QUERY_KEY(params),
    queryFn: async () => {
      try {
        const data = await handleApiCall<
          ApiResponse_GetAll_CalendarEvents,
          ApiRequest_GetAll_CalendarEvents
        >(session, METHOD, getResolvedEndpoint(`${ENDPOINT}/all`, {}), params);

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch calendar events.', { variant: 'error' });
        throw err;
      }
    },
  });
};
