export default function OverridesLoadingButton() {
  return {
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '&.MuiLoadingButton-loading': {
            opacity: 0.6,
            textShadow: 'none',
          },

          '&.MuiButton-sizeSmall .MuiLoadingButton-label .MuiSvgIcon-root': {
            fontSize: '16px',
          },
        },
      },
    },
  };
}
