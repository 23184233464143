import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import {
  PropertyLocationModel,
  PropertyLocationWithEmailAndPhoneModel,
} from '@/api/models/propertyLocation';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_GetOne_PropertyLocation = PropertyLocationWithEmailAndPhoneModel;

const METHOD = 'GET';
const ENDPOINT = 'property-location/:id';
export const QUERY_KEY = (id: PropertyLocationModel['id']) => getQueryKey(METHOD, ENDPOINT, { id });

export const useGetOnePropertyLocation = (id: PropertyLocationModel['id'], enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY(id),
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetOne_PropertyLocation>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {
            id: id.toString(),
          })
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch property location.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
