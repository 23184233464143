import { Session } from '@supabase/supabase-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import rocketLawyerService, {
  RocketLawyerLeaseGenerationParams,
  RocketLawyerSession,
} from '@/api/services/rocketLawyerService';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

// Init Lease Generation
const INIT_METHOD = 'POST';
const INIT_ENDPOINT = 'rocket-lawyer/lease-generation';
export const INIT_QUERY_KEY = () => getQueryKey(INIT_METHOD, INIT_ENDPOINT);

/**
 * Hook for initializing lease generation with RocketLawyer
 */
export const useInitLeaseGeneration = () => {
  const { session } = useAuth();

  return useMutation<RocketLawyerSession, Error, RocketLawyerLeaseGenerationParams>({
    mutationKey: INIT_QUERY_KEY(),
    mutationFn: async (params: RocketLawyerLeaseGenerationParams) => {
      try {
        const data = await handleApiCall<RocketLawyerSession, RocketLawyerLeaseGenerationParams>(
          session as Session,
          INIT_METHOD,
          INIT_ENDPOINT,
          params
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to initialize lease generation.', { variant: 'error' });
        throw err;
      }
    },
  });
};

// Get Existing Interview
const GET_INTERVIEW_METHOD = 'GET';
const GET_INTERVIEW_ENDPOINT = 'rocket-lawyer/interview';
export const GET_INTERVIEW_QUERY_KEY = (interviewId: string) =>
  getQueryKey(GET_INTERVIEW_METHOD, `${GET_INTERVIEW_ENDPOINT}/${interviewId}`);

/**
 * Hook for retrieving an existing interview by ID
 * @param interviewId - ID of the interview to retrieve
 */
export const useGetExistingInterview = (interviewId: string | undefined) => {
  const { session } = useAuth();

  return useQuery<RocketLawyerSession, Error>({
    queryKey: GET_INTERVIEW_QUERY_KEY(interviewId || ''),
    queryFn: async () => {
      if (!interviewId || !session) {
        throw new Error('Interview ID and session are required');
      }

      try {
        return await rocketLawyerService.getExistingInterview(session, interviewId);
      } catch (err) {
        enqueueSnackbar('Failed to retrieve existing interview.', { variant: 'error' });
        throw err;
      }
    },
    enabled: !!interviewId && !!session,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};

// Complete Lease Generation
const COMPLETE_METHOD = 'POST';
const COMPLETE_ENDPOINT = 'rocket-lawyer/complete-lease';
export const COMPLETE_QUERY_KEY = () => getQueryKey(COMPLETE_METHOD, COMPLETE_ENDPOINT);

/**
 * Hook for completing lease generation with RocketLawyer
 */
export const useCompleteLeaseGeneration = () => {
  const { session } = useAuth();

  return useMutation<void, Error, string>({
    mutationKey: COMPLETE_QUERY_KEY(),
    mutationFn: async (interviewId: string) => {
      try {
        await handleApiCall<void, { interviewId: string }>(
          session as Session,
          COMPLETE_METHOD,
          COMPLETE_ENDPOINT,
          { interviewId }
        );
      } catch (err) {
        enqueueSnackbar('Failed to complete lease generation.', { variant: 'error' });
        throw err;
      }
    },
  });
};
