import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { PropertyManagementRole } from '@/api/enums';
import { PropertyManagerModel } from '@/api/models/propertyManager';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY as QUERY_KEY_GET_ALL } from './useGetAllPropertyManagers';

export type ApiResponse_CreateWithUser_PropertyManager = PropertyManagerModel;

export type ApiRequest_CreateWithUser_PropertyManager = {
  fullName: string;
  email: string;
  phone?: string;
  role: keyof typeof PropertyManagementRole;
  locationId: Id;
};

const METHOD = 'POST';
const ENDPOINT = 'property-manager/with-user';

export const useCreateUserWithPropertyManage = (
  stateFns?: MutationStateFns<ApiResponse_CreateWithUser_PropertyManager>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_CreateWithUser_PropertyManager) =>
      handleApiCall<
        ApiResponse_CreateWithUser_PropertyManager,
        ApiRequest_CreateWithUser_PropertyManager
      >(session, METHOD, getResolvedEndpoint(ENDPOINT, {}), data),
    onSuccess: (data, variables) => {
      enqueueSnackbar('Property manager created successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_GET_ALL({ location_id: variables.locationId }),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to create property manager', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
