import { Theme } from '@mui/material/styles';

export default function OverridesFormGroup(theme: Theme) {
  return {
    MuiFormGroup: {
      styleOverrides: {
        root: {
          gap: '1.25rem',

          '& .MuiFormControlLabel-root': {
            padding: '1rem 0.75rem',
            margin: 0,
            borderRadius: '0.5rem',
            border: '1px solid',
            borderColor: theme.palette.divider,
          },

          '& + .MuiFormHelperText-root': {
            marginTop: '0.5rem !important',
          },
        },
      },
    },
  };
}
