import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';

import IconButton from '@/@mantis/components/@extended/IconButton';
import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { XCloseIcon } from '@/assets/icons/XCloseIcon';

import { ConfirmActionModal } from '../confirm/ConfirmActionModal';
import LoadingState from '../LoadingState';

type Props = PropsWithChildren<{
  buttonColor?: 'primary' | 'error' | 'success';
  buttonLabel?: string;
  buttonStartIcon?: React.ReactNode;
  confirmActionProps?: {
    title?: string;
    children: React.ReactNode;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
  isDataLoading?: boolean;
  maxWidth: Required<DialogProps>['maxWidth'];
  title: string;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: any) => Promise<any>;
}>;

const THROTTLE_DELAY = 100;

const ModalWithForm: React.FC<Props> = React.memo(
  ({
    children,
    form,
    maxWidth,
    title,
    buttonColor = 'primary',
    buttonLabel = title,
    buttonStartIcon,
    confirmActionProps,
    isDataLoading = false,
    onClose,
    onSubmit,
  }) => {
    const formRef = useRef<HTMLFormElement>(null);
    const submitButtonRef = useRef<HTMLButtonElement>(null);
    const isConfirmed = useRef(false);
    const lastSubmitTime = useRef(0);

    const [isConfirmActionModalOpen, setIsConfirmActionModalOpen] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [formError, setFormError] = useState<any | null>(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOnSubmit: SubmitHandler<any> = useCallback(
      async data => {
        const currentTime = Date.now();
        if (currentTime - lastSubmitTime.current < THROTTLE_DELAY) return;
        lastSubmitTime.current = currentTime;

        try {
          setFormError(null);

          if (!isConfirmed.current && confirmActionProps) return setIsConfirmActionModalOpen(true);

          const response = await onSubmit?.(data);
          return response;
        } catch (err) {
          setFormError(err);
        }
      },
      [confirmActionProps, onSubmit]
    );

    return (
      <Dialog
        component="form"
        maxWidth={maxWidth}
        open
        onClose={form.formState.isSubmitting ? undefined : onClose}
        onSubmit={form.handleSubmit(handleOnSubmit)}
        // @ts-ignore
        ref={formRef}
        fullWidth
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {title}

            <IconButton
              onClick={onClose}
              aria-label="close"
              color="secondary"
              disabled={form.formState.isSubmitting}
            >
              <XCloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ position: 'relative', overflow: isDataLoading ? 'hidden' : 'auto' }}>
          <Stack spacing={3}>
            {children}

            {!!formError && (
              <Typography color="error" variant="body3">
                {formError?.response?.data?.message ?? formError.message}
              </Typography>
            )}
          </Stack>

          {isDataLoading && (
            <Stack
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'grey.100',
              }}
            >
              <LoadingState />
            </Stack>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => onClose()}
            color="secondary"
            variant="outlined"
            disabled={form.formState.isSubmitting}
          >
            Cancel
          </Button>

          <LoadingButton
            ref={submitButtonRef}
            type="submit"
            variant="contained"
            color={buttonColor}
            loading={form.formState.isSubmitting}
            disabled={isDataLoading || form.formState.isSubmitting}
            startIcon={buttonStartIcon}
          >
            {buttonLabel}
          </LoadingButton>
        </DialogActions>

        {confirmActionProps && isConfirmActionModalOpen && (
          <ConfirmActionModal
            onReject={() => setIsConfirmActionModalOpen(false)}
            onConfirm={() => {
              isConfirmed.current = true;
              setIsConfirmActionModalOpen(false);
              formRef.current?.requestSubmit(submitButtonRef.current);
            }}
            title={confirmActionProps.title}
          >
            {confirmActionProps.children}
          </ConfirmActionModal>
        )}
      </Dialog>
    );
  }
);

export default ModalWithForm;
