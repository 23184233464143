import { Box } from '@mui/material';

import patternImage from '@/assets/bg/lines-pattern.svg';
import backgroundImage from '@/assets/bg/mockup-full.png';

const AuthSideContent: React.FC = () => (
  <Box
    sx={{
      height: '100%',
      width: '100%',
      py: 16,
      pl: 8,
      bgcolor: 'grey.100',
      position: 'relative',
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${patternImage})`,
        backgroundSize: 'auto auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left bottom',
      }}
    />

    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${patternImage})`,
        backgroundSize: 'auto auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top',
      }}
    />

    <Box
      sx={{
        height: '100%',
        width: '100%',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center',
        position: 'relative',
      }}
    />
  </Box>
);

export default AuthSideContent;
