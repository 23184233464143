import { Button, Stack, Typography } from '@mui/material';

import { PMPRoutes } from '@/config/routes';

import SplitMuiPage from '../common/SplitMuiPage';

const OnboardingTenantsPage: React.FC = () => (
  <SplitMuiPage>
    <Stack spacing={3} width="100%">
      <Stack spacing={1.5} mb={1}>
        <Typography variant="h3">Tenant Upload</Typography>

        <Typography variant="body2">
          Tenant Upload is coming soon. Add tenants manually in Unit page through your Properties.
        </Typography>

        <Button variant="contained" color="primary" href={PMPRoutes.propertyLocationList}>
          My Properties
        </Button>
      </Stack>
    </Stack>
  </SplitMuiPage>
);

export default OnboardingTenantsPage;
