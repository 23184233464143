import { Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { ApiResponse_Delete_Rule, useDeleteRule } from '@/api/endpoints/rule/useDeleteRule';
import { ApiResponse_GetAll_Rule } from '@/api/endpoints/rule/useGetAllRules';

import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  onClose: () => void;
  onRuleDeleted?: (data: ApiResponse_Delete_Rule) => void;
  rule: ApiResponse_GetAll_Rule[0];
}

const RuleDeleteModal: React.FC<Props> = ({ onClose, onRuleDeleted, rule }) => {
  const form = useForm();

  const deleteRuleMutation = useDeleteRule(rule.id, {
    onSuccess: data => {
      onRuleDeleted?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(() => deleteRuleMutation.mutateAsync(), [deleteRuleMutation]);

  return (
    <ModalWithForm
      title="Delete Rule"
      onClose={onClose}
      onSubmit={onSubmit}
      form={form}
      maxWidth="md"
      buttonColor="error"
    >
      <Typography variant="body2">Are you sure you want to delete this rule?</Typography>

      <Stack spacing={1}>
        <Typography variant="subtitle3">Rule Description:</Typography>
        <Typography variant="body3">{rule.description}</Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="subtitle3">Expected Output:</Typography>
        <Typography variant="body3">{rule.expected_output}</Typography>
      </Stack>

      <Typography variant="subtitle3">This action cannot be undone.</Typography>
    </ModalWithForm>
  );
};

export default RuleDeleteModal;
