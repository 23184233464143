import { useQueryClient } from '@tanstack/react-query';

import { QUERY_KEY as GET_SUBSCRIPTION_QUERY_KEY } from './useGetSubscription';

/**
 * Hook to handle Paddle checkout completion
 * @returns Functions to handle checkout completion and redirect user
 */
export const usePaddleCheckout = () => {
  const queryClient = useQueryClient();

  /**
   * Handle successful checkout completion
   * Redirects user to success page and invalidates subscription query
   */
  const handleCheckoutCompleted = () => {
    // Redirect to success page
    window.location.href = '/property-manager/subscription?success=true';
    
    // Update subscription data after a small delay to allow webhook processing
    setTimeout(() => {
      queryClient.invalidateQueries({
        queryKey: GET_SUBSCRIPTION_QUERY_KEY(),
      });
    }, 2000);
  };

  return {
    handleCheckoutCompleted,
  };
};
