import { Button, IconButton, Stack } from '@mui/material';
import { useFieldArray, useForm, UseFormReturn, FieldArrayWithId } from 'react-hook-form';

import { ClausesFormData } from '@/types/lease';

import { MinusIcon } from '@/assets/icons/MinusIcon';
import { PlusIcon } from '@/assets/icons/PlusIcon';

import FormTextField from '@/components/form/FormTextField';
import ModalWithForm from '@/components/modals/ModalWithForm';

interface Props {
  initialData?: ClausesFormData;
  onSave: (data: ClausesFormData) => Promise<void>;
  onClose: () => void;
  disabled?: boolean;
}

interface CustomClause {
  id?: string;
  title: string;
  content: string;
}

interface FormContentProps {
  form: UseFormReturn<ClausesFormData>;
  fields: FieldArrayWithId<ClausesFormData, 'custom_clauses'>[];
  append: (data: Omit<CustomClause, 'id'>) => void;
  remove: (index: number) => void;
  disabled?: boolean;
}

const defaultClauses: Omit<CustomClause, 'id'>[] = [
  {
    title: 'Rent',
    content:
      "Lessee shall pay to Lessor or Lessor's authorized agent, at the address set forth above, or through Terr.ai, as rent for the Premises, Parking, or otherwise the sum as stated above. Rent is due and payable on the first day of each calendar month, in advance. The timely payment of each installment of rent is deemed to be of the essence of this Lease.",
  },
  {
    title: 'Jointly and Severally Liable',
    content:
      'Each Lessee is jointly and severally liable for the payment of rent and performance of all other terms of this agreement.',
  },
  {
    title: 'Security Deposit',
    content:
      'Lessee has deposited with Lessor, the sum set forth above as a security deposit to be held by the Lessor under State or local law or ordinance to secure the faithful performance by the Lessee of all of the provisions contained in this lease. If Lessee performs all of the obligations as provided in this lease and pays all sums due Lessor, then Lessor, after the Lessee has surrendered possession of the Premises and delivered the keys to Lessor, shall refund said deposit to Lessee, including interest as provided by law within 15 days.',
  },
  {
    title: 'Possession',
    content:
      "If Lessor cannot deliver possession of the Premises at the commencement of the lease term, the rent shall be abated until the Premises are available for occupancy by Lessee, or at Lessee's option, the Lessee may terminate this lease upon written notice to Lessor. Lessor shall not be liable to Lessee for any consequential damages arising as a result of Lessor's inability to give possession of the Premises at the commencement of the lease term.",
  },
  {
    title: 'Condition of Premises',
    content:
      "Lessee has examined the Premises prior to accepting the same and is satisfied with the physical condition thereof, including but not limited to the heating, plumbing, and smoke detectors. Lessee's acceptance of possession shall constitute conclusive evidence of receipt of the Premises in good order and repair as of the commencement of the lease term.",
  },
  {
    title: 'Use of Premises',
    content:
      'The Premises shall be occupied for residential purposes only, and only by the persons disclosed in this lease and on the Application for Lease submitted by Lessee. Lessee shall not allow trash to accumulate in the common areas or engage in any activity that may increase the insurance rate on the property.',
  },
  {
    title: 'Limitation of Liability',
    content:
      "The Lessee agrees to indemnify and hold the Lessor harmless from any and all liability, loss or damage arising from any nuisance made or suffered on the leased premises by the Lessee or the Lessee's family or guests, caused by any negligence, or illegal or improper conduct of Lessee or Lessee's family or guests.",
  },
  {
    title: 'Lessee to Maintain',
    content:
      'Lessee shall keep the Premises and the fixtures and appliances therein in a clean and healthy condition, and in good working order. Upon termination of the lease, Lessee shall return the Premises in as good a condition of cleanliness and repair as at the commencement of the lease, reasonable wear and tear excepted.',
  },
  {
    title: 'Sublet or Assignment',
    content:
      "Lessee shall not sublet the Premises or any part thereof, nor assign this lease, without obtaining Lessor's prior written permission.",
  },
  {
    title: 'Smoking Prohibition',
    content:
      "Lessee and members of Lessee's household shall not smoke anywhere in the dwelling, or in the building in which the dwelling is a part, or in any of the common areas or adjoining grounds of such building.",
  },
  {
    title: 'Renters Insurance Required',
    content:
      'Lessee is required to provide proof of renters insurance within 14 days of the lease start date.',
  },
  {
    title: 'Late Charges',
    content:
      'Rent received by Lessor later than the 5th day after the due date will incur a late charge of $200.00. If rent is mailed and received after the 5th, a late charge will still apply regardless of the mailing date.',
  },
  {
    title: 'Forcible Detainer',
    content:
      'If Lessee defaults in the performance of any of the covenants or agreements herein contained, Lessor may terminate this Lease and, if abandoned or vacated, may re-enter the Premises. Non-performance of any obligations shall constitute a default and forfeiture of this lease.',
  },
  {
    title: 'Notice of Termination',
    content:
      'If Lessee intends to vacate the Premises at the end of the lease term, they must provide at least sixty (60) days written notice prior to the end of this lease. If such notice is not given, Lessee is responsible for the equivalent rent due for the sixty (60) days after notice is given.',
  },
  {
    title: 'Surrender of Possession',
    content:
      'Upon expiration or termination of this Lease, Lessee shall immediately vacate and surrender possession of the Premises in as good and clean order and condition as the Premises was at the beginning of the Lease Term, reasonable wear and tear excepted.',
  },
  {
    title: 'Termination for Cause',
    content:
      'Lessor may terminate this Lease for cause if Lessee defaults in the performance of any of the covenants or agreements herein contained, or if Lessee is in default in the payment of any rent or other sums due hereunder, or if Lessee is in default in the performance of any other obligation hereunder.',
  },
];

export const FormContent: React.FC<FormContentProps> = ({
  form,
  fields,
  append,
  remove,
  disabled,
}) => {
  const {
    control,
    register,
    formState: { errors },
  } = form;

  return (
    <Stack spacing={2}>
      <FormTextField
        label="Maintenance Responsibilities"
        control={control}
        {...register('maintenance_responsibilities')}
        disabled={disabled}
        textFieldProps={{
          multiline: true,
          rows: 4,
        }}
        error={!!errors.maintenance_responsibilities}
        helperText={errors.maintenance_responsibilities?.message?.toString()}
      />

      <FormTextField
        label="Property Access"
        control={control}
        {...register('property_access')}
        disabled={disabled}
        textFieldProps={{
          multiline: true,
          rows: 4,
        }}
        error={!!errors.property_access}
        helperText={errors.property_access?.message?.toString()}
      />

      <FormTextField
        label="Subletting Policy"
        control={control}
        {...register('subletting_policy')}
        disabled={disabled}
        textFieldProps={{
          multiline: true,
          rows: 4,
        }}
        error={!!errors.subletting_policy}
        helperText={errors.subletting_policy?.message?.toString()}
      />

      <FormTextField
        label="Noise Policy"
        control={control}
        {...register('noise_policy')}
        disabled={disabled}
        textFieldProps={{
          multiline: true,
          rows: 4,
        }}
        error={!!errors.noise_policy}
        helperText={errors.noise_policy?.message?.toString()}
      />

      <Stack spacing={2}>
        {fields.map((field, index) => (
          <Stack key={field.id} direction="row" spacing={2} alignItems="flex-start">
            <FormTextField
              label="Clause Title"
              control={control}
              {...register(`custom_clauses.${index}.title`)}
              disabled={disabled}
            />

            <FormTextField
              label="Clause Content"
              textFieldProps={{
                multiline: true,
                rows: 4,
              }}
              control={control}
              {...register(`custom_clauses.${index}.content`)}
              disabled={disabled}
            />

            {!disabled && (
              <IconButton onClick={() => remove(index)} size="small">
                <MinusIcon />
              </IconButton>
            )}
          </Stack>
        ))}

        {!disabled && (
          <Button
            startIcon={<PlusIcon />}
            onClick={() =>
              append({
                title: '',
                content: '',
              })
            }
            variant="outlined"
            color="secondary"
          >
            Add Custom Clause
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

const ClausesForm: React.FC<Props> = ({ initialData, onSave, onClose, disabled = false }) => {
  const form = useForm<ClausesFormData>({
    defaultValues: initialData || {
      maintenance_responsibilities: '',
      property_access: '',
      subletting_policy: '',
      noise_policy: '',
      custom_clauses: defaultClauses,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'custom_clauses',
  });

  return (
    <ModalWithForm
      form={form}
      title="Lease Clauses"
      maxWidth="lg"
      onClose={onClose}
      onSubmit={onSave}
      buttonLabel="Save Clauses"
    >
      <FormContent
        form={form}
        fields={fields}
        append={append}
        remove={remove}
        disabled={disabled}
      />
    </ModalWithForm>
  );
};

export default ClausesForm;
