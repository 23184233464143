import { Button, IconButton, Stack } from '@mui/material';
import {
  useFieldArray,
  useForm,
  UseFormReturn,
  FieldError,
  FieldArrayWithId,
} from 'react-hook-form';

import { MinusIcon } from '@/assets/icons/MinusIcon';
import { PlusIcon } from '@/assets/icons/PlusIcon';

import FormSelect from '@/components/form/FormSelect';
import FormTextField from '@/components/form/FormTextField';
import ModalWithForm from '@/components/modals/ModalWithForm';

const DOCUMENT_TYPES = ['inspection_report', 'inventory_list', 'photos', 'other'] as const;
type DocumentType = (typeof DOCUMENT_TYPES)[number];

interface Document {
  title: string;
  file_url: string;
  type: DocumentType;
  description?: string;
}

interface AttachmentsFormData {
  documents: Document[];
}

interface Props {
  initialData?: AttachmentsFormData;
  onSave: (data: AttachmentsFormData) => Promise<void>;
  onClose: () => void;
  disabled?: boolean;
  title?: string;
}

interface FormContentProps {
  form: UseFormReturn<AttachmentsFormData>;
  fields: FieldArrayWithId<AttachmentsFormData, 'documents'>[];
  append: (data: Document) => void;
  remove: (index: number) => void;
  disabled?: boolean;
}

const DOCUMENT_TYPE_OPTIONS = DOCUMENT_TYPES.map(value => ({
  value,
  label: value
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' '),
}));

export const FormContent: React.FC<FormContentProps> = ({
  form,
  fields,
  append,
  remove,
  disabled,
}) => (
  <Stack spacing={2}>
    {fields.map((field, index) => (
      <Stack key={field.id} direction="row" spacing={2} alignItems="flex-start">
        <FormTextField
          label="Document Title"
          control={form.control}
          name={`documents.${index}.title`}
          error={!!form.formState.errors.documents?.[index]?.title}
          helperText={(form.formState.errors.documents?.[index]?.title as FieldError)?.message}
          disabled={disabled}
        />

        <FormTextField
          label="File URL"
          control={form.control}
          name={`documents.${index}.file_url`}
          error={!!form.formState.errors.documents?.[index]?.file_url}
          helperText={(form.formState.errors.documents?.[index]?.file_url as FieldError)?.message}
          disabled={disabled}
        />

        <FormSelect
          label="Document Type"
          control={form.control}
          name={`documents.${index}.type`}
          options={DOCUMENT_TYPE_OPTIONS}
          error={!!form.formState.errors.documents?.[index]?.type}
          helperText={(form.formState.errors.documents?.[index]?.type as FieldError)?.message}
          disabled={disabled}
        />

        <FormTextField
          label="Description"
          textFieldProps={{
            multiline: true,
            rows: 4,
          }}
          control={form.control}
          name={`documents.${index}.description`}
          error={!!form.formState.errors.documents?.[index]?.description}
          helperText={
            (form.formState.errors.documents?.[index]?.description as FieldError)?.message
          }
          disabled={disabled}
        />

        {!disabled && (
          <IconButton onClick={() => remove(index)} size="small">
            <MinusIcon />
          </IconButton>
        )}
      </Stack>
    ))}

    {!disabled && (
      <Button
        startIcon={<PlusIcon />}
        onClick={() =>
          append({
            title: '',
            file_url: '',
            type: 'other',
            description: '',
          })
        }
        variant="outlined"
        color="secondary"
      >
        Add Document
      </Button>
    )}
  </Stack>
);

const AttachmentsForm: React.FC<Props> = ({
  initialData,
  onSave,
  onClose,
  disabled = false,
  title = 'Attachments',
}) => {
  const form = useForm<AttachmentsFormData>({
    defaultValues: initialData || {
      documents: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'documents',
  });

  return (
    <ModalWithForm form={form} maxWidth="lg" title={title} onClose={onClose} onSubmit={onSave}>
      <FormContent
        form={form}
        fields={fields}
        append={append}
        remove={remove}
        disabled={disabled}
      />
    </ModalWithForm>
  );
};

export default AttachmentsForm;
