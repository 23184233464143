import { Stack, Typography } from '@mui/material';

interface Props {
  children: React.ReactNode;
  title?: string;
  subtitle?: React.ReactNode;
}

const DashboardSection: React.FC<Props> = ({ children, title, subtitle }) => (
  <Stack
    sx={{
      backgroundColor: 'background.paper',
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: '12px',
      boxShadow: '0px 1px 2px 0px #0A0D120D',
    }}
  >
    {!!(title || subtitle) && (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          height: 44,
          px: 3,
          backgroundColor: 'grey.50',
          borderRadius: '12px 12px 0 0',
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Typography variant="caption" color="grey.500">
          {title}
        </Typography>

        {subtitle}
      </Stack>
    )}

    <Stack sx={{ px: 3, py: 2, flex: '1 1 100%', overflowY: 'auto' }} spacing={2.5}>
      {children}
    </Stack>
  </Stack>
);

export default DashboardSection;
