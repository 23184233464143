import { setupIonicReact } from '@ionic/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { PostHogProvider } from 'posthog-js/react';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { environment } from '@env';

import {
  HOPRoutes,
  OnboardingRoutes,
  PMPRoutes,
  PublicRoutes,
  ResidentRoutes,
} from '@/config/routes';
import AuthProvider from '@/context/AuthProvider';
import OnboardingProgressProvider from '@/context/OnboardingProgressProvider';
import PropertyManagerProvider from '@/context/PropertyManagerProvider';
import { isAssociaSubdomain } from '@/utils/communities';

import ConditionalRoute from '@/components/ConditionalRoute';

import { queryClient } from './api/queryClient';

const AboutUsPage = lazy(() => import('@/pages/public/AboutUsPage'));
const SignUpPage = lazy(() => import('@/pages/public/auth/SignUpPage'));
const LogInPage = lazy(() => import('@/pages/public/auth/LogInPage'));
const LogOutPage = lazy(() => import('@/pages/public/auth/LogOutPage'));
const VerifyEmailPage = lazy(() => import('@/pages/public/auth/VerifyEmailPage'));
const ChatPage = lazy(() => import('@/pages/public/ChatPage'));
// const HomePage = lazy(() => import('@/pages/public/HomePage'));
const PrivacyPolicyPage = lazy(() => import('@/pages/public/PrivacyPolicyPage'));
const TermsOfServicePage = lazy(() => import('@/pages/public/TermsOfServicePage'));
const AssociaHomePage = lazy(() => import('@/pages/associa/AssociaHomePage'));
const LandingPage = lazy(() => import('@/pages/public/LandingPage'));
const PricingPage = lazy(() => import('@/pages/public/PricingPage'));
const HOPDashboardPage = lazy(() => import('@/pages/home-owner-portal/DashboardPage'));
const HOPDocumentListPage = lazy(() => import('@/pages/home-owner-portal/DocumentListPage'));
const HOPDocumentViewPage = lazy(() => import('@/pages/home-owner-portal/DocumentViewPage'));
const HOPPropertyCreatePage = lazy(() => import('@/pages/home-owner-portal/PropertyCreatePage'));

const OnboardingHomePage = lazy(() => import('@/pages/onboarding/HomePage'));

const PMPHomePage = lazy(() => import('@/pages/property-manager-portal/HomePage'));
const PMPWorkflowsPage = lazy(() => import('@/pages/property-manager-portal/WorkflowPage'));

const ResidentHomePage = lazy(() => import('@/pages/resident/HomePage'));

const options = {
  api_host: environment.posthogUrl,
};

setupIonicReact();

const App: React.FC = () => (
  <PostHogProvider apiKey={environment.posthogKey} options={options}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <PropertyManagerProvider>
            <OnboardingProgressProvider>
              <Suspense>
                <Switch>
                  <Route
                    exact
                    path={PublicRoutes.home}
                    component={isAssociaSubdomain() ? AssociaHomePage : LandingPage}
                  />

                  <Route exact path={PublicRoutes.login} component={LogInPage} />
                  <Route exact path={PublicRoutes.signup} component={SignUpPage} />
                  <Route exact path={PublicRoutes.logout} component={LogOutPage} />

                  <Route exact path={PublicRoutes.privacyPolicy} component={PrivacyPolicyPage} />
                  <Route exact path={PublicRoutes.termsOfService} component={TermsOfServicePage} />
                  <Route exact path={PublicRoutes.aboutUs} component={AboutUsPage} />
                  <Route exact path={PublicRoutes.pricing} component={PricingPage} />

                  <Route exact path={PublicRoutes.verifyEmail} component={VerifyEmailPage} />

                  <ConditionalRoute exact path={PublicRoutes.chat} component={ChatPage} />

                  <ConditionalRoute exact path={HOPRoutes.dashboard} component={HOPDashboardPage} />
                  <ConditionalRoute exact path={HOPRoutes.propertyList}>
                    <Redirect to={HOPRoutes.dashboard} />
                  </ConditionalRoute>
                  <ConditionalRoute
                    exact
                    path={HOPRoutes.propertyCreate}
                    component={HOPPropertyCreatePage}
                  />
                  <ConditionalRoute
                    exact
                    path={HOPRoutes.documentList}
                    component={HOPDocumentListPage}
                  />
                  <ConditionalRoute
                    exact
                    path={HOPRoutes.documentView}
                    component={HOPDocumentViewPage}
                  />

                  <ConditionalRoute path={OnboardingRoutes.index} component={OnboardingHomePage} />

                  <ConditionalRoute path={PMPRoutes.dashboard} component={PMPHomePage} />
                  <ConditionalRoute path={PMPRoutes.workflows} component={PMPWorkflowsPage} />

                  <ConditionalRoute path={ResidentRoutes.dashboard} component={ResidentHomePage} />

                  <Route>
                    <Redirect to={PublicRoutes.home} />
                  </Route>
                </Switch>
              </Suspense>
            </OnboardingProgressProvider>
          </PropertyManagerProvider>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </PostHogProvider>
);

export default App;
