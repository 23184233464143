import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

export interface PriceModel {
  id: string;
  product_id: string;
  description: string;
  name: string;
  billing_cycle: {
    interval: 'month' | 'year';
    frequency: number;
  };
  unit_price: {
    amount: string;
    currency_code: string;
  };
  status: string;
}

// Product information from the API
export interface ProductModel {
  id: string;
  name: string;
  description: string;
  features: string[];
  prices: PriceModel[];
}

export type ApiResponse_GetSubscriptionPlans = ProductModel[];

const METHOD = 'GET';
const ENDPOINT = 'subscription/plans';
export const QUERY_KEY_PLANS = () => getQueryKey(METHOD, ENDPOINT, {});

export const useGetSubscriptionPlans = (enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY_PLANS(),
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetSubscriptionPlans>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {})
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch subscription plans.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
