import { Box, Stack } from '@mui/material';

import { useAuth } from '@/context/AuthProvider';

import Navigation from './navigation/Navigation';
import NavPropertyLocationSelect from './NavPropertyLocationSelect';
import NavUser from './NavUser';

interface Props {
  isOpen: boolean;
}

const DrawerContent: React.FC<Props> = ({ isOpen }) => {
  const { isPropertyManager } = useAuth();

  return (
    <>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Navigation />
      </Box>

      {isOpen ? (
        <Stack spacing={2} p={2} mt={2}>
          {isPropertyManager && <NavPropertyLocationSelect />}

          <NavUser />
        </Stack>
      ) : (
        <Stack px={1} py={2} mt={2} alignItems="center" justifyContent="center">
          <NavUser />
        </Stack>
      )}
    </>
  );
};

export default DrawerContent;
