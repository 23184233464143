import { HOPRoutes, PMPRoutes, PublicRoutes, ResidentRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { isAssociaSubdomain } from '@/utils/communities';

export const useHomeRoute = () => {
  const { isAuthenticated, isPropertyManager, isResident } = useAuth();

  if (!isAuthenticated) {
    return PublicRoutes.home;
  }

  if (isAssociaSubdomain()) {
    return HOPRoutes.documentList;
  }

  if (isResident) {
    return ResidentRoutes.dashboard;
  }

  if (isPropertyManager) {
    return PMPRoutes.dashboard;
  }

  return HOPRoutes.dashboard;
};
