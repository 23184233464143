import { Button, Stack, Typography } from '@mui/material';
import React from 'react';

interface Props {
  title: string;
  image?: React.ReactNode;
  description: string;
  buttonLabel: string;
  href: string;
}

const Banner: React.FC<Props> = ({ title, image, description, buttonLabel, href }) => (
  <Stack
    direction="column"
    spacing={4}
    sx={{
      backgroundColor: '#9D150005',
      px: 4,
      pt: 4,
      pb: 5,
      border: '1px solid #9D150033',
      borderRadius: '16px',
      textAlign: 'center',
      alignItems: 'center',
    }}
  >
    {image}

    <Stack spacing={1}>
      <Typography variant="h5">{title}</Typography>

      <Typography variant="body1" sx={{ fontSize: 18 }}>
        {description}
      </Typography>
    </Stack>

    <Button variant="contained" color="primary" LinkComponent="a" href={href}>
      {buttonLabel}
    </Button>
  </Stack>
);

export default Banner;
