import ModalWithPreview from '../modals/ModalWithPreview';

import TermsOfServiceContent from './TermsOfServiceContent';

interface Props {
  onClose: () => void;
}

const TermsOfServiceModal: React.FC<Props> = ({ onClose }) => (
  <ModalWithPreview onClose={onClose} title="Terms and Conditions">
    <TermsOfServiceContent />
  </ModalWithPreview>
);

export default TermsOfServiceModal;
