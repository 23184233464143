import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiRequest_Update_PropertyManager,
  ApiResponse_Update_PropertyManager,
  useUpdatePropertyManager,
} from '@/api/endpoints/propertyManager/useUpdatePropertyManager';
import { PropertyManagementWithManagerWithLimitedUserView } from '@/api/models/propertyManagement';

import FormSelect from '../form/FormSelect';
import FormSwitch from '../form/FormSwitch';
import FormTextField from '../form/FormTextField';
import ModalWithForm from '../modals/ModalWithForm';

import { propertyManagerRolesOptions } from './options';

interface Props {
  onClose: () => void;
  onPropertyManagerUpdated?: (propertyManager: ApiResponse_Update_PropertyManager) => void;
  propertyManager: PropertyManagementWithManagerWithLimitedUserView;
}

const PropertyManagerUpdateModal: React.FC<Props> = ({
  onClose,
  onPropertyManagerUpdated,
  propertyManager,
}) => {
  const form = useForm<
    ApiRequest_Update_PropertyManager & {
      email: string;
    }
  >({
    defaultValues: {
      full_name: propertyManager.manager.limitedUser.full_name,
      email: propertyManager.manager.limitedUser.email,
      phone: propertyManager.manager.limitedUser.phone,
      role: propertyManager.role,
      is_emergency_contact: !!propertyManager.manager.is_emergency_contact,
    },
  });
  const {
    register,
    control,
    formState: { errors },
  } = form;

  const updatePropertyManagerMutation = useUpdatePropertyManager(propertyManager.manager.id, {
    onSuccess: data => {
      onPropertyManagerUpdated?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_Update_PropertyManager) => updatePropertyManagerMutation.mutateAsync(data),
    [updatePropertyManagerMutation]
  );

  return (
    <ModalWithForm
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="sm"
      title="Update Property Partner"
      form={form}
      buttonLabel="Update"
    >
      <FormTextField
        label="Full Name"
        control={control}
        required
        {...register('full_name', { required: 'Full Name is required' })}
        error={!!errors.full_name}
        helperText={errors.full_name?.message?.toString()}
        size="small"
      />

      <FormTextField
        label="Email"
        control={control}
        required
        {...register('email', { required: 'Email is required' })}
        error={!!errors.email}
        helperText={errors.email?.message?.toString()}
        size="small"
      />

      <FormTextField
        label="Phone"
        control={control}
        {...register('phone')}
        error={!!errors.phone}
        helperText={errors.phone?.message?.toString()}
        size="small"
      />

      <FormSelect
        label="Role"
        control={control}
        required
        {...register('role', { required: 'Role is required' })}
        options={propertyManagerRolesOptions}
        error={!!errors.role}
        helperText={errors.role?.message?.toString()}
        size="small"
      />

      <FormSwitch
        label="Emergency Contact (who will be notified in case of an emergency detected from inbound communication)"
        control={control}
        {...register('is_emergency_contact')}
        size="small"
      />
    </ModalWithForm>
  );
};

export default PropertyManagerUpdateModal;
