import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { ConversationModel } from '@/api/models/conversation';
import { ConversationParticipantModel } from '@/api/models/conversationParticipant';
import { DocumentModel } from '@/api/models/document';
import { MessageModel } from '@/api/models/message';
import { PropertyLocationModel } from '@/api/models/propertyLocation';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Uuid } from '@/api/utils/sql';
import { LimitedUserView } from '@/api/views/limitedUser';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_GetThread_Conversation = {
  conversation_sid: Uuid;
};

export type ApiResponse_GetThread_Conversation = ConversationModel & {
  location: PropertyLocationModel;
  participants: Array<
    ConversationParticipantModel & {
      propertyManager: {
        limitedUser: LimitedUserView;
      };
    }
  >;
  messages: Array<
    MessageModel & {
      documents: Array<DocumentModel>;
    }
  >;
};

const METHOD = 'GET';
const ENDPOINT = 'conversation/:conversation_sid';
export const QUERY_KEY = (params: ApiRequest_GetThread_Conversation) =>
  getQueryKey(METHOD, ENDPOINT, params);

export const useGetConversationThread = (
  params: ApiRequest_GetThread_Conversation,
  enabled = true
) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token && !!params.conversation_sid,
    queryKey: QUERY_KEY(params),
    queryFn: async () => {
      try {
        const data = await handleApiCall<
          ApiResponse_GetThread_Conversation,
          ApiRequest_GetThread_Conversation
        >(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, { conversation_sid: params.conversation_sid })
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch conversation messages.', { variant: 'error' });
        // eslint-disable-next-line no-console
        console.error(err);
        throw err;
      }
    },
  });
};
