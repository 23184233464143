import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { PropertyLocationPhoneModel } from '@/api/models/propertyLocationPhone';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_GetAllOwnedAndPublicPhoneNumbers_PropertyManager =
  Array<PropertyLocationPhoneModel>;

const METHOD = 'GET';
const ENDPOINT = '/property-location-phone/all';
export const QUERY_KEY = getQueryKey(METHOD, ENDPOINT);

export const useGetAllOwnedAndPublicPhoneNumbers = (enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY,
    queryFn: async () => {
      try {
        const data =
          await handleApiCall<ApiResponse_GetAllOwnedAndPublicPhoneNumbers_PropertyManager>(
            session,
            METHOD,
            getResolvedEndpoint(ENDPOINT, {})
          );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch available phone numbers.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
