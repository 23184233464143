import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { BuildingModel } from '@/api/models/building';
import { DocumentModel } from '@/api/models/document';
import { EmailSessionModel } from '@/api/models/emailSession';
import { TenantModelWithContacts } from '@/api/models/tenant';
import { UnitModel } from '@/api/models/unit';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_GetAll_EmailSession = {
  unit_id: Id;
};

export type ApiResponse_GetAll_EmailSession = Array<
  EmailSessionModel & {
    tenant: TenantModelWithContacts & {
      unit: UnitModel & {
        building: BuildingModel;
      };
    };
    documents: DocumentModel[];
  }
>;

const METHOD = 'GET';
const ENDPOINT = 'email-session/all';
export const QUERY_KEY = (params: ApiRequest_GetAll_EmailSession) =>
  getQueryKey(METHOD, ENDPOINT, params);

export const useGetAllEmailSessions = (params: ApiRequest_GetAll_EmailSession, enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY(params),
    queryFn: async () => {
      try {
        const data = await handleApiCall<
          ApiResponse_GetAll_EmailSession,
          ApiRequest_GetAll_EmailSession
        >(session, METHOD, getResolvedEndpoint(ENDPOINT, {}), params);

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch email sessions.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
