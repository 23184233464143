// eslint-disable-next-line import/order
import styled from '@emotion/styled';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { subtitle } from '@/styles/typography';
import { getStyledScrollbar } from '@/styles/utils/scrollbar';

import CircleIconButton from '@/components/@ionic/CircleIconButton';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DocumentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  flex: 1 1 100%;

  ${getStyledScrollbar('dark')}

  .react-pdf__Document {
    margin: auto auto;
  }

  .react-pdf__Page[style] {
    background: transparent !important;
    display: flex;
  }

  .react-pdf__Page__canvas {
    margin: auto auto;
  }

  .react-pdf__Page__textContent {
    margin: auto auto;
  }

  .react-pdf__Page__annotations {
    margin: auto auto;
  }
`;

const PreviousPage = styled.div`
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
  z-index: 2;
`;

const NextPage = styled.div`
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  z-index: 2;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  padding: 0 1.5rem;
  border-radius: 2rem;
  background-color: var(--figma-color-darkgray-300-80);
  color: var(--figma-color-neutral-100);
  flex: 0 0 3rem;
`;

const FileName = styled.div`
  ${subtitle}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CurrentPage = styled.div`
  ${subtitle}
  text-align: center;
  display: flex;
  text-wrap: nowrap;
  align-items: center;
  gap: 0.5rem;
`;

const Zoom = styled.div`
  ${subtitle}
  text-align: center;
  display: flex;
  align-items: center;
`;

const ZoomButton = styled(CircleIconButton)`
  height: 2rem !important;
  width: 2rem !important;
  flex-basis: 2rem !important;

  &::part(native) {
    padding: 0;
    background: transparent;
  }

  ion-icon {
    font-size: 0.75rem !important;
  }
`;

const ZoomOutButton = styled(ZoomButton)`
  &::part(native) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const ZoomInButton = styled(ZoomButton)`
  &::part(native) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const Input = styled.input`
  background-color: var(--figma-color-darkgray-300);
  color: var(--figma-color-neutral-100);
  font-size: 0.75rem;
  height: 2rem;
  min-width: 2.25rem;
  text-align: center;
  border: none;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
  margin: 0;

  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-right: -1rem;

  ion-button {
    margin: 0;
  }
`;

export default {
  Container,
  DocumentContainer,
  ControlsContainer,
  PreviousPage,
  NextPage,
  Input,
  FileName,
  CurrentPage,
  Zoom,
  ZoomOutButton,
  ZoomInButton,
  Buttons,
};
