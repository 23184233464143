import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';

import { MakePaymentFormData } from '@/types/payment';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import { useGetPayments } from '@/api/endpoints/payment/useGetPayments';
import { useMakePayment } from '@/api/endpoints/payment/useMakePayment';
import { CreditCard01Icon } from '@/assets/icons/CreditCard01Icon';
import { useAuth } from '@/context/AuthProvider';
import { formatFullDate } from '@/utils/date';

import PaymentModal from '@/components/resident/PaymentModal';

// Mock data for development purposes
const MOCK_PAYMENTS = [
  {
    id: 1,
    amount: 1200.0,
    date: '2024-03-01T00:00:00Z',
    status: 'completed',
    description: 'March 2024 Monthly Rent',
    period: 'March 2024',
  },
  {
    id: 2,
    amount: 1200.0,
    date: '2024-02-01T00:00:00Z',
    status: 'completed',
    description: 'February 2024 Monthly Rent',
    period: 'February 2024',
  },
  {
    id: 3,
    amount: 1200.0,
    date: '2024-01-01T00:00:00Z',
    status: 'completed',
    description: 'January 2024 Monthly Rent',
    period: 'January 2024',
  },
  {
    id: 4,
    amount: 1200.0,
    date: '2023-12-01T00:00:00Z',
    status: 'completed',
    description: 'December 2023 Monthly Rent',
    period: 'December 2023',
  },
  {
    id: 5,
    amount: 1200.0,
    date: '2023-11-01T00:00:00Z',
    status: 'completed',
    description: 'November 2023 Monthly Rent',
    period: 'November 2023',
  },
];

const PaymentListPage: React.FC = () => {
  const { session } = useAuth();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const { data: payments = MOCK_PAYMENTS, isLoading } = useGetPayments(
    {
      tenant_id: session?.user?.id,
    },
    !!session?.access_token
  );

  const makePaymentMutation = useMakePayment();

  const handleMakePayment = useCallback(
    async (data: MakePaymentFormData) => {
      await makePaymentMutation.mutateAsync(data);
      setIsPaymentModalOpen(false);
    },
    [makePaymentMutation]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'period',
        headerName: 'Rent Period',
        width: 150,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            variant="body2"
            sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            {params.value}
          </Typography>
        ),
      },
      {
        field: 'amount',
        headerName: 'Amount',
        width: 120,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            variant="body2"
            sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            ${params.value.toFixed(2)}
          </Typography>
        ),
      },
      {
        field: 'date',
        headerName: 'Payment Date',
        width: 180,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            variant="body2"
            sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            {formatFullDate(new Date(params.value))}
          </Typography>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 120,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              textTransform: 'capitalize',
              color: params.value === 'completed' ? 'success.main' : 'error.main',
            }}
          >
            {params.value}
          </Typography>
        ),
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        minWidth: 200,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            variant="body2"
            sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            {params.value}
          </Typography>
        ),
      },
    ],
    []
  );

  const PaymentNoRowsOverlay = useCallback(() => <NoRowsOverlay entityName="Payment" />, []);

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100px' }}>
      <DashboardHeader hasInnerHtml title="Payments: <em>History</em>">
        <LoadingButton
          variant="contained"
          startIcon={<CreditCard01Icon />}
          onClick={() => setIsPaymentModalOpen(true)}
          loading={makePaymentMutation.isPending}
        >
          Make Payment
        </LoadingButton>
      </DashboardHeader>

      <Typography variant="dataGridBody">
        View your payment history and make new rent payments.
      </Typography>

      <DataGrid
        rows={payments}
        columns={columns}
        density="comfortable"
        disableColumnFilter
        disableColumnMenu
        disableEval
        loading={isLoading}
        getRowHeight={() => 'auto'}
        slots={{
          noRowsOverlay: PaymentNoRowsOverlay,
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
        }}
        disableRowSelectionOnClick
        hideFooter
      />

      {isPaymentModalOpen && (
        <PaymentModal onClose={() => setIsPaymentModalOpen(false)} onSubmit={handleMakePayment} />
      )}
    </Stack>
  );
};

export default PaymentListPage;
