import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { QuestionAnswerModel } from '@/api/models/ai/questionAnswer';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_GetAll_QuestionAnswer = {
  location_id: Id;
};

export type ApiResponse_GetAll_QuestionAnswer = Array<QuestionAnswerModel>;

const METHOD = 'GET';
const ENDPOINT = 'question/all';
export const QUERY_KEY = (params?: ApiRequest_GetAll_QuestionAnswer) =>
  getQueryKey(METHOD, ENDPOINT, params);

export const useGetAllQuestionAnswers = (
  params?: ApiRequest_GetAll_QuestionAnswer,
  enabled = true
) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY(params),
    queryFn: async () => {
      try {
        const data = await handleApiCall<
          ApiResponse_GetAll_QuestionAnswer,
          ApiRequest_GetAll_QuestionAnswer
        >(session, METHOD, getResolvedEndpoint(ENDPOINT, {}), params);

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch questions & answers.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
