import { Stack } from '@mui/material';
import { useState, useEffect, useMemo, useCallback } from 'react';

import {
  MessageSessionType,
  TMessageSession,
  TMessageThread,
  MessageThreadRole,
} from '@/types/message';

import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { useGetEmailThread } from '@/api/endpoints/email/useGetEmailThread';
import { useGetAllEmailSessions } from '@/api/endpoints/emailSession/useGetAllEmailSessions';
import { useOnMessageSend } from '@/api/endpoints/message/useOnMessageSend';
import { useGetAllSmsSessions } from '@/api/endpoints/smsSession/useGetAllSmsSessions';
import { useGetSmsThread } from '@/api/endpoints/twilio/useGetSmsThread';
import { useResident } from '@/context/ResidentProvider';
import {
  getMessageSessionFromEmailSession,
  getMessageThreadFromEmailThread,
} from '@/utils/message';

import MessageChat from '@/components/message/MessageChat';

const CommunicationPage: React.FC = () => {
  const { tenant } = useResident();
  const [selectedMessageSession, setSelectedMessageSession] = useState<TMessageSession | null>(
    null
  );

  // Get unit_id from resident context
  const unitId = useMemo(() => tenant?.unit?.id || 0, [tenant]);

  // Fetch email and SMS sessions
  const getAllEmailSessionsQuery = useGetAllEmailSessions(
    {
      unit_id: unitId,
    },
    !!unitId
  );

  const getAllSmsSessionsQuery = useGetAllSmsSessions(
    {
      unit_id: unitId,
    },
    !!unitId
  );

  // Transform email sessions to message sessions
  const emailMessageSessions: TMessageSession[] = useMemo(
    () =>
      getAllEmailSessionsQuery.data?.map(emailSession =>
        getMessageSessionFromEmailSession(emailSession)
      ) ?? [],
    [getAllEmailSessionsQuery.data]
  );

  // Transform SMS sessions to message sessions
  const smsMessageSessions: TMessageSession[] = useMemo(
    () =>
      getAllSmsSessionsQuery.data?.map(smsSession => ({
        type: MessageSessionType.sms,
        session_id: smsSession.session_id,
        building_id: smsSession.tenant.unit.building.id,
        property_location_id: smsSession.tenant.unit.building.property_location_id,
        tenant_id: smsSession.tenant.id,
        from: {
          name: smsSession.tenant?.full_name,
          source: smsSession.from_phone_number,
          to: smsSession.to_phone_number,
        },
        updated_at: smsSession.updated_at,
        created_at: smsSession.created_at,
        subject: 'SMS',
      })) ?? [],
    [getAllSmsSessionsQuery.data]
  );

  // Combine and sort all message sessions
  const allMessageSessions = useMemo(
    () =>
      [...emailMessageSessions, ...smsMessageSessions].sort(
        (a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      ),
    [emailMessageSessions, smsMessageSessions]
  );

  // Set first message session as selected when data is loaded
  useEffect(() => {
    if (!allMessageSessions.length || selectedMessageSession) return;

    // Open the first message
    setSelectedMessageSession(allMessageSessions[0]);
  }, [allMessageSessions, selectedMessageSession]);

  // Fetch thread data based on selected message session
  const getEmailThreadQuery = useGetEmailThread(
    selectedMessageSession?.session_id,
    selectedMessageSession?.type === MessageSessionType.email
  );

  const getSmsThreadQuery = useGetSmsThread(
    selectedMessageSession?.session_id,
    selectedMessageSession?.type === MessageSessionType.sms
  );

  // Process email thread data
  const emailThread = useMemo(
    () => getMessageThreadFromEmailThread(getEmailThreadQuery.data),
    [getEmailThreadQuery.data]
  );

  // Process SMS thread data
  const smsThread = useMemo(() => {
    if (!getSmsThreadQuery.data) return null;

    return {
      session: selectedMessageSession,
      messages: getSmsThreadQuery.data.messages.map(sms => ({
        id: sms.id,
        message: sms.message,
        created_at: new Date(sms.created_at),
        updated_at: new Date(sms.updated_at),
        role: MessageThreadRole[sms.role as keyof typeof MessageThreadRole],
      })),
    } as TMessageThread;
  }, [getSmsThreadQuery.data, selectedMessageSession]);

  // Determine which thread to display based on message type
  const thread = useMemo(() => {
    if (!selectedMessageSession) return null;

    switch (selectedMessageSession.type) {
    case MessageSessionType.email:
      return emailThread;

    case MessageSessionType.sms:
      return smsThread;
    }

    return null;
  }, [selectedMessageSession, emailThread, smsThread]);

  // Determine loading state for sessions
  const fetchSessionsQueryStatus = useMemo(() => {
    if (getAllEmailSessionsQuery.status === 'error' || getAllSmsSessionsQuery.status === 'error')
      return 'error';

    if (
      getAllEmailSessionsQuery.status === 'pending' ||
      getAllSmsSessionsQuery.status === 'pending'
    )
      return 'pending';

    return 'success';
  }, [getAllEmailSessionsQuery.status, getAllSmsSessionsQuery.status]);

  // Determine loading state for thread
  const fetchThreadQueryStatus = useMemo(() => {
    if (!selectedMessageSession) return 'idle';

    switch (selectedMessageSession.type) {
    case MessageSessionType.email:
      return getEmailThreadQuery.status;

    case MessageSessionType.sms:
      return getSmsThreadQuery.status;

    default:
      return 'idle';
    }
  }, [getEmailThreadQuery.status, getSmsThreadQuery.status, selectedMessageSession]);

  // Handle message session selection
  const handleMessageSessionSelect = useCallback((messageSession: TMessageSession) => {
    setSelectedMessageSession(messageSession);
  }, []);

  const onMessageSend = useOnMessageSend();

  return (
    <MuiPageWrapper>
      <Stack direction="column" spacing={3} sx={{ flex: '1 1 100%', position: 'relative' }}>
        <MessageChat
          messageSessions={allMessageSessions}
          messageThread={thread}
          fetchSessionsQueryStatus={fetchSessionsQueryStatus}
          fetchThreadQueryStatus={fetchThreadQueryStatus}
          selectedMessageSession={selectedMessageSession}
          onMessageSend={onMessageSend}
          onMessageSessionSelect={handleMessageSessionSelect}
        />
      </Stack>
    </MuiPageWrapper>
  );
};

export default CommunicationPage;
