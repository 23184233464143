import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_Delete_PropertyManager = never;
export type ApiRequest_Delete_PropertyManager = {
  id: Id;
};

const METHOD = 'DELETE';
const ENDPOINT = 'property-manager/:id';

export const useDeletePropertyManager = (
  stateFns?: MutationStateFns<ApiResponse_Delete_PropertyManager>
) => {
  const { session } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_Delete_PropertyManager) =>
      handleApiCall<ApiResponse_Delete_PropertyManager>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {
          id: data.id.toString(),
        })
      ),
    onSuccess: data => {
      enqueueSnackbar('Property manager deleted successfully', { variant: 'success' });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to delete property manager', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
