import { Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { environment } from '@env';

import { TDocument } from '@/types/document';

import { useGetDocumentCategories } from '@/api/endpoints/vault/useGetDocumentCategories';
import { useAuth } from '@/context/AuthProvider';

import FormSelect from '@/components/form/FormSelect';

interface DocumentCategoryCellProps {
  document: TDocument;
}

interface FormData {
  categoryId: string;
}

export const DocumentCategoryCell: React.FC<DocumentCategoryCellProps> = ({ document }) => {
  const queryClient = useQueryClient();
  const { session } = useAuth();
  const { control, setValue, watch } = useForm<FormData>({
    defaultValues: {
      categoryId: document.category_id || '',
    },
  });

  useEffect(() => {
    setValue('categoryId', document.category_id || '');
  }, [document.category_id, setValue]);

  const { data: categories = [] } = useGetDocumentCategories();

  const updateCategory = useMutation({
    mutationFn: async (categoryId: string) => {
      await axios.patch(
        `${environment.api}/document/${document.id}/category`,
        {
          categoryId,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return categoryId;
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['documents'] });
    },
  });

  // Watch for changes in the categoryId field and trigger the mutation
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'categoryId' && value.categoryId !== document.category_id && value.categoryId) {
        updateCategory.mutate(value.categoryId);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, document.category_id, updateCategory]);

  return (
    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', width: '100%' }}>
      <FormSelect
        name="categoryId"
        control={control}
        size="small"
        placeholder="None"
        options={categories.map(category => ({
          label: `${category.name}`,
          value: category.id,
        }))}
        sx={{
          textAlign: 'center',
          '& .MuiSelect-select': {
            textAlign: 'center',
          },
        }}
      />
    </Box>
  );
};

export default DocumentCategoryCell;
