import { Stack, Typography, CircularProgress } from '@mui/material';
import React from 'react';

const DashboardSectionLoading: React.FC = () => (
  <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} margin="auto">
    <CircularProgress size={20} />
    <Typography variant="body3">Loading...</Typography>
  </Stack>
);

export default DashboardSectionLoading;
