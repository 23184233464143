import { Theme } from '@mui/material/styles';

export default function OverridesDialogContent(theme: Theme) {
  return {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '1rem 3rem !important',
        },
      },
    },
  };
}
