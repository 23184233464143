import { Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiResponse_DeleteSpreadsheet_Invoice,
  useDeleteInvoiceSpreadsheet,
} from '@/api/endpoints/invoice/useDeleteInvoiceSpreadsheet';
import { Id } from '@/api/utils/sql';

import ModalWithForm from '@/components/modals/ModalWithForm';

interface Props {
  propertyLocationId: Id;
  onClose: () => void;
  onSpreadsheetDeleted?: (data: ApiResponse_DeleteSpreadsheet_Invoice) => void;
}

const InvoiceSpreadsheetDeleteModal: React.FC<Props> = ({
  propertyLocationId,
  onClose,
  onSpreadsheetDeleted,
}) => {
  const form = useForm();

  const deleteInvoiceSpreadhseetMutation = useDeleteInvoiceSpreadsheet({
    onSuccess: data => {
      onSpreadsheetDeleted?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    () =>
      deleteInvoiceSpreadhseetMutation.mutateAsync({
        location_id: propertyLocationId,
      }),
    [deleteInvoiceSpreadhseetMutation, propertyLocationId]
  );

  return (
    <ModalWithForm
      title="Delete Tracking Sheet"
      onClose={onClose}
      onSubmit={onSubmit}
      form={form}
      maxWidth="sm"
      buttonColor="error"
      buttonLabel="Delete"
    >
      <Stack spacing={2}>
        <Typography variant="body2">
          Are you sure you want to delete the invoice tracking spreadsheet? This action cannot be
          undone.
        </Typography>
      </Stack>
    </ModalWithForm>
  );
};

export default InvoiceSpreadsheetDeleteModal;
