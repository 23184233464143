// src/components/NodeConfigModal.tsx

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack } from '@mui/material';
import React, { useState } from 'react';

import type {
  NodeConfigModalProps,
  NodeConfig,
  DropdownValues,
  WorkflowNode as IWorkflowNode,
  NodeTypes,
  WorkflowNodeProps,
} from '../../types/workflow';

// Reuse the NodeContent component from WorkflowNode
import { WorkflowNode } from './WorkflowNode';

interface NodeFormValues {
  selectedOption: string | string[] | DropdownValues;
  remindInDays: string;
}

const noop = () => {
  /* intentionally empty */
};

export const NodeConfigModal: React.FC<NodeConfigModalProps> = ({
  open,
  onClose,
  onAdd,
  nodeType,
}) => {
  const [formValues, setFormValues] = useState<NodeFormValues>({
    selectedOption: nodeType.config?.selectedOption || '',
    remindInDays: '',
  });

  const handleDropdownChange = (
    _nodeId: string,
    value: string | string[] | DropdownValues
  ): void => {
    setFormValues(prev => ({
      ...prev,
      selectedOption: value,
    }));
  };

  const handleSubmit = (): void => {
    if (!nodeType.config) return;

    const config: NodeConfig = {
      dropdownType: nodeType.config.dropdownType,
      options: nodeType.config.options,
      selectedOption: formValues.selectedOption,
      remindInDays: formValues.remindInDays,
    };
    onAdd(config);
    onClose();
  };

  // Create a temporary node for the NodeContent component
  const tempNode: IWorkflowNode = {
    id: 'temp',
    type: nodeType.id as NodeTypes,
    label: nodeType.label,
    position: { x: 0, y: 0 },
    config: {
      dropdownType: nodeType.config?.dropdownType || 'single',
      options: nodeType.config?.options || [],
      selectedOption: formValues.selectedOption,
      remindInDays: formValues.remindInDays,
    },
  };

  // Empty handlers for required props
  const nodeProps: WorkflowNodeProps = {
    node: tempNode,
    isSelected: true,
    isConnecting: false,
    onDropdownChange: handleDropdownChange,
    connections: [],
    onNodeClick: noop,
    onNodeDragStart: noop,
    onDeleteClick: noop,
    onConnectionStart: noop,
  };

  if (!nodeType.config) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {nodeType.config.selectedOption ? 'Edit' : 'Configure'} {nodeType.label}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <WorkflowNode {...nodeProps} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          {nodeType.config.selectedOption ? 'Save Changes' : 'Add to Flow'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Helper function to validate node configuration
export const validateNodeConfig = (config: NodeConfig): boolean => {
  if (!config.dropdownType || !config.options) return false;

  // Check if required fields are filled
  if (config.dropdownType === 'single') {
    return typeof config.selectedOption === 'string' && config.selectedOption !== '';
  }

  if (config.dropdownType === 'multi') {
    if (Array.isArray(config.selectedOption)) {
      return config.selectedOption.length > 0;
    }
    if (typeof config.selectedOption === 'object') {
      const values = config.selectedOption as DropdownValues;
      return Object.values(values).some(
        value =>
          (Array.isArray(value) && value.length > 0) || (typeof value === 'string' && value !== '')
      );
    }
  }

  return false;
};

// Helper function to get default values for a node type
export const getDefaultNodeConfig = (nodeType: string): Partial<NodeConfig> => {
  switch (nodeType) {
  case 'trigger':
    return {
      selectedOption: {
        triggerType: '',
        participants: [],
        selectedTenant: undefined,
      },
    };
  case 'decision':
    return {
      selectedOption: {
        decisionType: '',
        approvers: [],
      },
    };
  case 'notify':
    return {
      selectedOption: {
        notifyType: '',
        recipients: [],
        remindInDays: '',
      },
    };
  case 'form':
    return {
      selectedOption: {
        formType: '',
        required: 'true',
      },
    };
  default:
    return {};
  }
};

export default NodeConfigModal;
