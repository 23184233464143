import { Box, Stack, Typography } from '@mui/material';

export interface Props {
  title: string;
  iconUrl: string;
  description: string;
}

const FeatureItem: React.FC<Props> = ({ title, iconUrl, description }) => (
  <Stack direction="row" alignItems="flex-start" spacing={2}>
    <Box
      component="img"
      src={iconUrl}
      alt={title}
      sx={{ width: 40, height: 40, objectFit: 'contain' }}
    />

    <Stack spacing={0.5}>
      <Typography variant="h5" color="primary.dark">
        {title}
      </Typography>
      <Typography variant="body2">{description}</Typography>
    </Stack>
  </Stack>
);

export default FeatureItem;
