import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiResponse_Approve_Invoice,
  useApproveInvoice,
} from '@/api/endpoints/invoice/useApproveInvoice';
import { InvoiceModel } from '@/api/models/ai/invoice';

import ModalWithForm from '@/components/modals/ModalWithForm';

interface Props {
  invoice: InvoiceModel;
  onClose: () => void;
  onInvoiceApproved?: (data: ApiResponse_Approve_Invoice) => void;
}

const InvoiceApproveModal: React.FC<Props> = ({ invoice, onClose, onInvoiceApproved }) => {
  const form = useForm();

  const approveInvoiceMutation = useApproveInvoice(invoice.id, {
    onSuccess: data => {
      onInvoiceApproved?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    () =>
      approveInvoiceMutation.mutateAsync({
        location_id: invoice.location_id,
        custom_metadata: invoice.custom_metadata,
      }),
    [approveInvoiceMutation, invoice]
  );

  return (
    <ModalWithForm
      title="Confirm Approval"
      onClose={onClose}
      onSubmit={onSubmit}
      form={form}
      maxWidth="sm"
    >
      <Typography>Are you sure you want to approve this invoice?</Typography>
    </ModalWithForm>
  );
};

export default InvoiceApproveModal;
