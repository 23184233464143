import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Id, Uuid } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_GetAll_Rule = {
  location_id?: Id;
};

export type ApiResponse_GetAll_Rule = Array<{
  created_at: string;
  updated_at: string;
  description: string;
  expected_output: string;
  id: Id;
  user_id?: Uuid;
}>;

const METHOD = 'GET';
const ENDPOINT = 'rule/all';
export const QUERY_KEY = (params?: ApiRequest_GetAll_Rule) => getQueryKey(METHOD, ENDPOINT, params);

export const useGetAllRules = (params?: ApiRequest_GetAll_Rule, enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY(params),
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetAll_Rule, ApiRequest_GetAll_Rule>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {}),
          params
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch rules.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
