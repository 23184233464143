import { IonButton, IonCol, IonRow } from '@ionic/react';
import React from 'react';

import { PublicRoutes } from '@/config/routes';
import BasePage from '@/pages/common/BasePage';

import { Topic } from '@/components/@ionic/TopicsCard';

import S from './AssociaHomePage.styles';

const AssociaHomePage: React.FC = () => {
  const pdfTopics: Topic[] = [
    {
      title: '1. Upload Your PDF',
      children: <h3>Simply select your PDF document from your device.</h3>,
    },
    {
      title: '2. Get an Instant Summary',
      children: (
        <h3>
          Our AI quickly processes your document and provides a clear, concise summary so you can
          grasp the key points.
        </h3>
      ),
    },
    {
      title: '3. Ask Your Questions',
      children: (
        <h3>
          Dive deeper into the details! Ask any question about the contents of your PDF, and the
          system will instantly provide answers tailored to your queries.
        </h3>
      ),
    },
  ];

  return (
    <BasePage>
      <IonRow>
        <IonCol
          sizeXl="6"
          sizeLg="8"
          offsetXl="3"
          offsetLg="2"
          sizeMd="10"
          offsetMd="1"
          sizeSm="12"
        >
          <h1 className="mt-2rem mb-2rem">Welcome, Associa Tennessee!</h1>

          <h2 className="mt-2rem mb-2rem text-dark-gradient">
            Upload, Summarize, and Chat with Your PDFs.
          </h2>

          <h3 className="mt-2rem mb-2rem">
            Easily extract valuable insights from your documents with AI-powered conversations.
          </h3>

          <S.TopicsCard
            buttonSize="default"
            className="mt-2rem mb-2rem"
            title="Here's how it works:"
            topics={pdfTopics}
          />

          <h3 className="mt-2rem mb-2rem">
            Whether it's a contract, report, or community guidelines—unlock the information you
            need, effortlessly.
          </h3>

          <IonButton
            className="mt-2rem mb-2rem"
            color="darkgray-300"
            size="large"
            expand="block"
            routerLink={PublicRoutes.login}
          >
            Get Started
          </IonButton>
        </IonCol>
      </IonRow>
    </BasePage>
  );
};

export default AssociaHomePage;
