import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { CreateCalendarEventDto } from '@/types/calendar';

import { CalendarModel } from '@/api/models/calendar';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY } from './useGetAllCalendarEvents';

export type ApiRequest_Create_CalendarEvent = Omit<
  CalendarModel,
  'id' | 'created_at' | 'updated_at' | 'created_by'
>;

export type ApiResponse_Create_CalendarEvent = CalendarModel;

const METHOD = 'POST';
const ENDPOINT = 'calendar-events';

export const useCreateCalendarEvent = (
  stateFns?: MutationStateFns<ApiResponse_Create_CalendarEvent>
) => {
  const queryClient = useQueryClient();
  const { session } = useAuth();

  return useMutation({
    mutationFn: async (data: CreateCalendarEventDto) =>
      handleApiCall<ApiResponse_Create_CalendarEvent, CreateCalendarEventDto>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Calendar event created successfully', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: QUERY_KEY() });
      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to create calendar event', { variant: 'error' });
      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
