import { Box, Stack, Typography, Link, useMediaQuery, useTheme } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import LogoMain from '@/@mantis/components/logo/LogoMain';
import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { Mail01Icon } from '@/assets/icons/Mail01Icon';
import { PublicRoutes } from '@/config/routes';

import LoadingState from '@/components/LoadingState';

interface Props extends PropsWithChildren {
  sideContent?: React.ReactNode;
  isSideContentPermanent?: boolean;
  isLoading?: boolean;
}

const SplitMuiPage: React.FC<Props> = ({
  children,
  sideContent,
  isSideContentPermanent,
  isLoading,
}) => {
  const theme = useTheme();
  const isDekstop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <MuiPageWrapper>
      {isLoading ? (
        <LoadingState />
      ) : (
        <Stack direction="row" spacing={0} height="100%" width="100%">
          <Box
            sx={{
              width: '100%',
              minHeight: '100%',
              flex: '1 0 50%',
              margin: '0 auto',
              display: 'flex',
              overflow: 'auto',
            }}
          >
            <Stack
              sx={{
                px: { xs: 2, md: 4 },
                width: '100%',
                flex: '1 1 100%',
              }}
            >
              <Link href={PublicRoutes.home}>
                <LogoMain sx={{ textAlign: 'left', mt: 4 }} />
              </Link>

              <Stack
                sx={{
                  py: 5,
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: 'auto auto',
                  width: '100%',
                }}
                gap={5}
              >
                <Stack
                  sx={{
                    width: '100%',
                    maxWidth: '22.5rem',
                  }}
                >
                  {children}
                </Stack>

                {!isDekstop && !!isSideContentPermanent && !!sideContent && (
                  <Stack
                    sx={{
                      width: '100%',
                    }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {sideContent}
                  </Stack>
                )}
              </Stack>

              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                pb={2}
              >
                <Typography variant="body3">© Terr.ai 2024</Typography>

                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Mail01Icon color="primary" sx={{ fontSize: '1rem' }} />
                  <Typography variant="body3">
                    <Link component="a" color="text.secondary" href="mailto:help@terr.ai">
                      help@terr.ai
                    </Link>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Box>

          {isDekstop && !!sideContent && (
            <Box
              sx={{
                flex: '1 0 50%',
                display: 'flex',
                bgcolor: 'grey.100',
              }}
            >
              {sideContent}
            </Box>
          )}
        </Stack>
      )}
    </MuiPageWrapper>
  );
};

export default SplitMuiPage;
