import { Box, SxProps } from '@mui/material';
import { LottieComponentProps } from 'lottie-react';
import React from 'react';

import BaseLottie from './BaseLottie';
import terriAnimation from './lotties/terri.json';

interface Props extends Partial<LottieComponentProps> {
  size?: number;
  loop?: boolean;
  sx?: SxProps;
}

const TerriLottie: React.FC<Props> = ({ size = 28, sx, ...props }) => (
  <Box
    sx={{
      height: size,
      width: size,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...sx,
    }}
  >
    <BaseLottie {...props} animationData={terriAnimation} />
  </Box>
);

export default TerriLottie;
