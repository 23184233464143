import { useCallback } from 'react';

import { TMessageThread, MessageThreadRole, MessageSessionType } from '@/types/message';

import { useGenerateEmailResponse } from '../email/useGenerateEmailResponse';
import { useGenerateSmsResponse } from '../twilio/useGenerateSmsResponse';

export const useOnGenerateAIResponse = () => {
  const generateEmailResponseMutation = useGenerateEmailResponse();
  const generateSmsResponseMutation = useGenerateSmsResponse();

  return useCallback(
    async (_messageThread: TMessageThread) => {
      const tenantMessages = _messageThread.messages.filter(
        message => message.role === MessageThreadRole.tenant
      );

      switch (_messageThread.session.type) {
      case MessageSessionType.email: {
        const emailData = await generateEmailResponseMutation.mutateAsync({
          message: tenantMessages[tenantMessages.length - 1].message,
          location_id: _messageThread.session.property_location_id,
          tenant_id: _messageThread.session.tenant_id,
          session_id: _messageThread.session.session_id,
        });
        return emailData.email;
      }

      case MessageSessionType.sms: {
        const smsData = await generateSmsResponseMutation.mutateAsync({
          message: tenantMessages[tenantMessages.length - 1].message,
          location_id: _messageThread.session.property_location_id,
          tenant_id: _messageThread.session.tenant_id,
          session_id: _messageThread.session.session_id,
        });
        return smsData.sms;
      }
      }
    },
    [generateEmailResponseMutation, generateSmsResponseMutation]
  );
};
