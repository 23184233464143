import { InputLabelProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

const InputLabel: React.FC<InputLabelProps> = props => (
  <Typography {...props} component="label" variant="h5" mb={0.75} />
);

const HelperText: React.FC = props => {
  const {
    // @ts-ignore
    ownerState: { error },
  } = props;

  return (
    <Typography variant="body3" mt={0.75} color={error ? 'error' : 'text.secondary'} {...props} />
  );
};

export default function OverridesSelect(theme: Theme) {
  return {
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        color: 'secondary',
        fullWidth: true,
        displayEmpty: true,
        slots: {
          inputLabel: InputLabel,
          formHelperText: HelperText,
        },
      },
      styleOverrides: {
        root: {
          '& legend > span.notranslate': {
            opacity: 0,
          },

          '& legend > span:not(.notranslate)': {
            display: 'none',
          },

          '& .MuiSelect-select': {
            fontSize: '1rem',
            fontWeight: 400,
            padding: '10px 14px',
          },

          '& .MuiSelect-select em': {
            color: theme.palette.text.disabled,
            fontStyle: 'normal',
          },

          '& .MuiMenuItem-root.Mui-disabled': {
            color: theme.palette.text.disabled,
            fontStyle: 'normal',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[300],
            borderRadius: theme.shape.borderRadius,
            borderWidth: 1,
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
          },

          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[400],
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[400],
            borderWidth: 1,
          },

          '&.Mui-focused': {
            outline: 'none !important',
          },

          '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
            outline: 'none !important',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '10px 16px',
          fontSize: '1rem',
          fontWeight: 400,

          '&.Mui-selected': {
            backgroundColor: '#F3F3F3 !important',
            color: theme.palette.text.primary,
            fontWeight: 400,
          },
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginTop: '4px',
          boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.08)',
          borderRadius: theme.shape.borderRadius,
        },
        list: {
          padding: '8px 0',
        },
      },
    },
  };
}
