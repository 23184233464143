import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid2,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

import { MinusCircleIcon } from '@/assets/icons/MinusCircleIcon';
import { PlusCircleIcon } from '@/assets/icons/PlusCircleIcon';

const faq = [
  {
    question: 'Is there a free trial available?',
    answer:
      'Yes, you can try us for free for 31 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
  },
  // {
  //   question: 'Can I change my plan later?',
  //   answer: '',
  // },
  // {
  //   question: 'What is your cancellation policy?',
  //   answer: '',
  // },
  // {
  //   question: 'Can other info be added to an invoice?',
  //   answer: '',
  // },
  // {
  //   question: 'How does billing work?',
  //   answer: '',
  // },
  // {
  //   question: 'How do I change my account email?',
  //   answer: '',
  // },
];

const FaqSection: React.FC = () => (
  <Grid2 size={12}>
    <Box sx={{ py: 6, textAlign: 'center', maxWidth: 770, margin: '0 auto' }}>
      <Stack spacing={2.5} sx={{ mb: 6 }}>
        <Typography variant="h4">Frequently asked questions</Typography>

        <Typography variant="body1">
          Everything you need to know about the Terr.AI and how to join.
        </Typography>
      </Stack>

      {faq.map(item => (
        <Accordion
          key={item.question}
          sx={{
            border: 'none',
            background: 'transparent',
            pt: 2,
            pb: 3,
            '&:not(:last-child)': { borderBottom: '1px solid #E9EAEB' },
          }}
        >
          <AccordionSummary
            sx={{
              backgroundColor: 'transparent',
              flexDirection: 'row',
              '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                color: 'primary.main',
                transform: 'none',
              },
            }}
            expandIcon={
              <>
                <PlusCircleIcon
                  color="secondary"
                  sx={{ display: 'initial', '.Mui-expanded &': { display: 'none' } }}
                />
                <MinusCircleIcon
                  color="primary"
                  sx={{
                    display: 'none',
                    '.Mui-expanded &': { display: 'initial' },
                  }}
                />
              </>
            }
          >
            <Typography variant="h5">{item.question}</Typography>
          </AccordionSummary>

          <AccordionDetails sx={{ border: 'none', textAlign: 'left' }}>
            <Typography variant="body2">{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  </Grid2>
);

export default FaqSection;
