import { Button, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ACCEPTED_FILE_TYPES } from '@/utils/document';
import { pluralize } from '@/utils/text';

interface Props {
  value?: FileList | null;
  multiple?: boolean;
  onChange: (value: FileList | null) => void;
  disabled?: boolean;
}

const EmailAttachmentInput: React.FC<Props> = ({
  value = null,
  multiple = false,
  onChange,
  disabled,
}) => {
  const [innerValue, setInnerValue] = useState<FileList | null>(value);

  const inputRef = useRef<HTMLInputElement>(null);

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInnerValue(event.target.files);
      onChange(event.target.files);
    },
    [onChange]
  );

  useEffect(() => {
    if (!value) return;

    setInnerValue(value);
  }, [value]);

  const label = useMemo(() => {
    if (!innerValue) return 'No attachment selected';

    if (innerValue.length === 0) return 'No attachment selected';

    if (innerValue.length === 1) return innerValue[0].name;

    return `${innerValue.length} attachments`;
  }, [innerValue]);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <input
        ref={inputRef}
        accept={ACCEPTED_FILE_TYPES.join(',')}
        style={{ display: 'none' }}
        multiple={multiple}
        onChange={onInputChange}
        type="file"
        disabled={disabled}
      />

      <Button variant="outlined" color="secondary" onClick={() => inputRef.current?.click()}>
        Select {pluralize(multiple ? 2 : 1, 'attachment')}
      </Button>

      <Typography variant="body3">{label}</Typography>
    </Stack>
  );
};

export default EmailAttachmentInput;
