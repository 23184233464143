import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { PropertyManagementWithManagerWithLimitedUserView } from '@/api/models/propertyManagement';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_GetAll_PropertyManager = {
  location_id?: Id;
};

export type ApiResponse_GetAll_PropertyManager =
  Array<PropertyManagementWithManagerWithLimitedUserView>;

const METHOD = 'GET';
const ENDPOINT = 'property-manager/all';
export const QUERY_KEY = (params: ApiRequest_GetAll_PropertyManager) =>
  getQueryKey(METHOD, ENDPOINT, params);

export const useGetAllPropertyManagers = (
  params: ApiRequest_GetAll_PropertyManager,
  enabled = true
) => {
  const { session } = useAuth();

  return useQuery({
    enabled: !!params.location_id && enabled && !!session?.access_token,
    queryKey: QUERY_KEY(params),
    queryFn: async () => {
      try {
        const data = await handleApiCall<
          ApiResponse_GetAll_PropertyManager,
          ApiRequest_GetAll_PropertyManager
        >(session, METHOD, getResolvedEndpoint(ENDPOINT, {}), params);

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch property managers.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
