import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useCallback, useState } from 'react';

import { TMessageThread } from '@/types/message';

import IconButton from '@/@mantis/components/@extended/IconButton';
import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { Send01Icon } from '@/assets/icons/Send01Icon';

interface Props {
  messageThread: TMessageThread | null;
  onGenerateAIResponse?: (messageThread: TMessageThread) => Promise<string | undefined>;
  onMessageSend: (message: string, messageThread: TMessageThread) => void;
}

const MessageChatInput: React.FC<Props> = ({
  messageThread,
  onMessageSend,
  onGenerateAIResponse,
}) => {
  const [message, setMessage] = useState('');
  const [isGeneratingAIResponse, setIsGeneratingAIResponse] = useState(false);
  const handleOnSend = useCallback(() => {
    if (!message.trim()) return;
    if (!messageThread) return;

    onMessageSend(message, messageThread);
    setMessage('');
  }, [message, messageThread, onMessageSend]);

  const handleOnGenerateAIResponse = useCallback(async () => {
    if (!messageThread) return;
    if (!onGenerateAIResponse) return;

    setIsGeneratingAIResponse(true);

    try {
      const response = await onGenerateAIResponse?.(messageThread);

      if (response) {
        setMessage(response);
      }
    } catch (error) {
      //
    } finally {
      setIsGeneratingAIResponse(false);
    }
  }, [messageThread, onGenerateAIResponse]);

  return (
    <Stack>
      <TextField
        fullWidth
        multiline
        rows={3}
        placeholder="Your Message..."
        value={message}
        onChange={e => setMessage(e.target.value)}
        disabled={isGeneratingAIResponse}
        onSubmit={handleOnSend}
        variant="standard"
        sx={{
          '& .MuiInput-root:before': { borderBottomColor: 'divider' },
        }}
      />
      <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1} mt={1}>
        {!!onGenerateAIResponse && (
          <LoadingButton
            variant="contained"
            size="small"
            onClick={handleOnGenerateAIResponse}
            loading={isGeneratingAIResponse}
            sx={{ mr: 'auto' }}
          >
            Generate AI Response
          </LoadingButton>
        )}
        {!!messageThread && (
          <Typography variant="caption" color="text.secondary">
            Responding to: {messageThread.session.from.source ?? messageThread.session.from.source}
          </Typography>
        )}

        <IconButton disabled={!message.trim()} color="primary" onClick={handleOnSend} size="large">
          <Send01Icon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default MessageChatInput;
