import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  CircularProgress,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';

import { environment } from '@env';

import { useAuth } from '@/context/AuthProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

interface SendNotificationModalProps {
  open: boolean;
  onClose: () => void;
  locationId: number;
  workflowId: string;
  invoiceId: string;
  nodeId: string;
}

const SendNotificationModal = ({
  open,
  onClose,
  locationId,
  workflowId,
  invoiceId,
  nodeId,
}: SendNotificationModalProps) => {
  const [message, setMessage] = useState('');
  const { session } = useAuth();
  const queryClient = useQueryClient();
  const { selectedPropertyLocation } = usePropertyManager();

  const sendNotification = useMutation({
    mutationFn: async () => {
      const response = await axios.post(
        `${environment.api}/workflow/location/${locationId}/workflow-instance/${workflowId}/${invoiceId}/notify`,
        {
          nodeId,
          message,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['workflowInstances', selectedPropertyLocation?.id],
      });
      onClose();
    },
  });

  const handleSend = () => {
    if (message.trim()) {
      sendNotification.mutate();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Send Notification</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Message"
            multiline
            rows={4}
            fullWidth
            value={message}
            onChange={e => setMessage(e.target.value)}
            disabled={sendNotification.isPending}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={sendNotification.isPending}>
          Cancel
        </Button>
        <Button
          onClick={handleSend}
          variant="contained"
          disabled={!message.trim() || sendNotification.isPending}
          startIcon={sendNotification.isPending ? <CircularProgress size={20} /> : null}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendNotificationModal;
