import { Box, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiRequest_Create_PropertyLocation,
  ApiResponse_Create_PropertyLocation,
  useCreatePropertyLocation,
} from '@/api/endpoints/propertyLocation/useCreatePropertyLocation';
import { PropertyLocationType } from '@/api/enums';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

import FormAddressAutocomplete from '../form/FormAddressAutocomplete';
import FormSelect from '../form/FormSelect';
import FormTextField from '../form/FormTextField';
import ModalWithForm from '../modals/ModalWithForm';

import { propertyLocationDeclaredUnitsCountOptions, propertyLocationTypeOptions } from './options';

interface Props {
  onClose: () => void;
  onPropertyLocationCreated: (propertyLocation: ApiResponse_Create_PropertyLocation) => void;
}

const PropertyLocationCreateModal: React.FC<Props> = ({ onClose, onPropertyLocationCreated }) => {
  const form = useForm<ApiRequest_Create_PropertyLocation>();
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = form;

  const propertyLocationType = watch('type');
  const propertyName = watch('name');
  const addressText = watch('address_text');

  const { setSelectedPropertyLocationId } = usePropertyManager();

  const generatedEmail = useMemo(() => {
    if (propertyName?.trim()) {
      return `${propertyName.trim().toLowerCase().replace(/\s+/g, '')}@mail.terr.ai`;
    }

    if (addressText) {
      // Extract street name from address
      const streetMatch = addressText.match(/^[^,]+/);
      const streetName = streetMatch ? streetMatch[0].trim().split(' ')[0] : '';
      if (streetName) {
        return `${streetName.toLowerCase().replace(/\s+/g, '')}@mail.terr.ai`;
      }
    }

    return '@mail.terr.ai';
  }, [propertyName, addressText]);

  const createPropertyLocationMutation = useCreatePropertyLocation({
    onSuccess: data => {
      onPropertyLocationCreated(data);
      setSelectedPropertyLocationId(data.id);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_Create_PropertyLocation) => createPropertyLocationMutation.mutateAsync(data),
    [createPropertyLocationMutation]
  );

  return (
    <ModalWithForm
      form={form}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="sm"
      title="Add Property Location"
      buttonLabel="Add"
    >
      <FormTextField
        label="Property Name (optional - used for email)"
        placeholder="Enter property name"
        error={!!errors.name}
        helperText={errors.name?.message?.toString()}
        {...register('name')}
        size="small"
        control={control}
        textFieldProps={{
          sx: {
            '& .MuiOutlinedInput-root': {
              borderRadius: 1,
              bgcolor: 'background.paper',
              '&:hover': {
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey.300',
                },
              },
              '&.Mui-focused': {
                outline: 'none !important',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
              },
            },
          },
        }}
      />

      <Box sx={{ mt: -1, mb: 2 }}>
        <Typography variant="caption" color="text.secondary">
          Your property email will be:{' '}
          <Typography component="span" variant="caption" color="primary">
            {generatedEmail}
          </Typography>
          {!propertyName?.trim() && addressText && (
            <Typography component="span" variant="caption" color="text.secondary">
              {' '}
              (using street name from address)
            </Typography>
          )}
        </Typography>
      </Box>

      <FormAddressAutocomplete
        label="Property Mailing Address"
        placeholder="Enter property mailing address"
        error={!!errors.address_text}
        helperText={errors.address_text?.message?.toString()}
        required
        {...register('address_text', {
          required: 'Property Mailing Address is required',
        })}
        size="small"
        control={control}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: 1,
            bgcolor: 'background.paper',
            '&:hover': {
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'grey.300',
              },
            },
            '&.Mui-focused': {
              outline: 'none !important',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
            },
          },
        }}
      />

      <FormSelect
        label="Property Type"
        placeholder="Select property type"
        error={!!errors.type}
        helperText={errors.type?.message?.toString()}
        required
        {...register('type', {
          required: 'Property Type is required',
        })}
        size="small"
        control={control}
        options={propertyLocationTypeOptions}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: 1,
            bgcolor: 'background.paper',
            '&:hover': {
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'grey.300',
              },
            },
            '&.Mui-focused': {
              outline: 'none !important',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
            },
          },
        }}
      />

      {propertyLocationType === PropertyLocationType.MULTI_UNIT && (
        <FormSelect
          label="Units Count"
          placeholder="Select units count"
          error={!!errors.declared_units_count}
          helperText={errors.declared_units_count?.message?.toString()}
          required
          {...register('declared_units_count', {
            required: 'Units Count is required',
          })}
          size="small"
          control={control}
          options={propertyLocationDeclaredUnitsCountOptions}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '0.5rem',
              bgcolor: 'background.paper',
              '&:hover': {
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey.300',
                },
              },
              '&.Mui-focused': {
                outline: 'none !important',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
              },
            },
          }}
        />
      )}
    </ModalWithForm>
  );
};

export default PropertyLocationCreateModal;
