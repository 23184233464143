import { Theme } from '@mui/material';
import MuiAvatar, { AvatarProps } from '@mui/material/Avatar';
import { PaletteColor, styled, useTheme } from '@mui/material/styles';
import { forwardRef, ReactNode } from 'react';

import {
  AvatarTypeProps,
  ColorProps,
  ExtendedStyleProps,
  SizeProps,
} from '@/@mantis/types/extended';
import getColors from '@/@mantis/utils/getColors';

interface AvatarStyleProps extends Omit<ExtendedStyleProps, 'color'> {
  type?: AvatarTypeProps;
  color?: ColorProps | PaletteColor;
}

function getColorStyle({ theme, color, type }: AvatarStyleProps & { theme: Theme }) {
  const colors = getColors(theme, color);
  const { lighter, light, main, contrastText } = colors;

  switch (type) {
  case 'filled':
    return {
      color: contrastText,
      background: main,
    };
  case 'outlined':
    return {
      color: main,
      border: '1px solid',
      borderColor: main,
      background: 'transparent',
    };
  case 'combined':
    return {
      color: main,
      border: '1px solid',
      borderColor: light,
      background: lighter,
    };
  default:
    return {
      color: main,
      background: lighter,
    };
  }
}

function getSizeStyle(size?: SizeProps) {
  switch (size) {
  case 'badge':
    return {
      border: '2px solid',
      fontSize: '0.675rem',
      width: 20,
      height: 20,
    };
  case 'xs':
    return {
      fontSize: '0.75rem',
      width: 24,
      height: 24,
    };
  case 'sm':
    return {
      fontSize: '0.875rem',
      width: 32,
      height: 32,
    };
  case 'lg':
    return {
      fontSize: '1.2rem',
      width: 52,
      height: 52,
    };
  case 'xl':
    return {
      fontSize: '1.5rem',
      width: 64,
      height: 64,
    };
  case 'md':
  default:
    return {
      fontSize: '1rem',
      width: 40,
      height: 40,
    };
  }
}

interface StyleProps {
  color?: ColorProps | PaletteColor;
  type?: AvatarTypeProps;
  size?: SizeProps;
}

const AvatarStyle = styled(MuiAvatar, {
  shouldForwardProp: prop => prop !== 'color' && prop !== 'type' && prop !== 'size',
})(({ color, type, size }: StyleProps) => {
  const theme = useTheme();

  return {
    ...getSizeStyle(size),
    ...getColorStyle({ theme, color, type }),
    ...(size === 'badge' && {
      borderColor: theme.palette.background.default,
    }),
  };
});

export interface Props extends Omit<AvatarProps, 'color'> {
  color?: ColorProps | PaletteColor;
  children?: ReactNode | string;
  type?: AvatarTypeProps;
  size?: SizeProps;
}

const Avatar = forwardRef<HTMLDivElement, Props>(
  ({ children, color = 'primary', type, size = 'md', ...others }, ref) => (
    // @ts-expect-error: Overwriting color prop
    <AvatarStyle color={color} type={type} size={size} {...others} ref={ref}>
      {children}
    </AvatarStyle>
  )
);

export default Avatar;
