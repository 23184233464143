import {
  ConversationDocument,
  MessageSessionType,
  MessageThreadRole,
  TMessageSession,
  TMessageThread,
} from '@/types/message';

import { ApiResponse_GetThread_Conversation } from '@/api/endpoints/conversation/useGetConversationThread';
import { ApiResponse_GetThread_Email } from '@/api/endpoints/email/useGetEmailThread';
import { ApiResponse_GetAll_EmailSession } from '@/api/endpoints/emailSession/useGetAllEmailSessions';
import { ApiResponse_GetAll_SmsSession } from '@/api/endpoints/smsSession/useGetAllSmsSessions';
import { ApiResponse_GetThread_Sms } from '@/api/endpoints/twilio/useGetSmsThread';

export const getMessageSessionFromEmailSession = (
  emailSession: ApiResponse_GetAll_EmailSession[0]
): TMessageSession => ({
  type: MessageSessionType.email,
  session_id: emailSession.session_id,
  tenant_id: emailSession.tenant.id,
  building_id: emailSession.tenant.unit.building.id,
  property_location_id: emailSession.tenant.unit.building.property_location_id,
  from: {
    name: emailSession.tenant?.full_name ?? emailSession.from_name,
    source: emailSession?.tenant?.contacts[0]?.email ?? emailSession.from_email,
    to:
      emailSession.from_email === emailSession.property_email
        ? emailSession?.tenant?.contacts[0]?.email ?? emailSession.from_email
        : emailSession.from_email,
  },
  subject: emailSession.subject,
  created_at: emailSession.created_at,
  updated_at: emailSession.updated_at,
});

export const mockMessageSessionFromEmailThread = (
  emailThread: ApiResponse_GetThread_Email
): TMessageSession => ({
  type: MessageSessionType.email,
  session_id: emailThread.email_thread.external_id,
  tenant_id: -1, // Mock it
  building_id: -1, // Mock it
  property_location_id: -1, // Mock it
  from: {
    name: emailThread.emailSession.from_name,
    source: emailThread.emailSession.from_email,
    to: emailThread.emailSession.property_email,
  },
  subject: emailThread.email_thread.title,
  created_at: emailThread.email_thread.created_at,
  updated_at: emailThread.email_thread.updated_at,
});

export const getMessageThreadFromEmailThread = (
  emailThread?: ApiResponse_GetThread_Email | null,
  emailSession?: ApiResponse_GetAll_EmailSession[0]
): TMessageThread | null => {
  if (!emailThread) return null;

  return {
    session: emailSession
      ? getMessageSessionFromEmailSession(emailSession)
      : mockMessageSessionFromEmailThread(emailThread),
    messages: emailThread.emails.map(email => ({
      id: email.id,
      external_id: emailThread.email_thread.external_id,
      message: email.message,
      created_at: new Date(email.created_at),
      updated_at: new Date(email.updated_at),
      role: MessageThreadRole[email.role as keyof typeof MessageThreadRole],
    })),
  } as TMessageThread;
};

export const getMessageSessionFromSmsSession = (
  smsSession: ApiResponse_GetAll_SmsSession[0]
): TMessageSession => ({
  type: MessageSessionType.sms,
  session_id: smsSession.session_id,
  tenant_id: smsSession.tenant.id,
  building_id: smsSession.tenant.unit.building.id,
  property_location_id: smsSession.tenant.unit.building.property_location_id,
  from: {
    name: smsSession.tenant.full_name,
    source: smsSession.from_phone_number,
    to: smsSession.to_phone_number,
  },
  subject: '',
  created_at: smsSession.created_at,
  updated_at: smsSession.updated_at,
});

export const mockMessageSessionFromSmsThread = (
  smsThread: ApiResponse_GetThread_Sms
): TMessageSession => {
  const message = smsThread.messages[0];

  return {
    type: MessageSessionType.sms,
    session_id: message?.external_id ?? -1,
    tenant_id: -1, // Mock it
    building_id: -1, // Mock it
    property_location_id: -1, // Mock it
    from: {
      name: message?.role ?? 'Unknown',
      source: '', // Mock it
      to: '', // Mock it
    },
    subject: '',
    created_at: smsThread.messages[0]?.created_at,
    updated_at: smsThread.messages[0]?.updated_at,
  };
};

export const getMessageThreadFromSmsThread = (
  smsThread?: ApiResponse_GetThread_Sms | null,
  smsSession?: ApiResponse_GetAll_SmsSession[0]
): TMessageThread | null => {
  if (!smsThread) return null;

  return {
    session: smsSession
      ? getMessageSessionFromSmsSession(smsSession)
      : mockMessageSessionFromSmsThread(smsThread),
    messages: smsThread.messages.map(sms => ({
      id: sms.id,
      external_id: sms.external_id,
      message: sms.message,
      created_at: new Date(sms.created_at),
      updated_at: new Date(sms.updated_at),
      role: MessageThreadRole[sms.role as keyof typeof MessageThreadRole],
    })),
  } as TMessageThread;
};

export const getMessageThreadFromConversationThread = (
  conversationThread?: ApiResponse_GetThread_Conversation | null
): TMessageThread | null => {
  if (!conversationThread) return null;

  return {
    session: {
      type: MessageSessionType.vendor,
      session_id: conversationThread.conversation_sid,
      tenant_id: -1,
      building_id: -1,
      property_location_id: conversationThread.property_location_id ?? -1,
      from: {
        name: conversationThread.friendly_name,
        source: conversationThread.conversation_sid.replace('vendor_', ''),
        to: conversationThread.property_email,
      },
      subject: conversationThread.friendly_name,
      created_at: conversationThread.created_at,
      updated_at: conversationThread.updated_at,
    },
    messages: conversationThread.messages.map(message => ({
      id: message.id,
      external_id: message.message_sid,
      message: message.body ?? '',
      created_at: new Date(message.created_at),
      updated_at: new Date(message.updated_at),
      role: MessageThreadRole[message.sender.toLocaleLowerCase() as keyof typeof MessageThreadRole],
      sender_name: message.author,
      participant_sid: message.participant_sid,
      media_url: message.media_url,
      documents: message.documents as unknown as ConversationDocument[],
    })),
  };
};
