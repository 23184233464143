import { Box, Typography, Skeleton, Button } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';

import { useGetLeaseDetails } from '@/api/endpoints/resident/useGetLeaseDetails';
import { CreditCard01Icon } from '@/assets/icons/CreditCard01Icon';
import { ResidentRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { useResident } from '@/context/ResidentProvider';

import DashboardSection from './DashboardSection';

const DashboardLeaseDetails: React.FC = () => {
  const { data, isLoading, error } = useGetLeaseDetails();
  const { isResident } = useAuth();
  const { tenant } = useResident();

  const formatDate = (dateString: string) => {
    try {
      return format(parseISO(dateString), 'MMMM d, yyyy');
    } catch (e) {
      return dateString;
    }
  };

  return (
    <DashboardSection title="Lease Details">
      {isLoading && (
        <Box>
          <Skeleton variant="text" height={40} />
          <Skeleton variant="text" height={40} />
          <Skeleton variant="text" height={40} />
        </Box>
      )}

      {error && <Typography color="error">Error loading lease details</Typography>}

      {!isLoading && !error && !data && (
        <Typography variant="body1">
          Please notify your landlord or property manager to upload your lease.
        </Typography>
      )}

      {data && (
        <Box>
          {/* Property Location Address (for residents, get from resident provider) */}
          {isResident && tenant?.unit?.building && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">
                Property Location
              </Typography>
              <Typography variant="body1">
                {tenant.unit.building.location?.address_text ||
                  tenant.unit.building.address_text ||
                  'N/A'}
              </Typography>
            </Box>
          )}

          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2, mb: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle2" color="text.secondary">
                Lease Start Date
              </Typography>
              <Typography variant="body1">{formatDate(data.start_date)}</Typography>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle2" color="text.secondary">
                Lease End Date
              </Typography>
              <Typography variant="body1">{formatDate(data.end_date)}</Typography>
            </Box>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography variant="subtitle2" color="text.secondary">
                  Monthly Rent
                </Typography>
                <Typography variant="body1">{data.rent}</Typography>
              </Box>
              {isResident && (
                <Button
                  variant="contained"
                  size="small"
                  component={RouterLink}
                  to={ResidentRoutes.payments}
                  startIcon={<CreditCard01Icon />}
                >
                  Pay Rent
                </Button>
              )}
            </Box>
          </Box>

          {data.duration && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">
                Lease Duration
              </Typography>
              <Typography variant="body1">{data.duration}</Typography>
            </Box>
          )}
        </Box>
      )}
    </DashboardSection>
  );
};

export default DashboardLeaseDetails;
