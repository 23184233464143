import { Button, Chip, Stack } from '@mui/material';
import React from 'react';

import { VaultDocument } from '@/types/vault';

import { useDeleteVaultDocumentTag } from '@/api/endpoints/vault/useDeleteVaultDocumentTag';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

interface VaultDocumentTagsProps {
  document: VaultDocument;
  onEditClick?: (document: VaultDocument) => void;
}

export const VaultDocumentTags: React.FC<VaultDocumentTagsProps> = ({ document, onEditClick }) => {
  const { selectedPropertyLocation } = usePropertyManager();
  const deleteTagMutation = useDeleteVaultDocumentTag(
    selectedPropertyLocation
      ? {
        property_location_id: selectedPropertyLocation.id,
      }
      : undefined
  );

  const findTagId = (
    entityId: string | number,
    type: 'property_location' | 'building' | 'unit' | 'tenant' | 'property_manager'
  ) => {
    const tag = document.tags.find(t => {
      switch (type) {
      case 'property_location':
        return t.property_location_id === Number(entityId);
      case 'building':
        return t.building_id === Number(entityId);
      case 'unit':
        return t.unit_id === Number(entityId);
      case 'tenant':
        return t.tenant_id === Number(entityId);
      case 'property_manager':
        return t.property_manager_id === Number(entityId);
      default:
        return false;
      }
    });
    return tag?.id;
  };

  const handleDeleteTag = (
    entityId: string | number,
    type: 'property_location' | 'building' | 'unit' | 'tenant' | 'property_manager'
  ) => {
    const tagId = findTagId(entityId, type);
    if (tagId) {
      deleteTagMutation.mutate({
        documentId: document.id,
        tagId,
      });
    }
  };

  const hasAnyTags =
    document.property_locations?.length ||
    document.buildings?.length ||
    document.units?.length ||
    document.tenants?.length ||
    document.property_managers?.length;

  if (!hasAnyTags) {
    return onEditClick ? (
      <Button size="small" onClick={() => onEditClick(document)}>
        Add Tags
      </Button>
    ) : null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
      {document.property_locations?.map(location => (
        <Chip
          key={location.id}
          label={location.name}
          size="small"
          onDelete={() => handleDeleteTag(location.id, 'property_location')}
        />
      ))}
      {document.buildings?.map(building => (
        <Chip
          key={building.id}
          label={building.name || building.address_text}
          size="small"
          onDelete={() => handleDeleteTag(building.id, 'building')}
        />
      ))}
      {document.units?.map(unit => (
        <Chip
          key={unit.id}
          label={unit.name}
          size="small"
          onDelete={() => handleDeleteTag(unit.id, 'unit')}
        />
      ))}
      {document.tenants?.map(tenant => (
        <Chip
          key={tenant.id}
          label={tenant.full_name}
          size="small"
          onDelete={() => handleDeleteTag(tenant.id, 'tenant')}
        />
      ))}
      {document.property_managers?.map(manager => (
        <Chip
          key={manager.id}
          label={manager.limitedUser.full_name}
          size="small"
          onDelete={() => handleDeleteTag(manager.id, 'property_manager')}
        />
      ))}
      {onEditClick && (
        <Button size="small" onClick={() => onEditClick(document)}>
          Edit
        </Button>
      )}
    </Stack>
  );
};

export default VaultDocumentTags;
