import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY } from './useGetAllRules';

export type ApiResponse_Delete_Rule = never;

const METHOD = 'DELETE';
const ENDPOINT = 'rule/:id';

export const useDeleteRule = (id: Id, stateFns?: MutationStateFns<ApiResponse_Delete_Rule>) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      handleApiCall<ApiResponse_Delete_Rule>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {
          id: id.toString(),
        })
      ),
    onSuccess: data => {
      enqueueSnackbar('Rule deleted successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY(),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to delete rule', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
