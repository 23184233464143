import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FetchStatus, QueryStatus } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import { TMessageSession, TMessageThread } from '@/types/message';

import MessageChatContent from './MessageChatContent';
import MessageChatDrawer from './MessageChatDrawer';

interface Props {
  messageSessions: TMessageSession[];
  messageThread: TMessageThread | null;
  fetchSessionsQueryStatus: QueryStatus | FetchStatus;
  fetchThreadQueryStatus: QueryStatus | FetchStatus;
  selectedMessageSession: TMessageSession | null;
  onMessageSend: (message: string, messageThread: TMessageThread) => void;
  onMessageSessionSelect: (messageSession: TMessageSession) => void;
}

const MessageChat: React.FC<Props> = ({
  messageSessions,
  fetchSessionsQueryStatus,
  fetchThreadQueryStatus,
  selectedMessageSession,
  onMessageSend,
  onMessageSessionSelect,
  messageThread,
}) => {
  const theme = useTheme();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const toggleIsDrawerOpen = useCallback(() => setIsDrawerOpen(flag => !flag), []);

  // close sidebar when widow size below 'md' breakpoint
  useEffect(() => {
    setIsDrawerOpen(!downLG);
  }, [downLG]);

  return (
    <Box sx={{ display: 'flex', flex: '1 1 100px' }}>
      <MessageChatDrawer
        isDrawerOpen={isDrawerOpen}
        isLoading={fetchSessionsQueryStatus === 'pending'}
        messageSessions={messageSessions}
        selectedMessageSession={selectedMessageSession}
        toggleIsDrawerOpen={toggleIsDrawerOpen}
        onMessageSessionSelect={onMessageSessionSelect}
      />

      <MessageChatContent
        messageThread={messageThread}
        isDrawerOpen={isDrawerOpen}
        isFailed={fetchSessionsQueryStatus === 'error' || fetchThreadQueryStatus === 'error'}
        isLoading={fetchSessionsQueryStatus === 'pending' || fetchThreadQueryStatus === 'pending'}
        onMessageSend={onMessageSend}
        toggleIsDrawerOpen={toggleIsDrawerOpen}
      />
    </Box>
  );
};

export default MessageChat;
