import { Stack, TextField, MenuItem } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { MakePaymentFormData } from '@/types/payment';

interface PaymentFormProps {
  form: ReturnType<typeof useForm<MakePaymentFormData>>;
}

const PaymentForm: FC<PaymentFormProps> = ({ form }) => {
  const { control } = form;

  return (
    <Stack spacing={3}>
      <Controller
        name="amount"
        control={control}
        rules={{
          required: 'Amount is required',
          min: { value: 0.01, message: 'Amount must be greater than 0' },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Amount"
            type="number"
            error={!!error}
            helperText={error?.message}
            inputProps={{ min: 0, step: 0.01 }}
            fullWidth
          />
        )}
      />

      <Controller
        name="type"
        control={control}
        rules={{ required: 'Payment type is required' }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            select
            label="Payment Type"
            error={!!error}
            helperText={error?.message}
            fullWidth
          >
            <MenuItem value="rent">Rent</MenuItem>
            <MenuItem value="deposit">Deposit</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </TextField>
        )}
      />

      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextField {...field} label="Description" multiline rows={3} fullWidth />
        )}
      />
    </Stack>
  );
};

export default PaymentForm;
