import React from 'react';

import BasePublicMuiPage from '../common/BasePublicMuiPage';

import CommunicationsSection from './sections/CommunicationsSection';
import FaqSection from './sections/FaqSection';
import FeaturesSection from './sections/FeaturesSection';
import FooterSection from './sections/FooterSection';
import HaveQuestionsSection from './sections/HaveQuestionsSection';
import LandingHeroSection from './sections/LandingHeroSection';
import NavSection from './sections/NavSection';
import RentCollectionSection from './sections/RentCollectionSection';
import WhyUsSection from './sections/WhyUsSection';

const LandingPage: React.FC = () => (
  <BasePublicMuiPage>
    <NavSection />

    <LandingHeroSection />

    <WhyUsSection />

    <RentCollectionSection />

    <FeaturesSection />

    <CommunicationsSection />

    <FaqSection />

    <HaveQuestionsSection />

    <FooterSection />
  </BasePublicMuiPage>
);

export default LandingPage;
