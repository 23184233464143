import { Box, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import React, { useEffect, useMemo, useRef } from 'react';

import { environment } from '@env';

import { GeocodeAPIV6Response } from '@/types/mapbox';

interface Props {
  addressText?: string;
  mapStyle?: string;
  interactive?: boolean;
  zoom?: number;
  bounds?: LngLatBoundsLike;
}

const DEFAULT_BOUNDS: LngLatBoundsLike = [
  [-125, 24],
  [-66, 50],
];

const MapboxAddressMap: React.FC<Props> = ({
  addressText,
  mapStyle = 'mapbox://styles/mapbox/standard',
  interactive = false,
  zoom = 4,
  bounds = DEFAULT_BOUNDS,
}) => {
  const theme = useTheme();
  const mapContainer = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const markerRef = useRef<mapboxgl.Marker | null>(null);

  const fetchAddressFeature = useQuery({
    enabled: !!addressText,
    queryKey: ['GET', 'geocode', addressText],
    queryFn: async () => {
      const response = await axios.get<GeocodeAPIV6Response>(
        'https://api.mapbox.com/search/geocode/v6/forward?',
        {
          params: new URLSearchParams({
            q: addressText ?? '',
            access_token: environment.mapboxApiKey,
            autocomplete: 'false',
            country: 'US',
            format: 'geojson',
            language: 'en-US',
            limit: '1',
            types: 'address',
          }),
        }
      );

      return response.data.features[0];
    },
  });

  const coordinates = useMemo(
    () => fetchAddressFeature.data?.geometry?.coordinates,
    [fetchAddressFeature.data]
  );

  useEffect(() => {
    if (!mapContainer.current) return;

    // Hide the container, so we don't get a screen flicker
    mapContainer.current.style.opacity = '0';

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      accessToken: environment.mapboxApiKey,
      style: mapStyle,
      interactive,
      zoom,
      bounds,
      trackResize: true,
    });

    mapRef.current.on('load', () => {
      // Remove Mapbox logo
      const logoEl = mapContainer.current?.querySelector('.mapboxgl-ctrl-logo');
      if (logoEl) {
        logoEl.remove();
      }

      // Remove Mapbox attribution
      const attrEl = mapContainer.current?.querySelector('.mapboxgl-ctrl-attrib');
      if (attrEl) {
        attrEl.remove();
      }

      // Resize map so it fits the container
      mapRef.current?.resize();

      // And show the container after resizing
      if (mapContainer.current) {
        mapContainer.current.style.opacity = '1';
      }
    });

    return () => {
      // Clean up marker
      if (markerRef.current) {
        markerRef.current.remove();
        markerRef.current = null;
      }

      // Clean up map
      mapRef.current?.remove();
    };
  }, [mapStyle, interactive, zoom, bounds]);

  useEffect(() => {
    if (!mapRef.current) return;

    // Clean up previous marker
    if (markerRef.current) {
      markerRef.current.remove();
      markerRef.current = null;
    }

    if (!coordinates) {
      mapRef.current.easeTo({
        center: [
          ((bounds as number[][])[0][0] + (bounds as number[][])[1][0]) / 2,
          ((bounds as number[][])[0][1] + (bounds as number[][])[1][1]) / 2,
        ],
        zoom,
        duration: 1000,
        curve: 1.42,
      });
      return;
    }

    mapRef.current.easeTo({
      center: coordinates,
      zoom: 16,
      duration: 1000,
      curve: 1.42,
    });

    markerRef.current = new mapboxgl.Marker({
      anchor: 'center',
      draggable: false,
      color: theme.palette.primary.main,
      pitchAlignment: 'map',
      rotationAlignment: 'map',
    })
      .setLngLat(coordinates)
      .addTo(mapRef.current);
  }, [coordinates, theme, bounds, zoom]);

  return (
    <Box
      ref={mapContainer}
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        transition: 'opacity 300ms ease-in',
        '& .mapboxgl-marker': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
      }}
    />
  );
};

export default MapboxAddressMap;
