import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { TenantModel } from '@/api/models/tenant';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY as GET_ALL_QUERY_KEY } from './useGetAllTenants';
import { QUERY_KEY as GET_ONE_QUERY_KEY } from './useGetOneTenant';

export type ApiResponse_Delete_Tenant = TenantModel & {
  is_deleted?: boolean;
};

const METHOD = 'DELETE';
const ENDPOINT = 'tenant/:id';

export const useDeleteTenant = (
  id: TenantModel['id'],
  stateFns?: MutationStateFns<ApiResponse_Delete_Tenant>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      handleApiCall<ApiResponse_Delete_Tenant>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: id.toString() })
      ),
    onSuccess: data => {
      enqueueSnackbar(`Tenant ${data.is_deleted ? 'deleted' : 'archived'} successfully`, {
        variant: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: GET_ALL_QUERY_KEY({ unit_id: data.unit_id }),
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: GET_ONE_QUERY_KEY(id),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to delete tenant', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
