import { Card, Stack, Typography } from '@mui/material';
import React from 'react';

import FeatureItem, { Props as FeatureItemProps } from './FeatureItem';

interface Props {
  title: string;
  image: React.ReactNode;
  features: FeatureItemProps[];
}
const Features: React.FC<Props> = ({ title, image, features }) => (
  <Card sx={{ width: '100%', height: '100%' }}>
    <Stack spacing={4} p={4}>
      <Typography variant="h4" textAlign="center">
        {title}
      </Typography>

      <Stack justifyContent="center">{image}</Stack>

      <Stack spacing={3}>
        {features.map(feature => (
          <FeatureItem key={feature.title} {...feature} />
        ))}
      </Stack>
    </Stack>
  </Card>
);

export default Features;
