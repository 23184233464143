import { Stack, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useForm } from 'react-hook-form';

import { CalendarEvent } from '@/types/calendar';

import { useDeleteCalendarEvent } from '@/api/endpoints/calendar/useDeleteCalendarEvent';
import { Building01Icon } from '@/assets/icons/Building01Icon';

import ModalWithForm from '@/components/modals/ModalWithForm';

interface Props {
  event: CalendarEvent;
  onClose: () => void;
  onEventDeleted?: () => void;
}

const CalendarEventEditModal: React.FC<Props> = ({ event, onClose, onEventDeleted }) => {
  const form = useForm({
    defaultValues: {
      id: event.id,
    },
  });

  const deleteCalendarEventMutation = useDeleteCalendarEvent({
    onSuccess: () => {
      onEventDeleted?.();
      onClose();
    },
  });

  const handleSubmit = async (data: { id: number }) =>
    deleteCalendarEventMutation.mutateAsync(data.id);

  return (
    <ModalWithForm
      title={event.title}
      form={form}
      maxWidth="sm"
      onClose={onClose}
      onSubmit={handleSubmit}
      buttonColor="error"
      buttonLabel="Delete Event"
    >
      <Stack spacing={2.5}>
        <Stack spacing={1}>
          <Typography variant="dataGridSmall" color="text.secondary">
            Date
          </Typography>
          <Typography variant="dataGridBody">
            {format(parseISO(event.start), 'PPP')}
            {parseISO(event.start).toISOString().split('T')[0] !==
              parseISO(event.end).toISOString().split('T')[0] &&
              ` - ${format(parseISO(event.end), 'PPP')}`}
          </Typography>
        </Stack>

        {event.description && (
          <Stack spacing={1}>
            <Typography variant="dataGridSmall" color="text.secondary">
              Description
            </Typography>
            <Typography variant="dataGridBody">{event.description}</Typography>
          </Stack>
        )}

        {(event.buildingId || event.unitId || event.tenantId) && (
          <Stack spacing={1}>
            <Typography variant="dataGridSmall" color="text.secondary">
              Associated With
            </Typography>
            {event.buildingId && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Building01Icon sx={{ fontSize: 14, color: 'text.secondary' }} />
                <Typography variant="dataGridBody">
                  {event.building?.name || event.buildingId}
                </Typography>
              </Stack>
            )}
            {event.unitId && (
              <Typography variant="dataGridBody">
                Unit: {event.unit?.name || event.unitId}
              </Typography>
            )}
            {event.tenantId && (
              <Typography variant="dataGridBody">
                Tenant: {event.tenant?.full_name || event.tenantId}
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
    </ModalWithForm>
  );
};

export default CalendarEventEditModal;
