import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { TypographyVariantsOptions } from '@mui/material/styles';

export const fontDefinitions = css`
  @font-face {
    font-family: 'Nohemi';
    src: url('/assets/fonts/Nohemi-VF.ttf');
    font-weight: 100 1000;
  }
`;

export default function ThemeTypography(theme: Theme): TypographyVariantsOptions {
  return {
    htmlFontSize: 16,
    fontFamily: 'Inter',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,

    h1: {
      fontFamily: 'Nohemi',
      fontSize: '4rem',
      fontWeight: 500,
      lineHeight: 1,
      letterSpacing: '0.01em',
      color: theme.palette.text.primary,
      strong: {
        fontWeight: 600,
      },
    },
    h2: {
      fontFamily: 'Nohemi',
      fontSize: '4rem',
      fontWeight: 600,
      lineHeight: 1,
      letterSpacing: '0.01em',
      color: theme.palette.text.primary,
    },
    h3: {
      fontFamily: 'Nohemi',
      fontSize: '2.25rem',
      fontWeight: 500,
      lineHeight: 1.25,
      letterSpacing: '0.01em',
      color: theme.palette.text.primary,
      strong: {
        fontWeight: 600,
      },
    },
    h4: {
      fontFamily: 'Nohemi',
      fontSize: '2.25rem',
      fontWeight: 600,
      lineHeight: 1.25,
      letterSpacing: '0.01em',
      color: theme.palette.text.primary,
    },
    h5: {
      fontFamily: 'Nohemi',
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.4,
      letterSpacing: '0.01em',
      color: theme.palette.text.primary,
    },
    h6: {
      fontFamily: 'Nohemi',
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: '0.01em',
      color: theme.palette.text.primary,
      strong: {
        fontWeight: 600,
      },
    },
    subtitle1: {
      fontFamily: 'Inter',
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1,
      color: theme.palette.text.primary,
    },
    subtitle2: {
      fontFamily: 'Inter',
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1,
      color: theme.palette.text.primary,
      strong: {
        fontWeight: 600,
      },
    },
    subtitle3: {
      fontFamily: 'Inter',
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1,
      color: theme.palette.text.primary,
    },
    caption: {
      fontFamily: 'Inter',
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: 1.4,
      color: theme.palette.text.secondary,
    },
    body1: {
      fontFamily: 'Inter',
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: theme.palette.text.secondary,
      strong: {
        fontWeight: 600,
      },
    },
    body2: {
      fontFamily: 'Inter',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: theme.palette.text.secondary,
      strong: {
        fontWeight: 600,
      },
    },
    body3: {
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: theme.palette.text.secondary,
    },
    body4: {
      fontFamily: 'Inter',
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.5,
      color: theme.palette.text.secondary,
    },
    overline: {
      fontFamily: 'Inter',
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.4,
      textTransform: 'none',
      color: theme.palette.primary.main,
    },
    button: {
      fontFamily: 'Inter',
      textTransform: 'none',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.5,
      textWrap: 'nowrap',
    },
    badge: {
      fontFamily: 'Inter',
      textTransform: 'none',
      fontWeight: 500,
      fontSize: '0.5rem',
      lineHeight: 1,
      textWrap: 'nowrap',
    },
    bigNumber: {
      fontFamily: 'Nohemi',
      fontSize: '3.75rem',
      fontWeight: 600,
      lineHeight: 1,
      color: theme.palette.primary.main,
    },
    mediumNumber: {
      fontFamily: 'Nohemi',
      fontSize: '1.875rem',
      fontWeight: 600,
      lineHeight: 1,
      color: theme.palette.primary.main,
    },
    dataGridTitle: {
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.5,
      color: theme.palette.text.primary,
    },
    dataGridBody: {
      fontFamily: 'Inter',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: theme.palette.text.secondary,
    },
    dataGridTitleSmall: {
      fontFamily: 'Inter',
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: 1.5,
      color: theme.palette.text.secondary,
    },
    dataGridSmall: {
      fontFamily: 'Inter',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: theme.palette.text.secondary,
    },
  };
}
