import { Button, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { TMessageThreadMessage } from '@/types/message';

import { Uuid } from '@/api/utils/sql';
import { FileAttachment01Icon } from '@/assets/icons/FileAttachment01Icon';

import DocumentViewModal from '../document/DocumentViewModal';

interface Props {
  message: TMessageThreadMessage;
  isExternalMessage: boolean;
}

const MessageThreadMessageContent: React.FC<Props> = ({ message, isExternalMessage }) => {
  const [documentIdToView, setDocumentIdToView] = useState<Uuid | null>(null);
  const [isDocumentViewModalOpen, setIsDocumentViewModalOpen] = useState(false);

  const handleOnAttachmentClick = useCallback((documentId: string) => {
    setDocumentIdToView(documentId);
    setIsDocumentViewModalOpen(true);
  }, []);

  return (
    <>
      <Typography
        variant="body3"
        color={isExternalMessage ? 'text.primary' : 'white'}
        sx={{ overflowWrap: 'anywhere' }}
      >
        {message.message}
      </Typography>

      {!!message.media_url && (
        <Stack direction="row" alignItems="center" spacing={1} mt={1}>
          <Button
            variant="outlined"
            size="small"
            color={isExternalMessage ? 'secondary' : 'primary'}
            startIcon={<FileAttachment01Icon />}
            sx={{ textTransform: 'none' }}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onClick={() => handleOnAttachmentClick(message.media_url!)}
          >
            View Attachment
          </Button>
        </Stack>
      )}

      {!!message.documents?.length && (
        <Stack direction="row" spacing={1} mt={1} flexWrap="wrap">
          {message.documents.map(document => (
            <Button
              variant="outlined"
              size="small"
              color={isExternalMessage ? 'secondary' : 'primary'}
              startIcon={<FileAttachment01Icon />}
              sx={{ textTransform: 'none' }}
              onClick={() => handleOnAttachmentClick(document.id)}
            >
              {document.original_name}
            </Button>
          ))}
        </Stack>
      )}

      {isDocumentViewModalOpen && !!documentIdToView && (
        <DocumentViewModal
          id={documentIdToView}
          onClose={() => setIsDocumentViewModalOpen(false)}
        />
      )}
    </>
  );
};

export default MessageThreadMessageContent;
