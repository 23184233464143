import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

import { WysiwygEditor } from '../WysiwygEditor/WsyiwygEditor';

import FormField, { FormFieldProps, getControllerProps, getFormFieldProps } from './FormField';

interface Props extends FormFieldProps {
  placeholder?: string;
}

const FormTextField = forwardRef<HTMLInputElement, Props>(
  ({ color = 'secondary', placeholder = 'Enter value', ...props }, ref) => (
    <FormField {...getFormFieldProps(props)} ref={ref}>
      <Controller
        {...getControllerProps(props)}
        render={({ field }) => (
          <WysiwygEditor disabled={props.disabled} placeholder={placeholder} {...field} />
        )}
      />
    </FormField>
  )
);

export default FormTextField;
