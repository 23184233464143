import { Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiRequest_Update_QuestionAnswer,
  ApiResponse_Update_QuestionAnswer,
  useUpdateQuestionAnswer,
} from '@/api/endpoints/questionAnswer/useUpdateQuestionAnswer';
import { QuestionAnswerModel } from '@/api/models/ai/questionAnswer';

import FormTextField from '../form/FormTextField';
import ModalWithForm from '../modals/ModalWithForm';

type Props = {
  questionAnswer: QuestionAnswerModel;
  onClose: () => void;
  onQuestionAnswerUpdated?: (questionAnswers: ApiResponse_Update_QuestionAnswer) => void;
};

const QuestionAnswerUpdateModal: React.FC<Props> = ({
  questionAnswer: questionAnswers,
  onClose,
  onQuestionAnswerUpdated,
}) => {
  const form = useForm<ApiRequest_Update_QuestionAnswer>({
    defaultValues: {
      question: questionAnswers?.question || '',
      answer: questionAnswers?.answer || '',
    },
  });

  const {
    control,
    register,
    formState: { errors },
  } = form;

  const updateQuestionAnswerMutation = useUpdateQuestionAnswer(questionAnswers.id, {
    onSuccess: data => {
      onQuestionAnswerUpdated?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_Update_QuestionAnswer) => updateQuestionAnswerMutation.mutateAsync(data),
    [updateQuestionAnswerMutation]
  );

  return (
    <ModalWithForm
      form={form}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="sm"
      title="Edit Q&A Item"
    >
      <Typography variant="body2">
        Update the Q&A item. Only changed fields will be sent to the server.
      </Typography>

      <FormTextField
        label="Question / Topic"
        error={!!errors.question}
        helperText={errors.question?.message?.toString()}
        control={control}
        required
        {...register('question', {
          required: 'Question is required',
        })}
        size="small"
      />

      <FormTextField
        label="Answer / Guidance"
        multiline
        rows={4}
        error={!!errors.answer}
        helperText={errors.answer?.message?.toString()}
        control={control}
        required
        {...register('answer', {
          required: 'Answer is required',
        })}
        size="small"
      />
    </ModalWithForm>
  );
};

export default QuestionAnswerUpdateModal;
