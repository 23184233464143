import { Stack } from '@mui/material';

import { HomeLineIcon } from '@/assets/icons/HomeLineIcon';
import { useResident } from '@/context/ResidentProvider';

import DashboardCard from '@/components/dashboard/DashboardCard';
import DashboardLeaseDetails from '@/components/dashboard/DashboardLeaseDetails';

const DashboardPage: React.FC = () => {
  const { tenant } = useResident();

  return (
    <Stack spacing={3}>
      <DashboardLeaseDetails />

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={3}
        sx={{
          flexWrap: 'wrap',
          '& > *': {
            minWidth: { xs: '100%', sm: 'calc(50% - 12px)', md: 'calc(33% - 16px)' },
          },
        }}
      >
        {!!tenant && (
          <DashboardCard title="Your Unit" icon={<HomeLineIcon />} iconTitle={tenant.unit.name} />
        )}
      </Stack>
    </Stack>
  );
};

export default DashboardPage;
