import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { TDocumentCategory } from '@/types/document';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

const METHOD = 'GET';
const ENDPOINT = 'document/categories';

export type ApiResponse_GetDocumentCategories = TDocumentCategory[];

export const QUERY_KEY = () => getQueryKey(METHOD, ENDPOINT);

export const useGetDocumentCategories = (enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    queryKey: QUERY_KEY(),
    enabled: enabled && !!session?.access_token,
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetDocumentCategories>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {})
        );

        return data;
      } catch (error) {
        enqueueSnackbar('Failed to fetch document categories.', { variant: 'error' });
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      }
    },
  });
};
