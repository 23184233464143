import { ListItemAvatar, ListItemText, Skeleton } from '@mui/material';

import { TMessageSession } from '@/types/message';

import Avatar from '@/@mantis/components/@extended/Avatar';
import { getPaletterColorFromString } from '@/utils/color';
import { getFromNow } from '@/utils/date';
import { getInitialsFromFullName } from '@/utils/user';

interface Props {
  messageSession: TMessageSession | null;
  isLoading?: boolean;
  noDataText?: string;
}

const getSkeletonWidth = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const MessageSessionListItemContent: React.FC<Props> = ({
  messageSession,
  isLoading = false,
  noDataText = 'No data available',
}) => {
  if (isLoading) {
    return (
      <>
        <ListItemAvatar>
          <Skeleton
            animation={isLoading ? 'wave' : false}
            variant="circular"
            width={40}
            height={40}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Skeleton
              animation={isLoading ? 'wave' : false}
              height={24}
              width={getSkeletonWidth(50, 100)}
            />
          }
          secondary={
            <Skeleton
              animation={isLoading ? 'wave' : false}
              height={20}
              width={getSkeletonWidth(80, 120)}
            />
          }
        />
      </>
    );
  }

  if (!messageSession) {
    return (
      <>
        <ListItemAvatar>
          <Avatar type="filled" sx={{ width: 40, height: 40 }}>
            ?
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={noDataText} />
      </>
    );
  }

  return (
    <>
      <ListItemAvatar>
        <Avatar
          color={getPaletterColorFromString(messageSession.session_id)}
          type="filled"
          sx={{ width: 40, height: 40 }}
        >
          {getInitialsFromFullName(messageSession.from.name ?? 'Unknown')}
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={messageSession.subject}
        secondary={`${getFromNow(new Date(messageSession.updated_at))} • ${
          messageSession.from.name || 'Unknown'
        }${messageSession.from.source ? ` <${messageSession.from.source}>` : ''}`}
      />
    </>
  );
};

export default MessageSessionListItemContent;
