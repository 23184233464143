export default function OverridesTypography() {
  return {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          badge: 'p',
          bigNumber: 'p',
          mediumNumber: 'p',
          body3: 'p',
          body4: 'p',
          subtitle3: 'p',
          dataGridBody: 'p',
          dataGridTitle: 'p',
          dataGridSmall: 'p',
        },
      },
    },
  };
}
