import { Box } from '@mui/material';
import React, { useMemo } from 'react';

import { MessageSessionType } from '@/types/message';

import { useGetConversationThread } from '@/api/endpoints/conversation/useGetConversationThread';
import { useGetEmailThread } from '@/api/endpoints/email/useGetEmailThread';
import { useOnGenerateAIResponse } from '@/api/endpoints/message/useOnGenerateAIResponse';
import { useOnMessageSend } from '@/api/endpoints/message/useOnMessageSend';
import { useGetSmsThread } from '@/api/endpoints/twilio/useGetSmsThread';
import { Uuid } from '@/api/utils/sql';
import {
  getMessageThreadFromConversationThread,
  getMessageThreadFromEmailThread,
  getMessageThreadFromSmsThread,
} from '@/utils/message';

import ModalWithPreview from '../modals/ModalWithPreview';

import MessageChatContent from './MessageChatContent';

interface Props {
  threadId: Uuid;
  type: MessageSessionType;
  onClose: () => void;
}

const MessageThreadHistoryModal: React.FC<Props> = ({ threadId, type, onClose }) => {
  const getEmailThreadQuery = useGetEmailThread(threadId, type === MessageSessionType.email);
  const getSmsThreadQuery = useGetSmsThread(threadId, type === MessageSessionType.sms);
  const getConversationThreadQuery = useGetConversationThread(
    {
      conversation_sid: threadId,
    },
    type === MessageSessionType.vendor
  );

  const messageThread = useMemo(() => {
    switch (type) {
    case MessageSessionType.email:
      return getMessageThreadFromEmailThread(getEmailThreadQuery.data ?? null);
    case MessageSessionType.sms:
      return getMessageThreadFromSmsThread(getSmsThreadQuery.data ?? null);
    case MessageSessionType.vendor:
      return getMessageThreadFromConversationThread(getConversationThreadQuery.data ?? null);
    }
  }, [type, getEmailThreadQuery.data, getSmsThreadQuery.data, getConversationThreadQuery.data]);

  const isLoading = useMemo(() => {
    switch (type) {
    case MessageSessionType.email:
      return getEmailThreadQuery.isLoading;
    case MessageSessionType.sms:
      return getSmsThreadQuery.isLoading;
    case MessageSessionType.vendor:
      return getConversationThreadQuery.isLoading;
    }
  }, [
    type,
    getEmailThreadQuery.isLoading,
    getSmsThreadQuery.isLoading,
    getConversationThreadQuery.isLoading,
  ]);

  const isFailed = useMemo(() => {
    switch (type) {
    case MessageSessionType.email:
      return getEmailThreadQuery.isError;
    case MessageSessionType.sms:
      return getSmsThreadQuery.isError;
    case MessageSessionType.vendor:
      return getConversationThreadQuery.isError;
    }
  }, [
    type,
    getEmailThreadQuery.isError,
    getSmsThreadQuery.isError,
    getConversationThreadQuery.isError,
  ]);

  const onGenerateAIResponse = useOnGenerateAIResponse();

  const onMessageSend = useOnMessageSend();

  return (
    <ModalWithPreview
      onClose={onClose}
      title="Message History"
      isDataLoading={getEmailThreadQuery.isLoading}
    >
      <Box sx={{ display: 'flex', flex: '1 1 100%', my: '-1rem', mx: '-3rem' }}>
        <MessageChatContent
          messageThread={messageThread}
          isLoading={isLoading}
          isFailed={isFailed}
          onMessageSend={onMessageSend}
          onGenerateAIResponse={
            type === MessageSessionType.vendor ? undefined : onGenerateAIResponse
          }
          sx={{
            marginLeft: 0,
          }}
        />
      </Box>
    </ModalWithPreview>
  );
};

export default MessageThreadHistoryModal;
