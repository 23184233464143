import { FormControlLabel, Switch, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

import FormField, { FormFieldProps, getControllerProps, getFormFieldProps } from './FormField';

interface Props extends FormFieldProps {
  controlLabel?: React.ReactNode | string;
}

const FormSwitch = forwardRef<HTMLDivElement, Props>(({ color, controlLabel, ...props }, ref) => (
  <FormField {...getFormFieldProps(props)} ref={ref}>
    <Controller
      {...getControllerProps(props)}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Switch
              {...field}
              disabled={props.disabled}
              color={color}
              checked={field.value}
              size={props.size}
            />
          }
          label={
            typeof controlLabel === 'string' ? (
              <Typography variant="body2">{controlLabel}</Typography>
            ) : (
              controlLabel
            )
          }
        />
      )}
    />
  </FormField>
));

export default FormSwitch;
