import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { PublicRoutes, Routes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { useGetIsRouteAccessible } from '@/hooks/useGetIsRouteAccessible';
import { useHomeRoute } from '@/hooks/useHomeRoute';

interface Props extends RouteProps {
  path: Routes;
}

const ConditionalRoute: React.FC<Props> = ({ path, exact, ...routeProps }) => {
  const getIsRouteAccessible = useGetIsRouteAccessible();
  const homeRoute = useHomeRoute();
  const { user } = useAuth();
  const location = useLocation();

  if (getIsRouteAccessible(path))
    return <Route path={path as string} exact={exact} {...routeProps} />;

  if (!user)
    return (
      <Redirect
        to={{
          pathname: PublicRoutes.login,
          state: {
            from: location,
          },
        }}
      />
    );

  return (
    <Route path={path as string} exact={exact}>
      <Redirect
        to={{
          pathname: homeRoute,
        }}
      />
    </Route>
  );
};

export default ConditionalRoute;
