import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

import { environment } from '@env';

import { TBuilding } from '@/types/building';
import { TTenant } from '@/types/tenant';
import { TUnit } from '@/types/unit';

import { PropertyLocationModel } from '@/api/models/propertyLocation';
import { PropertyManagerWithLimitedUserView } from '@/api/models/propertyManager';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

const METHOD = 'GET';
const ENDPOINT = 'vault/tagging-options';

export interface TaggingOptions {
  propertyLocations: Pick<PropertyLocationModel, 'id' | 'name'>[];
  buildings: Pick<TBuilding, 'id' | 'name' | 'address_text'>[];
  units: Pick<TUnit, 'id' | 'name' | 'building_id'>[];
  tenants: Pick<TTenant, 'id' | 'full_name' | 'unit_id'>[];
  propertyManagers: Array<
    Pick<PropertyManagerWithLimitedUserView, 'id'> & { limitedUser: { full_name: string } }
  >;
}

export type ApiRequest_GetTaggingOptions = {
  property_location_ids?: Id[];
  building_ids?: Id[];
  unit_ids?: Id[];
};

export const QUERY_KEY = (params?: ApiRequest_GetTaggingOptions) => [METHOD, ENDPOINT, params];
export const useGetTaggingOptions = (params?: ApiRequest_GetTaggingOptions, enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    queryKey: QUERY_KEY(params),
    enabled: enabled && !!session?.access_token,
    queryFn: async () => {
      try {
        const response = await axios.get<TaggingOptions>(`${environment.api}/${ENDPOINT}`, {
          params,
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        });
        return response.data;
      } catch (error) {
        enqueueSnackbar('Failed to fetch tagging options.', { variant: 'error' });
        throw error;
      }
    },
  });
};
