import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { CalendarEvent, CreateCalendarEventDto } from '@/types/calendar';

import {
  useGetAllBuildings,
  ApiResponse_GetAll_Building,
} from '@/api/endpoints/building/useGetAllBuildings';
import { useCreateCalendarEvent } from '@/api/endpoints/calendar/useCreateCalendarEvent';
import {
  useGetAllTenants,
  ApiResponse_GetAll_Tenant,
} from '@/api/endpoints/tenant/useGetAllTenants';
import { useGetAllUnits, ApiResponse_GetAll_Unit } from '@/api/endpoints/unit/useGetAllUnits';

import FormSelect from '@/components/form/FormSelect';
import FormSwitch from '@/components/form/FormSwitch';
import FormTextField from '@/components/form/FormTextField';
import ModalWithForm from '@/components/modals/ModalWithForm';

interface Props {
  onClose: () => void;
  propertyLocationId: number;
  buildingId?: number;
  unitId?: number;
  tenantId?: number;
  onEventCreated?: (event: CalendarEvent) => void;
  initialDate?: Date | null;
}

const CalendarEventCreateModal: React.FC<Props> = ({
  onClose,
  propertyLocationId,
  buildingId,
  unitId,
  tenantId,
  onEventCreated,
  initialDate,
}) => {
  const form = useForm<CreateCalendarEventDto & { isDateRange: boolean }>({
    defaultValues: {
      title: '',
      description: '',
      start: initialDate?.toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
      end: initialDate?.toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
      all_day: true,
      property_location_id: propertyLocationId,
      building_id: buildingId,
      unit_id: unitId,
      tenant_id: tenantId,
      isDateRange: false,
    },
  });

  const { watch, setValue } = form;
  const isDateRange = watch('isDateRange');
  const startDate = watch('start');
  const selectedBuildingId = watch('building_id');
  const selectedUnitId = watch('unit_id');

  const { data: buildings } = useGetAllBuildings({ location_id: propertyLocationId });
  const { data: units } = useGetAllUnits({ buildingId: selectedBuildingId }, !!selectedBuildingId);
  const { data: tenants } = useGetAllTenants({ unit_id: selectedUnitId }, !!selectedUnitId);

  useEffect(() => {
    if (!isDateRange) {
      setValue('end', startDate);
    } else {
      // If switching to date range and end date is before start date, update end date
      const endDate = watch('end');
      if (new Date(endDate) < new Date(startDate)) {
        setValue('end', startDate);
      }
    }
  }, [isDateRange, startDate, setValue, watch]);

  // Reset dependent fields when parent selection changes
  useEffect(() => {
    if (selectedBuildingId) {
      setValue('unit_id', undefined);
      setValue('tenant_id', undefined);
    }
  }, [selectedBuildingId, setValue]);

  useEffect(() => {
    if (selectedUnitId) {
      setValue('tenant_id', undefined);
    }
  }, [selectedUnitId, setValue]);

  const { mutateAsync: createEvent } = useCreateCalendarEvent({
    onSuccess: event => {
      if (onEventCreated) {
        onEventCreated({
          id: event.id,
          title: event.title,
          description: event.description,
          start: event.start,
          end: event.end,
          all_day: event.all_day,
          propertyLocationId: event.property_location_id,
          buildingId: event.building_id,
          unitId: event.unit_id,
          tenantId: event.tenant_id,
          created_by: event.created_by,
          created_at: event.created_at,
          updated_at: event.updated_at,
        });
      }
      onClose();
    },
  });

  const handleSubmit = (data: CreateCalendarEventDto & { isDateRange: boolean }) => {
    const { isDateRange: _, ...formData } = data;
    return createEvent(formData);
  };

  return (
    <ModalWithForm
      title="Add New Event"
      form={form}
      maxWidth="sm"
      onClose={onClose}
      onSubmit={handleSubmit}
      buttonLabel="Create Event"
    >
      <FormTextField
        control={form.control}
        textFieldProps={{
          fullWidth: true,
          label: 'Title',
        }}
        {...form.register('title', {
          required: 'Title is required',
        })}
        error={!!form.formState.errors.title}
        helperText={form.formState.errors.title?.message?.toString()}
      />
      <FormTextField
        name="description"
        control={form.control}
        textFieldProps={{
          fullWidth: true,
          label: 'Description',
          multiline: true,
          rows: 4,
        }}
      />
      <FormSelect
        control={form.control}
        label="Building"
        placeholder="Select building"
        options={[
          { label: 'None', value: '' },
          ...(buildings?.map((building: ApiResponse_GetAll_Building[0]) => ({
            label: building.name || building.address_text,
            value: building.id,
          })) || []),
        ]}
        {...form.register('building_id', {
          setValueAs: value => (value === '' ? undefined : Number(value)),
        })}
      />
      <FormSelect
        control={form.control}
        label="Unit"
        placeholder="Select unit"
        disabled={!selectedBuildingId}
        options={[
          { label: 'None', value: '' },
          ...(units?.map((unit: ApiResponse_GetAll_Unit[0]) => ({
            label: unit.name,
            value: unit.id,
          })) || []),
        ]}
        {...form.register('unit_id', {
          setValueAs: value => (value === '' ? undefined : Number(value)),
        })}
      />
      <FormSelect
        control={form.control}
        label="Tenant"
        placeholder="Select tenant"
        disabled={!selectedUnitId}
        options={[
          { label: 'None', value: '' },
          ...(tenants?.map((tenant: ApiResponse_GetAll_Tenant[0]) => ({
            label: tenant.full_name,
            value: tenant.id,
          })) || []),
        ]}
        {...form.register('tenant_id', {
          setValueAs: value => (value === '' ? undefined : Number(value)),
        })}
      />
      <FormSwitch name="isDateRange" control={form.control} controlLabel="Date Range" />
      <FormTextField
        label={isDateRange ? 'Start Date' : 'Event Date'}
        name="start"
        control={form.control}
        textFieldProps={{
          type: 'date',
          InputLabelProps: { shrink: true },
        }}
      />
      {isDateRange && (
        <FormTextField
          label="End Date"
          name="end"
          control={form.control}
          textFieldProps={{
            type: 'date',
            InputLabelProps: { shrink: true },
            inputProps: {
              min: startDate,
            },
          }}
        />
      )}
    </ModalWithForm>
  );
};

export default CalendarEventCreateModal;
