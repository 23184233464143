import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

import { ApiResponse_Delete_Rule } from '../rule/useDeleteRule';

import { QUERY_KEY as QUERY_KEY_GET_ALL } from './useGetAllInvoices';

export type ApiResquest_DeleteSpreadsheet_Invoice = {
  location_id: Id;
};
export type ApiResponse_DeleteSpreadsheet_Invoice = never;

const METHOD = 'DELETE';
const ENDPOINT = 'invoice/spreadsheet';

export const useDeleteInvoiceSpreadsheet = (
  stateFns?: MutationStateFns<ApiResponse_DeleteSpreadsheet_Invoice>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: ApiResquest_DeleteSpreadsheet_Invoice) =>
      handleApiCall<ApiResponse_Delete_Rule, ApiResquest_DeleteSpreadsheet_Invoice>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        params
      ),
    onSuccess: (data, variables) => {
      enqueueSnackbar('Invoice spredsheet deleted successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_GET_ALL({
          location_id: variables.location_id,
        }),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to delete invoice spredsheet', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
