import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import { Box, Theme } from '@mui/material';
import { PropsWithChildren } from 'react';

import StyledSnackbarProvider from '@/@mantis/components/third-party/Notistack';
import DrawerProvider from '@/@mantis/contexts/DrawerContext';
import ThemeCustomization from '@/@mantis/theme';
import { AlertOctagonIcon } from '@/assets/icons/AlertOctagonIcon';
import { AlertTriangleIcon } from '@/assets/icons/AlertTriangleIcon';
import { CheckCircleBrokenIcon } from '@/assets/icons/CheckCircleBrokenIcon';
import { InfoCircleIcon } from '@/assets/icons/InfoCircleIcon';

import NotistackCloseIcon from './NotistackCloseIcon';

interface Props extends PropsWithChildren {
  themeOverrides?: (theme: Theme) => Theme;
}

const MuiPageWrapper: React.FC<Props> = ({ children, themeOverrides }) => (
  <ThemeCustomization themeOverrides={themeOverrides}>
    <DrawerProvider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100dvh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {children}
      </Box>
      <StyledSnackbarProvider
        maxSnack={5}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        action={NotistackCloseIcon}
        iconVariant={{
          default: <InfoCircleIcon />,
          error: <AlertOctagonIcon />,
          success: <CheckCircleBrokenIcon />,
          warning: <AlertTriangleIcon />,
          info: <InfoCircleIcon />,
        }}
      />
    </DrawerProvider>
  </ThemeCustomization>
);

export default MuiPageWrapper;
