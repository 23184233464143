import { Id } from '@hello-pangea/dnd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { TenantModel, TenantModelWithContacts } from '@/api/models/tenant';
import { TenantContactModel } from '@/api/models/tenantContacts';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY as GET_ALL_QUERY_KEY } from './useGetAllTenants';
import { QUERY_KEY as GET_ONE_QUERY_KEY } from './useGetOneTenant';

export type ApiResponse_Update_Tenant = TenantModelWithContacts;

export type ApiRequest_Update_Tenant = {
  full_name: string;
  unit_id?: Id;
  contacts: Array<Pick<Partial<TenantContactModel>, 'email' | 'phone' | 'id'>>;
};

const METHOD = 'PUT';
const ENDPOINT = 'tenant/:id';

export const useUpdateTenant = (
  id: TenantModel['id'],
  stateFns?: MutationStateFns<ApiResponse_Update_Tenant>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_Update_Tenant) =>
      handleApiCall<ApiResponse_Update_Tenant, ApiRequest_Update_Tenant>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: id.toString() }),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Tenant updated successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: GET_ALL_QUERY_KEY({ unit_id: data.unit_id }),
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: GET_ONE_QUERY_KEY(id),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to update tenant', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
