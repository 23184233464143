import { Stack, Divider, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

import DashboardSection from './DashboardSection';

interface Props extends PropsWithChildren {
  title: string;
  icon: React.ReactNode;
  iconTitle?: string;
  footerContent?: React.ReactNode;
}

const DashboardCard: React.FC<Props> = ({ title, icon, iconTitle, children, footerContent }) => (
  <DashboardSection title={title}>
    <Stack gap={2} alignItems="flex-start" direction="row">
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 36,
          height: 36,
          border: '1px solid',
          borderColor: 'grey.200',
          borderRadius: '50%',
        }}
      >
        {icon}
      </Stack>

      <Typography variant="h4">{iconTitle}</Typography>
    </Stack>

    <Stack gap={1}>{children}</Stack>

    {footerContent && (
      <Stack gap={2} mt="auto">
        <Divider />

        {footerContent}
      </Stack>
    )}
  </DashboardSection>
);

export default DashboardCard;
