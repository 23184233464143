import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Id, Uuid } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_GenerateResponse_Email = {
  email: string;
  session_id: Uuid;
  urgent: false;
};

export type ApiRequest_GenerateResponse_Email = {
  message: string;
  location_id: Id;
  tenant_id: Id;
  session_id: Uuid;
};

const METHOD = 'POST';
const ENDPOINT = 'email/generate';

export const useGenerateEmailResponse = (
  stateFns?: MutationStateFns<ApiResponse_GenerateResponse_Email>
) => {
  const { session } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_GenerateResponse_Email) =>
      handleApiCall<ApiResponse_GenerateResponse_Email, ApiRequest_GenerateResponse_Email>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('AI response regenerated successfully', { variant: 'success' });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to regenerate AI response', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
