import { Box } from '@mui/material';

import BasePublicMuiPage from '@/pages/common/BasePublicMuiPage';

import TermsOfServiceContent from '@/components/legal/TermsOfServiceContent';

const TermsOfServicePage: React.FC = () => (
  <BasePublicMuiPage>
    <Box
      sx={{
        maxWidht: '800px',
        mx: 'auto',
        py: { xs: 2, sm: 4, md: 6 },
        px: { xs: 2, sm: 3 },
      }}
    >
      <TermsOfServiceContent />
    </Box>
  </BasePublicMuiPage>
);

export default TermsOfServicePage;
