import { TurnstileInstance } from '@marsidev/react-turnstile';
import { Stack, Typography, Link, Button, FormHelperText } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { GoogleIcon } from '@/assets/icons/social/GoogleIcon';
import { PublicRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';

import FormCaptcha from '@/components/form/FormCaptcha';
import FormTextField from '@/components/form/FormTextField';

import AuthPageWrapper from './AuthPageWrapper';

interface FormData {
  email: string;
  password: string;
  captchaToken: string;
}

const LogInPage: React.FC = () => {
  const { signInWithPassword, signInWithOAuth } = useAuth();
  const turnstileRef = useRef<TurnstileInstance>();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<Error | null>(null);

  const onSignUpWithGoogle = async () => {
    setFormError(null);

    try {
      const { error } = await signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: window.location.href,
        },
      });

      if (error) throw error;
    } catch (error) {
      setFormError(error);
    }
  };

  const onSignInWithPassword = async (data: FormData) => {
    const { email, password, captchaToken } = data;
    setFormError(null);

    try {
      const { error } = await signInWithPassword({
        email,
        password,
        options: {
          captchaToken,
        },
      });

      if (error) throw error;
    } catch (error) {
      setFormError(error);
      turnstileRef.current?.reset();
    }
  };

  return (
    <AuthPageWrapper>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSignInWithPassword)}
        spacing={3}
        width="100%"
      >
        <Stack spacing={1.5} mb={1}>
          <Typography variant="h3">Log in</Typography>

          <Typography variant="body2">Welcome back! Please enter your details.</Typography>
        </Stack>

        <Stack spacing={2.5}>
          <FormTextField
            label="Email"
            placeholder="Enter your email"
            textFieldProps={{
              autoComplete: 'email',
              autoFocus: true,
              type: 'email',
            }}
            error={!!errors.email}
            helperText={errors.email?.message?.toString()}
            control={control}
            required
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Invalid email address',
              },
            })}
          />

          <FormTextField
            label="Password"
            placeholder="Enter your password"
            textFieldProps={{
              autoComplete: 'password',
              type: 'password',
            }}
            error={!!errors.password}
            helperText={errors.password?.message?.toString()}
            control={control}
            required
            {...register('password', {
              required: 'Password is required',
            })}
          />

          <FormCaptcha
            control={control}
            error={!!errors.captchaToken}
            helperText={errors.captchaToken?.message?.toString()}
            required
            {...register('captchaToken', {
              required: 'Captcha Token is required',
            })}
            turnstileRef={turnstileRef}
          />

          {!!formError && <FormHelperText error>{formError.message}</FormHelperText>}
        </Stack>

        <Stack spacing={1} mb={1}>
          <LoadingButton loading={isSubmitting} fullWidth variant="contained" type="submit">
            Log in
          </LoadingButton>

          <Button
            fullWidth
            color="secondary"
            variant="outlined"
            onClick={onSignUpWithGoogle}
            startIcon={<GoogleIcon />}
          >
            Log in with Google
          </Button>
        </Stack>

        <Typography variant="body3" textAlign="center">
          Don’t have an account? <Link href={PublicRoutes.signup}>Sign up</Link>
        </Typography>
      </Stack>
    </AuthPageWrapper>
  );
};

export default LogInPage;
