import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { AnswerRole, ConversationType, Sentiment } from '@/api/enums/ai';
import { EmailSessionModel } from '@/api/models/emailSession';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Uuid } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_GetThread_Email = {
  emailSession: EmailSessionModel;
  email_thread: {
    id: number;
    property_id: string;
    user_id: string;
    conversation_type: ConversationType;
    updated_at: string;
    custom_metadata: {
      summary?: string;
      sentiment: Sentiment;
    };
    external_id: string;
    title: string;
    archived: boolean;
    created_at: string;
    location_id: string;
  };
  emails: Array<{
    message: string;
    created_at: string;
    custom_metadata: {
      summary?: string;
      sentiment: Sentiment;
    };
    role: AnswerRole;
    id: number;
    email_thread_id: number;
    updated_at: string;
  }>;
};

const METHOD = 'GET';
const ENDPOINT = 'email/:externalId';
export const QUERY_KEY = (extnernalId?: Uuid) => getQueryKey(METHOD, ENDPOINT, { extnernalId });

export const useGetEmailThread = (externalId?: Uuid, enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!externalId && !!session?.access_token,
    queryKey: QUERY_KEY(externalId),
    queryFn: async () => {
      if (!externalId) return null;
      try {
        const data = await handleApiCall<ApiResponse_GetThread_Email>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {
            externalId: externalId.toString(),
          })
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch email thread.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
