import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { environment } from '@env';

import { VaultDocument } from '@/types/vault';

import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY, ApiRequest_GetAll_VaultDocuments } from './useGetVaultDocuments';

const ENDPOINT = 'vault/documents';

export type ApiRequest_DeleteTag_VaultDocument = {
  documentId: string;
  tagId: number;
};

export const useDeleteVaultDocumentTag = (
  params?: ApiRequest_GetAll_VaultDocuments,
  stateFns?: MutationStateFns<{ success: boolean }>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ documentId, tagId }: ApiRequest_DeleteTag_VaultDocument) => {
      const response = await axios.delete<{ success: boolean }>(
        `${environment.api}/${ENDPOINT}/${documentId}/tags/${tagId}`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
    onMutate: async ({ documentId, tagId }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: QUERY_KEY(params) });

      // Snapshot the previous value
      const previousDocuments = queryClient.getQueryData<VaultDocument[]>(QUERY_KEY(params));

      // Optimistically update to the new value
      queryClient.setQueryData<VaultDocument[]>(QUERY_KEY(params), old => {
        if (!old) return [];
        return old.map(doc => {
          if (doc.id === documentId) {
            // Remove the tag from all tag arrays
            const updatedDoc = {
              ...doc,
              tags: doc.tags.filter(t => t.id !== tagId),
              property_locations: doc.property_locations?.filter(
                l => !doc.tags.find(t => t.id === tagId && t.property_location_id === Number(l.id))
              ),
              buildings: doc.buildings?.filter(
                b => !doc.tags.find(t => t.id === tagId && t.building_id === Number(b.id))
              ),
              units: doc.units?.filter(
                u => !doc.tags.find(t => t.id === tagId && t.unit_id === Number(u.id))
              ),
              tenants: doc.tenants?.filter(
                t => !doc.tags.find(tag => tag.id === tagId && tag.tenant_id === Number(t.id))
              ),
              property_managers: doc.property_managers?.filter(
                m =>
                  !doc.tags.find(
                    tag => tag.id === tagId && tag.property_manager_id === Number(m.id)
                  )
              ),
            };
            return updatedDoc;
          }
          return doc;
        });
      });

      // Return a context object with the snapshotted value
      return { previousDocuments };
    },
    onError: (error: AxiosError, variables, context) => {
      // Revert back to the previous value if there's an error
      if (context?.previousDocuments) {
        queryClient.setQueryData(QUERY_KEY(params), context.previousDocuments);
      }
      enqueueSnackbar('Failed to delete tag.', { variant: 'error' });
      stateFns?.onError?.(error);
    },
    onSuccess: data => {
      enqueueSnackbar('Tag deleted successfully.', { variant: 'success' });
      stateFns?.onSuccess?.(data);
    },
  });
};
