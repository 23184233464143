import { TOnboardingProgress } from '@/types/onboarding';

import { AccountType } from '@/api/enums/supabase';
import { HOPRoutes, OnboardingRoutes, PMPRoutes, ResidentRoutes, Routes } from '@/config/routes';

type LocationDescriptor = string;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteState = any;

export const getCurrentOnboardingStep = (
  onboardingProgress: TOnboardingProgress
): [LocationDescriptor, RouteState?] => {
  if (!onboardingProgress.hasAccountType) {
    return [
      OnboardingRoutes.accountTypeSelect,
      { autoSubmit: true, accountType: AccountType.property_manager },
    ];
  }

  if (onboardingProgress.accountType === AccountType.home_owner) {
    return [HOPRoutes.dashboard];
  }

  if (onboardingProgress.accountType === AccountType.resident) {
    return [ResidentRoutes.dashboard];
  }

  if (!onboardingProgress.hasPropertyLocation) {
    return [OnboardingRoutes.propertyLocationCreate];
  }

  // Redirect to dashboard after setting up Property Location
  return [PMPRoutes.dashboard];
};

export const getNextOnboardingStep = (
  currentStep: Routes,
  onboardingProgress: TOnboardingProgress
): [LocationDescriptor, RouteState?] => {
  if (!onboardingProgress.accountType)
    return [
      OnboardingRoutes.accountTypeSelect,
      { autoSubmit: true, accountType: AccountType.property_manager },
    ];

  if (onboardingProgress.accountType === AccountType.home_owner) {
    return [HOPRoutes.dashboard];
  }

  if (onboardingProgress.accountType === AccountType.resident) {
    return [ResidentRoutes.dashboard];
  }

  if (currentStep === OnboardingRoutes.accountTypeSelect) {
    return [OnboardingRoutes.propertyLocationCreate];
  }

  if (currentStep === OnboardingRoutes.propertyLocationCreate) {
    return [PMPRoutes.onboardingChecklist];
  }

  // Redirect to dashboard after setting up Property Location
  return [PMPRoutes.dashboard];
};
