export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

  // If 11 digits, take last 10
  if (cleanedPhoneNumber.length === 11 && cleanedPhoneNumber.startsWith('1')) {
    return formatPhoneNumber(cleanedPhoneNumber.slice(1));
  }

  if (cleanedPhoneNumber.length > 10) {
    return formatPhoneNumber(cleanedPhoneNumber.slice(0, 10));
  }

  const parts = [];

  if (cleanedPhoneNumber.length > 0) {
    parts.push('(');
    parts.push(cleanedPhoneNumber.slice(0, 3));
  }

  if (cleanedPhoneNumber.length > 3) {
    parts.push(') ');
    parts.push(cleanedPhoneNumber.slice(3, 6));
  }

  if (cleanedPhoneNumber.length > 6) {
    parts.push('-');
    parts.push(cleanedPhoneNumber.slice(6));
  }

  return parts.join('');
};
