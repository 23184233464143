import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

import EmailAttachmentInput from '../email/EmailAttachmentInput';

import FormField, { FormFieldProps, getControllerProps, getFormFieldProps } from './FormField';

interface Props extends FormFieldProps {
  multiple?: boolean;
}

const FormEmailAttachments = forwardRef<HTMLInputElement, Props>(({ multiple, ...props }, ref) => (
  <FormField {...getFormFieldProps(props)} ref={ref}>
    <Controller
      {...getControllerProps(props)}
      render={({ field }) => (
        <EmailAttachmentInput disabled={props.disabled} {...field} multiple={multiple} />
      )}
    />
  </FormField>
));

export default FormEmailAttachments;
