import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { SmsSessionModelWithSmsThread } from '@/api/models/smsSession';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_GetAll_Sms = {
  locationId?: Id;
  phone?: string;
  filterBy?: 'all' | 'emergency' | 'in_progress' | 'completed';
};

export type ApiResponse_GetAll_Sms = Array<SmsSessionModelWithSmsThread>;

const METHOD = 'GET';
const ENDPOINT = 'twilio/all';
export const QUERY_KEY = (params: ApiRequest_GetAll_Sms) => getQueryKey(METHOD, ENDPOINT, params);

export const useGetAllSms = (params: ApiRequest_GetAll_Sms, enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!params.locationId && !!session?.access_token,
    queryKey: QUERY_KEY(params),
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetAll_Sms, ApiRequest_GetAll_Sms>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {}),
          params
        );

        const { filterBy } = params;

        if (!filterBy) return data;

        return data.filter(email => {
          if (filterBy === 'all') return true;
          if (filterBy === 'emergency') return !!email.urgent;
          if (filterBy === 'in_progress') return !email.session.replied_to && !email.archived;
          if (filterBy === 'completed') return !!email.session.archived;

          return true;
        });
      } catch (err) {
        enqueueSnackbar('Failed to fetch sms.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
