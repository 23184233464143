import { Stack } from '@mui/material';
import { useForm, UseFormReturn, FieldError } from 'react-hook-form';

import { TermsAgreementFormData } from '@/types/lease';

import FormSelect from '@/components/form/FormSelect';
import FormTextField from '@/components/form/FormTextField';
import ModalWithForm from '@/components/modals/ModalWithForm';

interface Props {
  initialData?: TermsAgreementFormData;
  onSave: (data: TermsAgreementFormData) => Promise<void>;
  onClose: () => void;
  disabled?: boolean;
  title?: string;
}

interface FormContentProps {
  form: UseFormReturn<TermsAgreementFormData>;
  disabled?: boolean;
}

const BOOLEAN_OPTIONS = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

export const FormContent: React.FC<FormContentProps> = ({ form, disabled }) => {
  const witnessRequired = form.watch('witness_required');

  return (
    <Stack spacing={3}>
      <FormSelect
        label="Tenant Signature"
        control={form.control}
        name="tenant_signature"
        options={BOOLEAN_OPTIONS}
        error={!!form.formState.errors.tenant_signature}
        helperText={(form.formState.errors.tenant_signature as FieldError)?.message}
        disabled={disabled}
      />

      <FormTextField
        label="Tenant Name"
        control={form.control}
        name="tenant_name"
        error={!!form.formState.errors.tenant_name}
        helperText={(form.formState.errors.tenant_name as FieldError)?.message}
        disabled={disabled}
      />

      <FormTextField
        label="Tenant Date"
        textFieldProps={{
          type: 'date',
        }}
        control={form.control}
        name="tenant_date"
        error={!!form.formState.errors.tenant_date}
        helperText={(form.formState.errors.tenant_date as FieldError)?.message}
        disabled={disabled}
      />

      <FormSelect
        label="Lessor Signature"
        control={form.control}
        name="lessor_signature"
        options={BOOLEAN_OPTIONS}
        error={!!form.formState.errors.lessor_signature}
        helperText={(form.formState.errors.lessor_signature as FieldError)?.message}
        disabled={disabled}
      />

      <FormTextField
        label="Lessor Name"
        control={form.control}
        name="lessor_name"
        error={!!form.formState.errors.lessor_name}
        helperText={(form.formState.errors.lessor_name as FieldError)?.message}
        disabled={disabled}
      />

      <FormTextField
        label="Lessor Date"
        textFieldProps={{
          type: 'date',
        }}
        control={form.control}
        name="lessor_date"
        error={!!form.formState.errors.lessor_date}
        helperText={(form.formState.errors.lessor_date as FieldError)?.message}
        disabled={disabled}
      />

      <FormSelect
        label="Witness Required"
        control={form.control}
        name="witness_required"
        options={BOOLEAN_OPTIONS}
        error={!!form.formState.errors.witness_required}
        helperText={(form.formState.errors.witness_required as FieldError)?.message}
        disabled={disabled}
      />

      {witnessRequired && (
        <>
          <FormTextField
            label="Witness Name"
            control={form.control}
            name="witness_name"
            error={!!form.formState.errors.witness_name}
            helperText={(form.formState.errors.witness_name as FieldError)?.message}
            disabled={disabled}
          />

          <FormTextField
            label="Witness Date"
            textFieldProps={{
              type: 'date',
            }}
            control={form.control}
            name="witness_date"
            error={!!form.formState.errors.witness_date}
            helperText={(form.formState.errors.witness_date as FieldError)?.message}
            disabled={disabled}
          />
        </>
      )}
    </Stack>
  );
};

const TermsAgreementForm: React.FC<Props> = ({
  initialData,
  onSave,
  onClose,
  disabled = false,
  title = 'Terms Agreement',
}) => {
  const form = useForm<TermsAgreementFormData>({
    defaultValues: initialData || {
      tenant_signature: false,
      tenant_name: '',
      tenant_date: '',
      lessor_signature: false,
      lessor_name: '',
      lessor_date: '',
      witness_required: false,
      witness_name: '',
      witness_date: '',
    },
  });

  return (
    <ModalWithForm
      form={form}
      maxWidth="sm"
      title={title}
      onClose={onClose}
      onSubmit={onSave}
      buttonLabel="Save Agreement"
    >
      <FormContent form={form} disabled={disabled} />
    </ModalWithForm>
  );
};

export default TermsAgreementForm;
