import { List, ListItem, ListItemButton } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Drawer from '@mui/material/Drawer';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo, useState } from 'react';

import { MessageSessionType, TMessageSession } from '@/types/message';

import MainCard from '@/@mantis/components/MainCard';
import { SearchLgIcon } from '@/assets/icons/SearchLgIcon';
import { getDummyArray } from '@/utils/list';
import { formatPhoneNumber } from '@/utils/phone';

import MessageSessionListItemContent from './MessageSessionListItemContent';

interface Props {
  isDrawerOpen: boolean | undefined;
  isLoading?: boolean;
  messageSessions?: TMessageSession[];
  selectedMessageSession: TMessageSession | null;
  onMessageSessionSelect: (messageSession: TMessageSession) => void;
  toggleIsDrawerOpen: () => void;
}

export const DRAWER_WIDTH = 320;

const MessageChatDrawer: React.FC<Props> = ({
  isDrawerOpen,
  isLoading = false,
  messageSessions,
  selectedMessageSession,
  onMessageSessionSelect,
  toggleIsDrawerOpen,
}) => {
  const theme = useTheme();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const drawerBG = 'white';

  const [searchQuery, setSearchQuery] = useState<string | undefined>('');

  const filteredMessageSessions = useMemo(() => {
    if (!messageSessions) return [];

    if (!searchQuery) return messageSessions;

    return messageSessions?.filter(({ from, subject, type }) =>
      (
        [
          from.name,
          from.source,
          subject,
          ...[type === MessageSessionType.sms && from.source ? formatPhoneNumber(from.source) : []],
        ] as string[]
      ).some(value => value.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }, [searchQuery, messageSessions]);

  return (
    <Drawer
      sx={{
        mt: { xs: 7.5, lg: 0 },
        width: DRAWER_WIDTH,
        height: '100%',
        maxHeight: '100dvh',
        overflowX: 'hidden',
        display: { xs: isDrawerOpen ? 'flex' : 'none', lg: 'flex' },
        flex: `0 0 ${DRAWER_WIDTH}px`,
        zIndex: { xs: isDrawerOpen ? 1100 : -1, lg: 0 },
        '& .MuiDrawer-paper': {
          height: 1,
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          position: 'relative',
          border: 'none',
        },
      }}
      variant={downLG ? 'temporary' : 'persistent'}
      anchor="left"
      open={isDrawerOpen}
      ModalProps={{ keepMounted: true }}
      onClose={toggleIsDrawerOpen}
    >
      <MainCard
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 100px',
          bgcolor: downLG ? 'transparent' : drawerBG,
          borderRadius: '12px 0 0 12px',
          borderColor: 'divider',
          borderRight: 'none',
          height: 1,
          '& div:nth-of-type(2)': {
            height: 'auto',
          },
        }}
        border={!downLG}
        content={false}
      >
        <Box sx={{ p: 3, pb: 1, flex: '0 0 auto' }}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography variant="h6" color="inherit">
                Messages
              </Typography>
              {!isLoading && (
                <Chip
                  label={messageSessions?.length || 0}
                  color={
                    messageSessions?.length && messageSessions.length > 0 ? 'primary' : 'secondary'
                  }
                  size="small"
                  sx={{
                    height: 20,
                    minWidth: 20,
                    borderRadius: 10,
                    p: 0,
                    '& .MuiChip-label': {
                      px: 0.5,
                    },
                  }}
                />
              )}
            </Stack>

            <OutlinedInput
              fullWidth
              id="input-search-header"
              placeholder="Search"
              value={searchQuery}
              color="secondary"
              onChange={e => setSearchQuery(e.target.value ?? '')}
              sx={{
                '& .MuiOutlinedInput-input': {
                  p: '10.5px 0px 12px',
                },
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchLgIcon style={{ fontSize: 'large' }} />
                </InputAdornment>
              }
            />
          </Stack>
        </Box>

        <Stack
          direction="column"
          sx={{
            flex: '1 1 100px',
            overflowX: 'hidden',
          }}
        >
          <Box sx={{ p: 3, pt: 0, width: 1 }}>
            <List component="nav">
              {isLoading
                ? getDummyArray(4).map((_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem key={i} sx={{ px: 1 }} divider>
                    <MessageSessionListItemContent messageSession={null} isLoading />
                  </ListItem>
                ))
                : filteredMessageSessions.map(messageSession => (
                  <ListItemButton
                    key={messageSession.session_id}
                    divider
                    selected={messageSession.session_id === selectedMessageSession?.session_id}
                    sx={{
                      px: 1,
                      '&.Mui-selected': {
                        bgcolor: 'grey.200',
                        '&:focus': {
                          bgcolor: 'grey.200',
                        },
                      },
                    }}
                    onClick={() => onMessageSessionSelect(messageSession)}
                  >
                    <MessageSessionListItemContent messageSession={messageSession} />
                  </ListItemButton>
                ))}
            </List>
          </Box>
        </Stack>
      </MainCard>
    </Drawer>
  );
};

export default MessageChatDrawer;
