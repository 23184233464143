import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Uuid } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_Archive_Sms = never;

export type ApiRequest_Archive_Sms = {
  thread_external_id: Uuid;
};

const METHOD = 'POST';
const ENDPOINT = 'twilio/:thread_external_id/archive';

export const useArchiveSms = (stateFns?: MutationStateFns<ApiResponse_Archive_Sms>) => {
  const { session } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_Archive_Sms) =>
      handleApiCall<ApiResponse_Archive_Sms, ApiRequest_Archive_Sms>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, data)
      ),
    onSuccess: data => {
      enqueueSnackbar('Sms archived successfully', { variant: 'success' });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to archive sms', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
