import AppBar, { AppBarProps } from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactNode, useMemo } from 'react';

import IconButton from '@/@mantis/components/@extended/IconButton';
import { DRAWER_WIDTH, MINI_DRAWER_WIDTH } from '@/@mantis/config';
import { useDrawer } from '@/@mantis/contexts/DrawerContext';
import { Menu01Icon } from '@/assets/icons/Menu01Icon';
import { Menu02Icon } from '@/assets/icons/Menu02Icon';

import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

const Header: React.FC = () => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { isOpen, setIsOpen } = useDrawer();

  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  // common header
  const mainHeader: ReactNode = (
    <Toolbar>
      <IconButton
        aria-label="open drawer"
        onClick={() => setIsOpen(!isOpen)}
        edge="start"
        color="secondary"
        variant="light"
        sx={{
          color: 'text.primary',
          bgcolor: 'transparent',
          ml: { xs: 0, lg: -2 },
          '&:hover': {
            bgcolor: 'primary.0',
          },
        }}
      >
        {isOpen ? <Menu02Icon /> : <Menu01Icon />}
      </IconButton>
      {headerContent}
    </Toolbar>
  );

  // app-bar params
  const appBar: AppBarProps = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: '1px solid',
      borderBottomColor: 'divider',
      zIndex: 1200,
      width: {
        xs: '100%',
        lg: isOpen ? `calc(100% - ${DRAWER_WIDTH}px)` : `calc(100% - ${MINI_DRAWER_WIDTH}px)`,
      },
    },
  };

  if (downLG)
    return (
      <AppBarStyled open={isOpen} {...appBar}>
        {mainHeader}
      </AppBarStyled>
    );

  return <AppBar {...appBar}>{mainHeader}</AppBar>;
};

export default Header;
