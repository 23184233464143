import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { AnswerRole, ConversationType, Sentiment } from '@/api/enums/ai';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Uuid } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_GetThread_Sms = {
  messages: Array<{
    archived: boolean;
    conversation_type: ConversationType;
    created_at: string;
    custom_metadata?: {
      summary?: string;
      sentiment: Sentiment;
    };
    external_id: string;
    id: number;
    location_id: string;
    message: string;
    property_id: string;
    role: AnswerRole;
    session_id: string;
    updated_at: string;
    user_id: string;
  }>;
};

const METHOD = 'GET';
const ENDPOINT = 'twilio/:id';
export const QUERY_KEY = (id?: Uuid) => getQueryKey(METHOD, ENDPOINT, { id });

export const useGetSmsThread = (id?: Uuid, enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!id && !!session?.access_token,
    queryKey: QUERY_KEY(id),
    queryFn: async () => {
      if (!id) return null;
      try {
        const data = await handleApiCall<ApiResponse_GetThread_Sms>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {
            id: id.toString(),
          })
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch email thread.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
