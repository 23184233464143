import { IconButton, IconButtonProps } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';

interface Props extends IconButtonProps {
  expand: boolean;
  drawerOpen: boolean;
}

const ExpandMore = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'expand' && prop !== 'drawerOpen',
})(({ expand, drawerOpen }: Props) => {
  const theme = useTheme();
  return {
    transform: !expand ? 'rotate(0deg)' : 'rotate(-90deg)',
    marginLeft: 'auto',
    color: theme.palette.secondary.dark,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      backgroundColor: theme.palette.primary[0],
    },
    ...(!drawerOpen && {
      opacity: 0,
      width: 50,
      height: 50,
    }),
  };
});

export default ExpandMore;
