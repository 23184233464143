import { Theme } from '@mui/material/styles';

export default function OverridesDialogActions(theme: Theme) {
  return {
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0.5rem 3rem 3rem',
        },
      },
    },
  };
}
