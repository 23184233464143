import { Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiResponse_Delete_QuestionAnswer,
  useDeleteQuestionAnswer,
} from '@/api/endpoints/questionAnswer/useDeleteQuestionAnswer';
import { QuestionAnswerModel } from '@/api/models/ai/questionAnswer';

import ModalWithForm from '../modals/ModalWithForm';

type Props = {
  questionAnswer: QuestionAnswerModel;
  onClose: () => void;
  onQuestionAnswerDeleted?: (questionAnswer: ApiResponse_Delete_QuestionAnswer) => void;
};

const QuestionAnswerDeleteModal = ({ questionAnswer, onClose, onQuestionAnswerDeleted }: Props) => {
  const form = useForm();

  const deleteQuestionAnswerMutation = useDeleteQuestionAnswer(questionAnswer.id, {
    onSuccess: data => {
      onQuestionAnswerDeleted?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    () => deleteQuestionAnswerMutation.mutateAsync(),
    [deleteQuestionAnswerMutation]
  );

  return (
    <ModalWithForm
      title="Delete Q&A Item"
      onClose={onClose}
      onSubmit={onSubmit}
      form={form}
      maxWidth="sm"
      buttonColor="error"
    >
      <Typography variant="body2">
        Are you sure you want to delete this Q&A item? This action cannot be undone.
      </Typography>

      <Stack spacing={1}>
        <Typography variant="subtitle3">Question / Topic:</Typography>
        <Typography variant="body3">{questionAnswer.question}</Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="subtitle3">Answer / Guidance:</Typography>
        <Typography variant="body3">{questionAnswer.answer}</Typography>
      </Stack>
    </ModalWithForm>
  );
};

export default QuestionAnswerDeleteModal;
