import { Box, Button, Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

import { PublicRoutes } from '@/config/routes';

import BlurBackground from './BlurBackground';

const LandingHeroSection: React.FC = () => (
  <Grid2 size={12} sx={{ py: 6 }}>
    <Grid2 container spacing={2}>
      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{
          py: 6,
          pt: { md: 4 },
          display: 'flex',
          alignItems: 'flex-start',
          position: 'relative',
        }}
      >
        <BlurBackground
          isStrong
          sx={{
            left: { xs: '50%', md: 0 },
            top: 0,
            transform: 'translate(-50%, -50%)',
          }}
        />

        <Stack spacing={{ md: 6, xs: 3 }} sx={{ textAlign: { xs: 'center', md: 'initial' } }}>
          <Stack spacing={{ md: 3, xs: 2 }}>
            <Typography variant="h1">
              AI-Powered Property Management
              <br />
              for <strong>Single Homes</strong> and
              <br />
              <strong>Multi-Unit Buildings</strong>
            </Typography>

            <Typography variant="body1">
              Our AI-powered platform revolutionizes property management for both single-home owners
              and multi-unit property managers. Save time, reduce stress, and increase revenue.
            </Typography>
          </Stack>

          <Stack
            spacing={1.5}
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'center', md: 'initial' }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ order: { xs: -1, sm: 0 } }}
              href={PublicRoutes.signup}
            >
              Get Started
            </Button>
          </Stack>
        </Stack>
      </Grid2>

      <Grid2 size={{ xs: 12, md: 6 }} sx={{ position: 'relative' }}>
        <BlurBackground
          sx={{
            right: { xs: '50%', sm: 0 },
            top: '100%',
            transform: 'translate(50%, -50%)',
          }}
        />

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: { md: 'calc(100dvh - 200px)' },
            mb: { xs: 4, md: 0 },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src="/assets/images/undraw_house_searching.svg"
            alt="Property Management"
            style={{
              width: '100%',
              maxWidth: '500px',
              height: 'auto',
            }}
          />
        </Box>
      </Grid2>
    </Grid2>
  </Grid2>
);

export default LandingHeroSection;
