// src/utils/workflow.utils.ts

import {
  WorkflowType,
  WorkflowData,
  WorkflowNode,
  NodeStyle,
  NodeTypes,
  Point,
  DropdownValues,
} from '../types/workflow';

const NODE_HEIGHT = 50;
const CONNECTION_OFFSET = 10;

export const getInitialWorkflowState = (workflowType: WorkflowType): WorkflowData => {
  switch (workflowType) {
  case 'lease-creation':
    return {
      name: 'Lease Creation',
      type: 'lease-creation',
      nodes: [
        {
          id: 'trigger-1',
          type: 'trigger',
          label: 'Start Lease Creation',
          position: { x: 240, y: 50 },
          config: {
            dropdownType: 'multi',
            options: [
              {
                id: 'triggerType',
                label: 'Trigger Type',
                type: 'single',
                options: [{ id: 'new_lease_request', label: 'New Lease Request' }],
              },
              {
                id: 'selectedTenant',
                label: 'Select Tenant',
                type: 'single',
                options: [], // Will be populated with tenants
              },
            ],
            selectedOption: {
              triggerType: 'new_lease_request',
              selectedTenant: undefined,
            },
          },
        },
      ],
      connections: [],
    };

  case 'invoice-approval':
    return {
      name: 'Invoice Approval',
      type: 'invoice-approval',
      nodes: [
        {
          id: 'trigger-1',
          type: 'trigger',
          label: 'Start Invoice Approval',
          position: { x: 240, y: 50 },
          config: {
            dropdownType: 'multi',
            options: [],
            selectedOption: {
              triggerType: 'invoice_received',
            },
          },
        },
      ],
      connections: [],
    };
  }
};

export const validateWorkflowConnection = (
  fromNode: WorkflowNode,
  toNode: WorkflowNode,
  workflowType: WorkflowType
): boolean => {
  if (fromNode.id === toNode.id) return false;

  if (workflowType === 'lease-creation') {
    if (fromNode.type === 'form' && toNode.type === 'form') {
      const formOrder = [
        'lease_term',
        'rent_deposit',
        'options',
        'clauses',
        'rules',
        'disclosures',
        'attachments',
        'lessor_info',
        'terms_agreement',
      ];

      const fromSelectedOption = fromNode.config?.selectedOption;
      const toSelectedOption = toNode.config?.selectedOption;

      // Type guard to check if selectedOption is DropdownValues
      const isDropdownValues = (
        value: string | string[] | DropdownValues | undefined
      ): value is DropdownValues =>
        typeof value === 'object' && value !== null && !Array.isArray(value);

      if (isDropdownValues(fromSelectedOption) && isDropdownValues(toSelectedOption)) {
        const fromFormType = fromSelectedOption.formType;
        const toFormType = toSelectedOption.formType;

        if (fromFormType && toFormType) {
          const fromIndex = formOrder.indexOf(fromFormType);
          const toIndex = formOrder.indexOf(toFormType);
          return toIndex === fromIndex + 1;
        }
      }
    }
  }

  const connectionRules: Record<NodeTypes, NodeTypes[]> = {
    trigger: ['decision', 'form', 'notify'],
    decision: ['notify', 'form'],
    form: ['form', 'decision', 'notify'],
    notify: ['decision', 'form'],
  };

  return connectionRules[fromNode.type]?.includes(toNode.type) || false;
};

export const getNodeStyle = (node: WorkflowNode, isSelected: boolean): NodeStyle => {
  const baseStyle: NodeStyle = {
    width: isSelected ? 300 : 200,
    transition: 'width 0.2s ease-in-out',
    boxShadow: 1,
    borderRadius: '8px',
    borderLeft: '4px solid',
    borderLeftColor: 'grey.400',
    bgcolor: isSelected ? 'grey.100' : 'background.paper',
  };

  switch (node.type) {
  case 'trigger':
    return {
      ...baseStyle,
      borderLeftColor: 'primary.main',
      bgcolor: isSelected ? 'primary.lighter' : 'background.paper',
    };
  case 'decision':
    return {
      ...baseStyle,
      borderLeftColor: 'warning.main',
      bgcolor: isSelected ? 'warning.lighter' : 'background.paper',
    };
  case 'notify':
    return {
      ...baseStyle,
      borderLeftColor: 'success.main',
      bgcolor: isSelected ? 'success.lighter' : 'background.paper',
    };
  case 'form':
    return {
      ...baseStyle,
      borderLeftColor: 'info.main',
      bgcolor: isSelected ? 'info.lighter' : 'background.paper',
    };
  default:
    return baseStyle;
  }
};

export const isPointNearLine = (point: Point, start: Point, end: Point, threshold = 5): boolean => {
  const dx = end.x - start.x;
  const dy = end.y - start.y;
  const length = Math.sqrt(dx * dx + dy * dy);

  if (length === 0) return false;

  const t = Math.max(
    0,
    Math.min(1, ((point.x - start.x) * dx + (point.y - start.y) * dy) / (length * length))
  );

  const projectionX = start.x + t * dx;
  const projectionY = start.y + t * dy;

  const distanceSquared = (point.x - projectionX) ** 2 + (point.y - projectionY) ** 2;
  return distanceSquared <= threshold * threshold;
};

export const calculateConnectionPoints = (
  fromNode: WorkflowNode,
  toNode: WorkflowNode,
  selectedNodeId: string | null
): { from: Point; to: Point } => {
  const getNodeWidth = (node: WorkflowNode, isSelected: boolean): number =>
    node.config?.dropdownType && isSelected ? 300 : 200;

  const fromWidth = getNodeWidth(fromNode, selectedNodeId === fromNode.id);
  const toWidth = getNodeWidth(toNode, selectedNodeId === toNode.id);

  const fromCenterX = fromNode.position.x + fromWidth / 2;
  const fromBottomY = fromNode.position.y + NODE_HEIGHT;
  const toCenterX = toNode.position.x + toWidth / 2;
  const toTopY = toNode.position.y;

  const from: Point = {
    x: fromCenterX,
    y: fromBottomY + CONNECTION_OFFSET,
  };

  const to: Point = {
    x: toCenterX,
    y: toTopY - CONNECTION_OFFSET,
  };

  return { from, to };
};
