import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  CardActionArea,
  Box,
  Chip,
  Stack,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { environment } from '@env';

import { SearchSmIcon } from '@/assets/icons/SearchSmIcon';
import { PMPRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

export interface WorkflowTemplate {
  id: string;
  name: string;
  type: string;
  nodes: Array<{
    id: string;
    type: string;
    label: string;
    config: {
      options: Array<{
        id: string;
        type: string;
        label: string;
        options: Array<{
          id: string;
          label: string;
        }>;
      }>;
      selectedOption: Record<string, unknown>;
      [key: string]: unknown;
    };
    position: {
      x: number;
      y: number;
    };
  }>;
  connections: Array<{
    id: string;
    to: string;
    from: string;
    label?: string;
  }>;
}

interface WorkflowTemplatesModalProps {
  open: boolean;
  onClose: () => void;
  onSelectTemplate: (template: WorkflowTemplate) => void;
}

const WorkflowTemplatesModal = ({
  open,
  onClose,
  onSelectTemplate,
}: WorkflowTemplatesModalProps) => {
  const history = useHistory();
  const { session } = useAuth();
  const { selectedPropertyLocation } = usePropertyManager();
  const [searchQuery, setSearchQuery] = useState('');

  const {
    data: templates,
    isLoading,
    error,
  } = useQuery<WorkflowTemplate[]>({
    queryKey: ['workflowTemplates', selectedPropertyLocation?.id],
    queryFn: async () => {
      if (!selectedPropertyLocation?.id) return [];
      const response = await axios.get(
        `${environment.api}/property-location/${selectedPropertyLocation.id}`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      const workflowsData = response.data.metadata?.workflows || {};
      return Object.entries(workflowsData).map(([id, workflow]) => ({
        ...(workflow as Omit<WorkflowTemplate, 'id'>),
        id,
      }));
    },
    enabled: !!selectedPropertyLocation?.id && open,
  });

  const filteredTemplates = useMemo(() => {
    if (!templates || !searchQuery) return templates;

    const query = searchQuery.toLowerCase();
    return templates.filter(
      template =>
        template.name.toLowerCase().includes(query) || template.type.toLowerCase().includes(query)
    );
  }, [templates, searchQuery]);

  const handleSelectTemplate = (template: WorkflowTemplate) => {
    history.push(`${PMPRoutes.workflows}?templateId=${template.id}&type=${template.type}`);
    onSelectTemplate(template);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Workflow Templates</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            placeholder="Search templates..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchSmIcon />
                </InputAdornment>
              ),
            }}
            size="small"
          />
        </Box>
        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Alert severity="error">
            Error loading workflow templates: {(error as Error).message}
          </Alert>
        )}
        {!isLoading && !error && (!filteredTemplates || filteredTemplates.length === 0) && (
          <Typography color="text.secondary">
            {templates?.length
              ? 'No matching templates found.'
              : 'No workflow templates available.'}
          </Typography>
        )}
        {filteredTemplates && filteredTemplates.length > 0 && (
          <Box sx={{ mt: 1 }}>
            <Stack
              direction="row"
              spacing={2}
              useFlexGap
              flexWrap="wrap"
              sx={{
                gap: 2,
                '& > *': {
                  width: 250,
                  height: 250,
                },
              }}
            >
              {filteredTemplates.map(template => (
                <Card
                  key={template.id}
                  sx={{
                    flexShrink: 0,
                    '&:hover': {
                      boxShadow: theme => theme.shadows[4],
                    },
                  }}
                >
                  <CardActionArea
                    onClick={() => handleSelectTemplate(template)}
                    sx={{ height: '100%' }}
                  >
                    <CardContent>
                      <Typography variant="h6" gutterBottom noWrap>
                        {template.name}
                      </Typography>
                      <Box sx={{ mb: 1 }}>
                        <Chip
                          label={template.type}
                          size="small"
                          color="primary"
                          variant="outlined"
                        />
                      </Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{
                          display: 'block',
                          wordBreak: 'break-all',
                          opacity: 0.7,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        ID: {template.id}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        {template.nodes.length} step{template.nodes.length !== 1 ? 's' : ''}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Stack>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkflowTemplatesModal;
