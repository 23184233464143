import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { AccountType } from '@/api/enums/supabase';
import { UserModel } from '@/api/models/user';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_SetAccountType_User = UserModel;

export type ApiRequest_SetAccountType_User = {
  account_type: AccountType;
};

const METHOD = 'POST';
const ENDPOINT = 'user/account_type';

export const useSetAccountType = (stateFns?: MutationStateFns<ApiResponse_SetAccountType_User>) => {
  const { session, refreshUser } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_SetAccountType_User) =>
      handleApiCall<ApiResponse_SetAccountType_User, ApiRequest_SetAccountType_User>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: async data => {
      enqueueSnackbar('Account type set successfully', { variant: 'success' });
      await refreshUser();

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to set account type', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
