import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { VaultDocument } from '@/types/vault';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

const METHOD = 'GET';
const ENDPOINT = 'vault/documents';

export type ApiRequest_GetAll_VaultDocuments = {
  property_location_id?: number;
  building_id?: number;
  unit_id?: number;
  tenant_id?: number;
};

export type ApiResponse_GetAll_VaultDocuments = VaultDocument[];

export const QUERY_KEY = (params?: ApiRequest_GetAll_VaultDocuments) =>
  getQueryKey(METHOD, ENDPOINT, params);

export const useGetVaultDocuments = (params?: ApiRequest_GetAll_VaultDocuments, enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    queryKey: QUERY_KEY(params),
    enabled: enabled && !!session?.access_token,
    queryFn: async () => {
      try {
        const data = await handleApiCall<
          ApiResponse_GetAll_VaultDocuments,
          ApiRequest_GetAll_VaultDocuments
        >(session, METHOD, getResolvedEndpoint(ENDPOINT, {}), params);

        return data;
      } catch (error) {
        enqueueSnackbar('Failed to fetch vault documents.', { variant: 'error' });
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      }
    },
  });
};
