export const capitalizeFirstLetter = (str?: string): string | undefined =>
  typeof str === 'string'
    ? str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
    : str;

export const pluralize = (count: number, word: string, pluralizedWord = `${word}s`): string =>
  count === 1 ? word : pluralizedWord;

export const labelFromEnum = (str: string, delimiter = '_'): string =>
  str.split(delimiter).map(capitalizeFirstLetter).join(' ');

export const nonBreakingSpace = '\u00A0';
