import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import DashboardLayout from '@/@mantis/layout/dashboard/DashboardLayout';
import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { ResidentRoutes } from '@/config/routes';
import ResidentProvider from '@/context/ResidentProvider';

import LoadingState from '@/components/LoadingState';

const DashboardPage = lazy(() => import('./DashboardPage'));
const PaymentListPage = lazy(() => import('./PaymentListPage'));
const CommunicationPage = lazy(() => import('./CommunicationPage'));
const AccountPage = lazy(() => import('@/pages/shared/AccountPage'));

const HomePage: React.FC = () => (
  <MuiPageWrapper>
    <DashboardLayout>
      <ResidentProvider>
        <Suspense fallback={<LoadingState />}>
          <Switch>
            <Route exact path={ResidentRoutes.dashboard} component={DashboardPage} />
            <Route exact path={ResidentRoutes.payments} component={PaymentListPage} />
            <Route exact path={ResidentRoutes.communication} component={CommunicationPage} />
            <Route exact path={ResidentRoutes.account} component={AccountPage} />
          </Switch>
        </Suspense>
      </ResidentProvider>
    </DashboardLayout>
  </MuiPageWrapper>
);

export default HomePage;
