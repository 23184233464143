import { SvgIcon, SvgIconProps } from '@mui/material';

export const DocumentIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M15.5 2H4.5C3.67 2 3 2.67 3 3.5v17c0 .83.67 1.5 1.5 1.5h15c.83 0 1.5-.67 1.5-1.5v-12.5L15.5 2zm4 18.5c0 .28-.22.5-.5.5h-15c-.28 0-.5-.22-.5-.5v-17c0-.28.22-.5.5-.5h10.5v4c0 .55.45 1 1 1h4v12.5zM14 11H7v2h7v-2zm3 4H7v2h10v-2z"
    />
  </SvgIcon>
);

export default DocumentIcon;
