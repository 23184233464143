import Lottie, { LottieComponentProps } from 'lottie-react';
import React from 'react';

const BaseLottie: React.FC<LottieComponentProps> = ({
  loop = true,
  autoplay = true,
  style = {
    height: '100%',
    width: '100%',
  },
  ...props
}) => <Lottie loop={loop} autoplay={autoplay} style={style} {...props} />;

export default BaseLottie;
