import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { environment } from '@env';

import { TDocument } from '@/types/document';

import { useAuth } from '@/context/AuthProvider';

import MuiPDFDocument from '@/components/document/MuiPDFDocument';

import ModalWithPreview from '../modals/ModalWithPreview';

interface Props {
  id: string;
  onClose: () => void;
}

const DocumentViewModal: React.FC<Props> = ({ id, onClose }) => {
  const { session } = useAuth();

  const documentUrl = `${environment.api}/document/doc/${id}?token=${session?.access_token}`;

  const fetchDocumentQuery = useQuery<TDocument>({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'document', id, session?.access_token],
    queryFn: async () => {
      const response = await axios.get(`${environment.api}/document/${id}`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      return response.data;
    },
  });

  const isImage = fetchDocumentQuery.data?.mime_type.startsWith('image/');

  return (
    <ModalWithPreview onClose={onClose} title={fetchDocumentQuery.data?.original_name}>
      <Box sx={{ flex: '1 1 100%', overflow: 'auto' }}>
        {isImage ? (
          <img src={documentUrl} alt={fetchDocumentQuery.data?.original_name || id} />
        ) : (
          <MuiPDFDocument
            url={documentUrl}
            fileName={fetchDocumentQuery.data?.original_name || id}
          />
        )}
      </Box>
    </ModalWithPreview>
  );
};

export default DocumentViewModal;
