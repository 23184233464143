import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Uuid } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_Archive_Email = never;

export type ApiRequest_Archive_Email = {
  thread_external_id: Uuid;
};

const METHOD = 'POST';
const ENDPOINT = 'email/:thread_external_id/archive';

export const useArchiveEmail = (stateFns?: MutationStateFns<ApiResponse_Archive_Email>) => {
  const { session } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_Archive_Email) =>
      handleApiCall<ApiResponse_Archive_Email, ApiRequest_Archive_Email>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, data)
      ),
    onSuccess: data => {
      enqueueSnackbar('Email archived successfully', { variant: 'success' });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to archive email', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
