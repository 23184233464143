import { IonButton, IonSkeletonText } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { environment } from '@env';

import { Address } from '@/types/address';
import { Feature, GeocodeAPIV6Response } from '@/types/mapbox';
import { BaseProps } from '@/types/props';

import { addressToText } from '@/utils/address';
import { getDummyArray } from '@/utils/list';
import { featureToAddress } from '@/utils/mapbox';

import LLMChatBubble from '@/components/@ionic/stream-chat/LLMChatBubble';
import TerraiMessageInput from '@/components/@ionic/stream-chat/TerraiMessageInput';

import S from './AddressSelect.styles';

interface Props extends BaseProps {
  onAddressSelect: (address: Address) => void;
}

const AddressSelect: React.FC<Props> = ({ onAddressSelect, ...baseProps }) => {
  const [suggestions, setSuggestions] = useState<Feature[]>([]);
  const [addressText, setAddressText] = useState<string>('');

  const { isPending, isError, isSuccess, data } = useQuery({
    enabled: addressText.length > 2,
    queryKey: ['GET', 'suggestions', addressText],
    queryFn: async () => {
      const response = await axios.get<GeocodeAPIV6Response>(
        'https://api.mapbox.com/search/geocode/v6/forward?',
        {
          params: new URLSearchParams({
            q: addressText,
            access_token: environment.mapboxApiKey,
            autocomplete: 'true',
            country: 'US',
            format: 'geojson',
            language: 'en-US',
            limit: '5',
            types: 'address',
          }),
        }
      );

      return response.data;
    },
  });

  useEffect(() => {
    if (isError || isPending) {
      return setSuggestions([]);
    }

    if (addressText.length <= 2) return setSuggestions([]);

    if (isSuccess) return setSuggestions(data.features);
  }, [isSuccess, isError, isPending, addressText, data]);

  const onSuggestionClick = useCallback(
    async (suggestion: Feature) => {
      const address = featureToAddress(suggestion);

      setAddressText(addressToText(address));
      setSuggestions([]);

      onAddressSelect(address);
    },
    [onAddressSelect]
  );

  const isSearchableAddress = useMemo(
    () => addressText.replace(/\d|\s/g, '').length > 2,
    [addressText]
  );

  return (
    <div {...baseProps}>
      <TerraiMessageInput
        placeholder="Enter property address"
        value={addressText}
        onValueChange={setAddressText}
      />

      {isPending && isSearchableAddress && suggestions.length === 0 && (
        <S.IonButtons>
          {getDummyArray(5).map((_v, i) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <S.SuggestionWrapper key={i}>
              <IonButton expand="block" color="ocean-8" fill="solid" shape="round">
                <IonSkeletonText animated style={{ width: '15rem' }} />
              </IonButton>
            </S.SuggestionWrapper>
          ))}
        </S.IonButtons>
      )}

      {suggestions.length > 0 && (
        <S.IonButtons>
          {suggestions.map(suggestion => (
            <S.SuggestionWrapper key={suggestion.id}>
              <IonButton
                onClick={() => onSuggestionClick(suggestion)}
                expand="block"
                color="ocean-8"
                fill="solid"
                shape="round"
              >
                {addressToText(featureToAddress(suggestion))}
              </IonButton>
            </S.SuggestionWrapper>
          ))}
        </S.IonButtons>
      )}

      {isSuccess && isSearchableAddress && suggestions.length === 0 && (
        <LLMChatBubble className="mt-2rem" isFullWidth>
          Make sure to provide a <strong>full address</strong>, including <strong>building</strong>{' '}
          and <strong>apartment numbers</strong>.
        </LLMChatBubble>
      )}
    </div>
  );
};

export default AddressSelect;
