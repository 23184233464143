import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { TicketModel } from '@/api/models/ai/ticket';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_Assign_Ticket = {
  ticketId: Id;
  assigned_to: Id;
};

export type ApiRequest_Assign_Ticket = {
  managerId: Id;
};

const METHOD = 'POST';
const ENDPOINT = 'ticket/:id/assign';

export const useAssignTicket = (
  id: TicketModel['id'],
  stateFns?: MutationStateFns<ApiResponse_Assign_Ticket>
) => {
  const { session } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_Assign_Ticket) =>
      handleApiCall<ApiResponse_Assign_Ticket, ApiRequest_Assign_Ticket>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: id.toString() }),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Ticket assigned successfully', { variant: 'success' });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to assign ticket', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
