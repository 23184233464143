import { Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useForm } from 'react-hook-form';

import { environment } from '@env';

import { TLeaseDetails } from '@/types/todo';

import { useAuth } from '@/context/AuthProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

import FormSelect from '../form/FormSelect';

import DashboardTodoList, { TodoItem } from './DashboardTodoList';

const EXPIRES_IN_OPTIONS = [15, 30, 45, 60, 90, 180, 365].map(days => ({
  label: `${days} days`,
  value: days,
}));

interface FormData {
  expires_in: number;
}

const DashboardExpiringLeases: React.FC = () => {
  const { selectedPropertyLocation } = usePropertyManager();
  const { session } = useAuth();

  const { watch, control, register } = useForm<FormData>({
    defaultValues: {
      expires_in: 60,
    },
  });

  const expiriesIn = watch('expires_in');

  const fetchExpiringLeasesQuery = useQuery({
    enabled: !!selectedPropertyLocation?.id && !!session?.access_token,
    queryKey: ['expiringLeases', session?.access_token, expiriesIn, selectedPropertyLocation?.id],
    queryFn: async () => {
      if (!selectedPropertyLocation?.id) return [];

      const response = await axios.get<TLeaseDetails[]>(
        `${environment.api}/todo/location/${selectedPropertyLocation.id}/expiring-leases`,
        {
          params: {
            days: expiriesIn,
          },
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data.map(lease => ({
        id: lease.tenantId,
        title: `Lease Expiring - ${lease.tenantName}`,
        description: `Unit: ${lease.unitName} - Building: ${lease.buildingName}`,
        dueDate: lease.expiryDate,
        buildingId: lease.buildingId,
        tenantId: lease.tenantId,
      })) as TodoItem[];
    },
  });

  return (
    <DashboardTodoList
      title="Upcoming Lease Renewals"
      items={fetchExpiringLeasesQuery.data}
      isLoading={fetchExpiringLeasesQuery.isLoading}
    >
      <Stack direction="row" alignItems="center" spacing={3}>
        <Typography
          variant="subtitle3"
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          Expires In
        </Typography>

        <FormSelect
          placeholder="Select Expires In"
          options={EXPIRES_IN_OPTIONS}
          control={control}
          {...register('expires_in')}
        />
      </Stack>
    </DashboardTodoList>
  );
};

export default DashboardExpiringLeases;
