import { Tooltip, IconButton, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useLayoutEffect, useState, useEffect } from 'react';

import { useDrawer } from '@/@mantis/contexts/DrawerContext';
import { getPropertyManagerMenuItems } from '@/@mantis/menu-items/property-manager-menu-items';
import { getResidentMenuItems } from '@/@mantis/menu-items/resident-menu-items';
import { NavItemType } from '@/@mantis/types/menu';
import { HelpCircleIcon } from '@/assets/icons/HelpCircleIcon';
import { useAuth } from '@/context/AuthProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

import MenuTourGuide from '@/components/common/MenuTourGuide';

import NavGroup from './NavGroup';
import NavItem from './NavItem';

// Local storage key for tracking if the user has seen the tour
const TOUR_VIEWED_KEY = 'terrai_menu_tour_viewed';

const Navigation: React.FC = () => {
  const { isOpen } = useDrawer();
  const { isSingleFamilyLocation } = usePropertyManager();
  const { isPropertyManager, isResident } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [selectedID, setSelectedID] = useState<string | undefined>('');
  const [selectedItems, setSelectedItems] = useState<string | undefined>('');
  const [selectedLevel, setSelectedLevel] = useState<number>(0);
  const [menuItems, setMenuItems] = useState<{ items: NavItemType[] }>({ items: [] });
  const [isTourOpen, setIsTourOpen] = useState(false);

  useLayoutEffect(() => {
    let items: NavItemType[] = [];
    // Use property manager menu items or resident menu items based on user type
    if (isPropertyManager) {
      items = getPropertyManagerMenuItems(isSingleFamilyLocation);
    } else if (isResident) {
      items = getResidentMenuItems();
    }

    setMenuItems({ items });
    // eslint-disable-next-line
  }, [getPropertyManagerMenuItems, isSingleFamilyLocation, isPropertyManager]);

  // Check if tour has been viewed before and show automatically if not
  useEffect(() => {
    if (isOpen && !isMobile) {
      const hasTourBeenViewed = localStorage.getItem(TOUR_VIEWED_KEY) === 'true';
      if (!hasTourBeenViewed) {
        setIsTourOpen(true);
      }
    }
  }, [isOpen, isMobile]);

  const lastItemIndex = menuItems.items.length - 1;
  const remItems: NavItemType[] = [];

  const navGroups = menuItems.items.slice(0, lastItemIndex + 1).map((item, index) => {
    switch (item.type) {
    case 'group':
      if (item.url && item.id !== undefined) {
        return (
          <List key={item.id}>
            {/* Only show dividers for property manager menu items */}
            {isPropertyManager && index !== 0 && <Divider sx={{ my: 0.5 }} />}
            <NavItem item={item} level={1} isParents setSelectedID={setSelectedID} />
          </List>
        );
      }

      return (
        <NavGroup
          key={item.id}
          setSelectedID={setSelectedID}
          setSelectedItems={setSelectedItems}
          setSelectedLevel={setSelectedLevel}
          selectedLevel={selectedLevel}
          selectedID={selectedID}
          selectedItems={selectedItems}
          remItems={remItems}
          item={item}
        />
      );
    default:
      return (
        <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
        </Typography>
      );
    }
  });

  // Function to handle tour open
  const handleOpenTour = () => {
    setIsTourOpen(true);
  };

  // Function to handle tour close
  const handleCloseTour = () => {
    setIsTourOpen(false);
    // Mark that the user has seen the tour
    localStorage.setItem(TOUR_VIEWED_KEY, 'true');
  };

  return (
    <Box
      sx={{
        pt: !isOpen ? 0 : 2,
        px: !isOpen ? 0 : 2,
        display: 'block',
        '& > ul:first-of-type': { mt: 0 },
        position: 'relative',
      }}
    >
      {/* Tour Guide Help Button */}
      {isOpen && !isMobile && (
        <Box
          sx={{
            position: 'absolute',
            top: -45,
            right: 16,
            zIndex: 1,
          }}
        >
          <Tooltip title="Get a guided tour of the platform features">
            <IconButton
              size="small"
              onClick={handleOpenTour}
              sx={{
                color: theme.palette.primary.main,
                '&:hover': {
                  bgcolor: theme.palette.primary.light,
                  color: theme.palette.common.white,
                },
              }}
            >
              <HelpCircleIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {/* Menu Tour Guide */}
      <MenuTourGuide
        isOpen={isTourOpen}
        onClose={handleCloseTour}
        isSingleFamilyAccount={isSingleFamilyLocation}
      />

      {navGroups}
    </Box>
  );
};

export default Navigation;
