import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { BuildingModel } from '@/api/models/building';
import { PropertyLocationWithEmailAndPhoneModel } from '@/api/models/propertyLocation';
import { PropertyManagementModel } from '@/api/models/propertyManagement';
import { PropertyManagerModel } from '@/api/models/propertyManager';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Int } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_GetAll_PropertyLocation = Array<
  PropertyLocationWithEmailAndPhoneModel & {
    buildings: Array<
      BuildingModel & {
        _count: {
          units: Int;
        };
      }
    >;
    management: Array<
      PropertyManagementModel & {
        manager: PropertyManagerModel;
      }
    >;
  }
>;

const METHOD = 'GET';
const ENDPOINT = 'property-location/all';
export const QUERY_KEY = getQueryKey(METHOD, ENDPOINT);

export const useGetAllPropertyLocations = (enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY,
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetAll_PropertyLocation>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {})
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch property locations.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
