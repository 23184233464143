import { Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useForm, UseFormReturn, useFieldArray, FieldArrayWithId, Control } from 'react-hook-form';

import { environment } from '@env';

import {
  LessorInfoFormData,
  LeaseTermFormData,
  OptionsFormData,
  DisclosuresFormData,
  RentDepositFormData,
  TermsAgreementFormData,
  ClausesFormData,
  RulesFormData,
  AttachmentsFormData,
  Document,
} from '@/types/lease';
import { WorkflowNode } from '@/types/workflow';

import { useAuth } from '@/context/AuthProvider';

import { FormContent as AttachmentsFormContent } from '@/components/lease/AttachmentsForm';
import { FormContent as ClausesFormContent } from '@/components/lease/ClausesForm';
import { FormContent as DisclosuresFormContent } from '@/components/lease/DisclosuresForm';
import { FormContent as LeaseTermFormContent } from '@/components/lease/LeaseTermForm';
import { FormContent as LessorInfoFormContent } from '@/components/lease/LessorInfoForm';
import { FormContent as OptionsFormContent } from '@/components/lease/OptionsForm';
import { FormContent as RentDepositFormContent } from '@/components/lease/RentDepositForm';
import { FormContent as RulesFormContent } from '@/components/lease/RulesForm';
import { FormContent as TermsAgreementFormContent } from '@/components/lease/TermsAgreementForm';
import ModalWithForm from '@/components/modals/ModalWithForm';

type FormType =
  | 'lessor_info'
  | 'lease_term'
  | 'options'
  | 'disclosures'
  | 'rent_deposit'
  | 'terms_agreement'
  | 'rules'
  | 'clauses'
  | 'attachments';

type FormDataMap = {
  lessor_info: LessorInfoFormData;
  lease_term: LeaseTermFormData;
  options: OptionsFormData;
  disclosures: DisclosuresFormData;
  rent_deposit: RentDepositFormData;
  terms_agreement: TermsAgreementFormData;
  rules: RulesFormData;
  clauses: ClausesFormData;
  attachments: AttachmentsFormData;
};

export type FormConfig<T extends FormType = FormType> = {
  formData?: FormDataMap[T];
  selectedOption?: { formType?: T };
  executed?: boolean;
  executedAt?: string;
};

interface Props {
  open: boolean;
  onClose: () => void;
  node: WorkflowNode & {
    config?: FormConfig;
  };
  locationId: number;
  workflowId: string;
  invoiceId: string;
}

type BaseFormComponentProps<T extends FormType> = {
  form: UseFormReturn<FormDataMap[T]>;
  disabled?: boolean;
};

type ClausesFormComponent = React.ComponentType<{
  form: UseFormReturn<ClausesFormData>;
  fields: FieldArrayWithId<ClausesFormData, 'custom_clauses'>[];
  append: (data: { title: string; content: string }) => void;
  remove: (index: number) => void;
  disabled?: boolean;
}>;

type AttachmentsFormComponent = React.ComponentType<{
  form: UseFormReturn<AttachmentsFormData>;
  fields: FieldArrayWithId<AttachmentsFormData, 'documents'>[];
  append: (data: Document) => void;
  remove: (index: number) => void;
  disabled?: boolean;
}>;

type FormComponentType<T extends FormType> = T extends 'clauses'
  ? ClausesFormComponent
  : T extends 'attachments'
  ? AttachmentsFormComponent
  : React.ComponentType<BaseFormComponentProps<T>>;

const FORM_COMPONENTS = {
  lessor_info: LessorInfoFormContent as React.ComponentType<BaseFormComponentProps<'lessor_info'>>,
  lease_term: LeaseTermFormContent as React.ComponentType<BaseFormComponentProps<'lease_term'>>,
  options: OptionsFormContent as React.ComponentType<BaseFormComponentProps<'options'>>,
  disclosures: DisclosuresFormContent as React.ComponentType<BaseFormComponentProps<'disclosures'>>,
  rent_deposit: RentDepositFormContent as React.ComponentType<
    BaseFormComponentProps<'rent_deposit'>
  >,
  terms_agreement: TermsAgreementFormContent as React.ComponentType<
    BaseFormComponentProps<'terms_agreement'>
  >,
  rules: RulesFormContent as React.ComponentType<BaseFormComponentProps<'rules'>>,
  clauses: ClausesFormContent as ClausesFormComponent,
  attachments: AttachmentsFormContent as AttachmentsFormComponent,
} as const;

function isFormType(type: string | undefined): type is FormType {
  return type !== undefined && type in FORM_COMPONENTS;
}

function getFormComponent<T extends FormType>(formType: T): FormComponentType<T> {
  return FORM_COMPONENTS[formType] as FormComponentType<T>;
}

const LeaseFormModal: React.FC<Props> = ({
  open,
  onClose,
  node,
  locationId,
  workflowId,
  invoiceId,
}) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();
  const formType = node.config?.selectedOption?.formType;
  const isValidFormType = isFormType(formType);

  const form = useForm<FormDataMap[typeof formType & FormType]>({
    defaultValues: isValidFormType
      ? (node.config?.formData as FormDataMap[typeof formType & FormType])
      : undefined,
  });

  const updateFormData = useMutation<void, Error, FormDataMap[typeof formType & FormType]>({
    mutationFn: async data => {
      await axios.patch(
        `${environment.api}/workflow/location/${locationId}/workflow-instance/${workflowId}/${invoiceId}/node/${node.id}`,
        {
          formData: data,
          executed: true,
          executedAt: new Date().toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['workflowInstances', locationId],
      });
      onClose();
    },
  });

  const {
    fields: clausesFields,
    append: appendClause,
    remove: removeClause,
  } = useFieldArray<ClausesFormData>({
    control:
      isValidFormType && formType === 'clauses'
        ? (form.control as Control<ClausesFormData>)
        : (form.control as Control<ClausesFormData>),
    name: 'custom_clauses',
    shouldUnregister: true,
  });

  const {
    fields: attachmentsFields,
    append: appendAttachment,
    remove: removeAttachment,
  } = useFieldArray<AttachmentsFormData>({
    control:
      isValidFormType && formType === 'attachments'
        ? (form.control as Control<AttachmentsFormData>)
        : (form.control as Control<AttachmentsFormData>),
    name: 'documents',
    shouldUnregister: true,
  });

  if (!open || !isValidFormType) {
    return null;
  }

  const FormComponent = getFormComponent(formType);

  const renderFormComponent = () => {
    if (formType === 'clauses') {
      const typedForm = form as UseFormReturn<ClausesFormData>;
      const TypedComponent = FormComponent as ClausesFormComponent;

      return (
        <TypedComponent
          form={typedForm}
          fields={clausesFields}
          append={appendClause}
          remove={removeClause}
          disabled={node.config?.executed}
        />
      );
    }

    if (formType === 'attachments') {
      const typedForm = form as UseFormReturn<AttachmentsFormData>;
      const TypedComponent = FormComponent as AttachmentsFormComponent;

      return (
        <TypedComponent
          form={typedForm}
          fields={attachmentsFields}
          append={appendAttachment}
          remove={removeAttachment}
          disabled={node.config?.executed}
        />
      );
    }

    switch (formType) {
    case 'lessor_info': {
      const TypedComponent = FormComponent as React.ComponentType<
          BaseFormComponentProps<'lessor_info'>
        >;
      return (
        <TypedComponent
          form={form as UseFormReturn<LessorInfoFormData>}
          disabled={node.config?.executed}
        />
      );
    }
    case 'lease_term': {
      const TypedComponent = FormComponent as React.ComponentType<
          BaseFormComponentProps<'lease_term'>
        >;
      return (
        <TypedComponent
          form={form as UseFormReturn<LeaseTermFormData>}
          disabled={node.config?.executed}
        />
      );
    }
    case 'options': {
      const TypedComponent = FormComponent as React.ComponentType<
          BaseFormComponentProps<'options'>
        >;
      return (
        <TypedComponent
          form={form as UseFormReturn<OptionsFormData>}
          disabled={node.config?.executed}
        />
      );
    }
    case 'disclosures': {
      const TypedComponent = FormComponent as React.ComponentType<
          BaseFormComponentProps<'disclosures'>
        >;
      return (
        <TypedComponent
          form={form as UseFormReturn<DisclosuresFormData>}
          disabled={node.config?.executed}
        />
      );
    }
    case 'rent_deposit': {
      const TypedComponent = FormComponent as React.ComponentType<
          BaseFormComponentProps<'rent_deposit'>
        >;
      return (
        <TypedComponent
          form={form as UseFormReturn<RentDepositFormData>}
          disabled={node.config?.executed}
        />
      );
    }
    case 'terms_agreement': {
      const TypedComponent = FormComponent as React.ComponentType<
          BaseFormComponentProps<'terms_agreement'>
        >;
      return (
        <TypedComponent
          form={form as UseFormReturn<TermsAgreementFormData>}
          disabled={node.config?.executed}
        />
      );
    }
    case 'rules': {
      const TypedComponent = FormComponent as React.ComponentType<
          BaseFormComponentProps<'rules'>
        >;
      return (
        <TypedComponent
          form={form as UseFormReturn<RulesFormData>}
          disabled={node.config?.executed}
        />
      );
    }
    default:
      return null;
    }
  };

  return (
    <ModalWithForm
      form={form}
      maxWidth="md"
      title={node.label || 'Edit Form'}
      buttonLabel="Submit"
      onClose={onClose}
      onSubmit={updateFormData.mutateAsync}
    >
      <Box sx={{ py: 2 }}>{renderFormComponent()}</Box>
    </ModalWithForm>
  );
};

export default LeaseFormModal;
