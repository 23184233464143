// src/config/nodeTypes.ts

import { Tenant } from '@/api/endpoints/tenant/useGetAllTenantsByLocation';

import { NodeType, WorkflowType, ComplexDropdownOption } from './workflow';

export interface WorkflowPropertyManager {
  id: number;
  user_id: string;
  full_name: string;
  email: string;
  phone: string;
  role: 'MANAGER' | 'ADMIN';
  is_emergency_contact: boolean;
}

const commonNodeTypes: NodeType[] = [
  {
    id: 'trigger',
    label: 'Trigger',
    config: {
      dropdownType: 'multi',
      options: [
        {
          id: 'triggerType',
          label: 'Trigger Type',
          type: 'single',
          options: [
            { id: 'invoice_received', label: 'Invoice Received' },
            { id: 'new_lease_request', label: 'New Lease Request' },
          ],
        } as ComplexDropdownOption,
        {
          id: 'participants',
          label: 'Participants',
          type: 'multi',
          options: [], // Will be populated with property managers
        } as ComplexDropdownOption,
        {
          id: 'selectedTenant',
          label: 'Select Tenant',
          type: 'single',
          options: [], // Will be populated with tenants
        } as ComplexDropdownOption,
      ],
    },
  },
  {
    id: 'form',
    label: 'Form',
    config: {
      dropdownType: 'multi',
      options: [
        {
          id: 'formType',
          label: 'Form Type',
          type: 'single',
          options: [
            { id: 'lease_term', label: 'Lease Term' },
            { id: 'rent_deposit', label: 'Rent, Deposit & Fees' },
            { id: 'options', label: 'Options' },
            { id: 'clauses', label: 'Clauses' },
            { id: 'rules', label: 'Rules' },
            { id: 'disclosures', label: 'Disclosures' },
            { id: 'attachments', label: 'Attachments' },
            { id: 'lessor_info', label: 'Lessor Info' },
            { id: 'terms_agreement', label: 'Terms Agreement' },
          ],
        } as ComplexDropdownOption,
        {
          id: 'required',
          label: 'Required',
          type: 'single',
          options: [
            { id: 'true', label: 'Required' },
            { id: 'false', label: 'Optional' },
          ],
        } as ComplexDropdownOption,
      ],
    },
  },
  {
    id: 'decision',
    label: 'Decision',
    config: {
      dropdownType: 'multi',
      options: [
        {
          id: 'decisionType',
          label: 'Decision Type',
          type: 'single',
          options: [
            { id: 'need_approval', label: 'Need Approval' },
            { id: 'approved', label: 'Approved' },
            { id: 'review_required', label: 'Review Required' },
          ],
        } as ComplexDropdownOption,
        {
          id: 'approvers',
          label: 'Approvers',
          type: 'multi',
          options: [], // Will be populated with property managers
        } as ComplexDropdownOption,
      ],
    },
  },
  {
    id: 'notify',
    label: 'Notify',
    config: {
      dropdownType: 'multi',
      options: [
        {
          id: 'notifyType',
          label: 'Notify Type',
          type: 'single',
          options: [
            { id: 'property_manager', label: 'Property Manager' },
            { id: 'tenant', label: 'Tenant' },
            { id: 'vendor', label: 'Vendor' },
            { id: 'lessor', label: 'Lessor' },
          ],
        } as ComplexDropdownOption,
        {
          id: 'recipients',
          label: 'Recipients',
          type: 'multi',
          options: [], // Will be populated with property managers
        } as ComplexDropdownOption,
        {
          id: 'remindInDays',
          label: 'Remind In Days',
          type: 'single',
          options: Array.from({ length: 15 }, (_, i) => ({
            id: (i + 1).toString(),
            label: `${i + 1} ${i === 0 ? 'Day' : 'Days'}`,
          })),
        } as ComplexDropdownOption,
      ],
    },
  },
];

export const getWorkflowNodeTypes = (
  workflowType: WorkflowType,
  propertyManagers: WorkflowPropertyManager[],
  tenants: Tenant[] = []
): NodeType[] => {
  // Create a deep copy of the node types
  const nodeTypes: NodeType[] = JSON.parse(JSON.stringify(commonNodeTypes));

  // Add property managers to options
  const managerOptions = propertyManagers.map(pm => ({
    id: pm.id.toString(),
    label: pm.full_name,
  }));

  // Add tenant options
  const tenantOptions = tenants.map(tenant => ({
    id: JSON.stringify({
      id: tenant.id,
      full_name: tenant.full_name,
      email: tenant.contacts[0]?.email,
      phone: tenant.contacts[0]?.phone,
      unit_id: tenant.unit_id,
      unit_name: tenant.unit.name,
    }),
    label: `${tenant.full_name} (${tenant.unit.name})`,
  }));

  // Filter node types based on workflow type first
  const filteredNodeTypes =
    workflowType === 'invoice-approval' ? nodeTypes.filter(type => type.id !== 'form') : nodeTypes;

  // Update the options for the remaining node types
  return filteredNodeTypes.map(nodeType => {
    if (nodeType.id === 'trigger' && nodeType.config?.options) {
      // Create a new config object to avoid modifying the parameter
      const newConfig = { ...nodeType.config };
      const triggerOptions = newConfig.options as ComplexDropdownOption[];
      const triggerTypeOption = triggerOptions.find(opt => opt.id === 'triggerType');

      if (triggerTypeOption) {
        const newTriggerTypeOption = { ...triggerTypeOption };

        if (workflowType === 'lease-creation') {
          // For lease creation, only show new_lease_request
          newTriggerTypeOption.options = triggerTypeOption.options.filter(
            opt => opt.id === 'new_lease_request'
          );
          const selectedTenantOption = triggerOptions.find(opt => opt.id === 'selectedTenant');
          if (selectedTenantOption) {
            const newSelectedTenantOption = { ...selectedTenantOption, options: tenantOptions };
            // Keep only the trigger type and tenant selection options for lease creation
            newConfig.options = [newTriggerTypeOption, newSelectedTenantOption];
          }
        } else {
          // For invoice approval, only show invoice_received
          newTriggerTypeOption.options = triggerTypeOption.options.filter(
            opt => opt.id === 'invoice_received'
          );
          const participantsOption = triggerOptions.find(opt => opt.id === 'participants');
          if (participantsOption) {
            const newParticipantsOption = { ...participantsOption, options: managerOptions };
            newConfig.options = [newTriggerTypeOption, newParticipantsOption];
          }
        }
        return { ...nodeType, config: newConfig };
      }
    }

    if ((nodeType.id === 'decision' || nodeType.id === 'notify') && nodeType.config?.options) {
      // Create a new config object to avoid modifying the parameter
      const newConfig = { ...nodeType.config };
      const options = newConfig.options as ComplexDropdownOption[];
      const participantsOption = options.find(
        opt => opt.id === 'approvers' || opt.id === 'recipients'
      );

      if (participantsOption) {
        const newOptions = options.map(opt =>
          opt.id === 'approvers' || opt.id === 'recipients'
            ? { ...opt, options: managerOptions }
            : opt
        );
        newConfig.options = newOptions;
        return { ...nodeType, config: newConfig };
      }
    }

    return nodeType;
  });
};
