import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { ApiResponse_Delete_Tenant, useDeleteTenant } from '@/api/endpoints/tenant/useDeleteTenant';
import { TenantModel } from '@/api/models/tenant';

import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  tenant: TenantModel;
  onClose: () => void;
  onTenantDeleted?: (tenant: ApiResponse_Delete_Tenant) => void;
}

const TenantDeleteModal: React.FC<Props> = ({ tenant, onClose, onTenantDeleted }) => {
  const form = useForm();

  const isSoftDelete = !tenant.is_archived;

  const deleteTenantMutation = useDeleteTenant(tenant.id, {
    onSuccess: data => {
      onTenantDeleted?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(() => deleteTenantMutation.mutateAsync(), [deleteTenantMutation]);

  return (
    <ModalWithForm
      form={form}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="xs"
      title={`${isSoftDelete ? 'Archive' : 'Delete'} Tenant`}
      buttonColor="error"
    >
      <Typography variant="body2">
        Are you sure you want to {isSoftDelete ? 'archive' : 'delete'} tenant{' '}
        <strong>{tenant.full_name}</strong>?
      </Typography>

      {!isSoftDelete && <Typography variant="subtitle3">This action cannot be undone.</Typography>}
    </ModalWithForm>
  );
};

export default TenantDeleteModal;
