import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiRequest_Create_Rule,
  ApiResponse_Create_Rule,
  useCreateRule,
} from '@/api/endpoints/rule/useCreateRule';
import { Id } from '@/api/utils/sql';

import FormTextField from '../form/FormTextField';
import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  locationId: Id;
  onClose: () => void;
  onRuleCreated?: (rule: ApiResponse_Create_Rule) => void;
}

const RuleCreateModal: React.FC<Props> = ({ onClose, onRuleCreated, locationId }) => {
  const form = useForm<ApiRequest_Create_Rule>({
    defaultValues: {
      location_id: locationId,
    },
  });
  const {
    register,
    control,
    formState: { errors },
  } = form;

  const createRuleMutation = useCreateRule({
    onSuccess: rule => {
      onRuleCreated?.(rule);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_Create_Rule) => createRuleMutation.mutateAsync(data),
    [createRuleMutation]
  );

  return (
    <ModalWithForm onSubmit={onSubmit} form={form} onClose={onClose} maxWidth="md" title="Add Rule">
      <FormTextField
        multiline
        rows={3}
        label="Description"
        control={control}
        required
        {...register('description', {
          required: 'Description is required',
        })}
        placeholder="Enter rule description"
        size="small"
        error={!!errors.description}
        helperText={errors.description?.message?.toString()}
      />

      <FormTextField
        multiline
        rows={3}
        control={control}
        required
        {...register('expected_output', {
          required: 'Expected output is required',
        })}
        label="Expected Output"
        placeholder="Enter expected output"
        size="small"
        error={!!errors.expected_output}
        helperText={errors.expected_output?.message?.toString()}
      />
    </ModalWithForm>
  );
};

export default RuleCreateModal;
