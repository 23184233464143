import { IconButton, Stack, Button } from '@mui/material';
import { useFieldArray, useForm, UseFormReturn, FieldError } from 'react-hook-form';

import { RentDepositFormData, OtherFee } from '@/types/lease';

import { MinusIcon } from '@/assets/icons/MinusIcon';
import { PlusIcon } from '@/assets/icons/PlusIcon';

import FormSelect from '@/components/form/FormSelect';
import FormTextField from '@/components/form/FormTextField';
import ModalWithForm from '@/components/modals/ModalWithForm';

interface Props {
  initialData?: RentDepositFormData;
  onSave: (data: RentDepositFormData) => Promise<void>;
  onClose: () => void;
  isOpen: boolean;
  disabled?: boolean;
}

const PAYMENT_METHOD_OPTIONS = [
  { value: 'check', label: 'Check' },
  { value: 'bank_transfer', label: 'Bank Transfer' },
  { value: 'cash', label: 'Cash' },
  { value: 'other', label: 'Other' },
];

const FEE_FREQUENCY_OPTIONS = [
  { value: 'one_time', label: 'One Time' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'annual', label: 'Annual' },
];

interface FormContentProps {
  form: UseFormReturn<RentDepositFormData>;
  disabled?: boolean;
}

export const FormContent: React.FC<FormContentProps> = ({ form, disabled }) => {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'other_fees',
  });

  return (
    <Stack spacing={3}>
      <FormTextField
        label="Monthly Rent"
        textFieldProps={{
          type: 'number',
          InputProps: {
            startAdornment: '$',
          },
        }}
        control={form.control}
        name="monthly_rent"
        error={!!form.formState.errors.monthly_rent}
        helperText={(form.formState.errors.monthly_rent as FieldError)?.message}
        disabled={disabled}
      />

      <FormTextField
        label="Security Deposit"
        textFieldProps={{
          type: 'number',
          InputProps: {
            startAdornment: '$',
          },
        }}
        control={form.control}
        name="security_deposit"
        error={!!form.formState.errors.security_deposit}
        helperText={(form.formState.errors.security_deposit as FieldError)?.message}
        disabled={disabled}
      />

      <FormTextField
        label="Pet Deposit"
        textFieldProps={{
          type: 'number',
          InputProps: {
            startAdornment: '$',
          },
        }}
        control={form.control}
        name="pet_deposit"
        error={!!form.formState.errors.pet_deposit}
        helperText={(form.formState.errors.pet_deposit as FieldError)?.message}
        disabled={disabled}
      />

      <FormTextField
        label="Late Fee"
        textFieldProps={{
          type: 'number',
          InputProps: {
            startAdornment: '$',
          },
        }}
        control={form.control}
        name="late_fee"
        error={!!form.formState.errors.late_fee}
        helperText={(form.formState.errors.late_fee as FieldError)?.message}
        disabled={disabled}
      />

      <FormTextField
        label="Late Fee Grace Period (days)"
        textFieldProps={{
          type: 'number',
        }}
        control={form.control}
        name="late_fee_grace_period"
        error={!!form.formState.errors.late_fee_grace_period}
        helperText={(form.formState.errors.late_fee_grace_period as FieldError)?.message}
        disabled={disabled}
      />

      <FormTextField
        label="Rent Due Day"
        textFieldProps={{
          type: 'number',
        }}
        control={form.control}
        name="rent_due_day"
        error={!!form.formState.errors.rent_due_day}
        helperText={(form.formState.errors.rent_due_day as FieldError)?.message}
        disabled={disabled}
      />

      <FormSelect
        label="Payment Methods"
        control={form.control}
        name="payment_methods"
        options={PAYMENT_METHOD_OPTIONS}
        error={!!form.formState.errors.payment_methods}
        helperText={(form.formState.errors.payment_methods as FieldError)?.message}
        disabled={disabled}
        muiSelectProps={{
          multiple: true,
        }}
      />

      <Stack spacing={2}>
        {fields.map((field: OtherFee & { id: string }, index: number) => (
          <Stack key={field.id} direction="row" spacing={2} alignItems="center">
            <FormTextField
              label="Fee Name"
              control={form.control}
              name={`other_fees.${index}.name`}
              error={!!form.formState.errors.other_fees?.[index]?.name}
              helperText={(form.formState.errors.other_fees?.[index]?.name as FieldError)?.message}
              disabled={disabled}
            />
            <FormTextField
              label="Amount"
              textFieldProps={{
                type: 'number',
                InputProps: {
                  startAdornment: '$',
                },
              }}
              control={form.control}
              name={`other_fees.${index}.amount`}
              error={!!form.formState.errors.other_fees?.[index]?.amount}
              helperText={
                (form.formState.errors.other_fees?.[index]?.amount as FieldError)?.message
              }
              disabled={disabled}
            />
            <FormSelect
              label="Frequency"
              control={form.control}
              name={`other_fees.${index}.frequency`}
              options={FEE_FREQUENCY_OPTIONS}
              error={!!form.formState.errors.other_fees?.[index]?.frequency}
              helperText={
                (form.formState.errors.other_fees?.[index]?.frequency as FieldError)?.message
              }
              disabled={disabled}
            />
            <IconButton
              onClick={() => remove(index)}
              disabled={disabled}
              sx={{ mt: form.formState.errors.other_fees?.[index] ? 3 : 0 }}
            >
              <MinusIcon />
            </IconButton>
          </Stack>
        ))}

        {!disabled && (
          <Button
            startIcon={<PlusIcon />}
            onClick={() =>
              append({
                name: '',
                amount: 0,
                frequency: 'one_time',
              })
            }
            variant="outlined"
            color="secondary"
          >
            Add Other Fee
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

const RentDepositForm: React.FC<Props> = ({
  initialData,
  onSave,
  onClose,
  isOpen,
  disabled = false,
}) => {
  const form = useForm<RentDepositFormData>({
    defaultValues: initialData || {
      monthly_rent: 0,
      security_deposit: 0,
      pet_deposit: 0,
      late_fee: 0,
      late_fee_grace_period: 5,
      rent_due_day: 1,
      payment_methods: ['bank_transfer'],
      other_fees: [],
    },
  });

  return (
    <ModalWithForm
      form={form}
      maxWidth="md"
      title="Rent & Deposit Details"
      onClose={onClose}
      onSubmit={onSave}
    >
      <FormContent form={form} disabled={disabled} />
    </ModalWithForm>
  );
};

export default RentDepositForm;
