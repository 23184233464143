import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { TenantModel } from '@/api/models/tenant';
import { UnitModel } from '@/api/models/unit';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY } from './useGetAllUnits';

export type ApiResponse_Create_Unit = UnitModel & {
  tenants: Array<Pick<TenantModel, 'full_name'>>;
};

export type ApiRequest_Create_Unit = {
  name: string;
  building_id: Id;
};

const METHOD = 'POST';
const ENDPOINT = 'unit';

export const useCreateUnit = (stateFns?: MutationStateFns<ApiResponse_Create_Unit>) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_Create_Unit) =>
      handleApiCall<ApiResponse_Create_Unit, ApiRequest_Create_Unit>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Unit created successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY({ buildingId: data.building_id }),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to create unit', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
