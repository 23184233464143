import { Theme } from '@mui/material/styles';
import merge from 'lodash/merge';

import OverridesAccordion from './Accordion';
import OverridesAccordionDetails from './AccordionDetails';
import OverridesAccordionSummary from './AccordionSummary';
import OverridesAlert from './Alert';
import OverridesAlertTitle from './AlertTitle';
import OverridesAutocomplete from './Autocomplete';
import OverridesBadge from './Badge';
import OverridesButton from './Button';
import OverridesButtonBase from './ButtonBase';
import OverridesButtonGroup from './ButtonGroup';
import OverridesCard from './Card';
import OverridesCardContent from './CardContent';
import OverridesCheckbox from './Checkbox';
import OverridesChip from './Chip';
import OverridesDataGrid from './DataGrid';
import OverridesDialog from './Dialog';
import OverridesDialogActions from './DialogActions';
import OverridesDialogContent from './DialogContent';
import OverridesDialogContentText from './DialogContentText';
import OverridesDialogTitle from './DialogTitle';
import OverridesFab from './Fab';
import OverridesFormGroup from './FormGroup';
import OverridesFormHelperText from './FormHelperText';
import OverridesFormLabel from './FormLabel';
import OverridesIconButton from './IconButton';
import OverridesInputBase from './InputBase';
import OverridesInputLabel from './InputLabel';
import OverridesLinearProgress from './LinearProgress';
import OverridesLink from './Link';
import OverridesListItemButton from './ListItemButton';
import OverridesListItemIcon from './ListItemIcon';
import OverridesListItemSecondaryAction from './ListItemSecondaryAction';
import OverridesListItemText from './ListItemText';
import OverridesLoadingButton from './LoadingButton';
import OverridesOutlinedInput from './OutlinedInput';
import OverridesPagination from './Pagination';
import OverridesPaginationItem from './PaginationItem';
import OverridesPaper from './Paper';
import OverridesPopover from './Popover';
import OverridesRadio from './Radio';
import OverridesSelect from './Select';
import OverridesSkeleton from './Skeleton';
import OverridesSlider from './Slider';
import OverridesStack from './Stack';
import OverridesSwitch from './Switch';
import OverridesTab from './Tab';
import OverridesTableBody from './TableBody';
import OverridesTableCell from './TableCell';
import OverridesTableFooter from './TableFooter';
import OverridesTableHead from './TableHead';
import OverridesTablePagination from './TablePagination';
import OverridesTableRow from './TableRow';
import OverridesTabs from './Tabs';
import OverridesTextField from './TextField';
import OverridesToggleButton from './ToggleButton';
import OverridesTooltip from './Tooltip';
import OverridesTreeItem from './TreeItem';
import OverridesTypography from './Typography';

export default function OverridesComponentsOverrides(theme: Theme) {
  return merge(
    OverridesAccordion(theme),
    OverridesAccordionDetails(theme),
    OverridesAccordionSummary(theme),
    OverridesAlert(theme),
    OverridesAlertTitle(),
    OverridesAutocomplete(),
    OverridesBadge(theme),
    OverridesButton(theme),
    OverridesButtonBase(),
    OverridesButtonGroup(),
    OverridesCard(theme),
    OverridesCardContent(),
    OverridesCheckbox(theme),
    OverridesChip(theme),
    OverridesDataGrid(theme),
    OverridesDialog(theme),
    OverridesDialogActions(theme),
    OverridesDialogContent(theme),
    OverridesDialogContentText(theme),
    OverridesDialogTitle(theme),
    OverridesFab(theme),
    OverridesFormHelperText(theme),
    OverridesFormLabel(theme),
    OverridesIconButton(theme),
    OverridesInputBase(theme),
    OverridesInputLabel(theme),
    OverridesLinearProgress(),
    OverridesLink(),
    OverridesListItemButton(theme),
    OverridesListItemIcon(theme),
    OverridesListItemSecondaryAction(theme),
    OverridesListItemText(theme),
    OverridesLoadingButton(),
    OverridesOutlinedInput(theme),
    OverridesPaper(theme),
    OverridesPagination(),
    OverridesPaginationItem(theme),
    OverridesPopover(theme),
    OverridesRadio(theme),
    OverridesFormGroup(theme),
    OverridesSelect(theme),
    OverridesSlider(theme),
    OverridesSkeleton(theme),
    OverridesStack(),
    OverridesSwitch(theme),
    OverridesTab(theme),
    OverridesTableBody(theme),
    OverridesTableCell(theme),
    OverridesTableFooter(theme),
    OverridesTableHead(theme),
    OverridesTablePagination(),
    OverridesTableRow(),
    OverridesTabs(),
    OverridesTextField(theme),
    OverridesToggleButton(theme),
    OverridesTooltip(theme),
    OverridesTreeItem(),
    OverridesTypography()
  );
}
