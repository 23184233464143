import { Autocomplete, AutocompleteChangeReason, CircularProgress, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { forwardRef, useMemo, useRef, useState } from 'react';
import { Controller, useController } from 'react-hook-form';

import { environment } from '@env';

import { GeocodeAPIV6Response } from '@/types/mapbox';

import FormField, { FormFieldProps, getControllerProps, getFormFieldProps } from './FormField';

interface Props extends FormFieldProps {
  placeholder?: string;
}

const FormAddressAutocomplete = forwardRef<HTMLDivElement, Props>(
  ({ color = 'secondary', placeholder = 'Enter address', ...props }, ref) => {
    const {
      field: { value, onChange },
    } = useController({
      name: props.name,
      control: props.control,
      defaultValue: props.defaultValue,
    });
    const [innerValue, setInnerValue] = useState(value ?? props.defaultValue ?? '');
    const [isOpen, setIsOpen] = useState(false);
    const optionsRef = useRef<GeocodeAPIV6Response['features']>([]);

    const fetchSuggestionsQuery = useQuery({
      enabled: innerValue.length > 2 && isOpen,
      queryKey: ['GET', 'suggestions', innerValue],
      queryFn: async () => {
        const response = await axios.get<GeocodeAPIV6Response>(
          'https://api.mapbox.com/search/geocode/v6/forward?',
          {
            params: new URLSearchParams({
              q: innerValue,
              access_token: environment.mapboxApiKey,
              autocomplete: 'true',
              country: 'US',
              format: 'geojson',
              language: 'en-US',
              limit: '5',
              types: 'address',
            }),
          }
        );

        optionsRef.current = response.data.features;

        return response.data;
      },
    });

    const options = useMemo(() => {
      if (fetchSuggestionsQuery.isError) return [];
      if (fetchSuggestionsQuery.isFetching) return optionsRef.current;

      return fetchSuggestionsQuery.data?.features ?? [];
    }, [
      fetchSuggestionsQuery.data?.features,
      fetchSuggestionsQuery.isError,
      fetchSuggestionsQuery.isFetching,
    ]);

    const handleOnChange = (
      event: React.SyntheticEvent,
      _value: GeocodeAPIV6Response['features'][0] | null,
      reason: AutocompleteChangeReason
    ) => {
      if (reason === 'clear') {
        onChange('');
        setInnerValue('');
        return;
      }

      onChange(_value?.properties.full_address ?? '');
      setInnerValue(_value?.properties.full_address ?? '');
    };

    return (
      <FormField {...getFormFieldProps(props)} ref={ref}>
        <Controller
          {...getControllerProps(props)}
          render={({ field }) => (
            <Autocomplete
              disabled={props.disabled}
              open={isOpen}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              onChange={handleOnChange}
              isOptionEqualToValue={(option, _value) =>
                option.properties.full_address === _value.properties.full_address
              }
              filterOptions={_options => _options}
              getOptionLabel={option => option.properties.full_address}
              options={options}
              defaultValue={{
                // @ts-ignore
                properties: {
                  full_address: innerValue,
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'grey.300',
                  },
                  '&.Mui-focused': {
                    outline: 'none !important',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    borderWidth: 1,
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
                  },
                },
                ...props.sx,
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  {...field}
                  size={props.size}
                  placeholder={placeholder}
                  value={innerValue}
                  onChange={e => setInnerValue(e.target.value)}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      color: 'text.primary',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&:focus, &.Mui-focused': {
                        outline: 'none !important',
                      },
                    },
                  }}
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isOpen && fetchSuggestionsQuery.isFetching && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    },
                  }}
                />
              )}
              PaperProps={{
                elevation: 3,
                sx: {
                  mt: 1,
                  borderRadius: 1,
                  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                  '& .MuiAutocomplete-listbox': {
                    padding: '8px 0',
                  },
                  '& .MuiAutocomplete-option': {
                    padding: '10px 16px',
                    fontSize: '1rem',
                    fontWeight: 400,
                    '&[aria-selected="true"]': {
                      backgroundColor: '#F3F3F3 !important',
                      color: 'text.primary',
                      fontWeight: 400,
                    },
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  },
                },
              }}
            />
          )}
        />
      </FormField>
    );
  }
);

export default FormAddressAutocomplete;
