import { Theme } from '@mui/material/styles';

export default function OverridesFormLabel(theme: Theme) {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...theme.typography.body3,
          margin: 0,
        },
      },
    },
  };
}
