import { Typography, Stack, Box } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { useGetAllEmailContacts } from '@/api/endpoints/building/useGetAllEmailContacts';
import { useGetAllEmailTemplates } from '@/api/endpoints/building/useGetAllEmailTemplates';
import { useGetEmailSignature } from '@/api/endpoints/building/useGetEmailSignature';
import { useSendBulkEmail } from '@/api/endpoints/building/useSendBulkEmail';
import { Users01Icon } from '@/assets/icons/Users01Icon';
import { emailContactsToOptions } from '@/utils/tenantContact';
import { pluralize } from '@/utils/text';

import FormAutocomplete, { Option } from '../form/FormAutocomplete';
import FormEmailAttachments from '../form/FormEmailAttachments';
import FormTextField from '../form/FormTextField';
import FormWysiwygEditor from '../form/FormWysiwygEditor';
import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  buildingId: number;
  onClose: () => void;
  onBulkEmailSent?: () => void;
  templateSlug?: string;
  preSelectedTenantIds?: number[];
}

interface FormData {
  template: Option;
  subject: string;
  body: string;
  recipients: Option[];
  attachments: FileList;
}

const EmailBulkSendModal: React.FC<Props> = ({
  buildingId,
  onClose,
  onBulkEmailSent,
  templateSlug,
  preSelectedTenantIds = [],
}) => {
  const templateIdRef = useRef<number | null>(null);

  const form = useForm<FormData>({
    defaultValues: {
      recipients: [],
    },
  });
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = form;

  const template = watch('template');
  const recipients = watch('recipients');

  const getAllEmailContactsQuery = useGetAllEmailContacts(buildingId);
  const getAllEmailTemplatesQuery = useGetAllEmailTemplates(buildingId);
  const getEmailSignatureQuery = useGetEmailSignature(buildingId);
  const sendBulkEmailMutation = useSendBulkEmail(buildingId, {
    onSuccess: () => {
      onBulkEmailSent?.();
      onClose();
    },
  });

  const recipientsOptions: Option[] = useMemo(
    () => emailContactsToOptions(getAllEmailContactsQuery.data),
    [getAllEmailContactsQuery.data]
  );

  const selectedTemplate = useMemo(
    () => getAllEmailTemplatesQuery.data?.find(t => t.id === template?.value),
    [template, getAllEmailTemplatesQuery.data]
  );

  // Add email signature to body when it's loaded
  useEffect(() => {
    if (!getEmailSignatureQuery.data) return;

    setValue('body', `<br /><br />—<br/>${getEmailSignatureQuery.data.signature}`);
  }, [getEmailSignatureQuery.data, setValue]);

  // Set subject and body when template is selected
  useEffect(() => {
    if (!selectedTemplate) return;
    if (templateIdRef.current === selectedTemplate.id) return;

    templateIdRef.current = selectedTemplate.id;

    setValue('subject', selectedTemplate.subject);
    setValue(
      'body',
      `${selectedTemplate.body}<br /><br />—<br/>${getEmailSignatureQuery.data?.signature}`
    );
  }, [selectedTemplate, setValue, getEmailSignatureQuery.data]);

  // Pre-select template when data is loaded
  useEffect(() => {
    if (templateSlug && getAllEmailTemplatesQuery.data) {
      const _template = getAllEmailTemplatesQuery.data.find(t => t.slug === templateSlug);

      if (!_template) return;

      setValue('template', {
        label: _template.name,
        value: _template.id,
      });
    }
  }, [templateSlug, getAllEmailTemplatesQuery.data, setValue]);

  // Pre-select contacts when data is loaded
  useEffect(() => {
    if (!getAllEmailContactsQuery.data?.length) return;
    if (!preSelectedTenantIds.length) return;

    const filteredContacts = getAllEmailContactsQuery.data.filter(contact => {
      // Handle potential type mismatches by converting both to string for comparison
      const contactTenantIdStr = String(contact.tenantId);
      return preSelectedTenantIds.some(id => String(id) === contactTenantIdStr);
    });

    if (filteredContacts.length > 0) {
      const options = emailContactsToOptions(filteredContacts);
      setValue('recipients', options);
    }
  }, [setValue, preSelectedTenantIds, getAllEmailContactsQuery.data]);

  const onSelectAll = useCallback(() => {
    setValue('recipients', recipientsOptions);
  }, [recipientsOptions, setValue]);

  const onSubmit = useCallback(
    (data: FormData) =>
      sendBulkEmailMutation.mutateAsync({
        subject: data.subject,
        body: data.body,
        recipients: data.recipients.map(recipient => recipient.value.toString()),
        attachments: data.attachments,
      }),
    [sendBulkEmailMutation]
  );

  return (
    <ModalWithForm
      onSubmit={onSubmit}
      onClose={onClose}
      form={form}
      maxWidth="md"
      title="Send Bulk Email"
      buttonLabel="Send"
      isDataLoading={
        getAllEmailContactsQuery.isLoading ||
        getAllEmailTemplatesQuery.isLoading ||
        getEmailSignatureQuery.isLoading
      }
      confirmActionProps={
        recipients.length > 1
          ? {
            children: (
              <>
                <Typography variant="body1">
                    Are you sure you want to send this email to the following {recipients.length}{' '}
                  {pluralize(recipients.length, 'recipient')}?
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {recipients.map(recipient => recipient.label).join(', ')}
                </Typography>
              </>
            ),
          }
          : undefined
      }
    >
      <FormAutocomplete
        label="Select Template"
        options={(getAllEmailTemplatesQuery.data ?? []).map(item => ({
          label: item.name,
          value: item.id,
        }))}
        control={control}
        placeholder="Select Template"
        {...register('template')}
        size="small"
        error={!!errors.template}
        helperText={errors.template?.message}
      />

      <Stack direction="row" spacing={1} alignItems="center">
        <FormAutocomplete
          multiple
          label="Select Recipients"
          placeholder="Select Recipients"
          filterSelectedOptions
          required
          control={control}
          {...register('recipients', {
            validate: value => {
              if (value.length === 0) {
                return 'Recipients are required';
              }

              return true;
            },
          })}
          size="small"
          options={recipientsOptions}
          error={!!errors.recipients}
          helperText={errors.recipients?.message ?? 'Type recipient name(s) or select from list'}
        />

        <Box>
          <LoadingButton
            variant="outlined"
            color="secondary"
            startIcon={<Users01Icon />}
            onClick={onSelectAll}
            loading={getAllEmailContactsQuery.isLoading}
            disabled={!getAllEmailContactsQuery.data}
            size="small"
          >
            Select All
          </LoadingButton>
        </Box>
      </Stack>

      <FormTextField
        label="Subject"
        required
        control={control}
        {...register('subject', {
          required: 'Subject is required',
        })}
        placeholder="Enter Subject"
        size="small"
        error={!!errors.subject}
        helperText={errors.subject?.message}
      />

      <FormWysiwygEditor
        placeholder="Message…"
        required
        control={control}
        {...register('body', {
          required: 'Message is required',
        })}
        error={!!errors.body}
        helperText={errors.body?.message}
        size="small"
      />

      <FormEmailAttachments multiple size="small" control={control} {...register('attachments')} />
    </ModalWithForm>
  );
};

export default EmailBulkSendModal;
