import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY } from './useGetAllCalendarEvents';

const METHOD = 'DELETE';
const ENDPOINT = 'calendar-events/:eventId';

export const useDeleteCalendarEvent = (stateFns?: MutationStateFns<void>) => {
  const queryClient = useQueryClient();
  const { session } = useAuth();

  return useMutation({
    mutationFn: async (eventId: number) =>
      handleApiCall<void, never>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { eventId: eventId.toString() }),
        undefined
      ),
    onSuccess: () => {
      enqueueSnackbar('Calendar event deleted successfully', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: QUERY_KEY() });
      stateFns?.onSuccess?.();
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to delete calendar event', { variant: 'error' });
      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
