import { DragDropContext, DropResult, SensorAPI } from '@hello-pangea/dnd';
import { Box, Stack } from '@mui/material';
import { useCallback, useMemo, useRef } from 'react';

import { TTicket } from '@/types/ticket';

import KanbanColumn from './KanbanColumn';

export enum BoardColumn {
  open = 'open',
  resolved = 'resolved',
}

interface TicketKanbanProps {
  tickets: TTicket[];
  onTicketAssigned?: (ticket: TTicket) => void;
  onTicketMovedTo: (ticket: TTicket, column: BoardColumn) => void;
}

const KanbanBoard: React.FC<TicketKanbanProps> = ({
  tickets,
  onTicketAssigned,
  onTicketMovedTo,
}) => {
  const apiRef = useRef<SensorAPI | null>(null);
  const openTickets = useMemo(() => tickets.filter(ticket => !ticket.resolved), [tickets]);
  const resolvedTickets = useMemo(() => tickets.filter(ticket => ticket.resolved), [tickets]);

  const getTicketByIndex = useCallback(
    (index: number, column: BoardColumn) => {
      switch (column) {
      case BoardColumn.open:
        return openTickets[index] ?? null;
      case BoardColumn.resolved:
        return resolvedTickets[index] ?? null;
      }

      return null;
    },
    [openTickets, resolvedTickets]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      if (result.destination.droppableId === result.source.droppableId) return;

      const ticket = getTicketByIndex(
        result.source.index,
        result.source.droppableId as BoardColumn
      );

      if (!ticket) return;

      onTicketMovedTo(ticket, result.destination.droppableId as BoardColumn);
    },
    [getTicketByIndex, onTicketMovedTo]
  );

  return (
    <DragDropContext
      sensors={[
        api => {
          apiRef.current = api;
        },
      ]}
      onDragEnd={onDragEnd}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        spacing={3}
        sx={{
          flex: '1 0 100px',
          overflowX: 'auto',
          overflowY: 'hidden',
          mb: -6,
          mx: -2,
          px: 2,
          pb: 4,
        }}
      >
        <Box
          sx={{
            minWidth: 344,
            maxWidth: 480,
            width: '50%',
            height: '100%',
            display: 'flex',
          }}
        >
          <KanbanColumn
            title="Open"
            tickets={openTickets}
            droppableId={BoardColumn.open}
            onTicketAssigned={onTicketAssigned}
            onTicketResolved={(ticket: TTicket) => onTicketMovedTo(ticket, BoardColumn.resolved)}
          />
        </Box>

        <Box
          sx={{
            minWidth: 344,
            maxWidth: 480,
            width: '50%',
            height: '100%',
            display: 'flex',
          }}
        >
          <KanbanColumn
            title="Resolved"
            tickets={resolvedTickets}
            droppableId={BoardColumn.resolved}
          />
        </Box>
      </Stack>
    </DragDropContext>
  );
};

export default KanbanBoard;
