import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export default function OverridesDialog(theme: Theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backgroundColor: alpha('#000', 0.7),
          },

          '& .MuiDialog-paper': {
            borderRadius: 0,
            margin: 0,
            maxHeight: '100%',
            width: '100%',

            [theme.breakpoints.down('sm')]: {
              height: '100%',
              maxWidth: '100%',
            },

            [theme.breakpoints.up('sm')]: {
              borderRadius: '1rem',
              maxHeight: 'calc(100% - 148px)',
              width: 'calc(100% - 148px)',
            },
          },
        },
      },
    },
  };
}
