import { enqueueSnackbar } from 'notistack';
import { useCallback } from 'react';

import { MessageSessionType, TMessageThread } from '@/types/message';

import { useSendConversationMessage } from '../conversation/useSendConversationMessage';
import { useRespondToEmail } from '../email/useRespondToEmail';
import { useStoreManagerResponseToEmail } from '../email/useStoreManagerResponseToEmail';
import { useRespondToSms } from '../twilio/useRespondToSms';
import { useStoreManagerResponseToSms } from '../twilio/useStoreManagerResponseToSms';

export const useOnMessageSend = () => {
  const respondToSmsMutation = useRespondToSms();
  const respondToEmailMutation = useRespondToEmail();
  const storeManagerResponseToEmailMutation = useStoreManagerResponseToEmail();
  const storeManagerResponseToSmsMutation = useStoreManagerResponseToSms();
  const sendConversationMessageMutation = useSendConversationMessage();

  return useCallback(
    async (message: string, messageThread: TMessageThread) => {
      if (!message?.trim()) {
        enqueueSnackbar('Message is required.', { variant: 'error' });
        return;
      }

      switch (messageThread.session.type) {
      case MessageSessionType.email:
      case MessageSessionType.vendor:
        if (messageThread.session.type === MessageSessionType.vendor) {
          await sendConversationMessageMutation.mutateAsync({
            conversationId: messageThread.session.session_id,
            content: message,
          });
          break;
        }

        await respondToEmailMutation.mutateAsync({
          sessionId: messageThread.session.session_id,
          subject: messageThread.session.subject ?? '',
          response: message,
        });

        // Store the response in AI system
        await storeManagerResponseToEmailMutation.mutateAsync({
          sessionId: messageThread.session.session_id,
          message,
          propertyId: messageThread.session.building_id.toString(),
        });
        break;

      case MessageSessionType.sms:
        await respondToSmsMutation.mutateAsync({
          sessionId: messageThread.session.session_id,
          response: message,
        });

        // Store the response in AI system
        await storeManagerResponseToSmsMutation.mutateAsync({
          sessionId: messageThread.session.session_id,
          message,
          propertyId: messageThread.session.building_id.toString(),
        });
        break;
      }
    },
    [
      respondToSmsMutation,
      respondToEmailMutation,
      storeManagerResponseToEmailMutation,
      storeManagerResponseToSmsMutation,
      sendConversationMessageMutation,
    ]
  );
};
