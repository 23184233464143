import React from 'react';

import BasePublicMuiPage from '../common/BasePublicMuiPage';

import FooterSection from './sections/FooterSection';
import HaveQuestionsSection from './sections/HaveQuestionsSection';
import NavSection from './sections/NavSection';
import PricingCustomPlanSection from './sections/PricingCustomPlanSection';
import PricingFeaturesSection from './sections/PricingFeaturesSection';
import PricingHeroSection from './sections/PricingHeroSection';
import PricingPlansSection from './sections/PricingPlansSection';

const PricingPage: React.FC = () => (
  <BasePublicMuiPage>
    <NavSection />

    <PricingHeroSection />

    <PricingPlansSection />

    <PricingCustomPlanSection />

    <PricingFeaturesSection />

    <HaveQuestionsSection />

    <FooterSection />
  </BasePublicMuiPage>
);

export default PricingPage;
