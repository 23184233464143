import { IconButton, Menu, MenuItem, Stack } from '@mui/material';
import React, { useCallback, useState } from 'react';

import CircularWithPath from '@/@mantis/components/@extended/progress/CircularWithPath';
import { DotsVerticalIcon } from '@/assets/icons/DotsVerticalIcon';

interface Item {
  label: string;
  isLoading?: boolean;
  loadingProgress?: number;
  onClick: () => Promise<void> | void;
}

type Props = {
  items: Item[];
};

const RowActionsMenu: React.FC<Props> = ({ items }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onOpenMenuClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const getOnClick = useCallback(
    (item: Item) => async () => {
      await item.onClick();

      onMenuClose();
    },
    [onMenuClose]
  );

  return (
    <>
      <IconButton onClick={onOpenMenuClick} size="small">
        <DotsVerticalIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items.map(item => (
          <MenuItem key={item.label} onClick={getOnClick(item)} disabled={item.isLoading}>
            <Stack direction="row" alignItems="center" gap={1}>
              {item.isLoading && (
                <CircularWithPath
                  size={16}
                  variant={item.loadingProgress !== undefined ? 'determinate' : 'indeterminate'}
                  value={item.loadingProgress}
                />
              )}
              {item.label}
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default RowActionsMenu;
