import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { MakePaymentFormData, Payment } from '@/types/payment';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY as QUERY_KEY_GET_ALL } from './useGetPayments';

export type ApiResponse_Make_Payment = Payment;

const METHOD = 'POST';
const ENDPOINT = 'payments';

export const useMakePayment = (stateFns?: MutationStateFns<ApiResponse_Make_Payment>) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: MakePaymentFormData) =>
      handleApiCall<ApiResponse_Make_Payment, MakePaymentFormData>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Payment processed successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_GET_ALL(),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to process payment', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
