import { Stack, Typography } from '@mui/material';
import React from 'react';

type Props = {
  text: string;
};

const BulletPointsList: React.FC<Props> = ({ text }) => {
  const points = text.split('\n').filter(point => point.trim().startsWith('-'));

  return (
    <Stack spacing={2}>
      {points.map(point => (
        <Typography
          key={point}
          variant="body2"
          color="text.secondary"
          sx={{
            pl: 2,
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              left: 0,
              top: '0.5em',
              width: '4px',
              height: '4px',
              backgroundColor: 'primary.main',
              borderRadius: '50%',
            },
          }}
        >
          {point.replace('-', '').trim()}
        </Typography>
      ))}
    </Stack>
  );
};

export default BulletPointsList;
