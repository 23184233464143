import { Session } from '@supabase/supabase-js';

import { environment } from '@env';

if (!environment.supabaseUrl) throw new Error('Missing supabaseUrl config.');

export const SUPABASE_AUTH_TOKEN_STORAGE_KEY = `sb-${
  environment.supabaseUrl
    .replace(/^https?:\/\//, '') // remove protocol
    .replace(/:\d*/, '') // remove port
    .split('.')[0]
}-auth-token`;

export const setSupabaseSessionInLocalStorage = (session: Session) =>
  localStorage.setItem(SUPABASE_AUTH_TOKEN_STORAGE_KEY, JSON.stringify(session));

export const getSupabaseSessionFromLocalStorage = () =>
  localStorage.getItem(SUPABASE_AUTH_TOKEN_STORAGE_KEY)
    ? (JSON.parse(localStorage.getItem(SUPABASE_AUTH_TOKEN_STORAGE_KEY) as string) as Session)
    : null;

export const clearSupabaseSessionFromLocalStorage = () =>
  localStorage.removeItem(SUPABASE_AUTH_TOKEN_STORAGE_KEY);
