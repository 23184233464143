import { Stack, Typography } from '@mui/material';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { listToLines } from '@/utils/list';

interface Props {
  addEntityButtonIcon?: React.ReactNode;
  addEntityButtonText?: string;
  detailsText?: string;
  entityName: string;
  entityNamePlural?: string;
  isLoading?: boolean;
  onAddEntityClick?: () => void;
}

const NoRowsOverlay: React.FC<Props> = ({
  addEntityButtonIcon,
  addEntityButtonText,
  detailsText,
  entityName,
  entityNamePlural = `${entityName}s`,
  isLoading = false,
  onAddEntityClick,
}) => (
  <Stack direction="column" justifyContent="center" alignItems="center" height="100%" gap={1}>
    <Typography variant="body2" textAlign="center">
      No {entityNamePlural} found.
    </Typography>

    {!!detailsText && (
      <Typography variant="body3" textAlign="center">
        {listToLines(detailsText.split('\n'))}
      </Typography>
    )}

    {onAddEntityClick && (
      <LoadingButton
        variant="outlined"
        color="secondary"
        startIcon={addEntityButtonIcon ?? <PlusIcon />}
        size="small"
        onClick={onAddEntityClick}
        loading={isLoading}
        loadingPosition="start"
      >
        {addEntityButtonText || `Add ${entityName}`}
      </LoadingButton>
    )}
  </Stack>
);

export default NoRowsOverlay;
