import { Stack, Box } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { useGetAllPhoneContacts } from '@/api/endpoints/building/useGetAllPhoneContacts';
import { ApiResponse_SendBulk_Sms, useSendBulkSms } from '@/api/endpoints/twilio/useSendBulkSms';
import { Users01Icon } from '@/assets/icons/Users01Icon';
import { phoneContactsToOptions } from '@/utils/tenantContact';

import FormAutocomplete, { Option } from '../form/FormAutocomplete';
import FormTextField from '../form/FormTextField';
import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  buildingId: number;
  onClose: () => void;
  onBulkSmsSent?: (data: ApiResponse_SendBulk_Sms) => void;
}

interface FormData {
  message: string;
  recipients: Option[];
}

const SMSBulkSendModal: React.FC<Props> = ({ buildingId, onClose, onBulkSmsSent }) => {
  const form = useForm<FormData>({
    defaultValues: {
      recipients: [],
    },
  });

  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = form;

  const getAllPhoneContactsQuery = useGetAllPhoneContacts(buildingId);

  const recipientsOptions: Option[] = useMemo(
    () => phoneContactsToOptions(getAllPhoneContactsQuery.data),
    [getAllPhoneContactsQuery.data]
  );

  const sendBulkSmsMutation = useSendBulkSms({
    onSuccess: data => {
      onBulkSmsSent?.(data);
      onClose();
    },
  });

  const onSelectAll = useCallback(() => {
    setValue('recipients', recipientsOptions);
  }, [recipientsOptions, setValue]);

  const onSubmit = useCallback(
    (data: FormData) =>
      sendBulkSmsMutation.mutateAsync({
        message: data.message,
        tenantIds: data.recipients.map(recipient => Number(recipient.value)),
      }),
    [sendBulkSmsMutation]
  );

  return (
    <ModalWithForm
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="md"
      title="Send Bulk SMS"
      buttonLabel="Send"
      form={form}
      isDataLoading={getAllPhoneContactsQuery.isLoading}
    >
      <FormTextField
        label="Message"
        control={control}
        required
        {...register('message', {
          required: 'Message is required',
        })}
        placeholder="Enter Message"
        size="small"
        multiline
        rows={4}
        error={!!errors.message}
        helperText={errors.message?.message}
      />

      <Stack direction="row" spacing={1} alignItems="center">
        <FormAutocomplete
          multiple
          label="Select Recipients"
          placeholder="Select Recipients"
          control={control}
          required
          {...register('recipients', {
            validate: value => {
              if (value.length === 0) {
                return 'Recipients are required';
              }

              return true;
            },
          })}
          size="small"
          options={recipientsOptions}
          error={!!errors.recipients}
          helperText={errors.recipients?.message ?? 'Type recipient name(s) or select from list'}
        />

        <Box>
          <LoadingButton
            variant="outlined"
            color="secondary"
            startIcon={<Users01Icon />}
            onClick={onSelectAll}
            loading={getAllPhoneContactsQuery.isLoading}
            disabled={!getAllPhoneContactsQuery.data}
            size="small"
          >
            Select All
          </LoadingButton>
        </Box>
      </Stack>
    </ModalWithForm>
  );
};

export default SMSBulkSendModal;
