import { Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiRequest_Create_QuestionAnswer,
  ApiResponse_Create_QuestionAnswer,
  useCreateQuestionAnswer,
} from '@/api/endpoints/questionAnswer/useCreateQuestionAnswer';

import FormTextField from '../form/FormTextField';
import ModalWithForm from '../modals/ModalWithForm';

type Props = {
  onClose: () => void;
  onQuestionAnswerCreated?: (questionAnswer: ApiResponse_Create_QuestionAnswer) => void;
  propertyLocationId: string;
};

const QuestionAnswerCreateModal: React.FC<Props> = ({
  onClose,
  onQuestionAnswerCreated,
  propertyLocationId,
}) => {
  const form = useForm<ApiRequest_Create_QuestionAnswer>({
    defaultValues: {
      location_id: propertyLocationId,
    },
  });
  const {
    register,
    control,
    formState: { errors },
  } = form;

  const createQuestionAnswerMutation = useCreateQuestionAnswer({
    onSuccess(data) {
      onQuestionAnswerCreated?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_Create_QuestionAnswer) => createQuestionAnswerMutation.mutateAsync(data),
    [createQuestionAnswerMutation]
  );

  return (
    <ModalWithForm
      form={form}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="sm"
      title="Add Q&A Item"
    >
      <Typography variant="body2">
        Enter any Q&A that you want Terr.ai to learn. This will help generate the right responses
        for your tenants.
      </Typography>

      <FormTextField
        label="Question / Topic"
        error={!!errors.question}
        helperText={errors.question?.message?.toString()}
        control={control}
        required
        {...register('question', {
          required: 'Question is required',
        })}
        size="small"
      />

      <FormTextField
        label="Answer / Guidance"
        multiline
        rows={4}
        error={!!errors.answer}
        helperText={errors.answer?.message?.toString()}
        control={control}
        required
        {...register('answer', {
          required: 'Answer is required',
        })}
        size="small"
      />
    </ModalWithForm>
  );
};

export default QuestionAnswerCreateModal;
