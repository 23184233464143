import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { MakePaymentFormData } from '@/types/payment';

import { CreditCard01Icon } from '@/assets/icons/CreditCard01Icon';

import ModalWithForm from '@/components/modals/ModalWithForm';

import PaymentForm from './PaymentForm';

interface PaymentModalProps {
  onClose: () => void;
  onSubmit: (data: MakePaymentFormData) => Promise<void>;
}

const PaymentModal: FC<PaymentModalProps> = ({ onClose, onSubmit }) => {
  const form = useForm<MakePaymentFormData>({
    defaultValues: {
      amount: 0,
      type: 'rent',
      description: '',
    },
  });

  return (
    <ModalWithForm
      form={form}
      title="Make a Payment"
      maxWidth="sm"
      buttonLabel="Make Payment"
      buttonColor="primary"
      buttonStartIcon={<CreditCard01Icon />}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <PaymentForm form={form} />
    </ModalWithForm>
  );
};

export default PaymentModal;
