import { Typography, Stack, Button } from '@mui/material';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { useGetAllTenantsByLocation } from '@/api/endpoints/tenant/useGetAllTenantsByLocation';
import { useInviteTenants } from '@/api/endpoints/tenant/useInviteTenants';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { Users01Icon } from '@/assets/icons/Users01Icon';
import { PMPRoutes } from '@/config/routes';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { getResolvedRoutePath } from '@/utils/router';

import FormAutocomplete, { Option } from '../form/FormAutocomplete';
import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  buildingId: number;
  locationId: number;
  onClose: () => void;
  onTenantsInvited?: () => void;
  unitId?: number;
}

interface FormData {
  tenants: Option[];
}

const InviteTenantModal: React.FC<Props> = ({
  buildingId,
  locationId,
  onClose,
  onTenantsInvited,
  unitId,
}) => {
  const { setOnboardingProgress } = useOnboardingProgress();

  const form = useForm<FormData>({
    defaultValues: {
      tenants: [],
    },
  });

  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = form;

  const tenants = watch('tenants');

  const getAllTenantsQuery = useGetAllTenantsByLocation(locationId);
  const inviteTenantsMutation = useInviteTenants(buildingId, {
    onSuccess: () => {
      setOnboardingProgress({
        hasTenantsWithOnboardingEmailSent: true,
      });
      onTenantsInvited?.();
      onClose();
    },
  });

  const tenantsOptions: Option[] = React.useMemo(
    () =>
      (getAllTenantsQuery.data ?? [])
        .filter(tenant => !tenant.user_id)
        .map(tenant => ({
          label: `${tenant.full_name} (${tenant.contacts[0]?.email || 'No email provided'})`,
          value: tenant.id,
        })),
    [getAllTenantsQuery.data]
  );

  const hasUninvitedTenants = tenantsOptions.length > 0;

  const onSelectAll = useCallback(() => {
    setValue('tenants', tenantsOptions);
  }, [tenantsOptions, setValue]);

  const onSubmit = useCallback(
    (data: FormData) =>
      inviteTenantsMutation.mutateAsync({
        tenantIds: data.tenants.map(tenant => Number(tenant.value)),
      }),
    [inviteTenantsMutation]
  );

  // If all tenants already have accounts, show a different message
  if (!getAllTenantsQuery.isLoading && !hasUninvitedTenants) {
    const hasTenants = getAllTenantsQuery.data && getAllTenantsQuery.data.length > 0;

    // Determine where to redirect for tenant creation
    const createTenantHref = unitId
      ? `${getResolvedRoutePath(PMPRoutes.unitViewTenantList, {
        id: unitId.toString(),
      })}?openAddTenant=true`
      : undefined;

    // Function to handle the create tenant action
    const handleCreateTenantClick = () => {
      // Close current modal
      onClose();

      // If we don't have a direct link, manually trigger tenant creation
      if (!createTenantHref) {
        // You could dispatch an event or use any existing tenant creation mechanism
      }
    };

    return (
      <ModalWithForm
        onSubmit={() => Promise.resolve()}
        onClose={onClose}
        form={form}
        maxWidth="md"
        title="Invite Tenants"
        buttonLabel={hasTenants ? 'Close' : 'Create Tenant'}
      >
        <Stack spacing={3} alignItems="center">
          <Typography>
            {hasTenants
              ? 'All tenants already have accounts or have been sent invites.'
              : 'No tenants found. Create a tenant before sending invites.'}
          </Typography>

          {!hasTenants && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlusIcon />}
              href={createTenantHref}
              onClick={handleCreateTenantClick}
            >
              Create Tenant
            </Button>
          )}
        </Stack>
      </ModalWithForm>
    );
  }

  return (
    <ModalWithForm
      onSubmit={onSubmit}
      onClose={onClose}
      form={form}
      maxWidth="md"
      title="Invite Tenants"
      buttonLabel="Send Invites"
      isDataLoading={getAllTenantsQuery.isLoading}
      confirmActionProps={
        tenants.length > 1
          ? {
            children: (
              <>
                <Typography variant="body1">
                    Are you sure you want to invite these {tenants.length} tenants to the platform?
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {tenants.map(tenant => tenant.label).join(', ')}
                </Typography>
              </>
            ),
          }
          : undefined
      }
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <FormAutocomplete
          multiple
          label="Select Tenants"
          placeholder="Select Tenants"
          filterSelectedOptions
          required
          control={control}
          {...register('tenants', {
            validate: value => {
              if (value.length === 0) {
                return 'Please select at least one tenant';
              }
              return true;
            },
          })}
          size="small"
          options={tenantsOptions}
          error={!!errors.tenants}
          helperText={errors.tenants?.message ?? 'Type tenant name(s) or select from list'}
        />

        <LoadingButton
          variant="outlined"
          color="secondary"
          startIcon={<Users01Icon />}
          onClick={onSelectAll}
          loading={getAllTenantsQuery.isLoading}
          disabled={!tenantsOptions.length}
          size="small"
        >
          Select All
        </LoadingButton>
      </Stack>
    </ModalWithForm>
  );
};

export default InviteTenantModal;
