import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { environment } from '@env';

import { VaultDocument, VaultDocumentTagFormData } from '@/types/vault';

import { MutationStateFns } from '@/api/utils/mutation';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY } from './useGetVaultDocuments';

const ENDPOINT = 'vault/documents';

// Define the option type that comes from FormAutocomplete
interface Option {
  label: string;
  value: Id;
}

export type ApiRequest_UpdateTags_VaultDocument = {
  documentId: string;
  tags: VaultDocumentTagFormData;
};

export type ApiResponse_UpdateTags_VaultDocument = VaultDocument;

export const useUpdateVaultDocumentTags = (
  stateFns?: MutationStateFns<ApiResponse_UpdateTags_VaultDocument>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ documentId, tags }: ApiRequest_UpdateTags_VaultDocument) => {
      // Transform the payload to extract just the ID values from the label-value objects
      const transformedTags = {
        property_location_ids: Array.isArray(tags.property_location_ids)
          ? tags.property_location_ids.map((item: Id | Option) =>
            typeof item === 'object' && item !== null && 'value' in item ? item.value : item
          )
          : [],
        building_ids: Array.isArray(tags.building_ids)
          ? tags.building_ids.map((item: Id | Option) =>
            typeof item === 'object' && item !== null && 'value' in item ? item.value : item
          )
          : [],
        unit_ids: Array.isArray(tags.unit_ids)
          ? tags.unit_ids.map((item: Id | Option) =>
            typeof item === 'object' && item !== null && 'value' in item ? item.value : item
          )
          : [],
        tenant_ids: Array.isArray(tags.tenant_ids)
          ? tags.tenant_ids.map((item: Id | Option) =>
            typeof item === 'object' && item !== null && 'value' in item ? item.value : item
          )
          : [],
        property_manager_ids: Array.isArray(tags.property_manager_ids)
          ? tags.property_manager_ids.map((item: Id | Option) =>
            typeof item === 'object' && item !== null && 'value' in item ? item.value : item
          )
          : [],
      };

      const response = await axios.put<ApiResponse_UpdateTags_VaultDocument>(
        `${environment.api}/${ENDPOINT}/${documentId}/tags`,
        transformedTags,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
    onSuccess: data => {
      enqueueSnackbar('Document tags updated successfully.', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: QUERY_KEY() });
      stateFns?.onSuccess?.(data);
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar('Failed to update document tags.', { variant: 'error' });
      stateFns?.onError?.(error);
    },
  });
};
