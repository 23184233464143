import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { TenantModelWithContacts } from '@/api/models/tenant';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY } from './useGetAllTenants';

export type ApiResponse_Create_Tenant = TenantModelWithContacts;

export type ApiRequest_Create_Tenant = {
  full_name: string;
  unit_id: Id;
  contacts: Array<
    Partial<{
      email: string;
      phone: string;
    }>
  >;
};

const METHOD = 'POST';
const ENDPOINT = 'tenant';

export const useCreateTenant = (stateFns?: MutationStateFns<ApiResponse_Create_Tenant>) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_Create_Tenant) =>
      handleApiCall<ApiResponse_Create_Tenant, ApiRequest_Create_Tenant>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Tenant created successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY({ unit_id: data.unit_id }),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to create tenant', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
