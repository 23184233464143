import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { PropertyLocationDeclaredUnitsCount, PropertyLocationType } from '@/api/enums';
import { PropertyLocationWithEmailAndPhoneModel } from '@/api/models/propertyLocation';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';
import { addressTextToTwoLinesChunks } from '@/utils/address';

import { QUERY_KEY } from './useGetAllPropertyLocations';

export type ApiResponse_Create_PropertyLocation = PropertyLocationWithEmailAndPhoneModel;

export type ApiRequest_Create_PropertyLocation = {
  name?: string;
  address_text: string;
  type: PropertyLocationType;
  declared_units_count?: PropertyLocationDeclaredUnitsCount;
};

const sanitizeData = (data: ApiRequest_Create_PropertyLocation) => {
  const sanitizedData = {
    ...data,
    declared_units_count:
      data.type === PropertyLocationType.MULTI_UNIT ? data.declared_units_count : undefined,
  };

  // If name is not provided, use the street name from the address
  if (!data.name) {
    const [streetName] = addressTextToTwoLinesChunks(data.address_text);
    sanitizedData.name = streetName;
  }

  return sanitizedData;
};

const METHOD = 'POST';
const ENDPOINT = 'property-location';

export const useCreatePropertyLocation = (
  stateFns?: MutationStateFns<ApiResponse_Create_PropertyLocation>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_Create_PropertyLocation) =>
      handleApiCall<ApiResponse_Create_PropertyLocation, ApiRequest_Create_PropertyLocation>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        sanitizeData(data)
      ),
    onSuccess: data => {
      enqueueSnackbar('Property location created successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY,
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to create property location', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
