import { InputLabelProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

const InputLabel: React.FC<InputLabelProps> = props => {
  const {
    // @ts-ignore
    ownerState: { size },
  } = props;

  return (
    <Typography
      {...props}
      component="label"
      variant={size === 'small' ? 'subtitle3' : 'h5'}
      mb={0.75}
    />
  );
};

const HelperText: React.FC = props => {
  const {
    // @ts-ignore
    ownerState: { error },
  } = props;

  return (
    <Typography variant="body3" mt={0.75} color={error ? 'error' : 'text.secondary'} {...props} />
  );
};

export default function OverridesTextField(theme: Theme) {
  return {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        slots: {
          inputLabel: InputLabel,
          formHelperText: HelperText,
        },
      },
      styleOverrides: {
        root: {
          '& legend > span.notranslate': {
            opacity: 0,
          },

          '& legend > span:not(.notranslate)': {
            display: 'none',
          },
        },
      },
    },
  };
}
