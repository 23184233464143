import { PropertyManagementRole } from '@/api/enums';

export const propertyManagerRolesOptions = [
  {
    label: 'Manager',
    value: PropertyManagementRole.MANAGER,
  },
  {
    label: 'Admin',
    value: PropertyManagementRole.ADMIN,
  },
  {
    label: 'Vendor',
    value: PropertyManagementRole.VENDOR,
  },
];
