import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Box,
} from '@mui/material';
import { FC } from 'react';

import { WorkflowType } from '@/types/workflow';

interface WorkflowTypeSelectionModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (workflowType: WorkflowType) => void;
}

const workflowOptions = [
  {
    type: 'lease-creation' as WorkflowType,
    title: 'Lease Creation Workflow',
    description: 'Create a workflow for managing lease creation process',
  },
  {
    type: 'invoice-approval' as WorkflowType,
    title: 'Invoice Approval Workflow',
    description: 'Create a workflow for managing invoice approval process',
  },
];

const WorkflowTypeSelectionModal: FC<WorkflowTypeSelectionModalProps> = ({
  open,
  onClose,
  onSelect,
}) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>Select Workflow Type</DialogTitle>
    <DialogContent>
      <List>
        {workflowOptions.map(option => (
          <ListItem key={option.type} disablePadding>
            <ListItemButton onClick={() => onSelect(option.type)}>
              <Box sx={{ py: 1 }}>
                <ListItemText
                  primary={option.title}
                  secondary={
                    <Typography variant="body2" color="text.secondary">
                      {option.description}
                    </Typography>
                  }
                />
              </Box>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </DialogContent>
  </Dialog>
);

export default WorkflowTypeSelectionModal;
