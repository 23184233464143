import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiRequest_CreateWithUser_PropertyManager,
  ApiResponse_CreateWithUser_PropertyManager,
  useCreateUserWithPropertyManage,
} from '@/api/endpoints/propertyManager/useCreateUserWithPropertyManage';

import FormSelect from '../form/FormSelect';
import FormTextField from '../form/FormTextField';
import ModalWithForm from '../modals/ModalWithForm';

import { propertyManagerRolesOptions } from './options';

interface Props {
  onClose: () => void;
  onPropertyManagerCreated?: (propertyManager: ApiResponse_CreateWithUser_PropertyManager) => void;
  propertyLocationId: number;
}

const PropertyManagerCreateModal: React.FC<Props> = ({
  onClose,
  onPropertyManagerCreated,
  propertyLocationId,
}) => {
  const form = useForm<ApiRequest_CreateWithUser_PropertyManager>({
    defaultValues: {
      role: 'MANAGER',
      locationId: propertyLocationId,
    },
  });
  const {
    register,
    control,
    formState: { errors },
  } = form;

  const createUserWithPropertyManageMutation = useCreateUserWithPropertyManage({
    onSuccess(data) {
      onPropertyManagerCreated?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_CreateWithUser_PropertyManager) =>
      createUserWithPropertyManageMutation.mutateAsync(data),
    [createUserWithPropertyManageMutation]
  );

  return (
    <ModalWithForm
      form={form}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="sm"
      title="Add Property Partner"
      buttonLabel="Add"
    >
      <FormTextField
        label="Full Name"
        control={control}
        required
        {...register('fullName', { required: 'Full Name is required' })}
        error={!!errors.fullName}
        helperText={errors.fullName?.message?.toString()}
        size="small"
      />

      <FormTextField
        label="Email"
        control={control}
        required
        {...register('email', { required: 'Email is required' })}
        error={!!errors.email}
        helperText={errors.email?.message?.toString()}
        size="small"
      />

      <FormTextField
        label="Phone"
        control={control}
        {...register('phone', { required: 'Phone is required' })}
        error={!!errors.phone}
        helperText={errors.phone?.message?.toString()}
        size="small"
      />

      <FormSelect
        label="Role"
        control={control}
        required
        {...register('role', { required: 'Role is required' })}
        options={propertyManagerRolesOptions}
        error={!!errors.role}
        helperText={errors.role?.message?.toString()}
        size="small"
      />
    </ModalWithForm>
  );
};

export default PropertyManagerCreateModal;
