import styled from '@emotion/styled';

import { from } from '@/styles/breakpoints';

import TopicsCard from '@/components/@ionic/TopicsCard';
import TopicsCardStyles from '@/components/@ionic/TopicsCard.styles';

const StyledTopicsCard = styled(TopicsCard)`
  --background: var(--figma-color-neutral-200);
  margin-top: 3rem;

  ${TopicsCardStyles.Divider} svg {
    color: var(--figma-color-neutral-300);
  }

  ${TopicsCardStyles.Topics} {
    ${from.lg} {
      flex: 0 0 15rem;
    }
  }
`;

export default {
  TopicsCard: StyledTopicsCard,
};
