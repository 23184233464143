import { Stack } from '@mui/material';

import CircularWithPath from '@/@mantis/components/@extended/progress/CircularWithPath';

const LoadingState: React.FC = () => (
  <Stack display="flex" justifyContent="center" alignItems="center" flex="1 1 100%">
    <CircularWithPath />
  </Stack>
);

export default LoadingState;
