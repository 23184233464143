import { IconButton, Menu, MenuItem, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useState } from 'react';

import { DotsVerticalIcon } from '@/assets/icons/DotsVerticalIcon';

interface Option {
  label: string | React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
}

interface Props {
  options: Option[];
  sx?: SxProps<Theme>;
}

const ContextualMenu: React.FC<Props> = ({ options, sx }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="small" color="secondary" onClick={onOpenMenu} sx={sx}>
        <DotsVerticalIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
            },
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onClick={() => {
              option.onClick();
              onCloseMenu();
            }}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ContextualMenu;
