import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { BuildingModel } from '@/api/models/building';
import { TenantModel } from '@/api/models/tenant';
import { TenantContactModel } from '@/api/models/tenantContacts';
import { UnitModel } from '@/api/models/unit';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_GetAllEmailContacts_Building = Array<
  Pick<TenantContactModel, 'id' | 'phone'> & {
    email: Required<TenantContactModel>['email'];
    tenantId: TenantContactModel['tenant_id'];
    tenantName: TenantModel['full_name'];
    unitId: UnitModel['id'];
    unitName: UnitModel['name'];
  }
>;

const METHOD = 'GET';
const ENDPOINT = 'building/:id/contacts';
export const QUERY_KEY = (id: BuildingModel['id']) =>
  getQueryKey(METHOD, ENDPOINT, { id, type: 'email' });

export const useGetAllEmailContacts = (buildingId: BuildingModel['id'], enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY(buildingId),
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetAllEmailContacts_Building>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {
            id: buildingId.toString(),
          })
        );

        return data.filter(contact => contact.email);
      } catch (err) {
        enqueueSnackbar('Failed to fetch building contacts.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
