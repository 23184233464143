import { matchPath } from 'react-router-dom';

import { NavItemType } from '@/@mantis/types/menu';
import { BarChartSquare02Icon } from '@/assets/icons/BarChartSquare02Icon';
import { File04Icon } from '@/assets/icons/File04Icon';
import { GitMergeIcon } from '@/assets/icons/GitMergeIcon';
import { HomeLineIcon } from '@/assets/icons/HomeLineIcon';
import { MessageSquare01Icon } from '@/assets/icons/MessageSquare01Icon';
import { PhoneIcon } from '@/assets/icons/PhoneIcon';
import { Ticket02Icon } from '@/assets/icons/Ticket02Icon';
import { PMPRoutes } from '@/config/routes';

export const getPropertyManagerMenuItems = (isSingleFamilyAccount: boolean): NavItemType[] => [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    url: PMPRoutes.dashboard,
    icon: BarChartSquare02Icon,
    description: 'View your property performance metrics and overall system status.',
    getIsSelected: pathname =>
      PMPRoutes.dashboard === pathname ||
      [PMPRoutes.onboardingChecklist].some(path => !!matchPath(pathname, path)),
  },
  {
    id: 'properties',
    title: 'My Properties',
    type: 'group',
    description: 'Manage all your property locations and buildings.',
    children: [
      {
        id: 'locations',
        title: 'Locations',
        type: 'item',
        icon: BarChartSquare02Icon,
        url: PMPRoutes.propertyLocationList,
        description: 'View and manage all your property locations with detailed information.',
        getIsSelected: pathname =>
          [
            PMPRoutes.propertyLocationList,
            PMPRoutes.propertyLocationView,
            PMPRoutes.propertyLocationManagerList,
            PMPRoutes.propertyLocationViewRuleList,
          ].some(path => !!matchPath(pathname, path)),
      },
      ...(isSingleFamilyAccount
        ? []
        : [
          {
            id: 'buildings',
            title: 'All Buildings',
            type: 'item',
            icon: HomeLineIcon,
            url: PMPRoutes.buildingList,
            description:
                'Access and manage all buildings, units, and tenants across your properties.',
            getIsSelected: (pathname: string) =>
              [
                PMPRoutes.buildingList,
                PMPRoutes.buildingView,
                PMPRoutes.buildingViewDocumentList,
                PMPRoutes.buildingViewUnitList,
                PMPRoutes.unitView,
                PMPRoutes.unitViewDocumentList,
                PMPRoutes.unitViewKnowledgeList,
                PMPRoutes.unitViewMessageList,
                PMPRoutes.unitViewMessageView,
                PMPRoutes.unitViewTenantList,
                PMPRoutes.tenantView,
                PMPRoutes.tenantViewDocumentList,
              ].some(path => !!matchPath(pathname, path)),
          },
        ]),
    ],
  },
  {
    id: 'communications',
    title: 'Communications',
    type: 'group',
    description: 'Manage all communications with tenants, vendors, and other stakeholders.',
    children: [
      // {
      //   id: PMPRoutes.conversationList,
      //   title: 'Conversations',
      //   type: 'item',
      //   icon: MessageSquare01Icon,
      //   url: PMPRoutes.conversationList,
      //   getIsSelected: pathname =>
      //     [PMPRoutes.conversationList, PMPRoutes.conversationView].some(
      //       path => !!matchPath(pathname, path)
      //     ),
      // },
      {
        id: 'messages',
        title: 'Inbound Messages',
        type: 'item',
        icon: MessageSquare01Icon,
        url: PMPRoutes.inboundMessages,
        description: 'View and respond to all incoming messages from tenants and vendors.',
        getIsSelected: pathname =>
          [
            PMPRoutes.inboundMessages,
            PMPRoutes.inboundMessagesWithTenants,
            PMPRoutes.inboundMessagesWithVendors,
          ].some(path => !!matchPath(pathname, path)),
      },
      // {
      //   id: 'emails',
      //   title: 'Emails',
      //   type: 'collapse',
      //   icon: Mail02Icon,
      //   children: [
      //     {
      //       id: PMPRoutes.inboundEmailList,
      //       title: 'Inbound',
      //       type: 'item',
      //       url: PMPRoutes.inboundEmailList,
      //       getIsSelected: pathname => PMPRoutes.inboundEmailList === pathname,
      //     },
      //     {
      //       id: PMPRoutes.sentEmailList,
      //       title: 'Sent',
      //       type: 'item',
      //       url: PMPRoutes.sentEmailList,
      //       getIsSelected: pathname => PMPRoutes.sentEmailList === pathname,
      //     },
      //   ],
      // },
      // {
      //   id: 'sms',
      //   title: 'SMS',
      //   type: 'collapse',
      //   icon: Phone02Icon,
      //   children: [
      //     {
      //       id: PMPRoutes.inboundSmsList,
      //       title: 'Inbound',
      //       type: 'item',
      //       url: PMPRoutes.inboundSmsList,
      //       getIsSelected: pathname => PMPRoutes.inboundSmsList === pathname,
      //     },
      //     {
      //       id: PMPRoutes.sentSmsList,
      //       title: 'Sent',
      //       type: 'item',
      //       url: PMPRoutes.sentSmsList,
      //       getIsSelected: pathname => PMPRoutes.sentSmsList === pathname,
      //     },
      //   ],
      // },
      {
        id: 'inbound-calls',
        title: 'Inbound Calls',
        type: 'item',
        url: PMPRoutes.inboundCallList,
        icon: PhoneIcon,
        description: 'Track and manage all incoming calls from tenants and vendors.',
        getIsSelected: pathname => PMPRoutes.inboundCallList === pathname,
      },
    ],
  },
  {
    id: 'operations',
    title: 'Operations Hub',
    type: 'group',
    description: 'Manage day-to-day property operations, maintenance, and documentation.',
    children: [
      {
        id: 'workflows',
        title: 'Workflows',
        type: 'item',
        icon: GitMergeIcon,
        url: PMPRoutes.workflowList,
        description: 'Configure and manage automated workflows for property management tasks.',
      },
      {
        id: 'invoices',
        title: 'Property Invoices',
        type: 'item',
        url: PMPRoutes.invoiceList,
        icon: File04Icon,
        description: 'View, create, and manage all property-related invoices and payments.',
        getIsSelected: pathname => PMPRoutes.invoiceList === pathname,
      },
      {
        id: 'calendar',
        title: 'Property Calendar',
        type: 'item',
        icon: BarChartSquare02Icon,
        url: PMPRoutes.calendar,
        description: 'Track important dates, appointments, and property-related events.',
        getIsSelected: pathname => PMPRoutes.calendar === pathname,
      },
      {
        id: 'tickets',
        title: 'Maintenance Requests',
        type: 'item',
        url: PMPRoutes.ticketList,
        icon: Ticket02Icon,
        description: 'Manage tenant maintenance requests and work orders for your properties.',
        getIsSelected: pathname => PMPRoutes.ticketList === pathname,
      },
      {
        id: 'vault',
        title: 'Document Vault',
        type: 'item',
        icon: File04Icon,
        url: PMPRoutes.vault,
        description: 'Access and manage all property-related documents and files securely.',
        getIsSelected: pathname => PMPRoutes.vault === pathname,
      },
    ],
  },
];
