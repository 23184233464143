import { Stack } from '@mui/material';
import { useForm, UseFormReturn } from 'react-hook-form';

import FormTextField from '@/components/form/FormTextField';
import ModalWithForm from '@/components/modals/ModalWithForm';

interface EmergencyContact {
  name: string;
  phone: string;
  email?: string;
}

export interface LessorInfoFormData {
  company_name: string;
  contact_name: string;
  email: string;
  phone: string;
  address: string;
  emergency_contact: EmergencyContact;
}

interface FormContentProps {
  form: UseFormReturn<LessorInfoFormData>;
  disabled?: boolean;
}

export const FormContent: React.FC<FormContentProps> = ({ form, disabled }) => (
  <Stack spacing={3}>
    <FormTextField
      label="Company Name"
      control={form.control}
      name="company_name"
      error={!!form.formState.errors.company_name}
      helperText={form.formState.errors.company_name?.message}
      disabled={disabled}
    />

    <FormTextField
      label="Contact Name"
      control={form.control}
      name="contact_name"
      error={!!form.formState.errors.contact_name}
      helperText={form.formState.errors.contact_name?.message}
      disabled={disabled}
    />

    <FormTextField
      label="Email"
      textFieldProps={{
        type: 'email',
      }}
      control={form.control}
      name="email"
      error={!!form.formState.errors.email}
      helperText={form.formState.errors.email?.message}
      disabled={disabled}
    />

    <FormTextField
      label="Phone"
      control={form.control}
      name="phone"
      error={!!form.formState.errors.phone}
      helperText={form.formState.errors.phone?.message}
      disabled={disabled}
    />

    <FormTextField
      label="Address"
      textFieldProps={{
        multiline: true,
        rows: 4,
      }}
      control={form.control}
      name="address"
      error={!!form.formState.errors.address}
      helperText={form.formState.errors.address?.message}
      disabled={disabled}
    />

    <Stack spacing={2}>
      <FormTextField
        label="Emergency Contact Name"
        control={form.control}
        name="emergency_contact.name"
        error={!!form.formState.errors.emergency_contact?.name}
        helperText={form.formState.errors.emergency_contact?.name?.message}
        disabled={disabled}
      />

      <FormTextField
        label="Emergency Contact Phone"
        control={form.control}
        name="emergency_contact.phone"
        error={!!form.formState.errors.emergency_contact?.phone}
        helperText={form.formState.errors.emergency_contact?.phone?.message}
        disabled={disabled}
      />

      <FormTextField
        label="Emergency Contact Email"
        textFieldProps={{
          type: 'email',
        }}
        control={form.control}
        name="emergency_contact.email"
        error={!!form.formState.errors.emergency_contact?.email}
        helperText={form.formState.errors.emergency_contact?.email?.message}
        disabled={disabled}
      />
    </Stack>
  </Stack>
);

interface Props {
  initialData?: LessorInfoFormData;
  onSave: (data: LessorInfoFormData) => Promise<void>;
  onClose: () => void;
  isOpen: boolean;
  disabled?: boolean;
}

const LessorInfoForm: React.FC<Props> = ({
  initialData,
  onSave,
  onClose,
  isOpen,
  disabled = false,
}) => {
  const form = useForm<LessorInfoFormData>({
    defaultValues: initialData || {
      company_name: '',
      contact_name: '',
      email: '',
      phone: '',
      address: '',
      emergency_contact: {
        name: '',
        phone: '',
        email: '',
      },
    },
  });

  return (
    <ModalWithForm
      form={form}
      title="Lessor Information"
      maxWidth="md"
      onClose={onClose}
      onSubmit={onSave}
      buttonLabel="Save Lessor Information"
      buttonColor="primary"
    >
      <FormContent form={form} disabled={disabled} />
    </ModalWithForm>
  );
};

export default LessorInfoForm;
