import { Box, Typography } from '@mui/material';

export interface Props {
  text: string;
  included?: boolean;
}

const PricingPlanFeatureItem: React.FC<Props> = ({ text, included = false }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
    <Box
      sx={{
        width: 20,
        height: 20,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: included ? 'success.main' : 'grey.300',
        color: 'white',
        fontSize: '12px',
      }}
    >
      {included ? '✓' : '×'}
    </Box>
    <Typography
      variant="body2"
      sx={{
        color: included ? 'text.primary' : 'text.secondary',
        fontWeight: included ? 500 : 400,
      }}
    >
      {text}
    </Typography>
  </Box>
);

export default PricingPlanFeatureItem;
