import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiRequest_CreateGroup_Conversation,
  ApiResponse_CreateGroup_Conversation,
  useCreateGroupConversation,
} from '@/api/endpoints/conversation/useCreateGroupConversation';

import FormTextField from '../form/FormTextField';
import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  onClose: () => void;
  onConversationGroupCreated?: (conversation: ApiResponse_CreateGroup_Conversation) => void;
}

const PHONE_PATTERN = /^\+1[0-9]{10}$/;

export const ConversationGroupCreateModal: React.FC<Props> = ({
  onClose,
  onConversationGroupCreated,
}) => {
  const form = useForm<ApiRequest_CreateGroup_Conversation>();

  const {
    register,
    control,
    formState: { errors },
  } = form;

  const createGroupConversationMutation = useCreateGroupConversation({
    onSuccess(data) {
      onConversationGroupCreated?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_CreateGroup_Conversation) =>
      createGroupConversationMutation.mutateAsync(data),
    [createGroupConversationMutation]
  );

  return (
    <ModalWithForm
      form={form}
      onClose={onClose}
      onSubmit={onSubmit}
      maxWidth="sm"
      title="Create Conversation"
    >
      <FormTextField
        label="Title"
        required
        {...register('friendlyName', {
          required: 'Title is required',
          minLength: {
            value: 3,
            message: 'Title must be at least 3 characters',
          },
        })}
        error={!!errors.friendlyName}
        helperText={errors.friendlyName?.message}
        control={control}
        size="small"
      />

      <FormTextField
        label="Property Phone Number"
        error={!!errors.propertyPhone}
        helperText={errors.propertyPhone?.message ?? 'Format: +1XXXXXXXXXX'}
        required
        control={control}
        {...register('propertyPhone', {
          required: 'Property phone number is required',
          pattern: {
            value: PHONE_PATTERN,
            message: 'Phone number must be in format: +1XXXXXXXXXX',
          },
        })}
        size="small"
      />

      <FormTextField
        label="Participant Phone Number"
        error={!!errors.participants?.[0]?.phoneNumber}
        helperText={errors.participants?.[0]?.phoneNumber?.message ?? 'Format: +1XXXXXXXXXX'}
        required
        control={control}
        {...register('participants.0.phoneNumber', {
          required: 'Participant phone number is required',
          pattern: {
            value: PHONE_PATTERN,
            message: 'Phone number must be in format: +1XXXXXXXXXX',
          },
        })}
        size="small"
      />
    </ModalWithForm>
  );
};
