import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { UserModel } from '@/api/models/user';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_UpdatePhone_User = UserModel;

export type ApiRequest_UpdatePhone_User = {
  phone: string;
};

const METHOD = 'PUT';
const ENDPOINT = 'user/phone';

export const useUpdatePhone = (stateFns?: MutationStateFns<ApiResponse_UpdatePhone_User>) => {
  const { session, refreshUser } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_UpdatePhone_User) =>
      handleApiCall<ApiResponse_UpdatePhone_User, ApiRequest_UpdatePhone_User>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: async data => {
      enqueueSnackbar('Phone updated successfully', { variant: 'success' });
      await refreshUser();

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to update phone', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
