import { Alert, Button, Stack, Typography, Paper } from '@mui/material';
import { addMonths, startOfMonth } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';

import {
  useCompleteLeaseGeneration,
  useInitLeaseGeneration,
} from '@/api/endpoints/rocketLawyer/useLeaseGeneration';
import { ApiResponse_GetAll_Tenant } from '@/api/endpoints/tenant/useGetAllTenants';
import { useAuth } from '@/context/AuthProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { getEmail, getFullName } from '@/utils/user';

import ModalWithPreview from '@/components/modals/ModalWithPreview';

interface TenantLeaseGeneratorModalProps {
  tenant: ApiResponse_GetAll_Tenant[0];
  onClose: () => void;
}

interface RocketLawyerData {
  interviewId: string;
  serviceToken: string;
}

const TenantLeaseGeneratorModal: React.FC<TenantLeaseGeneratorModalProps> = ({
  tenant,
  onClose,
}) => {
  const [error, setError] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [, setRocketLawyerData] = useState<RocketLawyerData | null>(null);
  const [step, setStep] = useState<'info' | 'document'>('info');

  const { user } = useAuth();
  const { selectedPropertyLocation } = usePropertyManager();

  // Calculate lease dates (1st of next month and 1 year after)
  const nextMonth = startOfMonth(addMonths(new Date(), 1));
  const leaseEndDate = startOfMonth(addMonths(nextMonth, 12));

  // Format dates to YYYY-MM-DD for input fields
  const formatDateToString = (date: Date): string => date.toISOString().split('T')[0];

  const initLeaseGeneration = useInitLeaseGeneration();
  const completeLeaseGeneration = useCompleteLeaseGeneration();

  // Use refs to maintain stable references to mutation functions
  const initLeaseGenerationRef = useRef(initLeaseGeneration);
  const completeLeaseGenerationRef = useRef(completeLeaseGeneration);

  // Update refs when mutation objects change
  useEffect(() => {
    initLeaseGenerationRef.current = initLeaseGeneration;
    completeLeaseGenerationRef.current = completeLeaseGeneration;
  }, [initLeaseGeneration, completeLeaseGeneration]);

  // Initialize the lease generation process when form is submitted
  const handleCreateLease = async () => {
    // Check if tenant has email
    const tenantEmail = tenant.contacts?.[0]?.email;
    if (!tenantEmail) {
      setError('Tenant must have an email address to generate a lease.');
      return;
    }

    // Get property information
    const propertyAddress = selectedPropertyLocation
      ? `${selectedPropertyLocation.address_text || ''}`
      : '';

    try {
      const result = await initLeaseGenerationRef.current.mutateAsync({
        tenantId: tenant.id,
        tenantName: tenant.full_name,
        tenantContactEmail: tenantEmail,
        propertyAddress,
        leaseStartDate: formatDateToString(nextMonth),
        leaseEndDate: formatDateToString(leaseEndDate),
        monthlyRent: '',
        securityDeposit: '',
        landlordName: getFullName(user),
        landlordEmail: getEmail(user),
        additionalTerms: '',
      });

      // Store data for reference
      setRocketLawyerData({
        interviewId: result.interviewId,
        serviceToken: result.serviceToken,
      });

      // Change to document view
      setStep('document');
    } catch (err) {
      setError('Failed to initialize the lease generation. Please try again later.');
    }
  };

  // Message event listener for Rocket Lawyer document completion
  useEffect(() => {
    const handleDocumentCompletion = (event: MessageEvent) => {
      // Check if the message is from Rocket Lawyer
      if (!event.origin.includes('rocketlawyer.com')) return;

      // Check for completion event
      if (
        event.data &&
        (event.data.status === 'completed' ||
          event.data.type === 'interview_completed' ||
          event.data.type === 'interview-completed')
      ) {
        // Get the stored interviewId from our state rather than DOM
        if (initLeaseGeneration.data?.interviewId) {
          // Complete the lease generation process
          completeLeaseGenerationRef.current.mutate(initLeaseGeneration.data.interviewId);
        }

        // Close the modal
        onClose();
      }
    };

    // Add event listener
    window.addEventListener('message', handleDocumentCompletion);

    // Cleanup
    return () => {
      window.removeEventListener('message', handleDocumentCompletion);
    };
  }, [onClose, initLeaseGeneration.data]);

  // Effect to set up custom element when data is available
  useEffect(() => {
    if (step === 'document' && containerRef.current && initLeaseGeneration.data) {
      const containerDiv = containerRef.current.querySelector('#rocket-document-container');
      if (!containerDiv) return;

      // Function to create the custom element
      const createRocketDocElement = () => {
        // Clear the container first
        containerDiv.innerHTML = '';

        // Create the custom element
        const rocketDocElement = document.createElement('rocket-document');

        // Set attributes
        if (initLeaseGeneration.data?.serviceToken) {
          rocketDocElement.setAttribute('serviceToken', initLeaseGeneration.data.serviceToken);
        }
        if (initLeaseGeneration.data?.interviewId) {
          rocketDocElement.setAttribute('interviewId', initLeaseGeneration.data.interviewId);
        }
        rocketDocElement.setAttribute('showProgressBar', 'true');
        rocketDocElement.setAttribute('showDocumentPreview', 'true');
        rocketDocElement.setAttribute('showDocumentTitle', 'true');

        // Set styles
        rocketDocElement.style.width = '100%';
        rocketDocElement.style.height = '100%';
        rocketDocElement.style.minHeight = '600px';
        rocketDocElement.style.display = 'block';

        // Add event listener for completion
        rocketDocElement.addEventListener('interview-completed', e => {
          // Complete the lease generation
          if (initLeaseGeneration.data?.interviewId) {
            completeLeaseGenerationRef.current.mutate(initLeaseGeneration.data.interviewId);
          }

          // Close the modal
          onClose();
        });

        // Append to container
        containerDiv.appendChild(rocketDocElement);
      };

      // If the custom element is registered, create it immediately
      if (customElements.get('rocket-document')) {
        createRocketDocElement();
      } else if (
        !document.querySelector(
          'script[src="https://rocket-document.sandbox.rocketlawyer.com/v2/rocket-document.esm.js"]'
        )
      ) {
        // Add the script to the document head
        const script = document.createElement('script');
        script.type = 'module';
        script.src = 'https://rocket-document.sandbox.rocketlawyer.com/v2/rocket-document.esm.js';
        document.head.appendChild(script);

        // Wait for script to load
        script.onload = () => {
          // Let's give it a moment to register the custom element
          setTimeout(createRocketDocElement, 100);
        };
      } else {
        // Script exists but custom element not registered yet
        // Wait for custom element to be registered
        const checkInterval = setInterval(() => {
          if (customElements.get('rocket-document')) {
            clearInterval(checkInterval);
            createRocketDocElement();
          }
        }, 100);

        // Cleanup interval
        return () => {
          clearInterval(checkInterval);
        };
      }
    }
  }, [step, initLeaseGeneration.data, onClose]);

  // Cleanup function
  useEffect(
    () => () => {
      // Attempt to cleanup any RocketLawyer elements
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
    },
    []
  );

  // Extract property state from address_text or metadata
  const getPropertyState = (): { code: string; name: string } => {
    if (!selectedPropertyLocation) return { code: '', name: 'your state' };

    // Try to extract state from address_text
    const addressText = selectedPropertyLocation.address_text || '';

    // State name to abbreviation mapping
    const stateNameToAbbr: Record<string, string> = {
      alabama: 'AL',
      alaska: 'AK',
      arizona: 'AZ',
      arkansas: 'AR',
      california: 'CA',
      colorado: 'CO',
      connecticut: 'CT',
      delaware: 'DE',
      florida: 'FL',
      georgia: 'GA',
      hawaii: 'HI',
      idaho: 'ID',
      illinois: 'IL',
      indiana: 'IN',
      iowa: 'IA',
      kansas: 'KS',
      kentucky: 'KY',
      louisiana: 'LA',
      maine: 'ME',
      maryland: 'MD',
      massachusetts: 'MA',
      michigan: 'MI',
      minnesota: 'MN',
      mississippi: 'MS',
      missouri: 'MO',
      montana: 'MT',
      nebraska: 'NE',
      nevada: 'NV',
      'new hampshire': 'NH',
      'new jersey': 'NJ',
      'new mexico': 'NM',
      'new york': 'NY',
      'north carolina': 'NC',
      'north dakota': 'ND',
      ohio: 'OH',
      oklahoma: 'OK',
      oregon: 'OR',
      pennsylvania: 'PA',
      'rhode island': 'RI',
      'south carolina': 'SC',
      'south dakota': 'SD',
      tennessee: 'TN',
      texas: 'TX',
      utah: 'UT',
      vermont: 'VT',
      virginia: 'VA',
      washington: 'WA',
      'west virginia': 'WV',
      wisconsin: 'WI',
      wyoming: 'WY',
      'district of columbia': 'DC',
    };

    // Create a reverse mapping from abbreviations to full state names
    const abbrToStateName = Object.entries(stateNameToAbbr).reduce<Record<string, string>>(
      (acc, [name, code]) => ({
        ...acc,
        [code]: name
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
      }),
      {}
    );

    // Try to match full state name first (e.g., "Ohio")
    for (const [stateName, stateCode] of Object.entries(stateNameToAbbr)) {
      // Create regex to find state name in the address (case insensitive)
      const statePattern = new RegExp(`\\b${stateName}\\b`, 'i');
      if (statePattern.test(addressText)) {
        return {
          code: stateCode,
          name: abbrToStateName[stateCode],
        };
      }
    }

    // Fallback: Try to find 2-letter state codes using array of pattern/description pairs
    const statePatterns = [
      [/,\s*([A-Z]{2})\s/, 'state followed by space'],
      [/,\s*([A-Z]{2}),/, 'state followed by comma'],
      [/,\s*([A-Z]{2})$/, 'state at end of string'],
      [/,\s*([A-Z]{2})\s+\d{5}/, 'state before ZIP code'],
    ] as const;

    // Try each pattern in sequence
    for (const [pattern] of statePatterns) {
      const match = addressText.match(pattern);
      if (match?.[1]) {
        return {
          code: match[1],
          name: abbrToStateName[match[1]] || match[1],
        };
      }
    }

    // Fallback to metadata if available
    if (
      selectedPropertyLocation.metadata &&
      typeof selectedPropertyLocation.metadata === 'object'
    ) {
      const metadata = selectedPropertyLocation.metadata as Record<
        string,
        string | number | boolean | null
      >;
      if (metadata.state && typeof metadata.state === 'string') {
        const metadataState = metadata.state;
        // Check if it's an abbreviation that needs conversion
        if (metadataState.length === 2 && abbrToStateName[metadataState]) {
          return {
            code: metadataState,
            name: abbrToStateName[metadataState],
          };
        }
        // Otherwise return as is
        return {
          code: metadataState,
          name: metadataState,
        };
      }
    }

    return { code: '', name: 'your state' };
  };

  const propertyState = getPropertyState();

  return (
    <ModalWithPreview
      title={`Generate Lease for ${tenant.full_name}`}
      onClose={onClose}
      isDataLoading={initLeaseGeneration.isPending}
      maxWidth="md"
      fullScreen={false}
    >
      <Stack sx={{ height: '100%', minHeight: '500px' }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {step === 'info' ? (
          <Stack spacing={3} sx={{ mt: 2, p: 3 }}>
            <Paper elevation={0} sx={{ p: 3, backgroundColor: 'background.neutral' }}>
              <Typography variant="h6" gutterBottom>
                State-Specific Residential Lease Agreement
              </Typography>
              <Typography variant="body1" paragraph>
                Generate a legally binding lease agreement for {propertyState.name}. Our lease
                agreements are:
              </Typography>
              <Stack spacing={1} sx={{ ml: 2 }}>
                <Typography variant="body1">
                  • Compliant with {propertyState.name} state laws
                </Typography>
                <Typography variant="body1">
                  • Automatically customized based on property location
                </Typography>
                <Typography variant="body1">• Ready for digital signatures</Typography>
                <Typography variant="body1">• Securely stored for future reference</Typography>
              </Stack>
            </Paper>

            <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleCreateLease}
                disabled={initLeaseGeneration.isPending}
              >
                {initLeaseGeneration.isPending ? 'Creating...' : 'Create Lease'}
              </Button>
            </Stack>
          </Stack>
        ) : (
          <div
            ref={containerRef}
            style={{
              width: '100%',
              height: '700px',
              minHeight: '600px',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {!initLeaseGeneration.data ? (
              // Show loading state if data is not available yet
              <div style={{ textAlign: 'center', padding: '20px' }}>
                Loading document interface...
              </div>
            ) : (
              // Container for the custom element (will be populated in useEffect)
              <div
                id="rocket-document-container"
                style={{
                  width: '100%',
                  height: '100%',
                  minHeight: '600px',
                  display: 'block',
                }}
              />
            )}
          </div>
        )}
      </Stack>
    </ModalWithPreview>
  );
};

export default TenantLeaseGeneratorModal;
