import { Stack, Button, IconButton } from '@mui/material';
import { useForm, UseFormReturn, useFieldArray, FieldError } from 'react-hook-form';

import { DisclosuresFormData, Hazard, StateSpecificDisclosure } from '@/types/lease';

import { MinusIcon } from '@/assets/icons/MinusIcon';
import { PlusIcon } from '@/assets/icons/PlusIcon';

import FormSelect from '@/components/form/FormSelect';
import FormTextField from '@/components/form/FormTextField';
import ModalWithForm from '@/components/modals/ModalWithForm';

interface Props {
  initialData?: DisclosuresFormData;
  onSave: (data: DisclosuresFormData) => Promise<void>;
  onClose: () => void;
  disabled?: boolean;
}

const BOOLEAN_OPTIONS = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

interface FormContentProps {
  form: UseFormReturn<DisclosuresFormData>;
  disabled?: boolean;
}

export const FormContent: React.FC<FormContentProps> = ({ form, disabled }) => {
  const {
    fields: hazardFields,
    append: appendHazard,
    remove: removeHazard,
  } = useFieldArray({
    control: form.control,
    name: 'other_hazards',
  });

  const {
    fields: disclosureFields,
    append: appendDisclosure,
    remove: removeDisclosure,
  } = useFieldArray({
    control: form.control,
    name: 'state_specific_disclosures',
  });

  return (
    <Stack spacing={3}>
      <FormSelect
        label="Lead Paint"
        control={form.control}
        name="lead_paint"
        options={BOOLEAN_OPTIONS}
        error={!!form.formState.errors.lead_paint}
        helperText={(form.formState.errors.lead_paint as FieldError)?.message}
        disabled={disabled}
      />

      <FormSelect
        label="Mold"
        control={form.control}
        name="mold"
        options={BOOLEAN_OPTIONS}
        error={!!form.formState.errors.mold}
        helperText={(form.formState.errors.mold as FieldError)?.message}
        disabled={disabled}
      />

      <FormSelect
        label="Asbestos"
        control={form.control}
        name="asbestos"
        options={BOOLEAN_OPTIONS}
        error={!!form.formState.errors.asbestos}
        helperText={(form.formState.errors.asbestos as FieldError)?.message}
        disabled={disabled}
      />

      <FormSelect
        label="Bed Bugs"
        control={form.control}
        name="bed_bugs"
        options={BOOLEAN_OPTIONS}
        error={!!form.formState.errors.bed_bugs}
        helperText={(form.formState.errors.bed_bugs as FieldError)?.message}
        disabled={disabled}
      />

      <Stack spacing={2}>
        {hazardFields.map((field: Hazard & { id: string }, index: number) => (
          <Stack key={field.id} direction="row" spacing={2} alignItems="flex-start">
            <FormTextField
              label="Hazard Type"
              control={form.control}
              name={`other_hazards.${index}.type`}
              error={!!form.formState.errors.other_hazards?.[index]?.type}
              helperText={
                (form.formState.errors.other_hazards?.[index]?.type as FieldError)?.message
              }
              disabled={disabled}
            />

            <FormTextField
              label="Description"
              textFieldProps={{
                multiline: true,
                rows: 4,
              }}
              control={form.control}
              name={`other_hazards.${index}.description`}
              error={!!form.formState.errors.other_hazards?.[index]?.description}
              helperText={
                (form.formState.errors.other_hazards?.[index]?.description as FieldError)?.message
              }
              disabled={disabled}
            />

            {!disabled && (
              <IconButton onClick={() => removeHazard(index)} size="small">
                <MinusIcon />
              </IconButton>
            )}
          </Stack>
        ))}

        {!disabled && (
          <Button
            startIcon={<PlusIcon />}
            onClick={() =>
              appendHazard({
                type: '',
                description: '',
              })
            }
            variant="outlined"
            color="secondary"
          >
            Add Other Hazard
          </Button>
        )}
      </Stack>

      <Stack spacing={2}>
        {disclosureFields.map((field: StateSpecificDisclosure & { id: string }, index: number) => (
          <Stack key={field.id} direction="row" spacing={2} alignItems="flex-start">
            <FormTextField
              label="Disclosure Title"
              control={form.control}
              name={`state_specific_disclosures.${index}.title`}
              error={!!form.formState.errors.state_specific_disclosures?.[index]?.title}
              helperText={
                (form.formState.errors.state_specific_disclosures?.[index]?.title as FieldError)
                  ?.message
              }
              disabled={disabled}
            />

            <FormTextField
              label="Content"
              textFieldProps={{
                multiline: true,
                rows: 4,
              }}
              control={form.control}
              name={`state_specific_disclosures.${index}.content`}
              error={!!form.formState.errors.state_specific_disclosures?.[index]?.content}
              helperText={
                (form.formState.errors.state_specific_disclosures?.[index]?.content as FieldError)
                  ?.message
              }
              disabled={disabled}
            />

            {!disabled && (
              <IconButton onClick={() => removeDisclosure(index)} size="small">
                <MinusIcon />
              </IconButton>
            )}
          </Stack>
        ))}

        {!disabled && (
          <Button
            startIcon={<PlusIcon />}
            onClick={() =>
              appendDisclosure({
                title: '',
                content: '',
              })
            }
            variant="outlined"
            color="secondary"
          >
            Add State-Specific Disclosure
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

const DisclosuresForm: React.FC<Props> = ({ initialData, onSave, onClose, disabled = false }) => {
  const form = useForm<DisclosuresFormData>({
    defaultValues: initialData || {
      lead_paint: 'false',
      mold: 'false',
      asbestos: 'false',
      bed_bugs: 'false',
      other_hazards: [],
      state_specific_disclosures: [],
    },
  });

  return (
    <ModalWithForm
      form={form}
      maxWidth="sm"
      title="Disclosures"
      onClose={onClose}
      onSubmit={onSave}
      buttonLabel="Save Disclosures"
    >
      <FormContent form={form} disabled={disabled} />
    </ModalWithForm>
  );
};

export default DisclosuresForm;
