import { Id, Uuid } from '@/api/utils/sql';

export enum MessageThreadRole {
  ai = 'ai',
  tenant = 'tenant',
  property_manager = 'property_manager',
  vendor = 'vendor',
}

export enum MessageSessionType {
  email = 'email',
  sms = 'sms',
  vendor = 'vendor',
}

export interface TMessageSession {
  type: MessageSessionType;
  session_id: string;
  tenant_id: number;
  building_id: number;
  property_location_id: number;
  from: {
    name?: string;
    source?: string; // email or phone number
    to?: string;
  };
  subject?: string;
  created_at: string;
  updated_at: string;
}

export interface Message {
  id: string;
  content: string;
  createdAt: string;
  sender: {
    id: string;
    name: string;
  };
  recipient: {
    id: string;
    name: string;
  };
  status: 'unread' | 'read' | 'replied';
}

export interface ConversationDocument {
  id: string;
  created_by: string;
  original_name: string;
  file_path: string;
  mime_type: string;
  size: number;
  created_at: string;
  updated_at: string;
  status: string;
  progress: number;
  building_id: number | null;
  unit_id: number | null;
  email_session_id: number | null;
  from_sender: string;
  tenant_id: number | null;
  category_id: number | null;
  message_id: number;
}

export interface TMessageThreadMessage {
  id: Id;
  external_id: Uuid;
  message: string;
  created_at: Date;
  updated_at: Date;
  role: MessageThreadRole;
  sender_name?: string;
  participant_sid?: string;
  media_url?: string;
  documents?: ConversationDocument[];
}

export interface TMessageThread {
  session: TMessageSession;
  messages: TMessageThreadMessage[];
}
