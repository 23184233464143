import { forwardRef, useMemo } from 'react';

import { usePropertyManager } from '@/context/PropertyManagerProvider';

import FormSelect, { Props as FormSelectProps } from './FormSelect';

type Props = Omit<FormSelectProps, 'options'>;

const FormPropertyLocationSelect = forwardRef<HTMLDivElement, Props>(
  ({ placeholder = 'Select Property Location', ...props }, ref) => {
    const { propertyLocations, isFetching } = usePropertyManager();

    const options = useMemo(
      () =>
        propertyLocations.map(location => ({
          label: location.name,
          value: location.id,
        })),
      [propertyLocations]
    );

    return (
      <FormSelect
        {...props}
        placeholder={placeholder}
        disabled={isFetching || props.disabled}
        options={options}
        ref={ref}
      />
    );
  }
);

export default FormPropertyLocationSelect;
