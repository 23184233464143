import { InboundMessageItem } from '@/types/inboundMessage';
import { MessageSessionType } from '@/types/message';

import { ApiResponse_GetAll_VendorConversation } from '@/api/endpoints/conversation/useGetAllVendorConversations';
import { ApiResponse_GetAll_Email } from '@/api/endpoints/email/useGetAllEmails';
import { ApiResponse_GetAll_Sms } from '@/api/endpoints/twilio/useGetAllSms';
import { MessageStatus } from '@/api/enums';

import { getLastUserMessage } from './email';

export const getInboundMessageItemFromEmail = (
  email: ApiResponse_GetAll_Email[0]
): InboundMessageItem => ({
  id: email.session.session_id,
  thread_external_id: email.session.session_id,
  tenantName:
    email.session.tenant?.full_name ?? email.session.from_name ?? email.session.from_email,
  unitName: email.session.tenant?.unit.name ? `Unit ${email.session.tenant.unit.name}` : '',
  subject: email.session.subject,
  body: getLastUserMessage(email),
  updated_at: email.session.updated_at,
  isUrgent: !!email.urgent,
  documents: email.session.documents,
  type: MessageSessionType.email,
  status: email.status,
});

export const getInboundMessageItemFromSms = (
  sms: ApiResponse_GetAll_Sms[0]
): InboundMessageItem => ({
  id: sms.session_id ?? sms.external_id,
  thread_external_id: sms.external_id,
  tenantName: sms.session.tenant?.full_name ?? sms.session.from_phone_number,
  unitName: sms.session.tenant?.unit.name ? `Unit ${sms.session.tenant.unit.name}` : '',
  body: sms.first_user_message,
  updated_at: sms.session.updated_at,
  isUrgent: !!sms.urgent,
  type: MessageSessionType.sms,
  status: sms.status,
});

export const getInboundMessageItemFromConversation = (
  conversation: ApiResponse_GetAll_VendorConversation[0]
): InboundMessageItem => ({
  id: conversation.id,
  thread_external_id: conversation.conversation_sid,
  tenantName: '',
  unitName: conversation.friendly_name,
  body: conversation.messages[0]?.body,
  updated_at: conversation.updated_at,
  isUrgent: false,
  type: MessageSessionType.vendor,
  status: conversation.archived ? MessageStatus.done : MessageStatus.needs_response,
});
