// src/components/WorkflowCanvas.tsx

import { Box, Paper } from '@mui/material';
import React, { useRef, useState, useCallback, useEffect } from 'react';

import { Tenant } from '@/api/endpoints/tenant/useGetAllTenantsByLocation';

import { getWorkflowNodeTypes, WorkflowPropertyManager } from '../../types/nodeType';
import {
  WorkflowData,
  Point,
  WorkflowNode as WorkflowNodeType,
  DraggingState,
  WorkflowConnection,
  NodeConfig,
  NodeTypes,
} from '../../types/workflow';
import { validateWorkflowConnection, calculateConnectionPoints } from '../../utils/workflow.utils';

import { NodeConfigModal } from './NodeConfigModal';
import { WorkflowNode } from './WorkflowNode';

interface WorkflowCanvasProps {
  workflowState: WorkflowData;
  onWorkflowChange: (workflow: WorkflowData) => void;
  scale: number;
  pan: Point;
  workflowType: 'lease-creation' | 'invoice-approval';
  draggingState: DraggingState | null;
  onDraggingStateChange: (state: DraggingState | null) => void;
  propertyManagers: WorkflowPropertyManager[];
  tenants: Tenant[];
  onPanChange: (newPan: Point) => void;
}

interface PendingNode {
  type: string;
  position: Point;
  nodeType: {
    id: string;
    label: string;
    config?: NodeConfig;
  };
}

interface PendingConnection {
  fromId: string;
  fromType: string;
  label?: 'Yes' | 'No';
}

export const WorkflowCanvas: React.FC<WorkflowCanvasProps> = ({
  workflowState,
  onWorkflowChange,
  scale,
  pan,
  workflowType,
  draggingState,
  onDraggingStateChange,
  propertyManagers,
  tenants,
  onPanChange,
}) => {
  const canvasRef = useRef<HTMLDivElement>(null);
  const connectionsCanvasRef = useRef<HTMLCanvasElement>(null);
  const [selectedNode, setSelectedNode] = useState<string | null>(null);
  const [connecting, setConnecting] = useState<string | null>(null);
  const [, setMousePosition] = useState<Point | null>(null);
  const [pendingNode, setPendingNode] = useState<PendingNode | null>(null);
  const [pendingConnection, setPendingConnection] = useState<PendingConnection | null>(null);
  const [isPanning, setIsPanning] = useState(false);
  const [lastPanPoint, setLastPanPoint] = useState<Point | null>(null);

  useEffect(() => {
    const canvas = connectionsCanvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.strokeStyle = '#666';
    ctx.lineWidth = 2;

    workflowState.connections.forEach(connection => {
      const fromNode = workflowState.nodes.find(node => node.id === connection.from);
      const toNode = workflowState.nodes.find(node => node.id === connection.to);

      if (!fromNode || !toNode) return;

      const points = calculateConnectionPoints(fromNode, toNode, selectedNode);
      const { from, to } = points;

      const transformPoint = (point: Point): Point => ({
        x: point.x * scale + pan.x,
        y: point.y * scale + pan.y,
      });

      const transformedFrom = transformPoint(from);
      const transformedTo = transformPoint(to);

      ctx.beginPath();
      ctx.moveTo(transformedFrom.x, transformedFrom.y);
      ctx.lineTo(transformedTo.x, transformedTo.y);
      ctx.stroke();

      if (connection.label) {
        const midX = (transformedFrom.x + transformedTo.x) / 2;
        const midY = (transformedFrom.y + transformedTo.y) / 2;

        ctx.fillStyle = connection.label === 'Yes' ? '#2e7d32' : '#d32f2f';
        ctx.font = '12px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(connection.label, midX, midY - 10);
      }
    });
  }, [workflowState.nodes, workflowState.connections, scale, pan, selectedNode]);

  const handleDragOver = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      if (!canvasRef.current) return;

      const rect = canvasRef.current.getBoundingClientRect();
      setMousePosition({
        x: (e.clientX - rect.left - pan.x) / scale,
        y: (e.clientY - rect.top - pan.y) / scale,
      });
    },
    [scale, pan]
  );

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      if (!draggingState || !canvasRef.current) return;

      const rect = canvasRef.current.getBoundingClientRect();
      const x = (e.clientX - rect.left - pan.x) / scale - (draggingState.offsetX || 0);
      const y = (e.clientY - rect.top - pan.y) / scale - (draggingState.offsetY || 0);

      if (draggingState.type === 'existing' && draggingState.nodeId) {
        onWorkflowChange({
          ...workflowState,
          nodes: workflowState.nodes.map(node =>
            node.id === draggingState.nodeId ? { ...node, position: { x, y } } : node
          ),
        });
      } else if (draggingState.type === 'new' && draggingState.nodeType) {
        const nodeTypeDefinition = getWorkflowNodeTypes(
          workflowType,
          propertyManagers,
          tenants
        ).find(type => type.id === draggingState.nodeType);

        if (nodeTypeDefinition) {
          const newNode: WorkflowNodeType = {
            id: `node-${Date.now()}`,
            type: draggingState.nodeType as NodeTypes,
            label: nodeTypeDefinition.label,
            position: { x, y },
            config: nodeTypeDefinition.config
              ? JSON.parse(JSON.stringify(nodeTypeDefinition.config))
              : {
                dropdownType: 'single',
                options: [],
              },
          };
          onWorkflowChange({
            ...workflowState,
            nodes: [...workflowState.nodes, newNode],
          });
        }
      }
      onDraggingStateChange(null);
      setMousePosition(null);
      setIsPanning(false);
      setLastPanPoint(null);
    },
    [
      draggingState,
      scale,
      pan,
      workflowState,
      onWorkflowChange,
      onDraggingStateChange,
      propertyManagers,
      workflowType,
      tenants,
    ]
  );

  const handleDragLeave = useCallback(() => {
    setMousePosition(null);
  }, []);

  const handleNodeDragStart = (e: React.DragEvent, nodeId: string) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    onDraggingStateChange({ type: 'existing', nodeId, offsetX, offsetY });
    e.stopPropagation();
  };

  const handleNodeClick = (nodeId: string) => {
    const clickedNode = workflowState.nodes.find(n => n.id === nodeId);
    if (!clickedNode) return;

    if (pendingConnection) {
      if (pendingConnection.fromId !== nodeId) {
        const fromNode = workflowState.nodes.find(n => n.id === pendingConnection.fromId);
        if (fromNode && validateWorkflowConnection(fromNode, clickedNode, workflowType)) {
          const newConnection: WorkflowConnection = {
            id: `conn-${Date.now()}`,
            from: pendingConnection.fromId,
            to: nodeId,
            label: pendingConnection.label,
          };
          onWorkflowChange({
            ...workflowState,
            connections: [...workflowState.connections, newConnection],
          });
        }
      }
      setPendingConnection(null);
      setConnecting(null);
      setSelectedNode(null);
    } else {
      setSelectedNode(nodeId);
    }
  };

  const handleConnectionStart = (nodeId: string, label?: 'Yes' | 'No') => {
    const node = workflowState.nodes.find(n => n.id === nodeId);
    if (!node) return;

    setPendingConnection({
      fromId: nodeId,
      fromType: node.type,
      label,
    });
    setConnecting(nodeId);
  };

  const handleDropdownChange = (
    nodeId: string,
    value: string | string[] | Record<string, unknown>,
    field = 'selectedOption'
  ) => {
    onWorkflowChange({
      ...workflowState,
      nodes: workflowState.nodes.map(node => {
        if (node.id !== nodeId || !node.config) return node;
        return {
          ...node,
          config: {
            ...node.config,
            [field]: value,
          },
        };
      }),
    });
  };

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      // Only start panning with middle mouse button (button 1) or when holding space
      // and when not dragging a node
      if ((e.button === 1 || e.button === 0) && !draggingState) {
        setIsPanning(true);
        setLastPanPoint({ x: e.clientX, y: e.clientY });
      }
    },
    [draggingState]
  );

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (isPanning && lastPanPoint) {
        const dx = e.clientX - lastPanPoint.x;
        const dy = e.clientY - lastPanPoint.y;
        onPanChange({
          x: pan.x + dx,
          y: pan.y + dy,
        });
        setLastPanPoint({ x: e.clientX, y: e.clientY });
      }
    },
    [isPanning, lastPanPoint, pan, onPanChange]
  );

  const handleMouseUp = useCallback(() => {
    setIsPanning(false);
    setLastPanPoint(null);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsPanning(false);
    setLastPanPoint(null);
  }, []);

  const getCursorStyle = (): string => {
    if (isPanning) return 'grabbing';
    if (draggingState) return 'grabbing';
    return 'grab';
  };

  return (
    <Paper
      ref={canvasRef}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        bgcolor: 'background.default',
        cursor: getCursorStyle(),
        userSelect: 'none',
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
    >
      <canvas
        ref={connectionsCanvasRef}
        id="connections-canvas"
        width={2000}
        height={2000}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          transform: `scale(${scale}) translate(${pan.x / scale}px, ${pan.y / scale}px)`,
          transformOrigin: '0 0',
        }}
      >
        {workflowState.nodes.map(node => (
          <WorkflowNode
            key={node.id}
            node={node}
            isSelected={selectedNode === node.id}
            isConnecting={Boolean(connecting)}
            onNodeClick={handleNodeClick}
            onNodeDragStart={handleNodeDragStart}
            onDeleteClick={() => {
              onWorkflowChange({
                ...workflowState,
                nodes: workflowState.nodes.filter(n => n.id !== node.id),
                connections: workflowState.connections.filter(
                  conn => conn.from !== node.id && conn.to !== node.id
                ),
              });
            }}
            onDropdownChange={handleDropdownChange}
            onConnectionStart={handleConnectionStart}
            connections={workflowState.connections}
          />
        ))}
      </Box>
      {pendingNode && (
        <NodeConfigModal
          open
          onClose={() => setPendingNode(null)}
          onAdd={config => {
            const newNode: WorkflowNodeType = {
              id: `${pendingNode.type}-${Date.now()}`,
              type: pendingNode.type as WorkflowNodeType['type'],
              label: pendingNode.nodeType.label,
              position: pendingNode.position,
              config,
            };
            onWorkflowChange({
              ...workflowState,
              nodes: [...workflowState.nodes, newNode],
            });
            setPendingNode(null);
          }}
          nodeType={pendingNode.nodeType}
        />
      )}
    </Paper>
  );
};

export default WorkflowCanvas;
