import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

export interface Props {
  onReject: () => void;
  onConfirm: () => void;
  title?: string;
  children: React.ReactNode;
}

export const ConfirmActionModal: React.FC<Props> = ({
  onReject,
  onConfirm,
  title = 'Confirm Action',
  children,
}) => (
  <Dialog open maxWidth="sm">
    <DialogTitle>{title}</DialogTitle>

    <DialogContent>{children}</DialogContent>

    <DialogActions>
      <Button onClick={() => onReject()} color="secondary" variant="outlined">
        Cancel
      </Button>

      <Button onClick={() => onConfirm()} variant="contained" color="primary">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);
