import { PropsWithChildren } from 'react';
import { Redirect } from 'react-router-dom';

import { OnboardingRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import SplitMuiPage from '@/pages/common/SplitMuiPage';

import AuthSideContent from './AuthSideContent';

export interface AuthRouteState {
  from?: Location;
}

const AuthPageWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();

  if (user) return <Redirect to={OnboardingRoutes.index} />;

  return <SplitMuiPage sideContent={<AuthSideContent />}>{children}</SplitMuiPage>;
};

export default AuthPageWrapper;
