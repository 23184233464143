import { Button, IconButton, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiRequest_Create_Tenant,
  ApiResponse_Create_Tenant,
  useCreateTenant,
} from '@/api/endpoints/tenant/useCreateTenant';
import { UnitModel } from '@/api/models/unit';
import { MinusIcon } from '@/assets/icons/MinusIcon';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { formatPhoneNumber } from '@/utils/phone';

import FormTextField from '../form/FormTextField';
import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  unitId: UnitModel['id'];
  onClose: () => void;
  onTenantCreated?: (unit: ApiResponse_Create_Tenant) => void;
  hasExistingTenants?: boolean;
}

const TenantCreateModal: React.FC<Props> = ({
  unitId,
  onClose,
  onTenantCreated,
  hasExistingTenants = false,
}) => {
  const { setOnboardingProgress } = useOnboardingProgress();

  const form = useForm<ApiRequest_Create_Tenant>({
    defaultValues: {
      unit_id: unitId,
      contacts: [
        {
          email: '',
          phone: '',
        },
      ],
    },
  });

  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = form;

  const contacts = watch('contacts');

  const onAddContactRow = () => {
    setValue('contacts', [...contacts, { email: '', phone: '' }]);
  };

  const onRemoveContactRow = (index: number) => {
    setValue(
      'contacts',
      contacts.filter((_, i) => i !== index)
    );
  };

  const handlePhoneChange = (index: number, value: string) => {
    const formattedValue = formatPhoneNumber(value);
    setValue(`contacts.${index}.phone`, formattedValue);
  };

  const createTenantMutation = useCreateTenant({
    onSuccess: data => {
      setOnboardingProgress({
        hasTenant: true,
      });
      onTenantCreated?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_Create_Tenant) =>
      createTenantMutation.mutateAsync({
        ...data,
        contacts: data.contacts
          .map(contact => ({
            ...(contact.email ? { email: contact.email } : {}),
            ...(contact.phone ? { phone: contact.phone.replace(/-/g, '') } : {}),
          }))
          .filter(contact => contact.email || contact.phone),
      }),
    [createTenantMutation]
  );

  return (
    <ModalWithForm
      form={form}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="sm"
      title="Add a Tenant"
      buttonLabel="Add"
    >
      <FormTextField
        label="Full Name"
        fullWidth
        required
        {...register('full_name', {
          required: 'Full name is required',
        })}
        control={control}
        error={!!errors.full_name}
        helperText={errors.full_name?.message}
        size="medium"
      />

      <Stack spacing={2}>
        {contacts.map((contact, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Stack key={index} direction="row" spacing={2} alignItems="center">
            <FormTextField
              label={index === 0 ? 'Email' : undefined}
              placeholder="example@email.com"
              {...register(`contacts.${index}.email`, {
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Invalid email address',
                },
              })}
              control={control}
              error={!!errors.contacts?.[index]?.email}
              helperText={errors.contacts?.[index]?.email?.message}
              size="small"
            />

            <FormTextField
              label={index === 0 ? 'Phone' : undefined}
              placeholder="999-999-9999"
              {...register(`contacts.${index}.phone`, {
                pattern: {
                  value: /^\(\d{3}\)\s\d{3}-\d{4}$/,
                  message: 'Phone number must be in format (999) 999-9999',
                },
                onChange: e => handlePhoneChange(index, e.target.value),
              })}
              control={control}
              error={!!errors.contacts?.[index]?.phone}
              helperText={errors.contacts?.[index]?.phone?.message}
              size="small"
            />

            {contacts.length > 1 && (
              <IconButton
                sx={{
                  mt: index === 0 ? 3 : 0,
                }}
                size="small"
                color="error"
                onClick={() => onRemoveContactRow(index)}
              >
                <MinusIcon />
              </IconButton>
            )}
          </Stack>
        ))}

        <Button
          startIcon={<PlusIcon />}
          onClick={onAddContactRow}
          variant="outlined"
          color="secondary"
          size="small"
        >
          Add an additional email or phone number
        </Button>
      </Stack>

      {!hasExistingTenants && (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            mt: 2,
            bgcolor: 'primary.lighter',
            p: 2,
            borderRadius: 1,
          }}
        >
          Don't worry, we will not send any communication to the tenant until you send the
          onboarding message.
        </Typography>
      )}
    </ModalWithForm>
  );
};

export default TenantCreateModal;
