import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { QuestionAnswerModel } from '@/api/models/ai/questionAnswer';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY as QUERY_KEY_GET_ALL } from './useGetAllQuestionAnswers';

export type ApiResponse_Create_QuestionAnswer = QuestionAnswerModel;

export type ApiRequest_Create_QuestionAnswer = {
  question: string;
  answer: string;
  is_template?: boolean;
  location_id: string;
};

const METHOD = 'POST';
const ENDPOINT = 'question';

export const useCreateQuestionAnswer = (
  stateFns?: MutationStateFns<ApiResponse_Create_QuestionAnswer>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_Create_QuestionAnswer) =>
      handleApiCall<ApiResponse_Create_QuestionAnswer, ApiRequest_Create_QuestionAnswer>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: (data, variables) => {
      enqueueSnackbar('Question & answer created successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_GET_ALL({
          location_id: Number(variables.location_id),
        }),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to create question & answer', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
