import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  ApiRequest_Delete_PropertyManager,
  ApiResponse_Delete_PropertyManager,
  useDeletePropertyManager,
} from '@/api/endpoints/propertyManager/useDeletePropertyManager';
import { PropertyManagementWithManagerWithLimitedUserView } from '@/api/models/propertyManagement';

import ModalWithForm from '../modals/ModalWithForm';

interface Props {
  onClose: () => void;
  onPropertyManagerDeleted?: (data: ApiResponse_Delete_PropertyManager) => void;
  propertyManager: PropertyManagementWithManagerWithLimitedUserView;
}

const PropertyManagerDeleteModal: React.FC<Props> = ({
  onClose,
  onPropertyManagerDeleted,
  propertyManager,
}) => {
  const form = useForm<ApiRequest_Delete_PropertyManager>({
    defaultValues: {
      id: propertyManager.manager.id,
    },
  });

  const deletePropertyManagerMutation = useDeletePropertyManager({
    onSuccess: data => {
      onPropertyManagerDeleted?.(data);
      onClose();
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_Delete_PropertyManager) => deletePropertyManagerMutation.mutateAsync(data),
    [deletePropertyManagerMutation]
  );

  return (
    <ModalWithForm
      onClose={onClose}
      maxWidth="sm"
      title="Delete Property Manager"
      form={form}
      onSubmit={onSubmit}
      buttonColor="error"
    >
      <Typography variant="body2">
        Are you sure you want to delete {propertyManager.manager.limitedUser.full_name} from this
        property?
      </Typography>

      <Typography variant="subtitle3">This action cannot be undone.</Typography>
    </ModalWithForm>
  );
};

export default PropertyManagerDeleteModal;
