import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_SendBulk_Sms = never;

export type ApiRequest_SendBulk_Sms = {
  message: string;
  tenantIds: number[];
};

const METHOD = 'POST';
const ENDPOINT = 'twilio/bulk-send';

export const useSendBulkSms = (stateFns?: MutationStateFns<ApiResponse_SendBulk_Sms>) => {
  const { session } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_SendBulk_Sms) =>
      handleApiCall<ApiResponse_SendBulk_Sms, ApiRequest_SendBulk_Sms>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Bulk sms sent successfully.', { variant: 'success' });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to send bulk sms.', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
