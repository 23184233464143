import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { InvoiceModel } from '@/api/models/ai/invoice';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY as QUERY_KEY_GET_ALL } from './useGetAllInvoices';

export type ApiResponse_Approve_Invoice = never;

export type ApiRequest_Approve_Invoice = {
  location_id: Id;
  custom_metadata: Partial<InvoiceModel['custom_metadata']> &
    Pick<Required<InvoiceModel['custom_metadata']>, 'amount' | 'invoice_number'>;
};

const METHOD = 'POST';
const ENDPOINT = 'invoice/:id/approve';

export const useApproveInvoice = (
  id: InvoiceModel['id'],
  stateFns?: MutationStateFns<ApiResponse_Approve_Invoice>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_Approve_Invoice) =>
      handleApiCall<ApiResponse_Approve_Invoice, ApiRequest_Approve_Invoice>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {
          id: id.toString(),
        }),
        data
      ),
    onSuccess: (data, variables) => {
      enqueueSnackbar('Invoice approved successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_GET_ALL({ location_id: variables.location_id }),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to approve invoice', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
