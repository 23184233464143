import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { ConversationModel } from '@/api/models/conversation';
import { ConversationParticipantModel } from '@/api/models/conversationParticipant';
import { MessageModel } from '@/api/models/message';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_GetAll_VendorConversation = {
  location_id: Id;
};

export type ApiResponse_GetAll_VendorConversation = Array<
  ConversationModel & {
    participants: Array<
      ConversationParticipantModel & {
        propertyManager: {
          limitedUser: {
            full_name: string;
          };
        };
      }
    >;
    messages: Array<MessageModel>;
  }
>;

const METHOD = 'GET';
const ENDPOINT = 'conversation/vendor';
export const QUERY_KEY = (params?: ApiRequest_GetAll_VendorConversation) =>
  getQueryKey(METHOD, ENDPOINT, params);

export const useGetAllVendorConversations = (
  params: ApiRequest_GetAll_VendorConversation,
  enabled = true
) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token && !!params.location_id,
    queryKey: QUERY_KEY(params),
    queryFn: async () => {
      try {
        const data = await handleApiCall<
          ApiResponse_GetAll_VendorConversation,
          ApiRequest_GetAll_VendorConversation
        >(session, METHOD, getResolvedEndpoint(ENDPOINT, {}), params);

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch vendor conversations.', { variant: 'error' });
        // eslint-disable-next-line no-console
        console.error(err);
        throw err;
      }
    },
  });
};
