// src/components/WorkflowNode.tsx

import { Box, Paper, IconButton, Stack, Typography, Select, MenuItem, Chip } from '@mui/material';
import React, { memo } from 'react';

import { WorkflowNodeProps, ComplexDropdownOption, DropdownValues } from '@/types/workflow';

import { ArrowRightIcon } from '@/assets/icons/ArrowRightIcon';
import { Trash01Icon } from '@/assets/icons/Trash01Icon';
import { getNodeStyle } from '@/utils/workflow.utils';

interface NodeHeaderProps {
  type: string;
  label?: string;
}

const NodeHeader: React.FC<NodeHeaderProps> = ({ type, label }) => (
  <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
    {type === 'decision' && (
      <Box
        component="span"
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'warning.main',
          '&::before': {
            content: '"⇄"',
            fontSize: '20px',
            fontWeight: 'bold',
          },
        }}
      />
    )}
    <Typography variant="subtitle2" color="text.secondary">
      {label}
    </Typography>
  </Stack>
);

interface NodeContentProps {
  node: WorkflowNodeProps['node'];
  onDropdownChange: WorkflowNodeProps['onDropdownChange'];
  isSelected: boolean;
}

const NodeContent: React.FC<NodeContentProps> = ({ node, onDropdownChange, isSelected }) => {
  if (!node.config) {
    return (
      <Box sx={{ p: 2 }}>
        <NodeHeader type={node.type} label={node.label} />
      </Box>
    );
  }

  const isComplexDropdown = node.config.options.some(
    (option): option is ComplexDropdownOption => 'type' in option
  );

  const selectedValues = (node.config.selectedOption || {}) as DropdownValues;

  if (node.config.dropdownType && node.config.options) {
    if (isComplexDropdown) {
      const dropdownOptions = node.config.options as ComplexDropdownOption[];

      return (
        <Stack spacing={2} sx={{ p: 2, width: '100%' }}>
          <NodeHeader type={node.type} label={node.label} />
          {dropdownOptions.map(option => (
            <Box key={option.id} width="100%">
              <Typography variant="body2">{option.label}</Typography>
              <Select
                size="small"
                fullWidth
                multiple={option.type === 'multi'}
                value={
                  option.type === 'multi'
                    ? (selectedValues[option.id] as string[]) || []
                    : (selectedValues[option.id] as string) || ''
                }
                onChange={e => {
                  e.stopPropagation();
                  const newSelectedValues = {
                    ...selectedValues,
                    [option.id]: e.target.value,
                  };
                  onDropdownChange(node.id, newSelectedValues);
                }}
                renderValue={selected => {
                  if (option.type === 'multi' && Array.isArray(selected)) {
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map(value => {
                          const opt = option.options.find(o => o.id === value);
                          return (
                            <Chip
                              key={value}
                              label={opt?.label || value}
                              size="small"
                              onMouseDown={e => e.stopPropagation()}
                              onDelete={e => {
                                e.stopPropagation();
                                const newValue = selected.filter(v => v !== value);
                                const newSelectedValues = {
                                  ...selectedValues,
                                  [option.id]: newValue,
                                };
                                onDropdownChange(node.id, newSelectedValues);
                              }}
                            />
                          );
                        })}
                      </Box>
                    );
                  }
                  const opt = option.options.find(o => o.id === selected);
                  return opt?.label || selected;
                }}
              >
                {option.options.map(opt => (
                  <MenuItem key={opt.id} value={opt.id}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          ))}
        </Stack>
      );
    }
  }

  return (
    <Box sx={{ p: 2 }}>
      <NodeHeader type={node.type} label={node.label} />
    </Box>
  );
};

interface DecisionButtonsProps {
  nodeId: string;
  onConnectionStart: (nodeId: string, label: 'Yes' | 'No') => void;
}

const DecisionButtons: React.FC<DecisionButtonsProps> = ({ nodeId, onConnectionStart }) => (
  <Stack
    direction="row"
    spacing={1}
    sx={{
      position: 'absolute',
      top: -40,
      left: '50%',
      transform: 'translateX(-50%)',
      bgcolor: 'background.paper',
      borderRadius: 1,
      p: 0.5,
      boxShadow: 1,
    }}
  >
    <Box
      onClick={e => {
        e.stopPropagation();
        onConnectionStart(nodeId, 'Yes');
      }}
      sx={{
        px: 2,
        py: 1,
        bgcolor: 'success.main',
        color: 'white',
        borderRadius: 1,
        cursor: 'pointer',
        '&:hover': { bgcolor: 'success.dark' },
      }}
    >
      Yes
    </Box>
    <Box
      onClick={e => {
        e.stopPropagation();
        onConnectionStart(nodeId, 'No');
      }}
      sx={{
        px: 2,
        py: 1,
        bgcolor: 'error.main',
        color: 'white',
        borderRadius: 1,
        cursor: 'pointer',
        '&:hover': { bgcolor: 'error.dark' },
      }}
    >
      No
    </Box>
  </Stack>
);

export const WorkflowNode = memo(
  ({
    node,
    isSelected,
    isConnecting,
    onNodeClick,
    onNodeDragStart,
    onDeleteClick,
    onDropdownChange,
    onConnectionStart,
    connections,
  }: WorkflowNodeProps) => {
    const hasYesConnection = connections.some(
      conn => conn.from === node.id && conn.label === 'Yes'
    );
    const hasNoConnection = connections.some(conn => conn.from === node.id && conn.label === 'No');
    const showDecisionButtons =
      node.type === 'decision' &&
      isSelected &&
      !isConnecting &&
      (!hasYesConnection || !hasNoConnection);

    const showConnectButton = node.type !== 'decision' && isSelected && !isConnecting;

    const getCursorStyle = (): string => {
      if (isConnecting) return 'crosshair';
      if (isSelected) return 'crosshair';
      return 'grab';
    };

    return (
      <Paper
        sx={{
          position: 'absolute',
          left: node.position.x,
          top: node.position.y,
          cursor: getCursorStyle(),
          ...getNodeStyle(node, isSelected),
          '&:hover': {
            boxShadow: 3,
            '& .delete-button': {
              opacity: 1,
            },
          },
        }}
        draggable={!isConnecting}
        onDragStart={e => onNodeDragStart(e, node.id)}
        onClick={() => onNodeClick(node.id)}
      >
        {showDecisionButtons && (
          <Box className="interactive-element" sx={{ pointerEvents: 'auto' }}>
            <DecisionButtons nodeId={node.id} onConnectionStart={onConnectionStart} />
          </Box>
        )}
        {showConnectButton && (
          <IconButton
            className="connect-button interactive-element"
            size="small"
            sx={{
              position: 'absolute',
              bottom: -32,
              left: '50%',
              transform: 'translateX(-50%)',
              bgcolor: 'primary.main',
              color: 'white',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
              width: 32,
              height: 32,
              pointerEvents: 'auto',
            }}
            onClick={e => {
              e.stopPropagation();
              onConnectionStart(node.id, undefined);
            }}
          >
            <ArrowRightIcon sx={{ fontSize: 20 }} />
          </IconButton>
        )}
        <IconButton
          className="delete-button interactive-element"
          size="small"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            opacity: 0,
            transition: 'opacity 0.2s',
            bgcolor: 'error.main',
            color: 'white',
            '&:hover': {
              bgcolor: 'error.dark',
            },
            width: 24,
            height: 24,
            pointerEvents: 'auto',
          }}
          onClick={e => onDeleteClick(e, node.id)}
        >
          <Trash01Icon sx={{ fontSize: 16 }} />
        </IconButton>
        <Box className="interactive-element" sx={{ pointerEvents: 'auto', width: '100%' }}>
          <NodeContent node={node} onDropdownChange={onDropdownChange} isSelected={isSelected} />
        </Box>
      </Paper>
    );
  }
);

WorkflowNode.displayName = 'WorkflowNode';

export default WorkflowNode;
