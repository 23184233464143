import { Theme } from '@mui/material/styles';

export default function OverridesFormHelperText(theme: Theme) {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...theme.typography.h5,
          margin: 0,
          marginBottom: '6px',

          '&.Mui-focused': {
            color: theme.palette.text.primary,
          },
        },
      },
    },
  };
}
