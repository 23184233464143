import {
  OAuthResponse,
  Session,
  SignInWithOAuthCredentials,
  SignInWithPasswordCredentials,
  SignUpWithPasswordCredentials,
  User,
} from '@supabase/supabase-js';
import { useQueryClient } from '@tanstack/react-query';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AccountType } from '@/api/enums/supabase';
import { PublicRoutes } from '@/config/routes';
import { useResidentLoginMutation } from '@/hooks/useResidentLoginMutation';
import {
  clearSupabaseSessionFromLocalStorage,
  getSupabaseSessionFromLocalStorage,
  setSupabaseSessionInLocalStorage,
} from '@/localStorage/supabase';
import { getResolvedRoutePath } from '@/utils/router';
import { supabase } from '@/utils/supabaseClient';

type AuthResponse = {
  data: {
    user: User | null;
    session: Session | null;
  };
  error: Error | null;
};

type AuthContextType = {
  refreshUser: () => Promise<void>;
  signInWithPassword: (credentials: SignInWithPasswordCredentials) => Promise<AuthResponse>;
  signInWithOAuth: (credentials: SignInWithOAuthCredentials) => Promise<OAuthResponse>;
  signInAnonymously: () => Promise<AuthResponse>;
  signUp: (credentials: SignUpWithPasswordCredentials) => Promise<AuthResponse>;
  signOut: () => Promise<void>;
  user: User | null;
  session: Session | null;
  shouldHaveAuthToken: boolean;
  isAuthenticated: boolean;
  isPropertyManager: boolean;
  isResident: boolean;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [session, setSession] = useState<Session | null>(getSupabaseSessionFromLocalStorage());
  const residentLoginMutation = useResidentLoginMutation(session);

  // Haing Auth Token means that user is most likely logged in to an existing account,
  // but we might not yet have fetched their user details, so we use hasAuthToken to keep
  // track for edge cases.
  const [hasAuthToken, setHasAuthToken] = useState<boolean>(!!session);

  // If we're being redirected from OAuth, we get access_token in the location hash,
  // but there's a tick delay before it gets processed and stored as Auth Token in Local Storage.
  // At the same time, on the next tick, the access_token hash will disappear.
  // But we normally set hasAuthToken after auth state changes, but setState has a tick delay,
  // so there'll be a single tick when the Auth Token is present, but it's not yet reflected in
  // React state.
  // To handle that we set the flag already to true.
  useEffect(() => {
    if (location.hash.includes('access_token')) setHasAuthToken(true);
  }, [location.hash]);

  const shouldHaveAuthToken = useMemo(
    () => hasAuthToken || location.hash.includes('access_token'),
    [hasAuthToken, location]
  );

  const user = useMemo(() => session?.user ?? null, [session]);

  const isAuthenticated = useMemo(() => !!user, [user]);

  const isPropertyManager = useMemo(
    () => user?.user_metadata.account_type === AccountType.property_manager,
    [user]
  );

  const isResident = useMemo(
    () => user?.user_metadata.account_type === AccountType.resident,
    [user]
  );

  const refreshUser = useCallback(async () => {
    const _user = (await supabase.auth.getUser()).data.user;

    if (!_user) {
      setSession(null);
      clearSupabaseSessionFromLocalStorage();
      return;
    }

    setSession(_session => {
      if (!_session) return _session;

      const newSession: Session = {
        ..._session,
        user: _user,
      };
      setSupabaseSessionInLocalStorage(newSession);

      return newSession;
    });
  }, []);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, _session) => {
      setSession(_session);
      setHasAuthToken(!!_session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const signInWithPassword = useCallback(
    async (credentials: SignInWithPasswordCredentials): Promise<AuthResponse> => {
      if (user?.is_anonymous) {
        await supabase.auth.updateUser(credentials);
      }

      const response = await supabase.auth.signInWithPassword(credentials);

      if (response.error?.code === 'email_not_confirmed') {
        history.push(
          getResolvedRoutePath(PublicRoutes.verifyEmail, {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            email: (credentials as any).email,
          })
        );
      }

      // If the user is a resident, update their onboarding state
      if (
        !response.error &&
        response.data.user?.user_metadata.account_type === AccountType.resident
      ) {
        await residentLoginMutation.mutateAsync();
      }

      return response;
    },
    [user, history, residentLoginMutation]
  );

  const signInWithOAuth = useCallback(
    async (credentials: SignInWithOAuthCredentials) => {
      if (user?.is_anonymous) return supabase.auth.linkIdentity(credentials);

      const response = await supabase.auth.signInWithOAuth(credentials);

      // If the user is a resident, update their onboarding state after successful OAuth sign-in
      const {
        data: { session: oauthSession },
      } = await supabase.auth.getSession();
      if (oauthSession?.user?.user_metadata.account_type === AccountType.resident) {
        await residentLoginMutation.mutateAsync();
      }

      return response;
    },
    [user, residentLoginMutation]
  );

  const signInAnonymously = useCallback(async () => supabase.auth.signInAnonymously(), []);

  const signUp = useCallback(
    async (credentials: SignUpWithPasswordCredentials): Promise<AuthResponse> => {
      if (user?.is_anonymous) {
        await supabase.auth.updateUser(credentials);
        return supabase.auth.signInWithPassword(credentials);
      }

      const response = await supabase.auth.signUp(credentials);

      // Only redirect to verify email page if there's no error and user is not confirmed
      if (!response.error && !response.data.user?.confirmed_at) {
        history.push(
          getResolvedRoutePath(PublicRoutes.verifyEmail, {
            email: response.data.user?.email ?? '',
          })
        );
      }

      return response;
    },
    [user, history]
  );

  useEffect(() => {
    if (!user) return;

    if (user.confirmed_at) return;

    history.push(
      getResolvedRoutePath(PublicRoutes.verifyEmail, {
        email: user?.email ?? '',
      })
    );
  }, [user, history]);

  const signOut = useCallback(async () => {
    clearSupabaseSessionFromLocalStorage();
    setSession(null);
    queryClient.clear();
    await supabase.auth.signOut();
  }, [queryClient]);

  const value = useMemo(
    () => ({
      refreshUser,
      signInWithOAuth,
      signInWithPassword,
      signInAnonymously,
      signUp,
      signOut,
      shouldHaveAuthToken,
      user,
      session,
      isAuthenticated,
      isPropertyManager,
      isResident,
    }),
    [
      refreshUser,
      signInWithOAuth,
      signInWithPassword,
      signInAnonymously,
      signUp,
      signOut,
      shouldHaveAuthToken,
      user,
      session,
      isAuthenticated,
      isPropertyManager,
      isResident,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export default AuthProvider;
