import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { InvoiceModel } from '@/api/models/ai/invoice';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_GetAll_Invoice = {
  location_id?: Id;
};

export type ApiResponse_GetAll_Invoice = Array<InvoiceModel>;

const METHOD = 'GET';
const ENDPOINT = 'invoice/all';
export const QUERY_KEY = (params?: ApiRequest_GetAll_Invoice) =>
  getQueryKey(METHOD, ENDPOINT, params);

export const useGetAllInvoices = (params?: ApiRequest_GetAll_Invoice, enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: !!params?.location_id && enabled && !!session?.access_token,
    queryKey: QUERY_KEY(params),
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetAll_Invoice, ApiRequest_GetAll_Invoice>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {}),
          params
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch invoices.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
