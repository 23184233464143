import { User } from '@supabase/supabase-js';

import { TOnboardingProgress } from '@/types/onboarding';

import { ApiResponse_GetAll_PropertyLocation } from '@/api/endpoints/propertyLocation/useGetAllPropertyLocations';
import { OnboardingRoutes, PMPRoutes } from '@/config/routes';

import { getResolvedRoutePath } from './router';

export interface ChecklistItem {
  title: string;
  description: string;
  isCompleted: boolean;
  actionLabel: string;
  route: string;
  isDisabled?: boolean;
  benefits?: string[];
  completedTitle: string;
  letsTitle: string;
  letsDescription: string;
}

export const getChecklistItems = ({
  isSingleFamilyLocation,
  onboardingProgress,
  selectedPropertyLocation,
  user,
}: {
  isSingleFamilyLocation: boolean;
  onboardingProgress: TOnboardingProgress;
  selectedPropertyLocation: ApiResponse_GetAll_PropertyLocation[0] | null;
  user: User | null;
}): ChecklistItem[] => [
  {
    title: 'Complete personal details',
    description: 'Complete your profile.',
    isCompleted: !!user?.user_metadata.full_name,
    actionLabel: 'Update Profile',
    route: PMPRoutes.account,
    benefits: [
      'Personalize your experience.',
      'Help us tailor recommendations to your needs.',
      "Allow tenants to know who they're working with.",
    ],
    completedTitle: 'completed personal details',
    letsTitle: 'complete your personal details',
    letsDescription: 'Completing your profile',
  },
  {
    title: 'Find your property',
    description: 'Add your property details.',
    isCompleted: !!onboardingProgress.hasPropertyLocation,
    actionLabel: 'Add Property',
    route: OnboardingRoutes.propertyLocationCreate,
    benefits: [
      'Organize your rental portfolio.',
      'Access property-specific insights and tools.',
      'Enable location-based features and reporting.',
    ],
    completedTitle: 'found your property',
    letsTitle: 'find your property',
    letsDescription: 'Adding your property details',
  },
  {
    title: 'Add tenant details',
    description: 'Add tenant details to manage your property effectively.',
    isCompleted: !!onboardingProgress.hasTenant,
    actionLabel: 'Add Tenants',
    route: `${getResolvedRoutePath(PMPRoutes.unitViewTenantList, {
      id: onboardingProgress.firstUnitId?.toString() ?? '',
    })}?openAddTenant=true`,
    isDisabled: !!onboardingProgress.hasTenant || !onboardingProgress.firstUnitId,
    benefits: [
      'Track tenant information in one place.',
      'Streamline communication with tenants.',
      'Enable rent collection and maintenance requests.',
    ],
    completedTitle: 'added tenant details',
    letsTitle: 'add tenant details',
    letsDescription: 'Adding tenant details',
  },
  ...(!isSingleFamilyLocation
    ? [
      {
        title: 'Add building',
        description:
            'Add your building details to manage your property effectively. This helps organize your rental properties.',
        isCompleted: !!onboardingProgress.hasBuilding,
        actionLabel: 'Add Building',
        route: `${getResolvedRoutePath(PMPRoutes.propertyLocationViewBuildingList, {
          id: selectedPropertyLocation?.id?.toString() ?? '',
        })}?openAddBuilding=true`,
        isDisabled: !!onboardingProgress.hasBuilding || !selectedPropertyLocation?.id,
        benefits: [
          'Organize multi-unit properties efficiently.',
          'Group units for better management.',
          'Enable building-specific insights and reports.',
        ],
        completedTitle: 'added building details',
        letsTitle: 'add building details',
        letsDescription: 'Adding building details',
      },
      {
        title: 'Add unit that tenants live in',
        description:
            'Add units in your building to manage your tenants effectively. This helps track tenant occupancy.',
        isCompleted: !!onboardingProgress.hasUnit,
        actionLabel: 'Add Unit',
        route: `${getResolvedRoutePath(PMPRoutes.buildingViewUnitList, {
          id: onboardingProgress.firstBuildingId?.toString() ?? '',
        })}?openAddUnit=true`,
        isDisabled: !!onboardingProgress.hasUnit || !onboardingProgress.firstBuildingId,
        benefits: [
          'Track individual rental units separately.',
          'Assign tenants to specific units.',
          'Monitor unit-specific maintenance and financials.',
        ],
        completedTitle: 'added unit details',
        letsTitle: 'add unit details',
        letsDescription: 'Adding unit details',
      },
    ]
    : []),
  {
    title: 'Get property phone number',
    description:
      'Tenants, vendors, and other contacts will use this as the main phone number for your property.',
    isCompleted: !!onboardingProgress.hasPhoneNumber,
    actionLabel: 'Get Phone Number',
    route: getResolvedRoutePath(PMPRoutes.propertyLocationPhoneCreate, {
      id: selectedPropertyLocation?.id?.toString() ?? '',
    }),
    isDisabled: !selectedPropertyLocation?.id || !onboardingProgress.hasTenant,
    benefits: [
      'Create a dedicated property contact line.',
      'Keep personal and business communications separate.',
      'Provide professional contact information to tenants and vendors.',
    ],
    completedTitle: 'got property phone number',
    letsTitle: 'get property phone number',
    letsDescription: 'Getting property phone number',
  },
  {
    title: 'Send tenant onboarding email',
    description:
      'Invite your tenant to set up their account and access the portal. This allows them to pay their rent and look at their communication history.',
    isCompleted: !!onboardingProgress.hasTenantsWithOnboardingEmailSent,
    actionLabel: 'Send Email',
    route: `${getResolvedRoutePath(PMPRoutes.propertyLocationView, {
      id: selectedPropertyLocation?.id?.toString() ?? '',
    })}?openInviteTenant=true`,
    isDisabled: !onboardingProgress.hasTenant,
    benefits: [
      'Enable tenant self-service features.',
      'Simplify rent collection and maintenance requests.',
      'Establish clear communication channels.',
    ],
    completedTitle: 'sent tenant onboarding email',
    letsTitle: 'send tenant onboarding email',
    letsDescription: 'Sending tenant onboarding email',
  },
  ...(isSingleFamilyLocation
    ? [
      {
        title: 'Upload lease document',
        description: 'Upload the lease agreement for your tenant.',
        isCompleted: !!onboardingProgress.hasLeaseDocument,
        actionLabel: 'Upload Lease',
        route: `${PMPRoutes.vault}?openDocumentUpload=true&defaultCategoryId=2`,
        isDisabled: !onboardingProgress.hasTenant,
        benefits: [
          'Securely store important lease documents.',
          'Easily reference terms and conditions.',
          'Keep a digital record of all agreements.',
        ],
        completedTitle: 'uploaded lease document',
        letsTitle: 'upload lease document',
        letsDescription: 'Uploading lease document',
      },
    ]
    : []),
];

export const getIncompleteChecklistItems = (checklistItems: ChecklistItem[]) =>
  checklistItems.filter(item => !item.isCompleted);

export const getCompletedChecklistItems = (checklistItems: ChecklistItem[]) =>
  checklistItems.filter(item => !!item.isCompleted);

export const getLastCompletedItem = (checklistItems: ChecklistItem[]) => {
  const completedItems = getCompletedChecklistItems(checklistItems);
  return completedItems[completedItems.length - 1];
};

export const getNextItem = (checklistItems: ChecklistItem[]) => {
  const incompleteItems = getIncompleteChecklistItems(checklistItems);
  return incompleteItems[0];
};

export const getCompletionPercentage = (checklistItems: ChecklistItem[]) => {
  const incompleteItems = getIncompleteChecklistItems(checklistItems);
  return Math.round(
    ((checklistItems.length - incompleteItems.length) / checklistItems.length) * 100
  );
};
