import { Box, Button, Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

import { MessageChatCircleIcon } from '@/assets/icons/MessageChatCircleIcon';
import { MessageSquare01Icon } from '@/assets/icons/MessageSquare01Icon';
import { PhoneIcon } from '@/assets/icons/PhoneIcon';
import { PublicRoutes } from '@/config/routes';

import BlurBackground from './BlurBackground';

const CommunicationsSection: React.FC = () => (
  <Grid2
    size={12}
    sx={{ position: 'relative', py: 8, backgroundColor: 'rgba(245, 245, 245, 0.5)' }}
  >
    <BlurBackground
      sx={{
        left: '25%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />

    <Box sx={{ maxWidth: 1200, margin: '0 auto' }}>
      <Grid2 container spacing={4} alignItems="center">
        <Grid2 size={{ xs: 12, md: 6 }} order={{ xs: 2, md: 1 }}>
          <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
            <img
              src="/assets/images/undraw_customer_survey.svg"
              alt="AI-Powered Communications"
              style={{
                maxWidth: '100%',
                height: 'auto',
                maxHeight: '400px',
              }}
            />
          </Box>
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }} order={{ xs: 1, md: 2 }}>
          <Stack spacing={3} sx={{ p: 3 }}>
            <Box>
              <Box
                sx={{
                  py: 0.5,
                  px: 1.5,
                  borderRadius: '16px',
                  border: '1px solid #D9D9D9',
                  display: 'inline-flex',
                  height: '30px',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Typography variant="overline">Communication Hub</Typography>
              </Box>
              <Typography variant="h3" gutterBottom>
                <strong>AI-Powered</strong> Communication
              </Typography>
            </Box>

            <Typography variant="body1">
              Let AI handle your tenant communications, from routine inquiries to urgent maintenance
              requests. Our platform responds instantly with human-like replies, saving you time and
              ensuring nothing falls through the cracks.
            </Typography>

            <Box sx={{ my: 3 }}>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, md: 4 }}>
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      backgroundColor: 'white',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: 48,
                        height: 48,
                        borderRadius: '10px',
                        border: '1px solid #D9D9D9',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 1.5,
                      }}
                    >
                      <MessageChatCircleIcon fontSize="small" color="primary" />
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      Instant Messaging
                    </Typography>
                    <Typography variant="body2">
                      Automated responses to tenant queries 24/7
                    </Typography>
                  </Box>
                </Grid2>

                <Grid2 size={{ xs: 12, md: 4 }}>
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      backgroundColor: 'white',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: 48,
                        height: 48,
                        borderRadius: '10px',
                        border: '1px solid #D9D9D9',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 1.5,
                      }}
                    >
                      <PhoneIcon fontSize="small" color="primary" />
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      Call Handling
                    </Typography>
                    <Typography variant="body2">
                      Manage inbound calls and voicemails efficiently
                    </Typography>
                  </Box>
                </Grid2>

                <Grid2 size={{ xs: 12, md: 4 }}>
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      backgroundColor: 'white',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: 48,
                        height: 48,
                        borderRadius: '10px',
                        border: '1px solid #D9D9D9',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 1.5,
                      }}
                    >
                      <MessageSquare01Icon fontSize="small" color="primary" />
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      Smart Routing
                    </Typography>
                    <Typography variant="body2">
                      Prioritize and route urgent matters automatically
                    </Typography>
                  </Box>
                </Grid2>
              </Grid2>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Button variant="contained" color="primary" size="large" href={PublicRoutes.signup}>
                Experience AI Communication
              </Button>
            </Box>
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  </Grid2>
);

export default CommunicationsSection;
