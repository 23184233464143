import { SvgIcon, SvgIconProps } from '@mui/material';

export const ChevronUpIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <path
      d="M18 15L12 9L6 15"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
