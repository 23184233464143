import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

interface GetAICalendarEventsParams {
  location_id: string | number;
  start_date: string;
  end_date?: string;
}

interface AICalendarEvent {
  description: string;
  event_date: string;
  event_type: string;
  tenant_id: string;
}

interface GetAICalendarEventsResponse {
  events: AICalendarEvent[];
}

const ENDPOINT = 'api/v1/calendar';
const METHOD = 'GET';

export const useGetAICalendarEvents = (params: GetAICalendarEventsParams, enabled = true) => {
  const { session } = useAuth();
  const formattedParams = {
    location_id: params.location_id.toString(),
    start_date: params.start_date,
    end_date: params.end_date,
  };

  return useQuery({
    queryKey: ['ai-calendar-events', formattedParams],
    queryFn: async () => {
      const response = await handleApiCall<GetAICalendarEventsResponse, null>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        null,
        { params: formattedParams } as AxiosRequestConfig
      );
      return response.events;
    },
    enabled: enabled && !!params.location_id && !!session,
    refetchOnWindowFocus: false,
  });
};
