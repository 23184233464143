import { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { OnboardingRoutes } from '@/config/routes';
import { MessageNotificationProvider } from '@/context/MessageNotificationProvider';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { getCurrentOnboardingStep } from '@/utils/onboarding';

import LoadingState from '@/components/LoadingState';

import AccountTypeSelectPage from './AccountTypeSelectPage';
import PropertyLocationCreatePage from './PropertyLocationCreatePage';
import TenantUploadPage from './TenantUploadPage';

const HomePage: React.FC = () => {
  const { onboardingProgress, isFetching, refetch } = useOnboardingProgress();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (isFetching) return;

    history.replace(...getCurrentOnboardingStep(onboardingProgress));
  }, [onboardingProgress, isFetching, history]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <MessageNotificationProvider>
      <MuiPageWrapper>
        <Suspense fallback={<LoadingState />}>
          <Switch>
            <Route
              exact
              path={OnboardingRoutes.accountTypeSelect}
              component={AccountTypeSelectPage}
            />

            <Route
              exact
              path={OnboardingRoutes.propertyLocationCreate}
              component={PropertyLocationCreatePage}
            />

            <Route exact path={OnboardingRoutes.tenantUpload} component={TenantUploadPage} />

            <Route>
              <Redirect to={OnboardingRoutes.accountTypeSelect} />
            </Route>
          </Switch>
        </Suspense>
      </MuiPageWrapper>
    </MessageNotificationProvider>
  );
};

export default HomePage;
