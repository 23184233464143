import Grid2 from '@mui/material/Grid2/Grid2';

import Banner from './common/Banner';

const PricingCustomPlanSection: React.FC = () => (
  <Grid2 size={10} offset={1}>
    <Banner
      title="Need a custom plan for your portfolio?"
      description="Contact us for volume discounts and enterprise features."
      buttonLabel="Contact Sales"
      href="mailto:hello@terr.ai"
    />
  </Grid2>
);

export default PricingCustomPlanSection;
