import { NavItemType } from '@/@mantis/types/menu';
import { BarChartSquare02Icon } from '@/assets/icons/BarChartSquare02Icon';
import { MessageChatCircleIcon } from '@/assets/icons/MessageChatCircleIcon';
import { Wallet01Icon } from '@/assets/icons/Wallet01Icon';
import { ResidentRoutes } from '@/config/routes';

export const getResidentMenuItems = (): NavItemType[] => [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    url: ResidentRoutes.dashboard,
    icon: BarChartSquare02Icon,
    description: 'View your dashboard and important information',
    getIsSelected: pathname => ResidentRoutes.dashboard === pathname,
  },
  {
    id: 'payments',
    title: 'Payments',
    type: 'group',
    url: ResidentRoutes.payments,
    icon: Wallet01Icon,
    description: 'View and manage your payments',
    getIsSelected: pathname => ResidentRoutes.payments === pathname,
  },
  {
    id: 'communication',
    title: 'Communication',
    type: 'group',
    url: ResidentRoutes.communication,
    icon: MessageChatCircleIcon,
    description: 'View and manage your communications',
    getIsSelected: pathname => ResidentRoutes.communication === pathname,
  },
];
