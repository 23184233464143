import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useEffect, useRef } from 'react';

import { TMessageThread } from '@/types/message';

import ScrollX from '@/@mantis/components/ScrollX';
import { AlertTriangleIcon } from '@/assets/icons/AlertTriangleIcon';

import LoadingState from '../LoadingState';

import MessageThreadMessageItem from './MessageThreadMessageItem';

interface Props {
  messageThread: TMessageThread | null;
  isLoading?: boolean;
  isFailed?: boolean;
}

const MessageThreadMessageList: React.FC<Props> = ({
  messageThread,
  isLoading = false,
  isFailed = false,
}) => {
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [messageThread]);

  if (isLoading) {
    return <LoadingState />;
  }

  if (isFailed) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ flex: '1 1 100%' }} gap={1}>
        <AlertTriangleIcon color="error" fontSize="large" />
        <Typography variant="body2">Failed to load messages.</Typography>
      </Stack>
    );
  }

  if (!messageThread) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ flex: '1 1 100%' }} gap={1}>
        <Typography variant="body2">Select a thread to view messages.</Typography>
      </Stack>
    );
  }

  if (messageThread.messages.length === 0) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ flex: '1 1 100%' }} gap={1}>
        <Typography variant="body2">No messages found.</Typography>
      </Stack>
    );
  }

  return (
    <ScrollX
      sx={{
        overflowX: 'auto',
        flex: '1 1 100%',
        display: 'flex',
      }}
    >
      <Stack direction="column" gap={2.5} sx={{ flex: '1 1 100%', px: 2, py: 4 }}>
        {messageThread.messages.map(message => (
          <MessageThreadMessageItem
            key={message.id}
            message={message}
            messageSession={messageThread.session}
          />
        ))}

        <Box ref={bottomRef} />
      </Stack>
    </ScrollX>
  );
};

export default MessageThreadMessageList;
