import { Box, BoxProps } from '@mui/material';

interface Props {
  reverse?: boolean;
  sx?: BoxProps['sx'];
}

const LogoMain: React.FC<Props> = ({ sx }) => (
  <Box
    component="img"
    src="/assets/images/terrai-logo.svg"
    alt="Terr.ai"
    sx={{ width: 'auto', height: '30px', alignSelf: 'flex-start', ...sx }}
  />
);

export default LogoMain;
