import { RefetchOptions } from '@tanstack/react-query';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import {
  ApiResponse_GetAll_PropertyLocation,
  useGetAllPropertyLocations,
} from '@/api/endpoints/propertyLocation/useGetAllPropertyLocations';
import { PropertyLocationType } from '@/api/enums';
import { PropertyLocationModel } from '@/api/models/propertyLocation';
import { PMPRoutes } from '@/config/routes';
import {
  getSelectedPropertyLocationIdFromLocalStorage,
  setSelectedPropertyLocationIdInLocalStorage,
} from '@/localStorage/propertyLocation';
import { isPathPartOfRoutes } from '@/utils/router';

import LoadingStateWithMuiPageWrapper from '@/components/LoadingStateWithMuiPageWrapper';

import { useAuth } from './AuthProvider';

type PropertyManagerContextType = {
  selectedPropertyLocation: ApiResponse_GetAll_PropertyLocation[0] | null;
  setSelectedPropertyLocationId: (locationId: PropertyLocationModel['id'] | null) => void;
  propertyLocations: ApiResponse_GetAll_PropertyLocation;
  isFetching: boolean;
  isSingleFamilyAccount: boolean;
  isSingleFamilyLocation: boolean;
  refetch: (options?: RefetchOptions) => void;
};

const PropertyManagerContext = createContext<PropertyManagerContextType | undefined>(undefined);

const PropertyManagerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, isPropertyManager } = useAuth();
  const location = useLocation();

  const [selectedPropertyLocationId, setSelectedPropertyLocationId] = useState<number | null>(
    getSelectedPropertyLocationIdFromLocalStorage()
  );

  const handleSetSelectedPropertyLocationId = useCallback((locationId: number | null) => {
    setSelectedPropertyLocationId(locationId);
    setSelectedPropertyLocationIdInLocalStorage(locationId);
  }, []);

  const getAllPropertyLocationsQuery = useGetAllPropertyLocations(isPropertyManager);

  useEffect(() => {
    if (selectedPropertyLocationId) return;

    if (!getAllPropertyLocationsQuery.data) return;

    handleSetSelectedPropertyLocationId(getAllPropertyLocationsQuery.data?.[0]?.id ?? null);
  }, [
    getAllPropertyLocationsQuery.data,
    selectedPropertyLocationId,
    handleSetSelectedPropertyLocationId,
  ]);

  useEffect(() => {
    if (user) return;

    handleSetSelectedPropertyLocationId(null);
  }, [user, handleSetSelectedPropertyLocationId]);

  const selectedPropertyLocation = useMemo(() => {
    if (!selectedPropertyLocationId) return null;

    return (
      getAllPropertyLocationsQuery.data?.find(
        propertyLocation => propertyLocation.id === selectedPropertyLocationId
      ) ?? null
    );
  }, [getAllPropertyLocationsQuery.data, selectedPropertyLocationId]);

  const isSingleFamilyAccount = useMemo(
    () =>
      getAllPropertyLocationsQuery.data?.length === 1 &&
      getAllPropertyLocationsQuery.data?.every(
        propertyLocation => propertyLocation.type === PropertyLocationType.SINGLE_FAMILY_HOME
      ),
    [getAllPropertyLocationsQuery.data]
  );

  const isSingleFamilyLocation = useMemo(
    () => selectedPropertyLocation?.type === PropertyLocationType.SINGLE_FAMILY_HOME,
    [selectedPropertyLocation]
  );

  const { isFetching, isLoading } = getAllPropertyLocationsQuery;

  const value = useMemo(
    () => ({
      propertyLocations: getAllPropertyLocationsQuery.data ?? [],
      isSingleFamilyAccount,
      isSingleFamilyLocation,
      isFetching,
      selectedPropertyLocation,
      setSelectedPropertyLocationId: handleSetSelectedPropertyLocationId,
      refetch: getAllPropertyLocationsQuery.refetch,
    }),
    [
      getAllPropertyLocationsQuery.data,
      getAllPropertyLocationsQuery.refetch,
      isFetching,
      isSingleFamilyAccount,
      isSingleFamilyLocation,
      selectedPropertyLocation,
      handleSetSelectedPropertyLocationId,
    ]
  );

  const shouldShowLoadingState = useMemo(
    () =>
      isLoading &&
      isPropertyManager &&
      !isPathPartOfRoutes(location.pathname, [PMPRoutes.propertyLocationList]),
    [isLoading, isPropertyManager, location]
  );

  return (
    <PropertyManagerContext.Provider value={value}>
      {shouldShowLoadingState ? <LoadingStateWithMuiPageWrapper /> : children}
    </PropertyManagerContext.Provider>
  );
};

export const usePropertyManager = () => {
  const context = useContext(PropertyManagerContext);

  if (context === undefined) {
    throw new Error('usePropertyManager must be used within a PropertyManagerProvider');
  }

  return context;
};

export default PropertyManagerProvider;
