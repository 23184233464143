import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { PublicRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';

import LoadingState from '@/components/LoadingState';

const LogOutPage: React.FC = () => {
  const { user, signOut } = useAuth();

  useEffect(() => {
    signOut();
  }, [signOut]);

  if (!user) return <Redirect to={PublicRoutes.login} />;

  return (
    <MuiPageWrapper>
      <LoadingState />
    </MuiPageWrapper>
  );
};

export default LogOutPage;
