import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { EmailSessionModelWithEmailThread } from '@/api/models/emailSession';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_GetAll_Email = {
  locationId?: Id;
  email?: string;
  filterBy?: 'all' | 'emergency' | 'in_progress' | 'completed';
};

export type ApiResponse_GetAll_Email = Array<EmailSessionModelWithEmailThread>;

const METHOD = 'GET';
const ENDPOINT = 'email/all';
export const QUERY_KEY = (params: ApiRequest_GetAll_Email) => getQueryKey(METHOD, ENDPOINT, params);

export const useGetAllEmails = (params: ApiRequest_GetAll_Email, enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!params.locationId && !!session?.access_token,
    queryKey: QUERY_KEY(params),
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetAll_Email, ApiRequest_GetAll_Email>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {}),
          params
        );

        const { filterBy } = params;

        if (!filterBy) return data;

        return data.filter(email => {
          if (filterBy === 'all') return true;
          if (filterBy === 'emergency') return !!email.urgent;
          if (filterBy === 'in_progress')
            return !email.session.replied_to && !email.session.archived;
          if (filterBy === 'completed') return !!email.session.archived;

          return true;
        });
      } catch (err) {
        enqueueSnackbar('Failed to fetch emails.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
