import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { PropertyLocationDeclaredUnitsCount, PropertyLocationType } from '@/api/enums';
import {
  PropertyLocationModel,
  PropertyLocationWithEmailAndPhoneModel,
} from '@/api/models/propertyLocation';
import { PropertyManagementModel } from '@/api/models/propertyManagement';
import { PropertyManagerModel } from '@/api/models/propertyManager';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY as GET_ALL_QUERY_KEY } from './useGetAllPropertyLocations';
import { QUERY_KEY as GET_ONE_QUERY_KEY } from './useGetOnePropertyLocation';

export type ApiResponse_Update_PropertyLocation = PropertyLocationWithEmailAndPhoneModel & {
  management: Array<PropertyManagementModel & { manager: PropertyManagerModel }>;
};

export type ApiRequest_Update_PropertyLocation = {
  name: string;
  address_text: string;
  type: PropertyLocationType;
  declared_units_count?: PropertyLocationDeclaredUnitsCount;
};

const sanitizeData = (data: ApiRequest_Update_PropertyLocation) => ({
  ...data,
  declared_units_count:
    data.type === PropertyLocationType.MULTI_UNIT ? data.declared_units_count : undefined,
});

const METHOD = 'PUT';
const ENDPOINT = 'property-location/:id';

export const useUpdatePropertyLocation = (
  id: PropertyLocationModel['id'],
  stateFns?: MutationStateFns<ApiResponse_Update_PropertyLocation>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_Update_PropertyLocation) =>
      handleApiCall<ApiResponse_Update_PropertyLocation, ApiRequest_Update_PropertyLocation>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: id.toString() }),
        sanitizeData(data)
      ),
    onSuccess: data => {
      enqueueSnackbar('Property location updated successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: GET_ALL_QUERY_KEY,
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: GET_ONE_QUERY_KEY(id),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to update property location', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
