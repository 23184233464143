import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { FC } from 'react';

import { environment } from '@env';

import { DownloadCloud01Icon } from '@/assets/icons/DownloadCloud01Icon';
import { useAuth } from '@/context/AuthProvider';

interface Props {
  open: boolean;
  onClose: () => void;
  locationId: number;
  workflowId: string;
  invoiceId: string;
  nodeId: string;
}

const LeasePreviewModal: FC<Props> = ({
  open,
  onClose,
  locationId,
  workflowId,
  invoiceId,
  nodeId,
}) => {
  const { session } = useAuth();

  const { data: leaseData, isLoading } = useQuery({
    queryKey: ['leasePreview', locationId, workflowId, invoiceId, nodeId],
    queryFn: async () => {
      const response = await axios.get(
        `${environment.api}/workflow/location/${locationId}/workflow-instance/${workflowId}/${invoiceId}/lease-preview`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return response.data;
    },
    enabled: open,
  });

  const handleDownloadPDF = async () => {
    if (!leaseData?.html) return;

    try {
      const response = await axios.post(
        `${environment.api}/workflow/location/${locationId}/workflow-instance/${workflowId}/${invoiceId}/lease-preview/download`,
        null,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
          responseType: 'blob',
        }
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'lease-preview.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // console.error('Error downloading PDF:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Lease Preview</DialogTitle>
      <DialogContent>
        <Box sx={{ py: 2 }}>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box
              component="iframe"
              src={`data:text/html;charset=utf-8,${encodeURIComponent(leaseData?.html || '')}`}
              sx={{
                width: '100%',
                height: '70vh',
                border: 'none',
              }}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDownloadPDF}
          disabled={isLoading || !leaseData?.html}
          startIcon={<DownloadCloud01Icon />}
        >
          Download PDF
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeasePreviewModal;
