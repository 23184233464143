import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box, Button, GlobalStyles, Stack, useTheme } from '@mui/material';
import { addDays, format } from 'date-fns';
import { useState, useMemo, useCallback } from 'react';

import { CalendarEvent } from '@/types/calendar';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { useGetAICalendarEvents } from '@/api/endpoints/calendar/useGetAICalendarEvents';
import { useGetAllCalendarEvents } from '@/api/endpoints/calendar/useGetAllCalendarEvents';
import { Id } from '@/api/utils/sql';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

import CalendarEventCreateModal from './CalendarEventCreateModal';
import CalendarEventEditModal from './CalendarEventEditModal';

// Define a common interface for display events
interface DisplayEvent {
  id: string | number;
  title: string;
  start: string;
  end: string;
  all_day: boolean;
  description?: string | null;
  isAiEvent?: boolean;
  // Optional CalendarEvent properties
  propertyLocationId?: Id;
  buildingId?: Id;
  unitId?: Id;
  tenantId?: Id | string;
  created_by?: string;
  created_at?: string;
  updated_at?: string;
  location?: {
    id: Id;
    name: string;
    type: string;
  };
  building?: {
    id: Id;
    name: string;
  };
  unit?: {
    id: Id;
    name: string;
  };
  tenant?: {
    id: Id;
    full_name: string;
  };
}

const CalendarView = () => {
  const theme = useTheme();
  const [isAddEventOpen, setIsAddEventOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { selectedPropertyLocation } = usePropertyManager();
  const [dateRange, setDateRange] = useState({
    start: format(new Date(), 'yyyy-MM-dd'),
    end: format(addDays(new Date(), 30), 'yyyy-MM-dd'),
  });

  const { data: calendarEvents = [], refetch } = useGetAllCalendarEvents(
    {
      property_location_id: selectedPropertyLocation?.id ?? 0,
    },
    !!selectedPropertyLocation?.id
  );

  const { data: aiCalendarEvents = [], refetch: refetchAiEvents } = useGetAICalendarEvents(
    {
      location_id: selectedPropertyLocation?.id ?? 0,
      start_date: dateRange.start,
      end_date: dateRange.end,
    },
    !!selectedPropertyLocation?.id
  );

  // Format events for FullCalendar
  const formattedEvents = useMemo(() => {
    // Process regular calendar events
    const formattedRegularEvents: DisplayEvent[] = calendarEvents.map(event => {
      if (event.all_day) {
        const endDate = new Date(event.end);
        const nextDay = addDays(endDate, 1);

        return {
          ...event,
          // Format both dates as YYYY-MM-DD for all-day events
          start: new Date(event.start).toISOString().split('T')[0],
          end: nextDay.toISOString().split('T')[0],
        };
      }
      return event;
    });

    // Process AI calendar events
    const formattedAiEvents: DisplayEvent[] = aiCalendarEvents.map((aiEvent, index) => ({
      id: `ai-${index}`,
      title: aiEvent.event_type,
      start: aiEvent.event_date,
      end: aiEvent.event_date,
      description: aiEvent.description,
      tenantId: aiEvent.tenant_id,
      isAiEvent: true,
      all_day: true,
    }));

    return [...formattedRegularEvents, ...formattedAiEvents];
  }, [calendarEvents, aiCalendarEvents]);

  const handleEventClick = (info: { event: { id: string } }) => {
    const event = formattedEvents.find(e => e.id.toString() === info.event.id);
    if (event && !event.isAiEvent && 'propertyLocationId' in event) {
      // Only set selectedEvent for regular calendar events, not AI events
      // Make sure it's a valid CalendarEvent with required properties
      setSelectedEvent(event as CalendarEvent);
    }
  };

  const handleEventCreated = () => {
    refetch();
  };

  const handleEventDeleted = () => {
    refetch();
  };

  const handleDatesSet = useCallback(
    (info: { start: Date; end: Date }) => {
      const newStartDate = format(info.start, 'yyyy-MM-dd');
      const newEndDate = format(info.end, 'yyyy-MM-dd');

      if (newStartDate !== dateRange.start || newEndDate !== dateRange.end) {
        setDateRange({
          start: newStartDate,
          end: newEndDate,
        });
        refetchAiEvents();
      }
    },
    [dateRange, refetchAiEvents]
  );

  // Create calendar styles object for GlobalStyles
  const calendarStyles = useMemo(
    () => ({
      '.fc': {
        fontFamily: theme.typography.fontFamily,
        '--fc-border-color': theme.palette.divider,
        '--fc-button-text-color': '#fff',
        '--fc-button-bg-color': theme.palette.primary.main,
        '--fc-button-border-color': theme.palette.primary.main,
        '--fc-button-hover-bg-color': theme.palette.primary.dark,
        '--fc-button-hover-border-color': theme.palette.primary.dark,
        '--fc-button-active-bg-color': theme.palette.primary.dark,
        '--fc-today-bg-color': `${theme.palette.primary.lighter}40`,
        '--fc-event-bg-color': theme.palette.primary.main,
        '--fc-event-border-color': theme.palette.primary.main,
        '--fc-event-text-color': '#fff',
        color: theme.palette.text.primary,
      },
      '.fc-header-toolbar': {
        marginBottom: theme.spacing(3),
        '& .fc-button': {
          '&:focus': {
            boxShadow: `0 0 0 2px ${theme.palette.primary.lighter}`,
          },
        },
      },
      '.fc-view-harness': {
        minHeight: 'calc(100vh - 350px)',
      },
      '.fc-h-event': {
        backgroundColor: theme.palette.primary.main,
        border: 'none',
        cursor: 'pointer',
      },
      '.fc-daygrid-day': {
        cursor: 'pointer',
        '&.fc-day-today': {
          backgroundColor: `${theme.palette.primary.lighter}40`,
        },
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      },
      '.fc-daygrid-day-frame': {
        padding: '8px',
      },
      '.fc-scrollgrid-sync-inner': {
        minHeight: '100px',
      },
      '.fc-col-header-cell': {
        padding: '12px 0',
        backgroundColor: theme.palette.grey[100],
        '& .fc-col-header-cell-cushion': {
          padding: '8px',
          color: theme.palette.text.secondary,
          fontWeight: 600,
        },
      },
      '.fc-daygrid-day-number': {
        width: '100%',
        padding: '8px',
        textAlign: 'left',
        fontSize: '1rem',
        color: theme.palette.text.primary,
      },
      '.fc-day-other .fc-daygrid-day-number': {
        color: theme.palette.text.secondary,
        opacity: 0.7,
      },
      '.fc-daygrid-day-events': {
        marginTop: 1,
      },
      '.fc-daygrid-more-link': {
        color: theme.palette.primary.main,
        fontSize: '0.85em',
      },
      '.fc-event-title': {
        padding: '0 8px',
      },
    }),
    [theme]
  );

  return (
    <MuiPageWrapper>
      <GlobalStyles styles={calendarStyles} />
      <Box sx={{ height: 'calc(100vh - 200px)' }}>
        <DashboardHeader hasInnerHtml title="Calendar: <em>All</em>" />
        <Stack direction="row" justifyContent="flex-end" mb={2}>
          <Button
            variant="contained"
            onClick={() => setIsAddEventOpen(true)}
            startIcon={<PlusIcon />}
            sx={{ px: 2.5, py: 1 }}
          >
            Add Event
          </Button>
        </Stack>
        <Box>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            events={formattedEvents.map(event => ({
              id: event.id.toString(),
              title: event.title,
              start: event.start,
              end: event.end,
              allDay: event.all_day,
              display: 'block',
            }))}
            eventClick={handleEventClick}
            dateClick={info => {
              setSelectedDate(info.date);
              setIsAddEventOpen(true);
            }}
            height="auto"
            displayEventTime={false}
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: false,
            }}
            datesSet={handleDatesSet}
            dayMaxEvents={3}
            firstDay={0}
            fixedWeekCount={false}
            showNonCurrentDates
            timeZone="UTC"
            forceEventDuration
            defaultAllDay
          />
        </Box>

        {isAddEventOpen && (
          <CalendarEventCreateModal
            onClose={() => {
              setIsAddEventOpen(false);
              setSelectedDate(null);
            }}
            propertyLocationId={selectedPropertyLocation?.id ?? 0}
            initialDate={selectedDate}
            onEventCreated={handleEventCreated}
          />
        )}

        {selectedEvent && (
          <CalendarEventEditModal
            event={selectedEvent}
            onClose={() => setSelectedEvent(null)}
            onEventDeleted={handleEventDeleted}
          />
        )}
      </Box>
    </MuiPageWrapper>
  );
};

export default CalendarView;
