import { Theme } from '@mui/material/styles';

export default function OverridesInputBase(theme: Theme) {
  return {
    MuiInputBase: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            borderRadius: '0.5rem',
          },

          '& .MuiInputBase-input': {
            ...theme.typography.body2,
            sizeSmall: {
              ...theme.typography.body3,
            },
          },
        },
      },
    },
  };
}
