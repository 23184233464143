import { ApiResponse_GetAllEmailContacts_Building } from '@/api/endpoints/building/useGetAllEmailContacts';
import { ApiResponse_GetAllPhoneContacts_Building } from '@/api/endpoints/building/useGetAllPhoneContacts';

import { Option } from '@/components/form/FormAutocomplete';

import { formatPhoneNumber } from './phone';

export const emailContactsToOptions = (
  contacts?: ApiResponse_GetAllEmailContacts_Building
): Option[] =>
  contacts?.map(contact => ({
    label: `${contact.tenantName} (Unit ${contact.unitName}) <${contact.email}>`,
    value: contact.email,
  })) ?? [];

export const phoneContactsToOptions = (
  contacts?: ApiResponse_GetAllPhoneContacts_Building
): Option[] =>
  contacts?.map(contact => ({
    label: `${contact.tenantName} (Unit ${contact.unitName}) <${formatPhoneNumber(contact.phone)}>`,
    value: contact.tenantId ?? -1,
  })) ?? [];
