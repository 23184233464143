import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { TenantModelWithContacts } from '@/api/models/tenant';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_GetAll_Tenant = {
  unit_id?: Id;
  location_id?: Id;
  is_archived?: boolean;
};

export type ApiResponse_GetAll_Tenant = Array<TenantModelWithContacts>;

const METHOD = 'GET';
const ENDPOINT = 'tenant/all';
export const QUERY_KEY = (params: ApiRequest_GetAll_Tenant) =>
  getQueryKey(METHOD, ENDPOINT, params);

export const useGetAllTenants = (params: ApiRequest_GetAll_Tenant, enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY(params),
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetAll_Tenant, ApiRequest_GetAll_Tenant>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {}),
          params
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch tenants.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
