import { Box, Grid2, Stack, Typography, Tabs, Tab } from '@mui/material';
import React, { useState } from 'react';

import BlurBackground from './BlurBackground';

interface FeatureType {
  title: string;
  description: string;
  icon: string;
  isSharedFeature?: boolean;
}

const singleHomeFeatures: FeatureType[] = [
  {
    title: 'AI-Powered Communications',
    description:
      'Your AI assistant handles tenant inquiries 24/7, responding to messages and calls with human-like replies while you focus on what matters.',
    icon: '/assets/images/undraw_messaging.svg',
    isSharedFeature: true,
  },
  {
    title: 'Automated Rent Collection',
    description:
      'Set up automatic rent reminders, process payments, and generate receipts with minimal effort. Get paid on time, every time.',
    icon: '/assets/images/undraw_credit_card.svg',
    isSharedFeature: true,
  },
  {
    title: 'Maintenance Management',
    description:
      'Easily track, prioritize, and manage maintenance requests. Route tickets to the right vendors and keep tenants updated automatically.',
    icon: '/assets/images/undraw_maintenance.svg',
    isSharedFeature: true,
  },
];

const multiUnitFeatures: FeatureType[] = [
  {
    title: 'Portfolio Dashboard',
    description:
      "Get a bird's-eye view of all your properties, buildings, and units. Monitor performance metrics across your entire portfolio in one place.",
    icon: '/assets/images/undraw_dashboard.svg',
  },
  {
    title: 'Document Vault',
    description:
      'Securely store and organize all property-related documents including leases, inspections, and financial records for your entire portfolio.',
    icon: '/assets/images/undraw_folder.svg',
  },
  {
    title: 'Advanced Workflows',
    description:
      'Create customized workflows to automate complex property management tasks across multiple buildings and units.',
    icon: '/assets/images/undraw_process.svg',
  },
];

const FeaturesSection: React.FC = () => {
  const [tab, setTab] = useState<number>(0);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const currentFeatures = tab === 0 ? singleHomeFeatures : multiUnitFeatures;

  return (
    <Grid2 size={12} sx={{ position: 'relative' }}>
      <BlurBackground
        sx={{
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />

      <Box sx={{ py: 6, textAlign: 'center' }}>
        <Stack spacing={1.25} sx={{ maxWidth: 770, margin: '0 auto' }}>
          <Stack spacing={1}>
            <Box>
              <Box
                sx={{
                  py: 0.5,
                  px: 1.5,
                  borderRadius: '16px',
                  border: '1px solid #D9D9D9',
                  margin: '0 auto',
                  display: 'inline-flex',
                  height: '30px',
                  alignItems: 'center',
                }}
              >
                <Typography variant="overline">Powerful Features</Typography>
              </Box>
            </Box>

            <Typography variant="h3">
              Comprehensive Tools for
              <br />
              <strong>Every Property Owner</strong>
            </Typography>
          </Stack>

          <Typography variant="body1">
            Our platform offers specialized features tailored to both single-home owners and
            multi-unit property managers.
          </Typography>

          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 4 }}>
            <Tabs value={tab} onChange={handleTabChange} centered>
              <Tab label="Single Home Owners" />
              <Tab label="Multi-Unit Managers" />
            </Tabs>
          </Box>

          <Box sx={{ mt: 4, mb: 4, p: 2 }}>
            <Box sx={{ position: 'relative', height: '300px', overflow: 'hidden' }}>
              <img
                src={
                  tab === 0
                    ? '/assets/images/undraw_house.svg'
                    : '/assets/images/undraw_buildings.svg'
                }
                alt={tab === 0 ? 'Single Home' : 'Multi-Unit'}
                style={{
                  width: '100%',
                  maxHeight: '300px',
                  objectFit: 'contain',
                }}
              />
            </Box>
          </Box>
        </Stack>

        {tab === 1 && (
          <Box
            sx={{
              mb: 4,
              p: 2,
              backgroundColor: theme => theme.palette.primary.lighter,
              borderRadius: 2,
              maxWidth: 800,
              mx: 'auto',
            }}
          >
            <Typography variant="body1" color="primary.main">
              Multi-Unit Managers get access to all Single Home Owner features, plus these
              additional powerful tools:
            </Typography>
          </Box>
        )}

        <Box sx={{ mt: 4 }}>
          <Grid2 container spacing={3}>
            {currentFeatures.map(feature => (
              <Grid2 key={feature.title} size={{ xs: 12, md: 4 }}>
                <Box
                  sx={{
                    p: 3,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: '0px 6px 25px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: 80,
                      height: 80,
                      mb: 2,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={feature.icon}
                      alt={feature.title}
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                      }}
                    />
                  </Box>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                  >
                    {feature.title}
                    {tab === 1 && feature.isSharedFeature && (
                      <Box
                        component="span"
                        sx={{
                          fontSize: '0.75rem',
                          py: 0.5,
                          px: 1,
                          borderRadius: '12px',
                          backgroundColor: theme => theme.palette.primary.lighter,
                          color: theme => theme.palette.primary.main,
                        }}
                      >
                        Included in Single Home
                      </Box>
                    )}
                  </Typography>
                  <Typography variant="body2">{feature.description}</Typography>
                </Box>
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </Box>
    </Grid2>
  );
};

export default FeaturesSection;
