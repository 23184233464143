import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { PropertyLocationModel } from '@/api/models/propertyLocation';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Float } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_GetAllAvailablePhoneNumbers_PropertyLocation = Array<{
  addressRequirements: string;
  beta: boolean;
  capabilities: {
    MMS?: boolean;
    SMS?: boolean;
    voice?: boolean;
    fax?: boolean;
  };
  friendlyName: string;
  isoCountry: string;
  lata: string;
  latitude: Float;
  locality: string;
  longitude: Float;
  phoneNumber: string;
  postalCode: string;
  rateCenter: string;
  region: string;
}>;

export type ApiRequest_GetAllAvailablePhoneNumbers_PropertyLocation = {
  contains: string;
};

const METHOD = 'GET';
const ENDPOINT = 'property-location/:id/search-phone-number';
export const QUERY_KEY = (
  id: PropertyLocationModel['id'],
  params: ApiRequest_GetAllAvailablePhoneNumbers_PropertyLocation
) => getQueryKey(METHOD, ENDPOINT, { id, ...params });

export const useGetAllAvailablePhoneNumbers = (
  id: PropertyLocationModel['id'],
  params: ApiRequest_GetAllAvailablePhoneNumbers_PropertyLocation,
  enabled = true
) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY(id, params),
    queryFn: async () => {
      try {
        const data = await handleApiCall<
          ApiResponse_GetAllAvailablePhoneNumbers_PropertyLocation,
          ApiRequest_GetAllAvailablePhoneNumbers_PropertyLocation
        >(session, METHOD, getResolvedEndpoint(ENDPOINT, { id: id.toString() }), params);

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch available phone numbers.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
