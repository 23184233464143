import { Stack } from '@mui/material';
import { useForm, UseFormReturn, FieldError } from 'react-hook-form';

import { OptionsFormData } from '@/types/lease';

import FormAutocomplete from '@/components/form/FormAutocomplete';
import FormSelect from '@/components/form/FormSelect';
import FormTextField from '@/components/form/FormTextField';
import ModalWithForm from '@/components/modals/ModalWithForm';

interface Props {
  initialData?: OptionsFormData;
  onSave: (data: OptionsFormData) => Promise<void>;
  onClose: () => void;
  disabled?: boolean;
  title?: string;
}

const BOOLEAN_OPTIONS = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const UTILITIES_OPTIONS = [
  { value: 'water', label: 'Water' },
  { value: 'electricity', label: 'Electricity' },
  { value: 'gas', label: 'Gas' },
  { value: 'internet', label: 'Internet' },
  { value: 'trash', label: 'Trash' },
  { value: 'other', label: 'Other' },
];

interface FormContentProps {
  form: UseFormReturn<OptionsFormData>;
  disabled?: boolean;
}

export const FormContent: React.FC<FormContentProps> = ({ form, disabled }) => {
  const petsAllowed = form.watch('pets_allowed');
  const smokingAllowed = form.watch('smoking_allowed');
  const parkingIncluded = form.watch('parking_included');
  const utilitiesIncluded = form.watch('utilities_included');

  return (
    <Stack spacing={3}>
      <FormSelect
        label="Pets Allowed"
        control={form.control}
        name="pets_allowed"
        options={BOOLEAN_OPTIONS}
        error={!!form.formState.errors.pets_allowed}
        helperText={(form.formState.errors.pets_allowed as FieldError)?.message}
        disabled={disabled}
      />

      {petsAllowed === 'true' && (
        <FormTextField
          label="Pet Restrictions"
          textFieldProps={{
            multiline: true,
            rows: 4,
          }}
          control={form.control}
          name="pet_restrictions"
          error={!!form.formState.errors.pet_restrictions}
          helperText={(form.formState.errors.pet_restrictions as FieldError)?.message}
          disabled={disabled}
        />
      )}

      <FormSelect
        label="Smoking Allowed"
        control={form.control}
        name="smoking_allowed"
        options={BOOLEAN_OPTIONS}
        error={!!form.formState.errors.smoking_allowed}
        helperText={(form.formState.errors.smoking_allowed as FieldError)?.message}
        disabled={disabled}
      />

      {smokingAllowed === 'true' && (
        <FormTextField
          label="Smoking Restrictions"
          textFieldProps={{
            multiline: true,
            rows: 4,
          }}
          control={form.control}
          name="smoking_restrictions"
          error={!!form.formState.errors.smoking_restrictions}
          helperText={(form.formState.errors.smoking_restrictions as FieldError)?.message}
          disabled={disabled}
        />
      )}

      <FormSelect
        label="Parking Included"
        control={form.control}
        name="parking_included"
        options={BOOLEAN_OPTIONS}
        error={!!form.formState.errors.parking_included}
        helperText={(form.formState.errors.parking_included as FieldError)?.message}
        disabled={disabled}
      />

      {parkingIncluded === 'true' && (
        <FormTextField
          label="Parking Details"
          textFieldProps={{
            multiline: true,
            rows: 4,
          }}
          control={form.control}
          name="parking_details"
          error={!!form.formState.errors.parking_details}
          helperText={(form.formState.errors.parking_details as FieldError)?.message}
          disabled={disabled}
        />
      )}

      <FormAutocomplete
        label="Utilities Included"
        control={form.control}
        name="utilities_included"
        options={UTILITIES_OPTIONS}
        multiple
        filterSelectedOptions
        error={!!form.formState.errors.utilities_included}
        helperText={(form.formState.errors.utilities_included as FieldError)?.message}
        disabled={disabled}
      />

      {utilitiesIncluded?.includes('other') && (
        <FormTextField
          label="Other Utilities"
          textFieldProps={{
            multiline: true,
            rows: 4,
          }}
          control={form.control}
          name="other_utilities"
          error={!!form.formState.errors.other_utilities}
          helperText={(form.formState.errors.other_utilities as FieldError)?.message}
          disabled={disabled}
        />
      )}
    </Stack>
  );
};

const OptionsForm: React.FC<Props> = ({
  initialData,
  onSave,
  onClose,
  disabled = false,
  title = 'Options',
}) => {
  const form = useForm<OptionsFormData>({
    defaultValues: initialData || {
      pets_allowed: 'false',
      pet_restrictions: '',
      smoking_allowed: 'false',
      smoking_restrictions: '',
      parking_included: 'false',
      parking_details: '',
      utilities_included: [],
      other_utilities: '',
    },
  });

  return (
    <ModalWithForm
      form={form}
      maxWidth="sm"
      title={title}
      onClose={onClose}
      onSubmit={onSave}
      buttonLabel="Save Options"
    >
      <FormContent form={form} disabled={disabled} />
    </ModalWithForm>
  );
};

export default OptionsForm;
