import { Box, Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

import BlurBackground from './BlurBackground';

const PricingHeroSection: React.FC = () => (
  <Grid2 size={12} sx={{ position: 'relative' }}>
    <BlurBackground
      sx={{
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />

    <Box sx={{ py: 8, textAlign: 'center' }}>
      <Stack spacing={3} sx={{ maxWidth: 800, margin: '0 auto' }}>
        <Stack spacing={1}>
          <Box>
            <Box
              sx={{
                py: 0.5,
                px: 1.5,
                borderRadius: '16px',
                border: '1px solid #D9D9D9',
                margin: '0 auto',
                display: 'inline-flex',
                height: '30px',
                alignItems: 'center',
              }}
            >
              <Typography variant="overline">Transparent Pricing</Typography>
            </Box>
          </Box>

          <Typography variant="h1">
            Simple Pricing for <strong>Every Property Owner</strong>
          </Typography>
        </Stack>

        <Typography variant="body1">
          Start your <strong>30-day free trial</strong> with full access to all features. No credit
          card required.
        </Typography>
      </Stack>
    </Box>
  </Grid2>
);

export default PricingHeroSection;
