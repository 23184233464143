import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

import FormField, { FormFieldProps, getControllerProps, getFormFieldProps } from './FormField';

interface Props extends FormFieldProps {
  controlLabel?: React.ReactNode | string;
}

const FormCheckbox = forwardRef<HTMLDivElement, Props>(({ color, controlLabel, ...props }, ref) => (
  <FormField {...getFormFieldProps(props)} ref={ref}>
    <Controller
      {...getControllerProps(props)}
      render={({ field }) => (
        <FormControlLabel
          {...field}
          control={
            <Checkbox
              disabled={props.disabled}
              color={color}
              checked={field.value}
              size={props.size}
            />
          }
          label={
            typeof controlLabel === 'string' ? (
              <Typography variant="body2">
                {controlLabel}
                {props.required && (
                  <Typography
                    component="span"
                    color="error"
                    sx={{ fontSize: 'inherit', ml: '0.2em' }}
                  >
                    *
                  </Typography>
                )}
              </Typography>
            ) : (
              <Typography variant="body2">
                {controlLabel}
                {props.required && (
                  <Typography
                    component="span"
                    color="error"
                    sx={{ fontSize: 'inherit', ml: '0.2em' }}
                  >
                    *
                  </Typography>
                )}
              </Typography>
            )
          }
        />
      )}
    />
  </FormField>
));

export default FormCheckbox;
