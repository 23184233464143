import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import {
  ApiRequest_SetAccountType_User,
  useSetAccountType,
} from '@/api/endpoints/user/useSetAccountType';
import { AccountType } from '@/api/enums/supabase';
import { OnboardingRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { getNextOnboardingStep } from '@/utils/onboarding';

import FormRadioGroup from '@/components/form/FormRadioGroup';

import SplitMuiPage from '../common/SplitMuiPage';

export interface RouteState {
  accountType?: AccountType;
  autoSubmit?: boolean;
}

const OnboardingAccountType: React.FC = () => {
  const hasAutosubmitted = useRef(false);
  const { user, session } = useAuth();
  const location = useLocation<RouteState>();
  const history = useHistory();

  const accountTypeFromState = useMemo(() => {
    if (!location.state?.accountType) return null;

    if (location.state.accountType === AccountType.property_manager)
      return AccountType.property_manager;

    if (location.state.accountType === AccountType.home_owner) return AccountType.home_owner;

    if (location.state.accountType === AccountType.resident) return AccountType.resident;

    return null;
  }, [location.state?.accountType]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ApiRequest_SetAccountType_User>({
    defaultValues: {
      account_type: accountTypeFromState ?? (user?.user_metadata.account_type as AccountType),
    },
  });

  const { onboardingProgress } = useOnboardingProgress();
  const [formError, setFormError] = useState<Error | null>(null);

  const setAccountTypeMutation = useSetAccountType({
    onError: error => {
      setFormError(error);
    },
    onSuccess: data => {
      const nextStep = getNextOnboardingStep(OnboardingRoutes.accountTypeSelect, {
        ...onboardingProgress,
        hasAccountType: true,
        accountType: data.user_metadata.account_type as AccountType,
      });

      history.replace(...nextStep);
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_SetAccountType_User) => setAccountTypeMutation.mutateAsync(data),
    [setAccountTypeMutation]
  );

  useEffect(() => {
    if (!accountTypeFromState) return;

    if (!location.state?.autoSubmit) return;

    if (!session?.access_token) return;

    if (hasAutosubmitted.current) return;

    hasAutosubmitted.current = true;

    setAccountTypeMutation.mutate({
      account_type: accountTypeFromState,
    });
  }, [
    session?.access_token,
    accountTypeFromState,
    location.state?.autoSubmit,
    setAccountTypeMutation,
  ]);

  return (
    <SplitMuiPage
      isLoading={!!accountTypeFromState && !!location.state?.autoSubmit}
      sideContent={
        <Stack flex="1 1 100%" sx={{ p: 2 }}>
          <Box
            component="img"
            src="assets/images/onboarding-account-type.png"
            sx={{ width: 510, height: 430, maxWidth: '100%', maxHeight: '100%', m: 'auto' }}
          />
        </Stack>
      }
    >
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={3} width="100%">
        <Stack spacing={1.5} mb={1}>
          <Typography variant="h3">Who are you?</Typography>

          <Typography variant="body2">
            Let us know why you’re using our groundbreaking technology.
          </Typography>
        </Stack>

        <Stack spacing={2.5}>
          <FormRadioGroup
            options={[
              {
                label: <Typography variant="h6">I'm a landlord</Typography>,
                value: AccountType.property_manager,
              },
              {
                label: <Typography variant="h6">I'm a home owner</Typography>,
                value: AccountType.home_owner,
              },
              {
                label: <Typography variant="h6">I'm a resident</Typography>,
                value: AccountType.resident,
              },
            ]}
            error={!!errors.account_type}
            helperText={errors.account_type?.message?.toString()}
            required
            {...register('account_type', {
              required: 'Account Type is required',
            })}
            control={control}
          />

          {!!formError && <FormHelperText error>{formError.message}</FormHelperText>}
        </Stack>

        <Stack spacing={1} mb={1}>
          <LoadingButton loading={isSubmitting} fullWidth variant="contained" type="submit">
            Next
          </LoadingButton>
        </Stack>
      </Stack>
    </SplitMuiPage>
  );
};

export default OnboardingAccountType;
