import { GlobalStyles, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { Editor, EditorProps, EditorProvider, Separator, Toolbar } from 'react-simple-wysiwyg';

import BtnBold from './buttons/BtnBold';
import BtnClearFormatting from './buttons/BtnClearFormatting';
import BtnItalic from './buttons/BtnItalic';
import BtnLeftIndent from './buttons/BtnLeftIndent';
import BtnRedo from './buttons/BtnRedo';
import BtnRightIndent from './buttons/BtnRightIndent';
import BtnStrikethrough from './buttons/BtnStrikethrough';
import BtnUnderline from './buttons/BtnUnderline';
import BtnUndo from './buttons/BtnUndo';
import BtnUnorderedList from './buttons/BtnUnorderedList';
import BtnUrl from './buttons/BtnUrl';

export const WysiwygEditor: React.FC<EditorProps> = ({ containerProps, ...props }) => {
  const theme = useTheme();

  const styles = useMemo(
    () => ({
      '.rws-editor': {
        border: `1px solid ${theme.palette.grey[300]} !important`,
        borderRadius: '8px !important',
      },
      '.rsw-toolbar': {
        backgroundColor: `${theme.palette.grey[200]} !important`,
        borderBottomColor: `${theme.palette.grey[300]} !important`,
        padding: '0.75rem 1.25rem',
        overflowX: 'auto',
        gap: '0.75rem',

        '.rsw-btn': {
          width: '2rem !important',
          height: '2rem !important',
          flex: '0 0 2rem !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '&:hover': {
            backgroundColor: `${theme.palette.grey[300]} !important`,
          },

          '&[data-active="true"]': {
            backgroundColor: `${theme.palette.grey[400]} !important`,
          },
        },

        '.rsw-separator': {
          border: 'none !important',
          margin: '0 0.5rem !important',
        },
      },
      '.rsw-ce': {
        padding: '12px 14px !important',
      },
    }),
    [theme]
  );

  return (
    <EditorProvider>
      <GlobalStyles styles={styles} />

      <Editor
        {...props}
        containerProps={{
          ...containerProps,
          style: {
            minHeight: '200px',
            ...containerProps?.style,
          },
        }}
      >
        <Toolbar>
          <BtnBold />
          <BtnItalic />
          <BtnUnderline />
          <BtnStrikethrough />

          <Separator />

          <BtnUnorderedList />
          <BtnLeftIndent />
          <BtnRightIndent />

          <Separator />

          <BtnUrl />

          <Separator />

          <BtnClearFormatting />

          <Separator />

          <BtnUndo />
          <BtnRedo />
        </Toolbar>
      </Editor>
    </EditorProvider>
  );
};
