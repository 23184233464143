import * as Sentry from '@sentry/react';
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  PropsWithChildren,
} from 'react';
import { SSEvent } from 'sse.js';

import { environment } from '@env';

import { TDocumentStatus } from '@/types/document';

import { useAuth } from '@/context/AuthProvider';
import { useSSE } from '@/hooks/useSse';

type DocumentStatusContextType = {
  documentStatuses: Record<string, TDocumentStatus>;
  documentProgress: Record<string, number>;
};

const DocumentStatusContext = createContext<DocumentStatusContextType>({
  documentStatuses: {},
  documentProgress: {},
});

export const useDocumentStatus = () => {
  const context = useContext(DocumentStatusContext);
  if (!context) {
    throw new Error('useDocumentStatus must be used within a DocumentStatusProvider');
  }
  return context;
};

export const DocumentStatusProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { session } = useAuth();
  const [documentStatuses, setDocumentStatuses] = useState<Record<string, TDocumentStatus>>({});
  const [documentProgress, setDocumentProgress] = useState<Record<string, number>>({});

  const handleMessage = useCallback((event: SSEvent) => {
    try {
      const data = JSON.parse(event.data);
      const { document_id, status, progress } = data;

      // Only update state if we have a document_id and either status or progress
      if (document_id && (status !== undefined || progress !== undefined)) {
        if (status !== undefined) {
          setDocumentStatuses(prev => ({
            ...prev,
            [document_id]: status,
          }));
        }
        if (progress !== undefined) {
          setDocumentProgress(prev => ({
            ...prev,
            [document_id]: progress,
          }));
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  useSSE({
    endpoint: `${environment.direct}/document/status-stream`,
    headers: {
      Authorization: `Bearer ${session?.access_token ?? ''}`,
    },
    onMessage: handleMessage,
    enabled: !!session?.access_token,
  });

  const value = useMemo(
    () => ({
      documentStatuses,
      documentProgress,
    }),
    [documentStatuses, documentProgress]
  );

  return <DocumentStatusContext.Provider value={value}>{children}</DocumentStatusContext.Provider>;
};
