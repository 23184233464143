import { Stack } from '@mui/material';
import { useForm, UseFormReturn, FieldError } from 'react-hook-form';

import { LeaseTermFormData } from '@/types/lease';

import FormDatePicker from '@/components/form/FormDatePicker';
import FormSelect from '@/components/form/FormSelect';
import FormTextField from '@/components/form/FormTextField';
import ModalWithForm from '@/components/modals/ModalWithForm';

interface Props {
  initialData?: LeaseTermFormData;
  onSave: (data: LeaseTermFormData) => Promise<void>;
  onClose: () => void;
  disabled?: boolean;
  title: string;
}

const RENEWAL_TYPE_OPTIONS = [
  { value: 'automatic', label: 'Automatic Renewal' },
  { value: 'manual', label: 'Manual Renewal' },
  { value: 'none', label: 'No Renewal' },
];

interface FormContentProps {
  form: UseFormReturn<LeaseTermFormData>;
  disabled?: boolean;
}

export const FormContent: React.FC<FormContentProps> = ({ form, disabled }) => {
  const renewalType = form.watch('renewal_type');

  return (
    <Stack spacing={3}>
      <FormDatePicker
        label="Start Date"
        control={form.control}
        name="start_date"
        error={!!form.formState.errors.start_date}
        helperText={(form.formState.errors.start_date as FieldError)?.message}
        disabled={disabled}
      />

      <FormDatePicker
        label="End Date"
        control={form.control}
        name="end_date"
        error={!!form.formState.errors.end_date}
        helperText={(form.formState.errors.end_date as FieldError)?.message}
        disabled={disabled}
      />

      <FormTextField
        label="Term Length (months)"
        textFieldProps={{
          type: 'number',
          inputProps: {
            min: 1,
          },
          inputMode: 'numeric',
        }}
        control={form.control}
        name="term_length"
        error={!!form.formState.errors.term_length}
        helperText={(form.formState.errors.term_length as FieldError)?.message}
        disabled={disabled}
      />

      <FormSelect
        label="Renewal Type"
        control={form.control}
        name="renewal_type"
        options={RENEWAL_TYPE_OPTIONS}
        error={!!form.formState.errors.renewal_type}
        helperText={(form.formState.errors.renewal_type as FieldError)?.message}
        disabled={disabled}
      />

      {renewalType !== 'none' && (
        <>
          <FormTextField
            label="Renewal Notice Period (days)"
            textFieldProps={{
              type: 'number',
              inputProps: {
                min: 0,
              },
              inputMode: 'numeric',
            }}
            control={form.control}
            name="renewal_notice_period"
            error={!!form.formState.errors.renewal_notice_period}
            helperText={(form.formState.errors.renewal_notice_period as FieldError)?.message}
            disabled={disabled}
          />

          <FormTextField
            label="Renewal Terms"
            textFieldProps={{
              multiline: true,
              rows: 4,
            }}
            control={form.control}
            name="renewal_terms"
            error={!!form.formState.errors.renewal_terms}
            helperText={(form.formState.errors.renewal_terms as FieldError)?.message}
            disabled={disabled}
          />
        </>
      )}
    </Stack>
  );
};

const LeaseTermForm: React.FC<Props> = ({
  initialData,
  onSave,
  onClose,
  disabled = false,
  title,
}) => {
  const form = useForm<LeaseTermFormData>({
    defaultValues: initialData || {
      start_date: '',
      end_date: '',
      term_length: 12,
      renewal_type: 'manual',
      renewal_notice_period: 60,
      renewal_terms: '',
    },
    shouldUnregister: false,
  });

  return (
    <ModalWithForm
      form={form}
      maxWidth="sm"
      title={title}
      onClose={onClose}
      onSubmit={onSave}
      buttonLabel="Save Lease Term"
    >
      <FormContent form={form} disabled={disabled} />
    </ModalWithForm>
  );
};

export default LeaseTermForm;
