import { Box, Button, Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

import { PublicRoutes } from '@/config/routes';

import BlurBackground from './BlurBackground';

const RentCollectionSection: React.FC = () => (
  <Grid2 size={12} sx={{ position: 'relative', py: 8 }}>
    <BlurBackground
      sx={{
        right: '25%',
        top: '50%',
        transform: 'translate(50%, -50%)',
      }}
    />

    <Box sx={{ maxWidth: 1200, margin: '0 auto' }}>
      <Grid2 container spacing={4} alignItems="center">
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack spacing={3} sx={{ p: 3 }}>
            <Box>
              <Box
                sx={{
                  py: 0.5,
                  px: 1.5,
                  borderRadius: '16px',
                  border: '1px solid #D9D9D9',
                  display: 'inline-flex',
                  height: '30px',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Typography variant="overline">Single Home Owners</Typography>
              </Box>
              <Typography variant="h3" gutterBottom>
                Never Chase <strong>Rent Payments</strong> Again
              </Typography>
            </Box>

            <Typography variant="body1">
              Our automated rent collection system takes the stress out of property management for
              single home owners. Set up once and collect rent on time, every time.
            </Typography>

            <Stack spacing={2}>
              {[
                'Automatic payment reminders sent to tenants',
                'Multiple payment methods supported',
                'Late fee calculation and enforcement',
                'Automatic receipt generation and record-keeping',
                'Real-time payment tracking and reporting',
              ].map(feature => (
                <Box key={feature} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      borderRadius: '50%',
                      backgroundColor: 'primary.main',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mr: 2,
                      mt: 0.5,
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    ✓
                  </Box>
                  <Typography variant="body2">{feature}</Typography>
                </Box>
              ))}
            </Stack>

            <Box sx={{ mt: 2 }}>
              <Button variant="contained" color="primary" size="large" href={PublicRoutes.signup}>
                Start Collecting Rent Effortlessly
              </Button>
            </Box>
          </Stack>
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }}>
          <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
            <img
              src="/assets/images/undraw_mobile_payments.svg"
              alt="Automated Rent Collection"
              style={{
                maxWidth: '100%',
                height: 'auto',
                maxHeight: '400px',
              }}
            />
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  </Grid2>
);

export default RentCollectionSection;
