import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { environment } from '@env';

import { VaultDocument, VaultDocumentTagFormData } from '@/types/vault';

import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY } from './useGetVaultDocuments';

const ENDPOINT = 'vault/documents';

export type ApiRequest_Upload_VaultDocument = {
  file: File;
  tags?: VaultDocumentTagFormData;
};

export type ApiResponse_Upload_VaultDocument = VaultDocument;

export const useUploadVaultDocument = (
  stateFns?: MutationStateFns<ApiResponse_Upload_VaultDocument>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ file, tags }: ApiRequest_Upload_VaultDocument) => {
      const formData = new FormData();
      formData.append('file', file);

      if (tags) {
        formData.append('tags', JSON.stringify(tags));
      }

      const response = await axios.post<ApiResponse_Upload_VaultDocument>(
        `${environment.api}/${ENDPOINT}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
    onSuccess: data => {
      enqueueSnackbar('Document uploaded successfully.', { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: QUERY_KEY() });
      stateFns?.onSuccess?.(data);
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar('Failed to upload document.', { variant: 'error' });
      stateFns?.onError?.(error);
    },
  });
};
