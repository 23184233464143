import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

interface TenantContact {
  email: string;
  phone: string;
}

export interface Tenant {
  id: number;
  full_name: string;
  user_id?: string;
  contacts: TenantContact[];
  unit_id: number;
  unit: {
    id: number;
    name: string;
  };
}

const METHOD = 'GET';
const ENDPOINT = 'tenant/location';
export const QUERY_KEY = (locationId: Id) => getQueryKey(METHOD, `${ENDPOINT}/${locationId}`, {});

export const useGetAllTenantsByLocation = (locationId: Id, options?: UseQueryOptions<Tenant[]>) => {
  const { session } = useAuth();

  return useQuery<Tenant[]>({
    queryKey: QUERY_KEY(locationId),
    queryFn: async () => {
      try {
        const data = await handleApiCall<Tenant[]>(
          session,
          METHOD,
          getResolvedEndpoint(`${ENDPOINT}/${locationId}`, {})
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch tenants for this location.', { variant: 'error' });
        // eslint-disable-next-line no-console
        console.error(err);
        throw err;
      }
    },
    enabled: !!locationId && !!session?.access_token,
    ...options,
  });
};
