import { Card, Link, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Mail01Icon } from '@/assets/icons/Mail01Icon';
import SplitMuiPage from '@/pages/common/SplitMuiPage';
import { supabase } from '@/utils/supabaseClient';

const VerifyEmailPage: React.FC = () => {
  const { email } = useParams<{ email: string }>();
  const [isEmailResent, setIsEmailResent] = useState(false);

  const onResendEmail = async () => {
    setIsEmailResent(true);

    await supabase.auth.resend({
      type: 'signup',
      email,
    });
  };

  return (
    <SplitMuiPage>
      <Stack spacing={4} textAlign="center">
        <Stack spacing={1.5}>
          <Card
            sx={{
              height: 56,
              width: 56,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
              mb: 1,
            }}
          >
            <Mail01Icon />
          </Card>

          <Typography variant="h3">Check your email</Typography>

          <Typography variant="body2">
            We sent a verification link to <strong>{email}</strong>.
          </Typography>
        </Stack>

        {isEmailResent ? (
          <Typography component="span" variant="body3" color="primary">
            Email resent
          </Typography>
        ) : (
          <Typography variant="body3">
            Didn’t receive the email?
            <br />
            Check your spam folder or {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link component="button" onClick={onResendEmail}>
              click to resend
            </Link>
          </Typography>
        )}
      </Stack>
    </SplitMuiPage>
  );
};

export default VerifyEmailPage;
