/**
 * Formats price in cents to a display-friendly dollar amount
 * @param cents Price in cents
 * @param currencySymbol Currency symbol to use (defaults to $)
 * @returns Formatted price string
 */
export const formatPaddlePrice = (cents: string | number, currencySymbol = '$'): string => {
  // Convert to number if string
  const amount = typeof cents === 'string' ? parseFloat(cents) : cents;
  
  // Convert cents to dollars and format with 2 decimal places
  const dollars = (amount / 100).toFixed(2);
  
  return `${currencySymbol}${dollars}`;
};
