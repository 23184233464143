import { ExtractParams } from '@/types/params';

export const getResolvedEndpoint = <T extends string>(route: T, params: ExtractParams<T>) => {
  let resolvedRoute: string = route;

  Object.entries(params).forEach(([key, value]) => {
    resolvedRoute = resolvedRoute.replace(`/:${key}`, `/${value}`);
  });

  return resolvedRoute;
};
