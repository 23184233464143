import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { ConversationMessageModel } from '@/api/models/conversation';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY as MESSAGES_QUERY_KEY } from './useGetConversationThread';

export type ApiRequest_Send_ConversationMessage = {
  conversationId: string;
  content: string;
};

type RequestBody = Pick<ApiRequest_Send_ConversationMessage, 'content'>;

export type ApiResponse_Send_ConversationMessage = ConversationMessageModel;

const METHOD = 'POST';
const ENDPOINT = 'conversation/:id/send';

export const useSendConversationMessage = (
  stateFns?: MutationStateFns<ApiResponse_Send_ConversationMessage>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_Send_ConversationMessage) =>
      handleApiCall<ApiResponse_Send_ConversationMessage, RequestBody>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: data.conversationId }),
        { content: data.content }
      ),
    onSuccess: (data, variables) => {
      enqueueSnackbar('Message sent successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: MESSAGES_QUERY_KEY({ conversation_sid: variables.conversationId }),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to send message', { variant: 'error' });
      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
