import { createButton } from 'react-simple-wysiwyg';

import { Link01Icon } from '@/assets/icons/Link01Icon';

export default createButton('Link', <Link01Icon />, ({ $selection }) => {
  if ($selection?.nodeName === 'A') {
    document.execCommand('unlink');
  } else {
    // eslint-disable-next-line no-alert
    document.execCommand('createLink', false, prompt('URL', '') || undefined);
  }
});
