import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_Archive_VendorConversation = {
  conversation_sid: string;
};

export type ApiResponse_Archive_VendorConversation = never;

const METHOD = 'POST';
const ENDPOINT = 'conversation/vendor/:conversation_sid/archive';

export const useArchiveVendorConversation = (
  stateFns?: MutationStateFns<ApiResponse_Archive_VendorConversation>
) => {
  const { session } = useAuth();

  return useMutation<
    ApiResponse_Archive_VendorConversation,
    AxiosError,
    ApiRequest_Archive_VendorConversation
  >({
    mutationFn: async (data: ApiRequest_Archive_VendorConversation) =>
      handleApiCall<ApiResponse_Archive_VendorConversation, ApiRequest_Archive_VendorConversation>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, data)
      ),
    onSuccess: (data, variables) => {
      enqueueSnackbar('Conversation archived successfully.', { variant: 'success' });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to archive conversation.', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
