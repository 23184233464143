import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { BuildingModelWithLocationAndCounts } from '@/api/models/building';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { Id } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY } from './useGetAllBuildings';

export type ApiResponse_Create_Building = BuildingModelWithLocationAndCounts;

export type ApiRequest_Create_Building = {
  name: string;
  address_text: string;
  property_location_id: Id;
};

const METHOD = 'POST';
const ENDPOINT = 'building';

export const useCreateBuilding = (stateFns?: MutationStateFns<ApiResponse_Create_Building>) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_Create_Building) =>
      handleApiCall<ApiResponse_Create_Building, ApiRequest_Create_Building>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Building created successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY({
          location_id: data.property_location_id,
        }),
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY(),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to create building', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
