import { MenuItem, Select, SelectProps } from '@mui/material';
import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

import FormField, { FormFieldProps, getControllerProps, getFormFieldProps } from './FormField';

interface Option {
  label: React.ReactNode;
  value: string | number;
}

export interface Props extends FormFieldProps {
  options: Option[];
  placeholder?: string;
  muiSelectProps?: Omit<SelectProps, 'slotProps'>;
  slotProps?: SelectProps['slotProps'];
}

const FormSelect = forwardRef<HTMLDivElement, Props>(
  (
    {
      color = 'secondary',
      options,
      placeholder = 'Select value',
      muiSelectProps,
      slotProps,
      ...props
    },
    ref
  ) => (
    <FormField {...getFormFieldProps(props)} ref={ref}>
      <Controller
        {...getControllerProps(props)}
        render={({ field }) => (
          <Select
            {...field}
            color={color}
            size={props.size}
            disabled={props.disabled}
            sx={{
              '& .MuiSelect-select': {
                padding: '10px 14px',
              },
              '& .MuiSvgIcon-root': {
                color: 'text.primary',
              },
              '&:focus, &.Mui-focused': {
                outline: 'none !important',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'grey.300',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                borderWidth: 1,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
              },
              ...props.sx,
            }}
            MenuProps={{
              PaperProps: {
                elevation: 3,
                sx: {
                  mt: 1,
                  borderRadius: 1,
                  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                  '& .MuiList-root': {
                    padding: '8px 0',
                  },
                },
              },
              ...muiSelectProps?.MenuProps,
            }}
            slotProps={slotProps}
            {...muiSelectProps}
          >
            <MenuItem value={undefined} disabled>
              {placeholder}
            </MenuItem>

            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormField>
  )
);

export default FormSelect;
