import { Box, List, ListItem, Stack, SxProps } from '@mui/material';

import { TMessageThread } from '@/types/message';

import IconButton from '@/@mantis/components/@extended/IconButton';
import MainCard from '@/@mantis/components/MainCard';
import { Menu01Icon } from '@/assets/icons/Menu01Icon';
import { Menu02Icon } from '@/assets/icons/Menu02Icon';

import S from './MessageChatContent.styles';
import MessageChatInput from './MessageChatInput';
import MessageSessionListItemContent from './MessageSessionListItemContent';
import MessageThreadMessageList from './MessageThreadMessageList';

interface Props {
  messageThread: TMessageThread | null;
  isDrawerOpen?: boolean;
  isLoading?: boolean;
  isFailed?: boolean;
  onGenerateAIResponse?: (messageThread: TMessageThread) => Promise<string | undefined>;
  onMessageSend: (message: string, messageThread: TMessageThread) => void;
  toggleIsDrawerOpen?: () => void;
  sx?: SxProps;
}

const MessageChatContent: React.FC<Props> = ({
  messageThread,
  isDrawerOpen = false,
  isLoading = false,
  isFailed = false,
  onGenerateAIResponse,
  onMessageSend,
  toggleIsDrawerOpen,
  sx,
}) => {
  const isInputHidden = !messageThread || isLoading || isFailed;

  return (
    <S.Main isDrawerOpen={isDrawerOpen} sx={sx}>
      <MainCard
        content={false}
        sx={{
          bgcolor: 'white',
          borderRadius: '0 12px 12px 0',
          borderColor: 'divider',
          flex: '1 1 100%',
          display: 'flex',
        }}
      >
        <Stack direction="column" sx={{ flex: '1 1 100%' }}>
          <Box
            sx={{
              bgcolor: 'white',
              p: 2,
              borderBottom: '1px solid',
              borderBottomColor: 'divider',
              flex: '0 0 auto',
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              {!!toggleIsDrawerOpen && (
                <IconButton onClick={toggleIsDrawerOpen} color="secondary" size="large">
                  {isDrawerOpen ? <Menu02Icon /> : <Menu01Icon />}
                </IconButton>
              )}

              <List disablePadding>
                <ListItem disablePadding disableGutters>
                  <MessageSessionListItemContent messageSession={messageThread?.session ?? null} />
                </ListItem>
              </List>
            </Stack>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flex: '0 1 100%',
              overflow: 'hidden',
              maxHeight: isInputHidden ? 'calc(100dvh - 390px)' : 'calc(100dvh - 544px)',
            }}
          >
            <MessageThreadMessageList
              messageThread={messageThread}
              isLoading={isLoading}
              isFailed={isFailed}
            />
          </Box>

          {!isInputHidden && (
            <Box
              sx={{
                p: 2,
                bgcolor: 'background.paper',
                borderTop: '1px solid',
                borderTopColor: 'divider',
                flex: '0 0 auto',
                marginTop: 'auto',
              }}
            >
              <MessageChatInput
                messageThread={messageThread}
                onGenerateAIResponse={onGenerateAIResponse}
                onMessageSend={onMessageSend}
              />
            </Box>
          )}
        </Stack>
      </MainCard>
    </S.Main>
  );
};

export default MessageChatContent;
