import { useMemo } from 'react';

import { MessageStatus } from '@/api/enums';
import { PropertyLocationWithEmailAndPhoneModel } from '@/api/models/propertyLocation';
import {
  getInboundMessageItemFromEmail,
  getInboundMessageItemFromSms,
} from '@/utils/inboundMessage';

import { useGetAllEmails } from '../email/useGetAllEmails';
import { useGetAllSms } from '../twilio/useGetAllSms';

export const useGetAllMessages = (
  selectedPropertyLocation: PropertyLocationWithEmailAndPhoneModel
) => {
  const getAllEmailsQuery = useGetAllEmails({
    locationId: selectedPropertyLocation?.id,
    email: selectedPropertyLocation?.email?.value,
  });

  const getAllSmsQuery = useGetAllSms({
    locationId: selectedPropertyLocation?.id,
    phone: selectedPropertyLocation?.phone?.value,
  });

  const sortedMessages = useMemo(
    () =>
      [
        ...(getAllEmailsQuery.data ?? []).map(getInboundMessageItemFromEmail),
        ...(getAllSmsQuery.data ?? []).map(getInboundMessageItemFromSms),
      ].sort(
        (a, b) =>
          (b.updated_at ? new Date(b.updated_at) : new Date()).getTime() -
          (a.updated_at ? new Date(a.updated_at) : new Date()).getTime()
      ),
    [getAllEmailsQuery.data, getAllSmsQuery.data]
  );

  return useMemo(
    () => ({
      needsFirstResponse: sortedMessages.filter(
        message => message.status === MessageStatus.needs_response
      ),
      investigating: sortedMessages.filter(
        message => message.status === MessageStatus.investigating
      ),
      waitingForCustomer: sortedMessages.filter(
        message => message.status === MessageStatus.waiting_for_customer
      ),
      done: sortedMessages.filter(message => message.status === MessageStatus.done),

      isLoading: getAllEmailsQuery.isLoading || getAllSmsQuery.isLoading,
      isSuccess: getAllEmailsQuery.isSuccess && getAllSmsQuery.isSuccess,
      isError: getAllEmailsQuery.isError || getAllSmsQuery.isError,

      refetch: async () => Promise.all([getAllEmailsQuery.refetch(), getAllSmsQuery.refetch()]),
    }),
    [sortedMessages, getAllEmailsQuery, getAllSmsQuery]
  );
};
