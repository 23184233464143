import { Button, IconButton, Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { useFieldArray, useForm, UseFormReturn, FieldError } from 'react-hook-form';

import { MinusIcon } from '@/assets/icons/MinusIcon';
import { PlusIcon } from '@/assets/icons/PlusIcon';

import FormTextField from '@/components/form/FormTextField';
import ModalWithForm from '@/components/modals/ModalWithForm';

interface CustomRule {
  description: string;
}

interface RulesFormData {
  occupancy_limits: number;
  guest_policy: string;
  property_use_restrictions: string;
  maintenance_requirements: string;
  custom_rules: CustomRule[];
}

interface Props {
  initialData?: RulesFormData;
  onSave: (data: RulesFormData) => void;
  onClose: () => void;
  disabled?: boolean;
}

interface FormContentProps {
  form: UseFormReturn<RulesFormData>;
  disabled?: boolean;
}

const defaultRules: CustomRule[] = [
  {
    description:
      "No additional locks or similar devices shall be attached to any door without Lessor's written consent.",
  },
  {
    description:
      'Lessee shall not install a waterbed, pool table, or any other unusually heavy item of furniture without prior written permission from Lessor.',
  },
  {
    description:
      'Lessee shall not install or operate any machinery, refrigeration, or heating devices or use or permit onto the Premises any flammable fluids or materials which may be hazardous to life or property.',
  },
  {
    description:
      'Hallways, stairways, and elevators shall not be obstructed or used for any purpose other than ingress and egress from the building. Children are not permitted to play in the common areas.',
  },
  {
    description:
      'Operation of electrical appliances or other devices that interfere with radio or television reception is not permitted.',
  },
  {
    description:
      'Deliveries and moving of furniture must be conducted at times permitted by Lessor.',
  },
  {
    description: 'Lessee may not barbeque or operate cooking equipment on porches or balconies.',
  },
  {
    description:
      'Lessee shall not dispose of rubbish, rags, or other items that might clog toilets or sink drains.',
  },
  {
    description:
      'Lessee shall not place any signs or advertisements on the windows, within the property, or otherwise upon the Building if such signs are visible from the street.',
  },
  {
    description:
      'Lessee shall securely bag and dispose of garbage in designated garbage containers, ensuring lids are fully closed.',
  },
  {
    description:
      'Lessee shall not interfere in any manner with the heating, lighting, or other fixtures in the building nor run extension cords in violation of the Building Code.',
  },
  {
    description:
      "Lessor may bar individuals from the building or Lessee's Premises. Guests must observe all rules and regulations.",
  },
  {
    description:
      'Lessor will repair any appliance malfunction; however, Lessor is not responsible for any loss resulting from a malfunction.',
  },
  {
    description: '$50 per key will be charged to provide additional or replacement keys.',
  },
  {
    description:
      'If Lessee is locked out, Lessor will facilitate re-entry only during business hours for a minimal fee. Outside of business hours, Lessee must contact a locksmith at their expense.',
  },
  {
    description:
      "Lessee must secure renter's insurance. Lessor is not responsible for any damage to Lessee's personal property.",
  },
  {
    description: "Lessor provides no storage for Lessee's belongings.",
  },
  {
    description:
      'Lessee agrees to clean any lint filters and keep the laundry area free of debris.',
  },
  {
    description:
      'The dwelling has been designated as a smoke-free living environment. Lessee and their guests shall not smoke anywhere in the building.',
  },
  {
    description:
      'Lessee shall not place anything on the outside of the Property, on any window, or in any window sill.',
  },
  {
    description: 'Only Lessees and their guests are permitted to use the swimming pool.',
  },
  {
    description:
      'Lessor reserves the right to prescribe the weight and position of all safes and other heavy equipment.',
  },
  {
    description:
      'Lessee acknowledges that they have read and agree to be bound by the Rules and Regulations.',
  },
  {
    description:
      'Lessee shall be designated one parking spot for a fee of $100/month, which has been added to rent.',
  },
  {
    description:
      'Permission is granted to house pets at an additional one-time, non-refundable $200.00 fee.',
  },
  {
    description:
      'The unit is not to be used for short-term rentals, including Airbnb. Violation will be grounds for lease termination.',
  },
];

export const FormContent: React.FC<FormContentProps> = ({ form, disabled }) => {
  const { control } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'custom_rules',
  });

  return (
    <Stack spacing={3}>
      <FormTextField
        label="Occupancy Limits"
        textFieldProps={{
          type: 'number',
        }}
        control={control}
        name="occupancy_limits"
        disabled={disabled}
        required
        error={!!form.formState.errors.occupancy_limits}
        helperText={(form.formState.errors.occupancy_limits as FieldError)?.message}
      />

      <FormTextField
        label="Guest Policy"
        control={control}
        name="guest_policy"
        disabled={disabled}
        multiline
        rows={3}
        error={!!form.formState.errors.guest_policy}
        helperText={(form.formState.errors.guest_policy as FieldError)?.message}
      />

      <FormTextField
        label="Property Use Restrictions"
        control={control}
        name="property_use_restrictions"
        disabled={disabled}
        multiline
        rows={3}
        error={!!form.formState.errors.property_use_restrictions}
        helperText={(form.formState.errors.property_use_restrictions as FieldError)?.message}
      />

      <FormTextField
        label="Maintenance Requirements"
        control={control}
        name="maintenance_requirements"
        disabled={disabled}
        multiline
        rows={3}
        error={!!form.formState.errors.maintenance_requirements}
        helperText={(form.formState.errors.maintenance_requirements as FieldError)?.message}
      />

      <Stack spacing={2}>
        {fields.map((field, index) => (
          <Stack key={field.id} direction="row" spacing={2} alignItems="flex-start">
            <FormTextField
              label="Rule"
              textFieldProps={{
                multiline: true,
                rows: 4,
                fullWidth: true,
              }}
              control={control}
              name={`custom_rules.${index}.description`}
              disabled={disabled}
              error={!!form.formState.errors.custom_rules?.[index]?.description}
              helperText={
                (form.formState.errors.custom_rules?.[index]?.description as FieldError)?.message
              }
            />

            {!disabled && (
              <IconButton onClick={() => remove(index)} size="small">
                <MinusIcon />
              </IconButton>
            )}
          </Stack>
        ))}

        {!disabled && (
          <Button
            startIcon={<PlusIcon />}
            onClick={() =>
              append({
                description: '',
              })
            }
            variant="outlined"
            color="secondary"
          >
            Add Custom Rule
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

const RulesForm: React.FC<Props> = ({ initialData, onSave, onClose, disabled = false }) => {
  const form = useForm<RulesFormData>({
    defaultValues: initialData || {
      occupancy_limits: 2,
      guest_policy: '',
      property_use_restrictions: '',
      maintenance_requirements: '',
      custom_rules: defaultRules,
    },
  });

  const onSubmit = useCallback(
    async (data: RulesFormData) => {
      onSave(data);
      onClose();
    },
    [onClose, onSave]
  );

  return (
    <ModalWithForm
      title="Property Rules"
      onClose={onClose}
      onSubmit={onSubmit}
      form={form}
      maxWidth="md"
    >
      <FormContent form={form} disabled={disabled} />
    </ModalWithForm>
  );
};

export default RulesForm;
