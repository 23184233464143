import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useContext, useCallback, PropsWithChildren } from 'react';
import { SSEvent } from 'sse.js';

import { environment } from '@env';

import { useAuth } from '@/context/AuthProvider';
import { useSSE } from '@/hooks/useSse';

// type MessageType = 'email' | 'sms';

const MessageNotificationContext = createContext<null>(null);

export const useMessageNotification = () => {
  const context = useContext(MessageNotificationContext);
  if (!context) {
    throw new Error('useMessageNotification must be used within MessageNotificationProvider');
  }
  return context;
};

export const MessageNotificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { session, isPropertyManager } = useAuth();
  const queryClient = useQueryClient();

  const handleMessage = useCallback(
    (event: SSEvent) => {
      try {
        const data = JSON.parse(event.data);

        if (data.status === 'connected') {
          return;
        }

        // Based on message type, refetch relevant queries
        queryClient.invalidateQueries({
          queryKey: ['GET', 'dashboard', 'stats'],
          refetchType: 'active',
        });
        // Refetch dashboard stats and email list
        queryClient.invalidateQueries({
          queryKey: ['GET', data.type, 'inbound'], // data.type is 'email' or 'sms'
          refetchType: 'active',
        });

        // we can choose to be more granular and update specific data
        // using queryClient.setQueryData() if the SSE provides full message details
      } catch (error) {
        Sentry.captureException(error, {});
      }
    },
    [queryClient]
  );

  useSSE({
    endpoint: `${environment.direct}/msg/notify-stream`,
    headers: {
      Authorization: `Bearer ${session?.access_token ?? ''}`,
    },
    onMessage: handleMessage,
    enabled: !!session?.access_token && isPropertyManager,
  });

  return (
    <MessageNotificationContext.Provider value={null}>
      {children}
    </MessageNotificationContext.Provider>
  );
};
