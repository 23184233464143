import { Stack } from '@mui/material';

import CircularWithPath from '@/@mantis/components/@extended/progress/CircularWithPath';
import MuiPageWrapper from '@/@mui/MuiPageWrapper';

const LoadingStateWithMuiPageWrapper: React.FC = () => (
  <MuiPageWrapper>
    <Stack display="flex" justifyContent="center" alignItems="center" flex="1 1 100%">
      <CircularWithPath />
    </Stack>
  </MuiPageWrapper>
);

export default LoadingStateWithMuiPageWrapper;
