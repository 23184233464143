import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { ApiResponse_Get_Resident, useGetResident } from '@/api/endpoints/resident/useGetResident';

import LoadingStateWithMuiPageWrapper from '@/components/LoadingStateWithMuiPageWrapper';

import { useAuth } from './AuthProvider';

type ResidentContextType = {
  isFetching: boolean;
  tenant: ApiResponse_Get_Resident | null;
};

const ResidentContext = createContext<ResidentContextType | undefined>(undefined);

const ResidentProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isResident } = useAuth();

  const getResidentQuery = useGetResident(isResident);

  const { isFetching, data } = getResidentQuery;

  const value = useMemo(
    () => ({
      isFetching,
      tenant: data ?? null,
    }),
    [isFetching, data]
  );

  const shouldShowLoadingState = useMemo(() => isFetching && isResident, [isFetching, isResident]);

  return (
    <ResidentContext.Provider value={value}>
      {shouldShowLoadingState ? <LoadingStateWithMuiPageWrapper /> : children}
    </ResidentContext.Provider>
  );
};

export const useResident = () => {
  const context = useContext(ResidentContext);

  if (context === undefined) {
    throw new Error('useResident must be used within a ResidentProvider');
  }

  return context;
};

export default ResidentProvider;
