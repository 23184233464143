import { Grid2, Stack, Typography } from '@mui/material';
import React from 'react';

import BlurBackground from './BlurBackground';
import PricingPlan from './pricing/PricingPlan';

const FEATURES = [
  { text: 'AI-Powered Communications', included: true },
  { text: 'Automated Rent Collection', included: true },
  { text: 'Maintenance Management', included: true },
  { text: 'Portfolio Dashboard', included: true },
  { text: 'Document Vault', included: true },
  { text: 'Advanced Workflows', included: true },
  { text: 'Email & Phone Support', included: true },
];

const PricingPlansSection: React.FC = () => (
  <Grid2 size={12} sx={{ position: 'relative', py: 6 }}>
    <BlurBackground
      sx={{
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />

    <Grid2 container spacing={6}>
      <Grid2 size={12}>
        <Stack spacing={2.5} sx={{ textAlign: 'center' }}>
          <Typography variant="h3">
            Choose the <strong>Perfect Plan</strong> for Your Needs
          </Typography>
          <Typography variant="body1">Get started with our risk-free 14-day trial</Typography>
        </Stack>
      </Grid2>

      <Grid2 size={{ xs: 12, md: 5 }} offset={{ xs: 0, md: 1 }}>
        <PricingPlan
          name="FREE TRIAL"
          title="30 Days"
          titleDescription="Full access to all features"
          price="$0"
          priceDescription="No credit card required"
          buttonLabel="Start Free Trial"
          href="/"
          features={FEATURES}
        />
      </Grid2>

      <Grid2 size={{ xs: 12, md: 5 }}>
        <PricingPlan
          name="STANDARD"
          title="Monthly"
          titleDescription="Billed monthly"
          price="$9.99"
          priceDescription="per month"
          buttonLabel="Get Started"
          href="/"
          features={FEATURES}
          isPrimary
        />
      </Grid2>
    </Grid2>
  </Grid2>
);

export default PricingPlansSection;
