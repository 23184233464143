import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import {
  PropertyLocationModel,
  PropertyLocationWithEmailAndPhoneModel,
} from '@/api/models/propertyLocation';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

import { QUERY_KEY as GET_ALL_PROPERTY_LOCATIONS_QUERY_KEY } from './useGetAllPropertyLocations';
import { QUERY_KEY as GET_ONE_PROPERTY_LOCATIONS_QUERY_KEY } from './useGetOnePropertyLocation';

export type ApiResponse_AssignPhoneNumber_PropertyLocation = PropertyLocationWithEmailAndPhoneModel;

export type ApiRequest_AssignPhoneNumber_PropertyLocation = {
  phoneNumber: string;
};

const METHOD = 'POST';
const ENDPOINT = 'property-location/:id/phone-number';

export const useAssignPhoneNumber = (
  id: PropertyLocationModel['id'],
  stateFns?: MutationStateFns<ApiResponse_AssignPhoneNumber_PropertyLocation>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_AssignPhoneNumber_PropertyLocation) =>
      handleApiCall<
        ApiResponse_AssignPhoneNumber_PropertyLocation,
        ApiRequest_AssignPhoneNumber_PropertyLocation
      >(session, METHOD, getResolvedEndpoint(ENDPOINT, { id: id.toString() }), data),
    onSuccess: data => {
      enqueueSnackbar('Phone number assigned successfully', { variant: 'success' });
      queryClient.invalidateQueries({
        queryKey: GET_ALL_PROPERTY_LOCATIONS_QUERY_KEY,
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: GET_ONE_PROPERTY_LOCATIONS_QUERY_KEY(id),
        refetchType: 'all',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to assign phone number', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
