import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { BuildingModel } from '@/api/models/building';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_SendBulkEmail_Building = never;

export type ApiRequest_SendBulkEmail_Building = {
  subject: string;
  body: string;
  recipients: string[];
  attachments: FileList | null;
};

const METHOD = 'POST_FORM';
const ENDPOINT = 'building/:id/send-bulk-email';

export const useSendBulkEmail = (
  buildingId: BuildingModel['id'],
  stateFns?: MutationStateFns<ApiResponse_SendBulkEmail_Building>
) => {
  const { session } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_SendBulkEmail_Building) =>
      handleApiCall<ApiResponse_SendBulkEmail_Building, ApiRequest_SendBulkEmail_Building>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: buildingId.toString() }),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Bulk email sent successfully.', { variant: 'success' });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to send bulk email.', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
