import { Autocomplete, TextField } from '@mui/material';
import { forwardRef, useState } from 'react';
import { Controller } from 'react-hook-form';

import FormField, { FormFieldProps, getControllerProps, getFormFieldProps } from './FormField';

export interface Option {
  label: string;
  value: string | number;
}

interface Props extends FormFieldProps {
  multiple?: boolean;
  placeholder?: string;
  options: Option[];
  value?: string | number | string[] | number[];
  filterSelectedOptions?: boolean;
}

const FormAutocomplete = forwardRef<HTMLDivElement, Props>(
  (
    {
      color = 'secondary',
      placeholder = 'Search…',
      options,
      multiple = false,
      filterSelectedOptions,
      ...props
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <FormField {...getFormFieldProps(props)} ref={ref}>
        <Controller
          {...getControllerProps(props)}
          render={({ field }) => (
            <Autocomplete
              disabled={props.disabled}
              open={isOpen}
              multiple={multiple}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              onChange={(e, data) => field.onChange(data)}
              isOptionEqualToValue={(option, _value) => option.value === _value?.value}
              getOptionLabel={option => option.label}
              options={options}
              filterSelectedOptions={filterSelectedOptions}
              renderInput={params => (
                <TextField {...params} size={props.size} placeholder={placeholder} />
              )}
            />
          )}
        />
      </FormField>
    );
  }
);

export default FormAutocomplete;
