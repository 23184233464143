import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { DateTime, Id, Uuid } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_CreateGroup_Conversation = {
  conversation: {
    accountSid: string;
    chatServiceSid: string;
    messagingServiceSid: string;
    sid: string;
    friendlyName: string;
    uniqueName: string;
    attributes: string;
    state: 'inactive' | 'active' | 'closed';
    dateCreated: DateTime;
    dateUpdated: DateTime;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    timers: any;
    url: string;
    links: Record<string, string>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bindings: any;
  };
  participantResults: Array<{
    phone: string;
    participantSid: Uuid;
  }>;
};

export type ApiRequest_CreateGroup_Conversation = {
  name: string;
  friendlyName: string;
  propertyPhone: string;
  participants: Array<{
    phoneNumber: string;
    managerId?: Id;
  }>;
};

const METHOD = 'POST';
const ENDPOINT = 'group';

export const useCreateGroupConversation = (
  stateFns?: MutationStateFns<ApiResponse_CreateGroup_Conversation>
) => {
  const { session } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_CreateGroup_Conversation) =>
      handleApiCall<ApiResponse_CreateGroup_Conversation, ApiRequest_CreateGroup_Conversation>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Group conversation created successfully', { variant: 'success' });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to create group conversation', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
