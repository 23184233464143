import { FormHelperText, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import {
  ApiRequest_UpdatePassword_User,
  useUpdatePassword,
} from '@/api/endpoints/user/useUpdatePassword';

import FormTextField from '@/components/form/FormTextField';

type FormData = ApiRequest_UpdatePassword_User & {
  confirm_password: string;
};

const PasswordForm: React.FC = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();

  const [formError, setFormError] = useState<Error | null>(null);

  const updatePasswordMutation = useUpdatePassword({
    onError: error => {
      setFormError(error);
    },
  });

  const onSubmit = useCallback(
    (data: FormData) => {
      setFormError(null);
      return updatePasswordMutation.mutateAsync(data);
    },
    [updatePasswordMutation]
  );

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <FormTextField
        size="small"
        textFieldProps={{
          type: 'password',
        }}
        label="New Password"
        placeholder="Enter your new password"
        error={!!errors.password}
        helperText={errors.password?.message?.toString()}
        control={control}
        required
        {...register('password', {
          required: 'New Password is required',
          minLength: {
            value: 8,
            message: 'Password must be at least 8 characters',
          },
        })}
      />

      <FormTextField
        size="small"
        textFieldProps={{
          type: 'password',
        }}
        label="Confirm New Password"
        placeholder="Confirm your new password"
        error={!!errors.confirm_password}
        helperText={errors.confirm_password?.message?.toString()}
        control={control}
        required
        {...register('confirm_password', {
          required: 'Confirm New Password is required',
          validate: (value, data) => value === data.password || 'Passwords do not match',
        })}
      />

      {!!formError && <FormHelperText error>{formError.message}</FormHelperText>}

      <LoadingButton
        loading={isSubmitting}
        fullWidth
        variant="contained"
        type="submit"
        size="small"
      >
        Update Password
      </LoadingButton>
    </Stack>
  );
};

export default PasswordForm;
