export enum ContextSource {
  EMAIL = 'email',
  SMS = 'sms',
}

export type TKnowledgeItem = string;

export interface TKnowledgeRequest {
  context_source: ContextSource[];
  start_date?: string;
  end_date?: string;
}
