import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { QuestionAnswerModel } from '@/api/models/ai/questionAnswer';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_Delete_QuestionAnswer = never;

const METHOD = 'DELETE';
const ENDPOINT = 'question/:id';

export const useDeleteQuestionAnswer = (
  id: QuestionAnswerModel['id'],
  stateFns?: MutationStateFns<ApiResponse_Delete_QuestionAnswer>
) => {
  const { session } = useAuth();

  return useMutation({
    mutationFn: async () =>
      handleApiCall<ApiResponse_Delete_QuestionAnswer>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: id.toString() })
      ),
    onSuccess: data => {
      enqueueSnackbar('Question & answer deleted successfully', {
        variant: 'success',
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to delete question & answer', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
