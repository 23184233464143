import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { PropertyManagementRole } from '@/api/enums';
import { PropertyManagementModel } from '@/api/models/propertyManagement';
import { PropertyManagerModel } from '@/api/models/propertyManager';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';
import { sanitizeBoolean } from '@/utils/form';

import { QUERY_KEY as GET_ALL_QUERY_KEY } from './useGetAllPropertyManagers';

export type ApiResponse_Update_PropertyManager = PropertyManagerModel & {
  locations: PropertyManagementModel[];
};

export type ApiRequest_Update_PropertyManager = {
  role: keyof typeof PropertyManagementRole;
  phone?: string;
  full_name: string;
  is_emergency_contact?: boolean;
};

const METHOD = 'PUT';
const ENDPOINT = 'property-manager/:id';

const sanitizeData = (data: ApiRequest_Update_PropertyManager) => ({
  ...data,
  is_emergency_contact: sanitizeBoolean(data.is_emergency_contact),
});

export const useUpdatePropertyManager = (
  id: PropertyManagerModel['id'],
  stateFns?: MutationStateFns<ApiResponse_Update_PropertyManager>
) => {
  const { session } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ApiRequest_Update_PropertyManager) =>
      handleApiCall<ApiResponse_Update_PropertyManager, ApiRequest_Update_PropertyManager>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, { id: id.toString() }),
        sanitizeData(data)
      ),
    onSuccess: data => {
      enqueueSnackbar('Property manager updated successfully', { variant: 'success' });

      data.locations.forEach(location => {
        queryClient.invalidateQueries({
          queryKey: GET_ALL_QUERY_KEY({
            location_id: location.location_id,
          }),
          refetchType: 'all',
        });
      });

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to update property manager', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
