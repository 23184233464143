import { TextField } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

interface Props<TFormValues extends FieldValues> {
  name: Path<TFormValues>;
  label: string;
  control: Control<TFormValues>;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

const FormDatePicker = <TFormValues extends FieldValues>({
  name,
  label,
  control,
  error,
  helperText,
  disabled = false,
}: Props<TFormValues>): JSX.Element => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          type="date"
          label={label}
          value={field.value ? field.value.split('T')[0] : ''}
          error={error}
          helperText={helperText}
          disabled={disabled}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );

export default FormDatePicker;
