import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

import FormField, { FormFieldProps, getControllerProps, getFormFieldProps } from './FormField';

interface Props extends FormFieldProps {
  placeholder?: string;
  textFieldProps?: TextFieldProps;
  multiline?: boolean;
  rows?: number;
}

const FormTextField = forwardRef<HTMLInputElement, Props>(
  (
    { color = 'secondary', placeholder = 'Enter value', textFieldProps, multiline, rows, ...props },
    ref
  ) => (
    <FormField {...getFormFieldProps(props)} ref={ref}>
      <Controller
        {...getControllerProps(props)}
        render={({ field }) => (
          <TextField
            {...textFieldProps}
            {...field}
            disabled={props.disabled}
            color={color}
            placeholder={placeholder}
            size={props.size}
            multiline={multiline}
            rows={rows}
          />
        )}
      />
    </FormField>
  )
);

export default FormTextField;
