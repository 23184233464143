import { Button, Card, Divider, Stack, Typography } from '@mui/material';

import PricingPlanFeatureItem, { Props as FeatureItemProps } from './PricingPlanFeatureItem';

interface Props {
  name: string;
  title: string;
  titleDescription: string;
  price: string;
  priceDescription: string;
  buttonLabel: string;
  href: string;
  features: FeatureItemProps[];
  isPrimary?: boolean;
}

const PricingPlan: React.FC<Props> = ({
  name,
  title,
  titleDescription,
  price,
  priceDescription,
  buttonLabel,
  href,
  features,
  isPrimary = false,
}) => (
  <Card
    sx={{
      transition: 'transform 300ms ease',
      '&:hover': {
        transform: 'translateY(-0.5rem)',
      },
      border: '2px solid',
      borderColor: isPrimary ? 'primary.main' : 'transparent',
    }}
  >
    <Stack spacing={4.5} p={4}>
      <Stack spacing={3}>
        <Typography variant="overline">{name}</Typography>

        <Stack spacing={0.5}>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="body2">{titleDescription}</Typography>
        </Stack>

        <Stack spacing={0.5}>
          <Typography variant="h4">{price}</Typography>
          <Typography variant="body2">{priceDescription}</Typography>
        </Stack>

        <Button variant="contained" color="primary" href={href}>
          {buttonLabel}
        </Button>
      </Stack>

      <Divider />

      <Stack spacing={3}>
        <Typography variant="subtitle3">
          <strong>What's included:</strong>
        </Typography>

        <Stack spacing={1.5}>
          {features.map(feature => (
            <PricingPlanFeatureItem key={feature.text} {...feature} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  </Card>
);

export default PricingPlan;
