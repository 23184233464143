import { Grid2 } from '@mui/material';
import React from 'react';

import Banner from './common/Banner';

const HaveQuestionsSection: React.FC = () => (
  <Grid2 size={12} mb={6}>
    <Banner
      image={<img src="/assets/images/avatar-group.png" alt="People" style={{ width: 120 }} />}
      title="Still have questions?"
      description="Can’t find the answer you’re looking for? Please chat to our friendly team."
      buttonLabel="Get in touch"
      href="mailto:hello@terr.ai"
    />
  </Grid2>
);

export default HaveQuestionsSection;
