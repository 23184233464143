import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { DateTime, Id, Int, Uuid } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_GetAll_Conversation = {
  location_id?: Id;
};

export type ApiResponse_GetAll_Conversation = Array<{
  id: Uuid;
  title: string;
  created_at: DateTime;
  updated_at: DateTime;
  participant_count: Int;
  last_message?: {
    id: Id;
    content: string;
    sender_name: string;
    created_at: DateTime;
  };
}>;

const METHOD = 'GET';
const ENDPOINT = 'conversation/all';
export const QUERY_KEY = (params: ApiRequest_GetAll_Conversation) =>
  getQueryKey(METHOD, ENDPOINT, params);

export const useGetAllConversations = (params: ApiRequest_GetAll_Conversation, enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: !!params.location_id && enabled && !!session?.access_token,
    queryKey: QUERY_KEY(params),
    queryFn: async () => {
      try {
        const data = await handleApiCall<
          ApiResponse_GetAll_Conversation,
          ApiRequest_GetAll_Conversation
        >(session, METHOD, getResolvedEndpoint(ENDPOINT, {}), params);

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch conversations.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
