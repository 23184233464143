import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { BuildingModel } from '@/api/models/building';
import { UnitModel } from '@/api/models/unit';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_GetOne_Unit = UnitModel & {
  building: Pick<BuildingModel, 'address_text' | 'name'>;
};

const METHOD = 'GET';
const ENDPOINT = 'unit/:id';
export const QUERY_KEY = (id: UnitModel['id']) => getQueryKey(METHOD, ENDPOINT, { id });

export const useGetOneUnit = (id: UnitModel['id'], enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY(id),
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetOne_Unit>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {
            id: id.toString(),
          })
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch unit.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
