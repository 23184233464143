import { IonSpinner } from '@ionic/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import 'stream-chat-react/dist/css/v2/index.css';

import { environment } from '@env';

import { Address } from '@/types/address';
import { BaseProps } from '@/types/props';
import { UserProperty } from '@/types/userProperty';

import { useAuth } from '@/context/AuthProvider';
import { CenteredContent } from '@/pages/common/layouts.styles';
import { addressToText } from '@/utils/address';

import AddressSelect from '@/components/@ionic/address/AddressSelect';
import LLMChatBubble from '@/components/@ionic/stream-chat/LLMChatBubble';

import MainChat from './MainChat';
import S from './MainChatFlow.styles';

interface Props extends BaseProps {
  shouldCreateAnonymousUser?: boolean;
}

const MainChatFlow: React.FC<Props> = ({ shouldCreateAnonymousUser, ...baseProps }) => {
  const [address, setAddress] = useState<Address | null>(null);

  const { user, session, signInAnonymously } = useAuth();

  const fetchUserPropertyQuery = useQuery({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'user-property', session?.access_token],
    gcTime: 0,
    queryFn: async () => {
      const response = await axios.get<UserProperty>(`${environment.api}/user-property`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      setAddress(response.data.Property);

      return response.data;
    },
  });

  const saveUserPropertyMutation = useMutation({
    mutationKey: ['saveAddress', session?.access_token],
    mutationFn: async ({
      addressText,
      accessToken,
    }: {
      addressText: string;
      accessToken?: string;
    }) => {
      const response = await axios.post<UserProperty>(
        `${environment.api}/user-property/byaddress?address=${addressText}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken ?? session?.access_token}`,
          },
        }
      );

      setAddress(response.data.Property);

      return response.data;
    },
  });

  const onAddressSelect = useCallback(
    async (_address: Address) => {
      let accessToken = session?.access_token;

      if (shouldCreateAnonymousUser && !user) {
        const { data } = await signInAnonymously();

        accessToken = data.session?.access_token;
      }

      await saveUserPropertyMutation.mutate({
        addressText: addressToText(_address),
        accessToken,
      });
    },
    [
      shouldCreateAnonymousUser,
      signInAnonymously,
      session?.access_token,
      user,
      saveUserPropertyMutation,
    ]
  );

  if (!user && !shouldCreateAnonymousUser) {
    return (
      <CenteredContent>
        <IonSpinner name="circular" />
      </CenteredContent>
    );
  }

  // Fetching User Property
  if (fetchUserPropertyQuery.isFetching) {
    return (
      <CenteredContent>
        <IonSpinner name="circular" />
      </CenteredContent>
    );
  }

  // Saving User Property
  if (!address && saveUserPropertyMutation.isPending) {
    return (
      <CenteredContent>
        <IonSpinner name="circular" />
      </CenteredContent>
    );
  }

  if (!address) {
    return (
      <S.Container>
        <h1 className="mt-4rem">
          Hi there, I'm Terri!
          <br />
          Your go-to guide for everything in your community.
          <br />
          Ask me a question. I'm here to help!
        </h1>

        <LLMChatBubble isFullWidth>
          For personalized information, enter your address. Provide a full address, including
          building and apartment numbers.
          <br />
          Your data stays private—we never share it. Enjoy exploring!
        </LLMChatBubble>

        <AddressSelect onAddressSelect={onAddressSelect} />
      </S.Container>
    );
  }

  return <MainChat address={address} {...baseProps} />;
};

export default MainChatFlow;
