import { Stack, Typography, Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import { formatFullDate } from '@/utils/date';

import EmailBulkSendModal from '@/components/email/EmailBulkSendModal';

import DashboardSection from './DashboardSection';
import DashboardSectionLoading from './DashboardSectionLoading';

export interface TodoItem {
  id: number;
  title: string;
  description: string;
  dueDate: string;
  isCompleted?: boolean;
  onAction?: () => void;
  buildingId?: number;
  tenantId?: number;
}

interface Props {
  children?: React.ReactNode;
  items?: TodoItem[];
  isLoading?: boolean;
  title?: string;
}

const DashboardTodoList: React.FC<Props> = ({
  children,
  title = 'Todo List',
  items,
  isLoading,
}) => {
  const [modalItem, setModalItem] = useState<TodoItem | null>(null);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const handleSendRenewalEmail = (item: TodoItem) => {
    if (!item.buildingId) {
      enqueueSnackbar('Building ID is required to send renewal email', { variant: 'error' });
      return;
    }
    setModalItem(item);
    setIsEmailModalOpen(true);
  };

  return (
    <>
      <DashboardSection
        title={title}
        subtitle={
          !isLoading && (
            <Typography variant="dataGridTitle" color="primary" sx={{ whiteSpace: 'nowrap' }}>
              {items?.length ?? 0}{' '}
              <Typography variant="body3" component="span">
                Urgent
              </Typography>
            </Typography>
          )
        }
      >
        {children}
        {isLoading ? (
          <DashboardSectionLoading />
        ) : (
          <Stack spacing={2.5}>
            {items?.length ? (
              items.map(item => (
                <Stack key={item.id} spacing={0.5}>
                  <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant="subtitle3">{item.title}</Typography>
                  </Stack>

                  <Typography variant="body2" color="textSecondary">
                    {item.description}
                  </Typography>

                  <Typography variant="body2" color="textPrimary">
                    Due: {formatFullDate(new Date(item.dueDate))}
                  </Typography>

                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleSendRenewalEmail(item)}
                    sx={{ alignSelf: 'flex-start', mt: 1 }}
                  >
                    Send Renewal Email
                  </Button>
                </Stack>
              ))
            ) : (
              <Typography variant="body2" align="center" color="textSecondary">
                No Items.
              </Typography>
            )}
          </Stack>
        )}
      </DashboardSection>

      {isEmailModalOpen && modalItem?.buildingId && (
        <EmailBulkSendModal
          buildingId={modalItem.buildingId}
          onClose={() => {
            setIsEmailModalOpen(false);
            setModalItem(null);
          }}
          templateSlug="lease-renewal"
          preSelectedTenantIds={modalItem.tenantId ? [Number(modalItem.tenantId)] : []}
        />
      )}
    </>
  );
};

export default DashboardTodoList;
