import { Box } from '@mui/material';
import React from 'react';

interface Props {
  children?: React.ReactNode;
  index: number;
  value: number;
  dir?: string;
}

const AccountTabPanel: React.FC<Props> = ({ children, value, index, dir }) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`account-tabpanel-${index}`}
    aria-labelledby={`account-tab-${index}`}
    dir={dir}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </Box>
);

export default AccountTabPanel;

// A11y props for tabs
export const a11yProps = (index: number) => ({
  id: `account-tab-${index}`,
  'aria-controls': `account-tabpanel-${index}`,
});
