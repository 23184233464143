import { IonIcon, IonSkeletonText } from '@ionic/react';
import { homeOutline } from 'ionicons/icons';
import React, { useMemo } from 'react';

import { Property } from '@/types/property';
import { BaseProps } from '@/types/props';

import { capitalizeFirstLetter } from '@/utils/text';

import MapboxStreetviewMap from '@/components/mapbox/MapboxStreetviewMap';

import PropertyCard from './PropertyCard';
import S from './PropertyCardAddress.styles';

interface Props extends BaseProps {
  property?: Property;
  isLoading?: boolean;
  isShowingImage?: boolean;
}

const PropertyCardAddress: React.FC<Props> = ({
  property,
  isLoading,
  isShowingImage,
  ...baseProps
}) => {
  const addressLine1 = useMemo(() => {
    if (!property) return null;

    const street = capitalizeFirstLetter(property.street);
    const city = capitalizeFirstLetter(property.city);

    return [street, city].filter(v => !!v).join(', ');
  }, [property]);

  const addressLine2 = useMemo(() => {
    if (!property) return null;

    const zipcode = [property.state, property.zipcode].filter(v => !!v).join(' ');

    return [[zipcode, property.country].filter(v => !!v).join(', ')];
  }, [property]);

  return (
    <PropertyCard title={<IonIcon icon={homeOutline} />} {...baseProps}>
      {isLoading ? (
        <>
          <h3>
            <IonSkeletonText animated />
            <IonSkeletonText animated />
          </h3>

          {isShowingImage && (
            <S.ImageSizingWrapper>
              <S.ImageContainer>
                <IonSkeletonText animated style={{ height: '100%' }} />
              </S.ImageContainer>
            </S.ImageSizingWrapper>
          )}
        </>
      ) : (
        <>
          <h3>
            {addressLine1}
            {!!addressLine1 && !!addressLine2 && <br />}
            {addressLine2}
          </h3>

          {isShowingImage && (
            <S.ImageSizingWrapper>
              <S.ImageContainer>
                {!!property?.latitude && !!property?.longitude && (
                  <MapboxStreetviewMap houseCoordinates={[property.latitude, property.longitude]} />
                )}
              </S.ImageContainer>
            </S.ImageSizingWrapper>
          )}
        </>
      )}
    </PropertyCard>
  );
};

export default PropertyCardAddress;
