import { FormHelperText, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { ApiRequest_UpdatePhone_User, useUpdatePhone } from '@/api/endpoints/user/useUpdatePhone';
import { useAuth } from '@/context/AuthProvider';

import FormTextField from '@/components/form/FormTextField';

const PhoneForm: React.FC = () => {
  const { user } = useAuth();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ApiRequest_UpdatePhone_User>({
    defaultValues: {
      phone: user?.phone ?? '',
    },
  });

  const [formError, setFormError] = useState<Error | null>(null);

  const updatePhoneMutation = useUpdatePhone({
    onError: error => {
      setFormError(error);
    },
  });

  const onSubmit = useCallback(
    (data: ApiRequest_UpdatePhone_User) => {
      setFormError(null);
      return updatePhoneMutation.mutateAsync(data);
    },
    [updatePhoneMutation]
  );

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <FormTextField
        label="Your Phone Number"
        placeholder="Enter your phone number"
        size="small"
        error={!!errors.phone}
        helperText={errors.phone?.message?.toString()}
        control={control}
        required
        {...register('phone', {
          required: 'Phone Number is required',
        })}
      />

      {!!formError && <FormHelperText error>{formError.message}</FormHelperText>}

      <LoadingButton
        loading={isSubmitting}
        fullWidth
        variant="contained"
        type="submit"
        size="small"
      >
        Update Phone Number
      </LoadingButton>
    </Stack>
  );
};

export default PhoneForm;
