import { Box, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { VaultDocument } from '@/types/vault';

import { useGetTaggingOptions } from '@/api/endpoints/vault/useGetTaggingOptions';
import { useUpdateVaultDocumentTags } from '@/api/endpoints/vault/useUpdateVaultDocumentTags';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

import FormAutocomplete from '@/components/form/FormAutocomplete';
import ModalWithForm from '@/components/modals/ModalWithForm';

interface Option {
  label: string;
  value: number;
}

interface FormValues {
  property_location_ids: Option[];
  building_ids: Option[];
  unit_ids: Option[];
  tenant_ids: Option[];
  property_manager_ids: Option[];
}

interface Props {
  document: VaultDocument;
  onClose: () => void;
  onSuccess: () => void;
}

const VaultDocumentTagModal: React.FC<Props> = ({ document, onClose, onSuccess }) => {
  const { selectedPropertyLocation, isSingleFamilyLocation } = usePropertyManager();

  // Create initial values with just the IDs for the form
  const initialValues: FormValues = useMemo(
    () => ({
      property_location_ids: selectedPropertyLocation
        ? [
          {
            label: selectedPropertyLocation.name,
            value: Number(selectedPropertyLocation.id),
          },
        ]
        : [],
      building_ids:
        document.buildings?.map(building => ({
          label: building.name || building.address_text,
          value: Number(building.id),
        })) || [],
      unit_ids:
        document.units?.map(unit => ({
          label: unit.name,
          value: Number(unit.id),
        })) || [],
      tenant_ids:
        document.tenants?.map(tenant => ({
          label: tenant.full_name || 'Unnamed Tenant',
          value: Number(tenant.id),
        })) || [],
      property_manager_ids:
        document.property_managers?.map(manager => ({
          label: manager.limitedUser.full_name || 'Unnamed Manager',
          value: Number(manager.id),
        })) || [],
    }),
    [document, selectedPropertyLocation]
  );

  const form = useForm<FormValues>({
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = form;

  // Watch for changes in form values
  const watchedValues = watch();

  // Update form values when document changes
  React.useEffect(() => {
    if (document) {
      Object.keys(initialValues).forEach(key => {
        const currentValues = watchedValues[key as keyof FormValues] || [];
        const initialValuesForKey = initialValues[key as keyof FormValues];

        // Merge current values with initial values, removing duplicates
        const mergedValues = [...currentValues, ...initialValuesForKey].filter(
          (value, index, self) => index === self.findIndex(v => v.value === value.value)
        );

        setValue(key as keyof FormValues, mergedValues);
      });
    }
  }, [document, initialValues, setValue, watchedValues]);

  // Clear building selection when switching to single family home
  React.useEffect(() => {
    if (isSingleFamilyLocation) {
      setValue('building_ids', []);
    }
  }, [isSingleFamilyLocation, setValue]);

  // Fetch tagging options with initial values
  const { data: taggingOptions, isLoading } = useGetTaggingOptions({
    property_location_ids: initialValues.property_location_ids.map(item => item.value),
    building_ids: initialValues.building_ids.map(item => item.value),
    unit_ids: initialValues.unit_ids.map(item => item.value),
  });

  const updateTagsMutation = useUpdateVaultDocumentTags();

  // Convert data to options for select with number values
  const propertyLocationOptions = useMemo(() => {
    if (!selectedPropertyLocation) return [];

    return [
      {
        label: selectedPropertyLocation.name,
        value: Number(selectedPropertyLocation.id),
      },
    ];
  }, [selectedPropertyLocation]);

  const buildingOptions = useMemo(() => {
    const existingOptions =
      document.buildings?.map(building => ({
        label: building.name || building.address_text,
        value: Number(building.id),
      })) || [];

    const newOptions =
      taggingOptions?.buildings.map(building => ({
        label: building.name || building.address_text,
        value: Number(building.id),
      })) || [];

    return [...existingOptions, ...newOptions].filter(
      (option, index, self) => index === self.findIndex(o => o.value === option.value)
    );
  }, [taggingOptions?.buildings, document.buildings]);

  const unitOptions = useMemo(() => {
    const existingOptions =
      document.units?.map(unit => ({
        label: unit.name,
        value: Number(unit.id),
      })) || [];

    const newOptions =
      taggingOptions?.units.map(unit => ({
        label: unit.name,
        value: Number(unit.id),
      })) || [];

    return [...existingOptions, ...newOptions].filter(
      (option, index, self) => index === self.findIndex(o => o.value === option.value)
    );
  }, [taggingOptions?.units, document.units]);

  const tenantOptions = useMemo(() => {
    const existingOptions =
      document.tenants?.map(tenant => ({
        label: tenant.full_name || 'Unnamed Tenant',
        value: Number(tenant.id),
      })) || [];

    const newOptions =
      taggingOptions?.tenants.map(tenant => ({
        label: tenant.full_name || 'Unnamed Tenant',
        value: Number(tenant.id),
      })) || [];

    return [...existingOptions, ...newOptions].filter(
      (option, index, self) => index === self.findIndex(o => o.value === option.value)
    );
  }, [taggingOptions?.tenants, document.tenants]);

  const propertyManagerOptions = useMemo(() => {
    const existingOptions =
      document.property_managers?.map(manager => ({
        label: manager.limitedUser.full_name || 'Unnamed Manager',
        value: Number(manager.id),
      })) || [];

    const newOptions =
      taggingOptions?.propertyManagers.map(manager => ({
        label: manager.limitedUser.full_name || 'Unnamed Manager',
        value: Number(manager.id),
      })) || [];

    return [...existingOptions, ...newOptions].filter(
      (option, index, self) => index === self.findIndex(o => o.value === option.value)
    );
  }, [taggingOptions?.propertyManagers, document.property_managers]);

  const handleSubmit = useCallback(
    async (data: FormValues) => {
      try {
        // Get current form values
        const currentValues = {
          property_location_ids: data.property_location_ids || [],
          building_ids: data.building_ids || [],
          unit_ids: data.unit_ids || [],
          tenant_ids: data.tenant_ids || [],
          property_manager_ids: data.property_manager_ids || [],
        };

        // Create payload with merged and deduplicated values
        const payload = {
          documentId: document.id,
          tags: {
            property_location_ids: currentValues.property_location_ids
              .map(item => item.value)
              .filter((value, index, self) => self.indexOf(value) === index),
            building_ids: currentValues.building_ids
              .map(item => item.value)
              .filter((value, index, self) => self.indexOf(value) === index),
            unit_ids: currentValues.unit_ids
              .map(item => item.value)
              .filter((value, index, self) => self.indexOf(value) === index),
            tenant_ids: currentValues.tenant_ids
              .map(item => item.value)
              .filter((value, index, self) => self.indexOf(value) === index),
            property_manager_ids: currentValues.property_manager_ids
              .map(item => item.value)
              .filter((value, index, self) => self.indexOf(value) === index),
          },
        };

        await updateTagsMutation.mutateAsync(payload);
        onSuccess();
      } catch (error) {
        enqueueSnackbar('Failed to update tags', { variant: 'error' });
      }
    },
    [document.id, onSuccess, updateTagsMutation]
  );

  return (
    <ModalWithForm
      form={form}
      maxWidth="md"
      title={`Tag Document: ${document.original_name}`}
      buttonLabel="Save Tags"
      onClose={onClose}
      onSubmit={handleSubmit}
      isDataLoading={isLoading || updateTagsMutation.isPending}
    >
      <Box>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {!isSingleFamilyLocation
            ? 'Tag this document to associate it with properties, buildings, units, tenants, and property partners.'
            : 'Tag this document to associate it with units,tenants and property partners.'}
        </Typography>

        <Stack spacing={3} sx={{ mt: 2 }}>
          {!isSingleFamilyLocation && (
            <FormAutocomplete
              name="property_location_ids"
              label="Property Location"
              options={propertyLocationOptions}
              control={control}
              disabled
              filterSelectedOptions
              placeholder="Current property location"
              error={!!errors.property_location_ids}
              helperText={errors.property_location_ids?.message}
              size="small"
            />
          )}

          {!isSingleFamilyLocation && (
            <FormAutocomplete
              name="building_ids"
              label="Buildings"
              options={buildingOptions}
              control={control}
              multiple
              filterSelectedOptions
              placeholder="Select buildings"
              error={!!errors.building_ids}
              helperText={errors.building_ids?.message}
              size="small"
            />
          )}

          <FormAutocomplete
            name="unit_ids"
            label="Units"
            options={unitOptions}
            control={control}
            multiple
            filterSelectedOptions
            placeholder="Select units"
            error={!!errors.unit_ids}
            helperText={errors.unit_ids?.message}
            size="small"
          />

          <FormAutocomplete
            name="tenant_ids"
            label="Tenants"
            options={tenantOptions}
            control={control}
            multiple
            filterSelectedOptions
            placeholder="Select tenants"
            error={!!errors.tenant_ids}
            helperText={errors.tenant_ids?.message}
            size="small"
          />

          <FormAutocomplete
            name="property_manager_ids"
            label="Property Staff"
            options={propertyManagerOptions}
            control={control}
            multiple
            filterSelectedOptions
            placeholder="Select"
            error={!!errors.property_manager_ids}
            helperText={errors.property_manager_ids?.message}
            size="small"
          />
        </Stack>
      </Box>
    </ModalWithForm>
  );
};

export default VaultDocumentTagModal;
