import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { DateTime } from '@/api/utils/sql';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_GetLeaseDetails_Resident = {
  tenant_name: string;
  start_date: DateTime;
  end_date: DateTime;
  rent: string;
  duration?: string | null;
};

const METHOD = 'GET';
const ENDPOINT = 'resident/lease-details';
export const QUERY_KEY = () => getQueryKey(METHOD, ENDPOINT, {});

export const useGetLeaseDetails = (enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY(),
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetLeaseDetails_Resident>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {})
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch lease details.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
