import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Skeleton,
  Stack,
} from '@mui/material';
import { PropsWithChildren } from 'react';

import IconButton from '@/@mantis/components/@extended/IconButton';
import { XCloseIcon } from '@/assets/icons/XCloseIcon';
import { getSkeletonWidth } from '@/utils/skeleton';

import LoadingState from '../LoadingState';

type Props = PropsWithChildren<{
  fullScreen?: boolean;
  isDataLoading?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  title?: string;
  onClose: () => void;
  Actions?: React.ReactNode;
}>;

const ModalWithPreview: React.FC<Props> = ({
  children,
  fullScreen = true,
  isDataLoading = false,
  maxWidth,
  title,
  onClose,
  Actions,
}) => (
  <Dialog open onClose={onClose} fullWidth maxWidth={maxWidth} fullScreen={fullScreen}>
    <DialogTitle>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {title ?? <Skeleton width={getSkeletonWidth(230, 300)} />}

        <IconButton onClick={onClose} aria-label="close" color="secondary">
          <XCloseIcon />
        </IconButton>
      </Stack>
    </DialogTitle>

    <DialogContent sx={{ position: 'relative', overflow: isDataLoading ? 'hidden' : 'auto' }}>
      <Stack spacing={3} height="100%">
        {children}
      </Stack>

      {isDataLoading && (
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'grey.100',
            py: 3,
          }}
        >
          <LoadingState />
        </Stack>
      )}
    </DialogContent>

    {!!Actions && <DialogActions>{Actions}</DialogActions>}
  </Dialog>
);

export default ModalWithPreview;
