import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React from 'react';

import { environment } from '@env';

import { ContextSource } from '@/types/knowledge';

import { useAuth } from '@/context/AuthProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

import BulletPointsList from '../common/BulletPointsList';

import DashboardSection from './DashboardSection';
import DashboardSectionLoading from './DashboardSectionLoading';

const DashboardIssueSummary: React.FC = () => {
  const { session } = useAuth();
  const { selectedPropertyLocation } = usePropertyManager();

  const fetchIssueSummaryQuery = useQuery<string>({
    enabled: !!session?.access_token && !!selectedPropertyLocation?.id,
    queryKey: ['GET', 'knowledge', 'issues', 'summary', selectedPropertyLocation?.id],
    queryFn: async () => {
      try {
        // Set date range for last 7 days
        const endDate = new Date().toISOString();
        const startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString();

        const response = await axios.post<string>(
          `${environment.api}/knowledge/${selectedPropertyLocation?.id}/topic`,
          {
            context_source: [ContextSource.EMAIL, ContextSource.SMS],
            topic: 'issues',
            start_date: startDate,
            end_date: endDate,
          },
          {
            headers: {
              Authorization: `Bearer ${session?.access_token}`,
            },
          }
        );

        return response.data;
      } catch (error) {
        enqueueSnackbar('Failed to fetch issue summary.', { variant: 'error' });
        return '';
      }
    },
  });

  return (
    <DashboardSection title="Issues Summary (Last 7 Days)">
      {fetchIssueSummaryQuery.isLoading ? (
        <DashboardSectionLoading />
      ) : (
        <BulletPointsList text={fetchIssueSummaryQuery?.data ?? ''} />
      )}
    </DashboardSection>
  );
};

export default DashboardIssueSummary;
