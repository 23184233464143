import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { UserModel } from '@/api/models/user';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_UpdateFullName_User = UserModel;

export type ApiRequest_UpdateFullName_User = {
  full_name: string;
};

const METHOD = 'PUT';
const ENDPOINT = 'user/full_name';

export const useUpdateFullName = (stateFns?: MutationStateFns<ApiResponse_UpdateFullName_User>) => {
  const { session, refreshUser } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_UpdateFullName_User) =>
      handleApiCall<ApiResponse_UpdateFullName_User, ApiRequest_UpdateFullName_User>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: async data => {
      enqueueSnackbar('Full name updated successfully', { variant: 'success' });
      await refreshUser();

      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to update full name', { variant: 'error' });

      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
